import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_SELLER_ONBOARDING } from '../actions/sellerOnboardingActions';

const DEFAULT_STATE = null;

export default function sellerOnboardingReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_SELLER_ONBOARDING:
      return payload || DEFAULT_STATE;
    default:
      return state;
  }
}
