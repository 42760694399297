import { notePropertyLabelsMap, transformNotePropertyValue } from '@paperstac/common/lib/services/notesHelper';
import { UPDATE_NOTE } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import Input from '@paperstac/ui/lib/form/Input';
import InternationalPhoneInput from '@paperstac/ui/lib/form/InternationalPhoneInput';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import get from 'lodash/get';
import React from 'react';
import EditButton from './EditButton';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';

const NoteScreenUnderlyingLienItem = React.memo(({ wrap, ...props }) => {
  const { note, stacked } = React.useContext(ManageListingOverlayContext);
  const [isEditing, setEditing] = React.useState(false);
  const [isDeleting, setDeleting] = React.useState(false);
  const showEdit = React.useCallback(() => setEditing(true), []);
  const hideEdit = React.useCallback(() => setEditing(false), []);

  const onDelete = React.useCallback(() => setDeleting(true), []);

  React.useEffect(() => {
    if (!isDeleting) return;
    const deletedWraps = Object.fromEntries(Object.entries(note.wraps).filter(([key, value]) => key !== wrap.id));
    serverDispatch({
      action: UPDATE_NOTE,
      payload: {
        id: note.id,
        accountId: note.accountId,
        wraps: deletedWraps,
      },
    }).catch((error) => {
      alert(error.message);
      setDeleting(false);
    });
  }, [isDeleting, wrap.id, note.accountId, note.id, note.wraps]);

  if (isDeleting) return null;

  if (isEditing)
    return (
      <Box {...props} py={4}>
        <Formik
          initialValues={{
            lenderEmail: wrap.lenderEmail,
            lenderPhone: wrap.lenderPhone,
            lenderName: wrap.lenderName,
          }}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            serverDispatch({
              action: UPDATE_NOTE,
              payload: {
                id: note.id,
                accountId: note.accountId,
                wraps: {
                  ...note.wraps,
                  [wrap.id]: {
                    id: wrap.id,
                    ...values,
                  },
                },
              },
            })
              .then(hideEdit)
              .catch((error) => {
                setStatus({ errorMessage: error.message });
                setSubmitting(false);
              });
          }}
          children={(formik) => (
            <Form>
              <Flex flexDirection={stacked ? 'column' : 'row'}>
                <DescriptionGridCell term={notePropertyLabelsMap['lenderName']} as="label" sx={{}}>
                  <Field name="lenderName" component={Input} />
                  <ErrorMessage name="lenderName" component={ErrorText} />
                </DescriptionGridCell>
              </Flex>
              <Flex flexDirection={stacked ? 'column' : 'row'}>
                <DescriptionGridCell
                  term={notePropertyLabelsMap['lenderEmail']}
                  as="label"
                  sx={{ borderRight: stacked ? 'none' : 'default' }}
                >
                  <Field name="lenderEmail" type="email" component={Input} />
                  <ErrorMessage name="lenderEmail" component={ErrorText} />
                </DescriptionGridCell>
                <DescriptionGridCell term={notePropertyLabelsMap['lenderPhoneNumber']} as="label" sx={{}}>
                  <Field name="lenderPhone" component={InternationalPhoneInput} />
                  <ErrorMessage name="lenderPhone" component={ErrorText} />
                </DescriptionGridCell>
              </Flex>
              <DescriptionGridCell sx={{ borderBottom: 'none' }}>
                {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
                <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>
                  Update Wrap
                </Button>
                <Button variant="default" onClick={hideEdit}>
                  Cancel
                </Button>
                <Box mt={3}>
                  <LinkButton onClick={onDelete} sx={{ color: 'red', fontSize: 1 }}>
                    Delete Wrap
                  </LinkButton>
                </Box>
              </DescriptionGridCell>
            </Form>
          )}
        />
      </Box>
    );

  return (
    <Flex alignItems="center" {...props}>
      <Box flex={'1 1 0'} mr={stacked ? 3 : 5}>
        <Flex key={wrap} justifyContent="space-between" mt={1} fontSize={stacked ? 1 : 2}>
          <Box>{notePropertyLabelsMap['lenderName']}:</Box>
          <Box fontWeight="bold">{transformNotePropertyValue('lenderName', wrap['lenderName'])}</Box>
        </Flex>
      </Box>
      <Box>
        <EditButton onClick={showEdit} />
      </Box>
    </Flex>
  );
});

NoteScreenUnderlyingLienItem.propTypes = {};

NoteScreenUnderlyingLienItem.defaultProps = {};

NoteScreenUnderlyingLienItem.displayName = 'NoteScreenUnderlyingLienItem';

export default NoteScreenUnderlyingLienItem;
