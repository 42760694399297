import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { CREATE_ACCOUNT, UPDATE_ACCOUNT } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import React from 'react';
import CreateAccountForm from './CreateAccountForm';
import TitleSubtext from './TitleSubtext';
import ViewTitle from './ViewTitle';

const CreateAccountView = React.memo((props) => {
  const { identity } = React.useContext(IdentityContext);
  const { currentAccount, currentAccountId } = React.useContext(AccountsContext);

  if (!identity) return null;

  return (
    <>
      <ViewTitle>Create Public Profile</ViewTitle>
      <TitleSubtext>
        Setup your first public account. This is how other users on Paperstac will see you or your company. You can have
        multiple accounts.
      </TitleSubtext>
      <CreateAccountForm
        identityId={identity.id}
        initialValues={{
          displayName: currentAccount
            ? currentAccount.displayName || identity.isValid
              ? identity.displayName
              : ''
            : identity.isValid
              ? identity.displayName
              : '',
          avatar: currentAccount ? currentAccount.avatar || '' : '',
          isBroker: '',
        }}
        onSubmit={(payload, { setSubmitting, setStatus }) => {
          setSubmitting(true);
          payload.isBroker = typeof payload.isBroker === 'string' ? payload.isBroker === 'true' : payload.isBroker;
          if (!!currentAccountId) payload.id = currentAccountId;
          serverDispatch({ action: !currentAccountId ? CREATE_ACCOUNT : UPDATE_ACCOUNT, payload }).catch((error) => {
            setSubmitting(false);
            setStatus({ errorMessage: error.message });
          });
        }}
        submitText="Complete Registration"
      />
    </>
  );
});

CreateAccountView.propTypes = {};

CreateAccountView.defaultProps = {};

CreateAccountView.displayName = 'CreateAccountView';

export default CreateAccountView;
