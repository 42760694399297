import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import DefaultLayout from './DefaultLayout';

const BlogPostLayout = React.memo(({ metadata, children }) => (
  <DefaultLayout title={metadata.metaTitle}>
    <Helmet>
      <title>{metadata.metaTitle}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="Description" content={metadata.metaDescription} />
      <meta itemProp="description" content={metadata.metaDescription} />

      {/* Facebook Meta Tags */}
      <meta property="og:title" content={metadata.postTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={metadata.url} />
      <meta property="og:image" content={metadata.socialImageUrl} />
      <meta property="og:description" content={metadata.metaDescription} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metadata.postTitle} />
      <meta name="twitter:description" content={metadata.metaDescription} />
      <meta name="twitter:image" content={metadata.socialImageUrl} />
    </Helmet>
    {children}
  </DefaultLayout>
));

BlogPostLayout.propTypes = {
  metadata: PropTypes.object.isRequired,
};

BlogPostLayout.defaultProps = {};

BlogPostLayout.displayName = 'BlogPostLayout';

export default BlogPostLayout;
