import { UPDATE_NOTE } from '@paperstac/common/lib/serverDispatchActionTypes';
import { notePropertyLabelsMap, noteWrapsSchema } from '@paperstac/common/lib/services/notesHelper';
import dateid from '@paperstac/common/lib/services/dateid';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import Input from '@paperstac/ui/lib/form/Input';
import InternationalPhoneInput from '@paperstac/ui/lib/form/InternationalPhoneInput';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const NoteScreenEditUnderlyingLiens = React.memo(() => {
  const { note, showOverview: showListing, stacked } = React.useContext(ManageListingOverlayContext);
  const { showOverview } = React.useContext(NoteScreenContext);

  return (
    <Formik
      initialValues={{
        lenderEmail: '',
        lenderPhone: '',
        lenderName: '',
      }}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        const wrapId = dateid();
        serverDispatch({
          action: UPDATE_NOTE,
          payload: {
            id: note.id,
            accountId: note.accountId,
            wraps: {
              ...note.wraps,
              [wrapId]: {
                id: wrapId,
                ...values,
              },
            },
          },
        })
          .then(showOverview)
          .catch((error) => {
            setStatus({ errorMessage: error.message });
            setSubmitting(false);
          });
      }}
      validationSchema={noteWrapsSchema}
      children={(formik) => (
        <Form>
          <DescriptionGrid
            sx={{
              bg: 'gray.1',
              border: 'none',
              borderTop: 'default',
              borderBottom: 'default',
              borderRadius: 0,
            }}
          >
            <DescriptionGridCell bg="highlight">
              <Flex alignItems="center">
                <LinkButton onClick={showListing}>Listing</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                <LinkButton onClick={showOverview}>Note</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                Lienholder
              </Flex>
            </DescriptionGridCell>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['lenderName']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="lenderName" component={Input} />
                <ErrorMessage name="lenderName" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['lenderEmail']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="lenderEmail" type="email" component={Input} />
                <ErrorMessage name="lenderEmail" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell
                term={notePropertyLabelsMap['lenderPhoneNumber']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="lenderPhone" component={InternationalPhoneInput} />
                <ErrorMessage name="lenderPhone" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <DescriptionGridCell sx={{ borderBottom: 'none' }}>
              {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
              <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>
                Add Lien
              </Button>
              <Button variant="default" onClick={showOverview}>
                Cancel
              </Button>
            </DescriptionGridCell>
          </DescriptionGrid>
        </Form>
      )}
    />
  );
});

NoteScreenEditUnderlyingLiens.propTypes = {};

NoteScreenEditUnderlyingLiens.defaultProps = {};

NoteScreenEditUnderlyingLiens.displayName = 'NoteScreenEditUnderlyingLiens';

export default NoteScreenEditUnderlyingLiens;
