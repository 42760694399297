import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import Box from '@paperstac/ui/lib/Box';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import PageTitle from '@paperstac/ui/lib/PageTitle';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import AlgoliaSearch from '../../AlgoliaSearch';
import DefaultLayout from '../../layouts/DefaultLayout';
import CarouselSerp from './CarouselSerp';
import DefaultSerp from './DefaultSerp';
import Filters from './Filters';
import FiltersDrawer from './FiltersDrawer';
import ListingDetailsProvider from './ListingDetailsProvider';
import ListingsSearchProvider from './ListingsSearchProvider';
import SortByDrawer from './SortByDrawer';

const SlideInCssKeyframes = createGlobalStyle`
@keyframes slidein {
  from { transform: translateX(-100%); }
  to   { transform: translateX(0); }
}
`;

const ForSalePage = React.memo((props) => {
  const { isMobile, isDesktopOrLarger } = useBreakpoints();
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const openFilters = () => setFiltersOpen(true);
  const closeFilters = () => setFiltersOpen(false);
  const filtersShowing = filtersOpen && !isDesktopOrLarger;
  const [sortByOpen, setSortByOpen] = React.useState(false);
  const openSortBy = () => setSortByOpen(true);
  const closeSortBy = () => setSortByOpen(false);

  return (
    <DefaultLayout title="Mortgage Notes For Sale | Paperstac">
      <SlideInCssKeyframes />
      <Container>
        <PageTitle my={[40, 40, 40, 64]}>Mortgage Notes For Sale</PageTitle>
        <AlgoliaSearch indexName="listings">
          <ListingsSearchProvider>
            <ListingDetailsProvider>
              <FiltersDrawer onClose={closeFilters} isOpen={filtersShowing}>
                <Filters />
              </FiltersDrawer>
              {sortByOpen && <SortByDrawer onClose={closeSortBy} />}
              <Flex mb={6}>
                <Box flex="0 0 360px" mr={[6]} display={isDesktopOrLarger ? 'block' : 'none'}>
                  <Filters mt={-3} />
                </Box>
                <Box flex="1 1 0">
                  {isMobile ? (
                    <CarouselSerp onFilters={openFilters} onSort={openSortBy} />
                  ) : (
                    <DefaultSerp onFilters={openFilters} onSort={openSortBy} />
                  )}
                </Box>
              </Flex>
            </ListingDetailsProvider>
          </ListingsSearchProvider>
        </AlgoliaSearch>
      </Container>
    </DefaultLayout>
  );
});

ForSalePage.propTypes = {};

ForSalePage.defaultProps = {};

ForSalePage.displayName = 'ForSalePage';

export default ForSalePage;
