import React from 'react';
import LoadingMessage from '../../common/LoadingMessage';
import Section from './Section';

const Loading = React.memo((props) => (
  <Section>
    <LoadingMessage />
  </Section>
));

Loading.propTypes = {};

Loading.defaultProps = {};

Loading.displayName = 'Loading';

export default Loading;
