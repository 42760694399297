import React from 'react';
import CompleteView from './CompleteView';
import ExportView from './ExportView';
import IntroView from './IntroView';
import { MergeIdentitiesContext } from './MergeIdentitiesProvider';
import MergeView from './MergeView';

const EMPTY_DATA = {
  exportIdentityEmail: null,
  importIdentityEmail: null,
  token: null,
};

const MergeIdentitiesScreen = React.memo(() => {
  const [step, setStep] = React.useState(1);
  const { hideMergeIdentities } = React.useContext(MergeIdentitiesContext);
  const [data, setData] = React.useReducer((oldState, newState) => ({ ...oldState, ...newState }), EMPTY_DATA);
  const handleExport = React.useCallback((exportIdentityEmail, token) => {
    setData({ exportIdentityEmail, token });
    setStep(3);
  }, []);
  const handleMerge = React.useCallback((email) => {
    setData({ importIdentityEmail: email });
    setStep(4);
  }, []);
  const handleRestart = React.useCallback(() => {
    setData(EMPTY_DATA);
    setStep(1);
  }, []);

  const wizard = [
    <IntroView onContinue={() => setStep(2)} onCancel={hideMergeIdentities} />,
    <ExportView onContinue={handleExport} onCancel={hideMergeIdentities} />,
    <MergeView onContinue={handleMerge} onCancel={hideMergeIdentities} token={data.token} />,
    <CompleteView onComplete={hideMergeIdentities} onRestart={handleRestart} {...data} />,
  ];

  return wizard[step - 1] || null;
});

MergeIdentitiesScreen.propTypes = {};

MergeIdentitiesScreen.defaultProps = {};

MergeIdentitiesScreen.displayName = 'MergeIdentitiesScreen';

export default MergeIdentitiesScreen;
