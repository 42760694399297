import { centsToUsdString } from '@paperstac/helpers/lib/numberHelpers';
import { LISTING_NEGOTIATION_TYPE } from '@paperstac/firestore-collections/lib/listings';
import Box from '@paperstac/ui/lib/Box';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';

const ListingPrice = React.memo(({ listPrice, negotiationType, ...props }) => {
  if (negotiationType === LISTING_NEGOTIATION_TYPE.FIRM)
    return (
      <Box fontSize={1} {...props}>
        <Text variant="bold" fontSize={2} color="primary">
          {centsToUsdString(listPrice)}
        </Text>{' '}
        Firm
      </Box>
    );

  if (negotiationType === LISTING_NEGOTIATION_TYPE.MAKE_OFFER)
    return (
      <Box fontSize={1} {...props}>
        <Text variant="bold" fontSize={2} color="primary">
          Make an Offer
        </Text>
      </Box>
    );

  if (negotiationType === LISTING_NEGOTIATION_TYPE.BEST_OFFER)
    return (
      <Box fontSize={1} {...props}>
        <Text variant="bold" fontSize={2} color="primary">
          {centsToUsdString(listPrice)}
        </Text>{' '}
        or Best Offer
      </Box>
    );

  return (
    <Box {...props}>
      <Text variant="italic" fontSize={1} color="gray.6">
        (List Price Not Set)
      </Text>
    </Box>
  );
});

ListingPrice.propTypes = {};

ListingPrice.defaultProps = {};

ListingPrice.displayName = 'ListingPrice';

export default ListingPrice;
