import React from 'react';
import './Input.css';

const getButtonClasses = (hasError, squareRight, className) => {
  const classes = ['Input', ...className.split(' ')];
  if (hasError) {
    classes.push('Input--error');
  }
  if (squareRight) {
    classes.push('Input--squareRight');
  }
  return classes.join(' ');
};

export default ({ input, type, hasError, squareRight, placeholder, disabled, className = '', width = '100%' }) => (
  <input
    className={getButtonClasses(hasError, squareRight, className)}
    {...input}
    style={{ width }}
    type={type}
    placeholder={placeholder}
    disabled={disabled}
  />
);
