import { getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import Box from '@paperstac/ui/lib/Box';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import Flex from '@paperstac/ui/lib/Flex';
import Link from '@paperstac/ui/lib/Link';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import { LISTING_STATUS } from '../../pages/MyListingsPage/MyListingsPageProvider';
import EditButton from './EditButton';
import ListingPrice from './ListingPrice';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import NeedsAttentionList from './NeedsAttentionList';

const OverviewScreen = React.memo(() => {
  const { listing, showDescription, showNote, showPrice, showSelectNotes, showStatus, stacked } =
    React.useContext(ManageListingOverlayContext);

  return (
    <DescriptionGrid
      sx={{
        bg: 'gray.1',
        border: 'none',
        borderTop: 'default',
        borderBottom: 'default',
        borderRadius: 0,
      }}
    >
      <DescriptionGridCell term="Status">
        <Flex alignItems="center" mt={3}>
          <Box flex={'1 1 0'}>
            <Flex alignItems="center" mb={1}>
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: 'circle',
                  bg:
                    listing.status === LISTING_STATUS.ACTIVE
                      ? 'green'
                      : listing.status === LISTING_STATUS.INACTIVE
                        ? 'gray.6'
                        : 'red',
                  mr: '6px',
                }}
              />
              <Box fontWeight="bold">{listing.status}</Box>
            </Flex>
            {!listing.isPrivate && listing.status === LISTING_STATUS.ACTIVE && (
              <Box fontSize={1}>Your listing is visible in the Paperstac marketplace.</Box>
            )}
            {listing.isPrivate && listing.status === LISTING_STATUS.ACTIVE && (
              <Box fontSize={1}>
                Your listing is active but not visible on the Paperstac For Sale page. You can share your{' '}
                <Link to={listing.listingUrl} target="_blank">
                  listing link
                </Link>{' '}
                with potential buyers.
              </Box>
            )}
            {listing.status === LISTING_STATUS.INACTIVE && (
              <Box fontSize={1}>Your listing is not visible in the Paperstac marketplace.</Box>
            )}
            {listing.status === LISTING_STATUS.NEEDS_ATTENTION && (
              <Box fontSize={1}>Your listing needs attention before you can post it in the Paperstac marketplace.</Box>
            )}
          </Box>
          {listing.status !== LISTING_STATUS.NEEDS_ATTENTION && (
            <Box>
              <EditButton onClick={showStatus} />
            </Box>
          )}
        </Flex>
      </DescriptionGridCell>
      <DescriptionGridCell term={listing.notes.length === 1 ? 'Note' : 'Notes'}>
        {listing.notes.map((note) => (
          <Flex key={note.id} alignItems="center" mt={3}>
            <Box flex={'1 1 0'}>
              <Flex alignItems={stacked ? 'flex-start' : 'center'}>
                <Flex
                  sx={{
                    bg: note.lienPosition === 1 ? 'gray.4' : 'gray.7',
                    color: note.lienPosition === 1 ? 'gray.9' : 'white',
                    borderRadius: 'corner',
                    fontSize: 1,
                    textAlign: 'center',
                    width: 36,
                    py: 1,
                    mr: 2,
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {getOrdinal(note.lienPosition)}
                </Flex>
                <Flex flexDirection={stacked ? 'column' : 'row'} alignItems={stacked ? 'normal' : 'center'}>
                  <Box fontSize={2} mr={stacked ? 0 : 2}>
                    {note.streetAddress}
                  </Box>
                  <Box fontSize={1} color="gray.6">
                    {note.cityStateZip}
                  </Box>
                </Flex>
              </Flex>
              <NeedsAttentionList reasons={listing.needsAttention.notes[note.id]} mt={1} />
            </Box>
            <Box>
              <EditButton onClick={() => showNote(note.id)} />
            </Box>
          </Flex>
        ))}
        {listing.isPool && (
          <Box mt={3}>
            <LinkButton onClick={showSelectNotes}>Add or Remove Notes</LinkButton>
          </Box>
        )}
      </DescriptionGridCell>
      <DescriptionGridCell term="List Price">
        <Flex alignItems="center" mt={3}>
          <Box flex={'1 1 0'} mr={3}>
            <NeedsAttentionList reasons={listing.needsAttention.listing} mb={3} />
            <ListingPrice listPrice={listing.listPrice} negotiationType={listing.negotiationType} />
          </Box>
          <Box>
            <EditButton onClick={showPrice} />
          </Box>
        </Flex>
      </DescriptionGridCell>
      <DescriptionGridCell term="Listing Description" sx={{ borderBottom: 'none' }}>
        <Flex alignItems="center" mt={3}>
          <Box flex={'1 1 0'} mr={3}>
            <Box mb={3}>
              {!!listing.title && <Text variant="bold">{listing.title}</Text>}
              {!listing.title && (
                <Text variant="italic" color="gray.6" fontSize={1}>
                  (Subtitle Not Set)
                </Text>
              )}
            </Box>
            {!!listing.sellerComments && <Box fontSize={1}>{listing.sellerComments}</Box>}
            {!listing.sellerComments && (
              <Text variant="italic" color="gray.6" fontSize={1}>
                (Description Not Set)
              </Text>
            )}
          </Box>
          <Box>
            <EditButton onClick={showDescription} />
          </Box>
        </Flex>
      </DescriptionGridCell>
    </DescriptionGrid>
  );
});

OverviewScreen.propTypes = {};

OverviewScreen.defaultProps = {};

OverviewScreen.displayName = 'OverviewScreen';

export default OverviewScreen;
