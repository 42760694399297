import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { AlgoliaSearchStateContext } from '@paperstac/common/lib/components/AlgoliaSearchStateProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import useFirestoreSubscribe from '@paperstac/common/lib/hooks/useFirestoreSubscribe';
import { DELETE_SAVED_SEARCH, UPSERT_SAVED_SEARCH } from '@paperstac/common/lib/serverDispatchActionTypes';
import { getCache } from '@paperstac/common/lib/services/cache';
import dateid from '@paperstac/common/lib/services/dateid';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import {
  NOTE_TAPE_LEGAL_STATUS_BANKRUPTCY,
  NOTE_TAPE_LEGAL_STATUS_FORECLOSURE,
  NOTE_TAPE_LEGAL_STATUS_NONE,
} from '@paperstac/constants';
import { savedSearchesByAccountIdRef, savedSearchValidator } from '@paperstac/firestore-collections/lib/savedSearches';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import ConfirmPopover from '@paperstac/ui/lib/ConfirmPopover';
import Flex from '@paperstac/ui/lib/Flex';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Spinner from '@paperstac/ui/lib/Spinner';
import Text from '@paperstac/ui/lib/Text';
import { Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import SavedSearchForm from './SavedSearchForm';

const [getCachedSavedSearches, setCache] = getCache('SavedSearches', null);

const getDefaultValues = (accountId, identityId) => ({
  accountId,
  id: dateid(),
  notificationPreferences: {
    [identityId]: {
      email: true,
    },
  },
  searchState: {},
  star: false,
  title: '',
});

const SavedSearches = React.memo((props) => {
  const { currentAccountId } = React.useContext(AccountsContext);
  const { uid } = React.useContext(IdentityContext);
  const { setSearchState } = React.useContext(AlgoliaSearchStateContext);
  const [recordToEdit, setRecordToEdit] = React.useState(false);
  const [recordToDelete, setRecordToDelete] = React.useState(null);
  const [firestoreSavedSearches] = useFirestoreSubscribe(savedSearchesByAccountIdRef(currentAccountId), [
    currentAccountId,
  ]);
  if (firestoreSavedSearches) setCache(firestoreSavedSearches);
  const savedSearches = firestoreSavedSearches ? firestoreSavedSearches : getCachedSavedSearches();

  return (
    <Box>
      {!recordToEdit && !savedSearches && <Loading />}

      {!recordToEdit && Array.isArray(savedSearches) && !savedSearches.length && <NoSavedSearches />}

      {!recordToEdit &&
        Array.isArray(savedSearches) &&
        savedSearches.map((savedSearch) => (
          <Box key={savedSearch.id} sx={{ py: 3, borderTop: 'default' }}>
            <Text mb={1}>{savedSearch.title}</Text>
            <Flex>
              <LinkButton fontSize={1} mr={3} onClick={() => setSearchState(savedSearch.searchState)}>
                Search
              </LinkButton>
              <LinkButton fontSize={1} mr={3} onClick={() => setRecordToEdit(savedSearch)}>
                Edit
              </LinkButton>
              <ConfirmPopover
                text="Are you sure you want to delete this?"
                confirmText="Delete"
                isOpen={recordToDelete === savedSearch.id}
                onConfirm={() => {
                  serverDispatch({ action: DELETE_SAVED_SEARCH, payload: { id: savedSearch.id } });
                  setRecordToDelete(null);
                }}
                onCancel={() => setRecordToDelete(null)}
              >
                <LinkButton fontSize={1} onClick={() => setRecordToDelete(savedSearch.id)}>
                  Delete
                </LinkButton>
              </ConfirmPopover>
            </Flex>
          </Box>
        ))}

      {!recordToEdit && (
        <Box sx={{ py: 3, borderTop: 'default' }}>
          <Button
            variant="primary"
            size="small"
            onClick={() => setRecordToEdit(getDefaultValues(currentAccountId, uid))}
          >
            Create Saved Search
          </Button>
        </Box>
      )}

      {!!recordToEdit && (
        <Formik
          initialValues={transform(recordToEdit, uid)}
          validationSchema={savedSearchValidator}
          onSubmit={(payload, { setSubmitting, setStatus }) => {
            serverDispatch({ action: UPSERT_SAVED_SEARCH, payload: sanitize(payload, uid, recordToEdit) })
              .then(() => {
                setSubmitting(false);
                setRecordToEdit(false);
              })
              .catch((error) => {
                setSubmitting(false);
                setStatus({ errorMessage: error.message });
              });
          }}
          children={(props) => (
            <Box mt={5}>
              <SavedSearchForm {...props} onCancel={() => setRecordToEdit(false)} />
            </Box>
          )}
        />
      )}
    </Box>
  );
});

const Loading = () => (
  <Flex py={3} fontSize={1} alignItems="center">
    <Spinner size="tiny" />
    <Text ml={2}>Loading...</Text>
  </Flex>
);

const NoSavedSearches = () => (
  <Box py={3} fontSize={1}>
    You do not have any saved searches yet.
  </Box>
);

const transform = (savedSearch, identityId) => {
  const legalStatus = get(savedSearch.searchState, 'refinementList["noteMetaAgg.legalStatus"]') || [];
  const notificationPreferences = savedSearch.notificationPreferences[identityId] || {};
  return {
    id: savedSearch.id,
    title: savedSearch.title,
    accountId: savedSearch.accountId,
    notify: typeof notificationPreferences.email === 'boolean' ? notificationPreferences.email : true,
    star: savedSearch.star,
    filters: {
      interestRatePercent: {
        min: get(savedSearch.searchState, 'range["noteMetaAgg.interestRatePercent"].min') || '',
        max: get(savedSearch.searchState, 'range["noteMetaAgg.interestRatePercent"].max') || '',
      },
      investmentToBalancePercent: {
        min: get(savedSearch.searchState, 'range["investmentMetaAgg.investmentToBalancePercent"].min') || '',
        max: get(savedSearch.searchState, 'range["investmentMetaAgg.investmentToBalancePercent"].max') || '',
      },
      investmentToValuePercent: {
        min: get(savedSearch.searchState, 'range["investmentMetaAgg.investmentToValuePercent"].min') || '',
        max: get(savedSearch.searchState, 'range["investmentMetaAgg.investmentToValuePercent"].max') || '',
      },
      legalStatus: {
        bankruptcy: legalStatus.includes(NOTE_TAPE_LEGAL_STATUS_BANKRUPTCY),
        foreclosure: legalStatus.includes(NOTE_TAPE_LEGAL_STATUS_FORECLOSURE),
        none: legalStatus.includes(NOTE_TAPE_LEGAL_STATUS_NONE),
      },
      lienPosition: get(savedSearch.searchState, 'refinementList["noteMetaAgg.lienPosition"][0]') || '',
      listPrice: {
        min: get(savedSearch.searchState, 'range.listPrice.min') || '',
        max: get(savedSearch.searchState, 'range.listPrice.max') || '',
      },
      listingType: get(savedSearch.searchState, 'refinementList.isPool[0]') || '',
      loanToValuePercent: {
        min: get(savedSearch.searchState, 'range["noteMetaAgg.loanToValuePercent"].min') || '',
        max: get(savedSearch.searchState, 'range["noteMetaAgg.loanToValuePercent"].max') || '',
      },
      noteTypes: get(savedSearch.searchState, 'refinementList.noteTypes') || [],
      paymentsRemaining: {
        min: get(savedSearch.searchState, 'range["noteMetaAgg.paymentsRemaining"].min') || '',
        max: get(savedSearch.searchState, 'range["noteMetaAgg.paymentsRemaining"].max') || '',
      },
      performance: get(savedSearch.searchState, 'refinementList["noteMetaAgg.performance"][0]') || '',
      propertyTypes: get(savedSearch.searchState, 'refinementList.propertyTypes') || [],
      propertyValue: {
        min: get(savedSearch.searchState, 'range["noteMetaAgg.propertyValue"].min') || '',
        max: get(savedSearch.searchState, 'range["noteMetaAgg.propertyValue"].max') || '',
      },
      state: get(savedSearch.searchState, 'refinementList["noteMetaAgg.state"]') || [],
      stateClassifications: {
        isHardestHitFundState: !!get(savedSearch.searchState, 'refinementList["noteMetaAgg.isHardestHitFundState"][0]'),
        isJudicialState: !!get(savedSearch.searchState, 'refinementList["noteMetaAgg.isJudicialState"][0]'),
        isNonJudicialState: !!get(savedSearch.searchState, 'refinementList["noteMetaAgg.isNonJudicialState"][0]'),
      },
      uniqueSellerName: get(savedSearch.searchState, 'refinementList["uniqueSellerName"]') || [],
      upb: {
        min: get(savedSearch.searchState, 'range["noteMetaAgg.upb"].min') || '',
        max: get(savedSearch.searchState, 'range["noteMetaAgg.upb"].max') || '',
      },
    },
  };
};

const sanitize = (savedSearch, identityId, recordToEdit) => {
  const notificationPreferences = recordToEdit.notificationPreferences;
  const notificationPreferencesOfIdentity = {
    ...notificationPreferences[identityId],
    email: savedSearch.notify,
  };
  return {
    id: savedSearch.id,
    title: savedSearch.title,
    accountId: savedSearch.accountId,
    notificationPreferences: {
      ...notificationPreferences,
      [identityId]: notificationPreferencesOfIdentity,
    },
    star: savedSearch.star,
    searchState: Object.keys(savedSearch.filters).reduce(
      (searchState, propName) => {
        if (savedSearch.filters[propName] === '') return searchState;
        switch (propName) {
          case 'lienPosition':
          case 'performance':
            searchState.refinementList[`noteMetaAgg.${propName}`] = [savedSearch.filters[propName]];
            break;
          case 'listingType':
            searchState.refinementList.isPool = [savedSearch.filters[propName]];
            break;
          case 'noteTypes':
            searchState.refinementList.noteTypes = savedSearch.filters.noteTypes;
            break;
          case 'propertyTypes':
            searchState.refinementList.propertyTypes = savedSearch.filters.propertyTypes;
            break;
          case 'uniqueSellerName':
            searchState.refinementList.uniqueSellerName = savedSearch.filters.uniqueSellerName;
            break;
          case 'listPrice':
            if (savedSearch.filters[propName].min || savedSearch.filters[propName].max)
              searchState.range[propName] = {};
            if (savedSearch.filters[propName].min) searchState.range[propName].min = savedSearch.filters[propName].min;
            if (savedSearch.filters[propName].max) searchState.range[propName].max = savedSearch.filters[propName].max;
            break;
          case 'interestRatePercent':
          case 'loanToValuePercent':
          case 'paymentsRemaining':
          case 'propertyValue':
          case 'upb':
            if (savedSearch.filters[propName].min || savedSearch.filters[propName].max)
              searchState.range[`noteMetaAgg.${propName}`] = {};
            if (savedSearch.filters[propName].min)
              searchState.range[`noteMetaAgg.${propName}`].min = savedSearch.filters[propName].min;
            if (savedSearch.filters[propName].max)
              searchState.range[`noteMetaAgg.${propName}`].max = savedSearch.filters[propName].max;
            break;
          case 'investmentToBalancePercent':
          case 'investmentToValuePercent':
            if (savedSearch.filters[propName].min || savedSearch.filters[propName].max)
              searchState.range[`investmentMetaAgg.${propName}`] = {};
            if (savedSearch.filters[propName].min)
              searchState.range[`investmentMetaAgg.${propName}`].min = savedSearch.filters[propName].min;
            if (savedSearch.filters[propName].max)
              searchState.range[`investmentMetaAgg.${propName}`].max = savedSearch.filters[propName].max;
            break;
          case 'legalStatus':
            if (
              savedSearch.filters[propName].bankruptcy ||
              savedSearch.filters[propName].foreclosure ||
              savedSearch.filters[propName].none
            )
              searchState.refinementList[`noteMetaAgg.${propName}`] = [];
            if (savedSearch.filters[propName].bankruptcy)
              searchState.refinementList[`noteMetaAgg.${propName}`].push(NOTE_TAPE_LEGAL_STATUS_BANKRUPTCY);
            if (savedSearch.filters[propName].foreclosure)
              searchState.refinementList[`noteMetaAgg.${propName}`].push(NOTE_TAPE_LEGAL_STATUS_FORECLOSURE);
            if (savedSearch.filters[propName].none)
              searchState.refinementList[`noteMetaAgg.${propName}`].push(NOTE_TAPE_LEGAL_STATUS_NONE);
            break;
          case 'state':
            if (!savedSearch.filters[propName].length) return searchState;
            searchState.refinementList[`noteMetaAgg.${propName}`] = savedSearch.filters[propName];
            break;
          case 'stateClassifications':
            if (savedSearch.filters[propName].isHardestHitFundState)
              searchState.refinementList['noteMetaAgg.isHardestHitFundState'] = ['true'];
            if (savedSearch.filters[propName].isJudicialState)
              searchState.refinementList['noteMetaAgg.isJudicialState'] = ['true'];
            if (savedSearch.filters[propName].isNonJudicialState)
              searchState.refinementList['noteMetaAgg.isNonJudicialState'] = ['true'];
            break;
          default:
            return searchState;
        }
        return searchState;
      },
      { refinementList: {}, range: {} }
    ),
  };
};

SavedSearches.propTypes = {};

SavedSearches.defaultProps = {};

SavedSearches.displayName = 'SavedSearches';

export default SavedSearches;
