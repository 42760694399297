import { END_SESSION } from '../actions/authActions';
import { SET_CURRENT_ACCOUNT_FEE_OVERRIDES, UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';

const DEFAULT_STATE = [];

export default function currentAccountReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_CURRENT_ACCOUNT_FEE_OVERRIDES:
      return payload;
    default:
      return state;
  }
}

export const getCurrentAccountFeeOverrides = (state) => {
  return state;
};
