import 'indexeddb-getall-shim';
import './services/setupFirebaseClient';
import '@paperstac/validation/lib/initYupWebDictionary';
import 'core-js/stable';
// @ts-ignore
import { getState } from 'loadable-components';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import { Provider } from 'react-redux';
import 'regenerator-runtime/runtime';
import App from './App';
import { createRoot } from 'react-dom/client';
import campaign from './services/campaign';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

const container = document.getElementById('root');
// @ts-ignore
const root = createRoot(container);
const store = configureStore();

campaign.init();
// @ts-ignore
window.snapSaveState = () => getState();
// @ts-ignore
delete window.__PRELOADED_STATE__;

root.render(
  /** @ts-ignore */
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorker.register();
