import { differenceInMonths, parseISO } from 'date-fns';
import Finance from 'tvm-financejs';
var finance = new Finance();
export var purchasePriceToYieldStrict = function (purchasePrice, paymentsRemaining, principalAndInterestPayment, upb, interestOnly) {
    if (interestOnly === void 0) { interestOnly = false; }
    var future = interestOnly ? upb * -1 : 0;
    var monthlyRate = finance.RATE(paymentsRemaining, principalAndInterestPayment * -1, purchasePrice, future, 1, 0.2);
    return +(monthlyRate * 12 * 100).toFixed(2);
};
export var purchasePriceToYield = function (purchasePrice, paymentsRemaining, principalAndInterestPayment, upb, interestOnly) {
    if (interestOnly === void 0) { interestOnly = false; }
    var yieldPercent = purchasePriceToYieldStrict(purchasePrice, paymentsRemaining, principalAndInterestPayment, upb, interestOnly);
    if (!interestOnly && yieldPercent < 0) {
        yieldPercent = purchasePriceToYieldStrict(purchasePrice, paymentsRemaining, principalAndInterestPayment, upb, true);
    }
    return yieldPercent < 5 || yieldPercent > 30 || isNaN(yieldPercent) ? null : yieldPercent;
};
export var purchasePriceToItv = function (purchasePrice, propertyValue) {
    return Math.round((purchasePrice / propertyValue) * 100);
};
export var purchasePriceToItb = function (purchasePrice, upb) {
    return Math.round((purchasePrice / upb) * 100);
};
export var yieldToPurchasePrice = function (yieldPercent, paymentsRemaining, principalAndInterestPayment, upb, interestOnly) {
    if (interestOnly === void 0) { interestOnly = false; }
    var monthlyRate = yieldPercent / 12 / 100;
    var future = interestOnly ? upb * -1 : 0;
    var cents = finance.PV(monthlyRate, paymentsRemaining, principalAndInterestPayment * -1, future, 0);
    return Math.round(cents);
};
export var yieldToItb = function (yieldPercent, paymentsRemaining, principalAndInterestPayment, upb) {
    return purchasePriceToItb(yieldToPurchasePrice(yieldPercent, paymentsRemaining, principalAndInterestPayment, upb), upb);
};
export var yieldToItv = function (yieldPercent, paymentsRemaining, principalAndInterestPayment, propertyValue) {
    return purchasePriceToItv(yieldToPurchasePrice(yieldPercent, paymentsRemaining, principalAndInterestPayment, 0), propertyValue);
};
export var itbToPurchasePrice = function (itbPercent, upb) {
    var itb = itbPercent / 100;
    return Math.round(itb * upb);
};
export var itbToYield = function (itbPercent, upb, paymentsRemaining, principalAndInterestPayment) {
    return purchasePriceToYield(itbToPurchasePrice(itbPercent, upb), paymentsRemaining, principalAndInterestPayment, upb);
};
export var itbToItv = function (itbPercent, upb, propertyValue) {
    return purchasePriceToItv(itbToPurchasePrice(itbPercent, upb), propertyValue);
};
export var itvToPurchasePrice = function (itvPercent, propertyValue) {
    var itv = itvPercent / 100;
    return Math.round(itv * propertyValue);
};
export var itvToYield = function (itvPercent, propertyValue, paymentsRemaining, principalAndInterestPayment, upb) {
    return purchasePriceToYield(itvToPurchasePrice(itvPercent, propertyValue), paymentsRemaining, principalAndInterestPayment, upb);
};
export var itvToItb = function (itvPercent, propertyValue, upb) {
    return purchasePriceToItb(itvToPurchasePrice(itvPercent, propertyValue), upb);
};
export var loanToValuePercent = function (upb, propertyValue) {
    return upb && propertyValue ? Math.round((upb / propertyValue) * 100) : null;
};
export var combinedLoanToValuePercent = function (upb, seniorUpb, propertyValue) {
    return upb && seniorUpb && propertyValue ? Math.round(((upb + seniorUpb) / propertyValue) * 100) : null;
};
export var investmentToValuePercent = function (investment, propertyValue, decimalSpaces) {
    if (decimalSpaces === void 0) { decimalSpaces = 0; }
    return investment && propertyValue ? round((investment / propertyValue) * 100, decimalSpaces) : null;
};
export var investmentToBalancePercent = function (investment, upb, decimalSpaces) {
    if (decimalSpaces === void 0) { decimalSpaces = 0; }
    return investment && upb ? round((investment / upb) * 100, decimalSpaces) : null;
};
export var round = function (value, decimalSpaces) {
    if (decimalSpaces === void 0) { decimalSpaces = 0; }
    var multiplier = Math.pow(10, decimalSpaces);
    return Math.round(value * multiplier) / multiplier;
};
export var paymentsRemainingByMaturity = function (nextPaymentDate, maturityDate) {
    console.log('[math.paymentsRemainingByMaturity()] Starting...', { nextPaymentDate: nextPaymentDate, maturityDate: maturityDate });
    nextPaymentDate = nextPaymentDate.toDate
        ? nextPaymentDate.toDate()
        : typeof nextPaymentDate === 'string'
            ? parseISO(nextPaymentDate)
            : nextPaymentDate;
    maturityDate = maturityDate.toDate
        ? maturityDate.toDate()
        : typeof maturityDate === 'string'
            ? parseISO(maturityDate)
            : maturityDate;
    if (!(nextPaymentDate instanceof Date)) {
        console.log('[math.paymentsRemainingByMaturity()] Invalid nextPaymentDate given:', nextPaymentDate);
        return 0;
    }
    if (!(maturityDate instanceof Date)) {
        console.log('[math.paymentsRemainingByMaturity()] Invalid maturityDate given:', maturityDate);
        return 0;
    }
    var diff = differenceInMonths(maturityDate, nextPaymentDate);
    return diff < 0 ? 0 : diff;
};
/**
 * This function returns the number of periods for an investment or a loan based on periodic constant payments and a constant interest rate.
 *
 * @param {number} annualInterestRatePercent Annual interest rate
 * @param {number} principalAndInterestPayment Periodical amount to be paid
 * @param {number} upb Present value of the investment
 * @param {number} paymentsPerYear Per period. (Example: If a loan is to be repaid in monthly instalments, this will be 12. For quarterly repayments, this parameter will be 4, 6 for half-yearly and 1 for yearly repayments)
 * @param {number} balanceUponMaturity Future Value or a cash balance that you want to attain after the last payment is made. This is an optional parameter. If omitted, 0 is assumed.
 * @param {number} decimalSpaces Number of decimal spaces to round result to
 * @returns {number}
 */
export var paymentsRemainingByBalance = function (annualInterestRatePercent, principalAndInterestPayment, upb, paymentsPerYear, balanceUponMaturity, decimalSpaces) {
    if (paymentsPerYear === void 0) { paymentsPerYear = 12; }
    if (balanceUponMaturity === void 0) { balanceUponMaturity = 0; }
    if (decimalSpaces === void 0) { decimalSpaces = 2; }
    if (!paymentsPerYear || !principalAndInterestPayment || !upb)
        return 0;
    var interestRate = annualInterestRatePercent / (paymentsPerYear * 100);
    var paymentsRemaining = interestRate === 0
        ? -(balanceUponMaturity - upb) / principalAndInterestPayment
        : Math.log((-balanceUponMaturity * interestRate + principalAndInterestPayment) /
            (principalAndInterestPayment + interestRate * upb * -1)) / Math.log(1 + interestRate);
    return round(paymentsRemaining, decimalSpaces);
};
