import Box from '@paperstac/ui/lib/Box';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import Overlay from '@paperstac/ui/lib/Overlay';
import PropTypes from 'prop-types';
import React from 'react';
import Content from '../ListingPage/Content';
import ListingPageProvider from '../ListingPage/ListingPageProvider';

const ListingOverlay = React.memo(({ listingId, onClose }) => (
  <Overlay onClose={onClose}>
    <Drawer width={1400}>
      <DrawerHeader text="Listing Details" onClose={onClose} />
      <Box sx={{ p: 4, overflowY: 'auto', flex: '1 1 auto' }}>
        <ListingPageProvider listingId={listingId}>
          <Content />
        </ListingPageProvider>
      </Box>
    </Drawer>
  </Overlay>
));

ListingOverlay.propTypes = {
  listingId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

ListingOverlay.defaultProps = {};

ListingOverlay.displayName = 'ListingOverlay';

export default ListingOverlay;
