import React from 'react';
import BlogPostAuthor from './BlogPostAuthor';

const BlogPostAuthorBrettBurky = ({ publishDate }) => (
  <BlogPostAuthor
    name="Brett Burky"
    avatar="https://res.cloudinary.com/paperstac/image/upload/v1539625962/website-assets/brett-burky.png"
    publishDate={publishDate}
  />
);

export default BlogPostAuthorBrettBurky;
