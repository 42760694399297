import { END_SESSION } from '../actions/authActions';
import { SET_FLAGGED_EVENTS } from '../actions/flaggedEventActions';

const DEFAULT_STATE = [];

export default function flaggedEventsReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
      return DEFAULT_STATE;
    case SET_FLAGGED_EVENTS:
      return payload;
    default:
      return state;
  }
}

export const getFlaggedEvents = (state) => {
  return state;
};
