import AlgoliaChecklistRefinement from '@paperstac/ui/lib/AlgoliaChecklistRefinement';
import AlgoliaHasListRefinement from '@paperstac/ui/lib/AlgoliaHasListRefinement';
import React from 'react';
import FilterContainer from './FilterContainer';

const NoteTypeFilter = React.memo((props) => (
  <FilterContainer title="Note Type">
    <AlgoliaHasListRefinement attribute="noteTypes">
      <AlgoliaChecklistRefinement attribute="noteTypes" limit={5} selectAll={false} deselectAll={false} />
    </AlgoliaHasListRefinement>
  </FilterContainer>
));

NoteTypeFilter.propTypes = {};

NoteTypeFilter.defaultProps = {};

NoteTypeFilter.displayName = 'NoteTypeFilter';

export default NoteTypeFilter;
