import React from 'react';
import { PLAID_PAPERSTAC_TEMPLATE_ID, PLAID_SANDBOX, PLAID_SANDBOX_TEMPLATE_ID } from '@paperstac/env';
import useCreateIdentityVerificationLinkToken from './useCreateIdentityVerificationLinkToken';
import useIdentityVerificationSession from './useIdentityVerificationSession';

const TEMPLATE_ID = PLAID_SANDBOX ? PLAID_SANDBOX_TEMPLATE_ID : PLAID_PAPERSTAC_TEMPLATE_ID;

export default function useIdentityVerify(identity) {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const createIdentityVerificationLinkToken = useCreateIdentityVerificationLinkToken({
    domain: 'paperstac.com',
    email: identity.email,
    identityId: identity.id,
    templateId: TEMPLATE_ID,
  });
  const { element: plaidLink, start: startSession } = useIdentityVerificationSession({
    createIdentityVerificationLinkToken,
  });

  const initSession = React.useCallback(
    async (forceReset) => {
      if (isSubmitting) return;
      setSubmitting(true);
      startSession(forceReset)
        .catch((e) => alert(e.message))
        .finally(() => setSubmitting(false));
    },
    [isSubmitting, setSubmitting, startSession]
  );

  const handleStart = React.useCallback(async () => initSession(false), [initSession]);

  const handleRestart = React.useCallback(async () => initSession(true), [initSession]);

  return {
    plaidLink,
    handleStart,
    handleRestart,
  };
}
