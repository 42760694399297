import get from 'lodash/get';
import React from 'react';
import { AlgoliaSearchStateContext } from '../components/AlgoliaSearchStateProvider';
export default (function () {
  var searchState = React.useContext(AlgoliaSearchStateContext);
  var rangeFilters = get(searchState, 'searchState.range') || {};
  var refinementFilters = get(searchState, 'searchState.refinementList') || {};
  return Object.values(rangeFilters).length + Object.values(refinementFilters).filter(function (val) {
    return val !== '';
  }).length;
});