import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import Box from '@paperstac/ui/lib/Box';
import Text from '@paperstac/ui/lib/Text';
import format from 'date-fns/format';
import get from 'lodash/get';
import React from 'react';
import { IdentityManagerContext } from '../IdentityManager';
import LinkButton from './LinkButton';

const CompleteBySection = React.memo((props) => {
  const { identity } = React.useContext(IdentityContext);
  const { ignoreVerification } = React.useContext(IdentityManagerContext);
  const verificationDeadlineSeconds = get(identity, 'verificationDeadlineDate.seconds');

  const forcedToComplete = React.useMemo(
    (identity) => {
      if (!identity || !verificationDeadlineSeconds) return false;
      const now = new Date();
      const deadline = new Date(verificationDeadlineSeconds * 1000);
      return now.getTime() > deadline.getTime();
    },
    [verificationDeadlineSeconds]
  );

  return forcedToComplete ? null : (
    <Box mt={40} textAlign="center" {...props}>
      {!!verificationDeadlineSeconds && (
        <Text fontSize={1} mb={1}>
          Complete by {format(new Date(identity.verificationDeadlineDate.seconds * 1000), 'MMM d, yyyy')}
        </Text>
      )}
      <LinkButton onClick={ignoreVerification}>I'll do this later</LinkButton>
    </Box>
  );
});

CompleteBySection.propTypes = {};

CompleteBySection.defaultProps = {};

CompleteBySection.displayName = 'CompleteBySection';

export default CompleteBySection;
