import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import Image from '@paperstac/ui/lib/Image';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import { AuthOverlayContext } from './AuthOverlayProvider';

const Testimonial = React.memo((props) => {
  const { testimonial } = React.useContext(AuthOverlayContext);
  return (
    <Box textAlign="center">
      <Text
        sx={{
          fontFamily: 'serif',
          textAlign: 'center',
          mb: [18, 18, 18, 24, 32],
          fontSize: [18, 18, 18, 24, 32],
          maxWidth: [600, 600, 600, 600, 800],
          color: 'white',
        }}
      >
        &ldquo;{testimonial.comment}&rdquo;
      </Text>
      <Image
        src={testimonial.avatar}
        width={100}
        height={100}
        borderRadius="50%"
        alt={`Photo of ${testimonial.name}`}
      />
      <Heading fontSize={20} mb={1} textAlign="center" color="white">
        {testimonial.name}
      </Heading>
      <Text fontSize={14} textAlign="center" color="highlight">
        {testimonial.title}
      </Text>
    </Box>
  );
});

Testimonial.propTypes = {};

Testimonial.defaultProps = {};

Testimonial.displayName = 'Testimonial';

export default Testimonial;
