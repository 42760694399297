import { END_SESSION } from '../actions/authActions';
import {
  SET_CURRENT_ACCOUNT,
  UNSUBSCRIBE_CURRENT_ACCOUNT,
  UPDATE_CURRENT_ACCOUNT_AVATAR_FAILURE,
  UPDATE_CURRENT_ACCOUNT_AVATAR_REQUEST,
} from '../actions/currentAccountActions';

const DEFAULT_STATE = null;

export default function currentAccountReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_CURRENT_ACCOUNT:
      return payload || DEFAULT_STATE;
    case UPDATE_CURRENT_ACCOUNT_AVATAR_REQUEST:
      return { ...state, isUpdatingAvatar: true };
    case UPDATE_CURRENT_ACCOUNT_AVATAR_FAILURE:
      return { ...state, isUpdatingAvatar: false };
    default:
      return state;
  }
}
