import React from 'react';
import { REM } from '../theme';

const getCloudinaryUrl = (url = '', width, height, crop, fit) => {
  const resourceType = '/image/upload/';
  if (!url.includes(resourceType)) {
    return url;
  }
  const urlParts = url.split(resourceType);
  const baseUrl = `${urlParts.shift()}${resourceType}`;
  const transforms = ['q_auto', 'f_auto', 'dpr_2.0'];
  if (width) {
    transforms.push(`w_${width}`);
  }
  if (height) {
    transforms.push(`h_${height}`);
  }
  if (crop) {
    transforms.push('c_fill');
  }
  if (fit) {
    transforms.push('c_fit');
  }
  return `${baseUrl}${transforms.join(',')}/${urlParts.join(resourceType)}`;
};

const getStyles = (width, height, fit, circle, style = {}) => {
  const styles = {};
  if (width) {
    const prop = fit ? 'maxWidth' : 'width';
    styles[prop] = REM(width);
  }
  if (height) {
    const prop = fit ? 'maxHeight' : 'height';
    styles[prop] = REM(height);
  }
  if (circle) {
    styles.borderRadius = '50%';
  }
  return { ...styles, ...style };
};

export default ({ url, width, height, crop, fit, circle, style, alt, ...otherProps }) => (
  <img
    src={getCloudinaryUrl(url, width, height, crop, fit)}
    style={getStyles(width, height, fit, circle, style)}
    alt={alt}
    {...otherProps}
  />
);
