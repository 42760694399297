import { UPDATE_NOTE } from '@paperstac/common/lib/serverDispatchActionTypes';
import { noteListingSchemaPartial, notePropertyLabelsMap } from '@paperstac/common/lib/services/notesHelper';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { getStates } from '@paperstac/common/lib/services/states';
import {
  NOTE_TAPE_LEGAL_STATUS_BANKRUPTCY,
  NOTE_TAPE_LEGAL_STATUS_FORECLOSURE,
  NOTE_TAPE_LEGAL_STATUS_NONE,
  NOTE_TAPE_OCCUPANCY_OCCUPIED,
  NOTE_TAPE_OCCUPANCY_UNKNOWN,
  NOTE_TAPE_OCCUPANCY_VACANT,
  NOTE_TAPE_PERFORMANCE_NON_PERFORMING,
  NOTE_TAPE_PERFORMANCE_PERFORMING,
  NOTE_TAPE_PROPERTY_TYPE_BUSINESS,
  NOTE_TAPE_PROPERTY_TYPE_COMMERCIAL,
  NOTE_TAPE_PROPERTY_TYPE_CONDO,
  NOTE_TAPE_PROPERTY_TYPE_LAND,
  NOTE_TAPE_PROPERTY_TYPE_MULTI_FAMILY,
  NOTE_TAPE_PROPERTY_TYPE_OTHER,
  NOTE_TAPE_PROPERTY_TYPE_PUD,
  NOTE_TAPE_PROPERTY_TYPE_SINGLE_FAMILY,
} from '@paperstac/constants';
import { NOTE_TYPE } from '@paperstac/firestore-collections/lib/accountNotes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import Checkbox from '@paperstac/ui/lib/form/Checkbox';
import DateSelect from '@paperstac/ui/lib/form/DateSelect';
import Description from '@paperstac/ui/lib/form/Description';
import Input from '@paperstac/ui/lib/form/Input';
import NumberInput from '@paperstac/ui/lib/form/NumberInput';
import PercentInput from '@paperstac/ui/lib/form/PercentInput';
import Select from '@paperstac/ui/lib/form/Select';
import UsdInput from '@paperstac/ui/lib/form/UsdInput';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const NOTE_TYPE_OPTIONS = Object.values(NOTE_TYPE);

const PERFORMANCE_OPTIONS = [NOTE_TAPE_PERFORMANCE_PERFORMING, NOTE_TAPE_PERFORMANCE_NON_PERFORMING];

const PROPERTY_TYPE_OPTIONS = [
  NOTE_TAPE_PROPERTY_TYPE_SINGLE_FAMILY,
  NOTE_TAPE_PROPERTY_TYPE_MULTI_FAMILY,
  NOTE_TAPE_PROPERTY_TYPE_CONDO,
  NOTE_TAPE_PROPERTY_TYPE_PUD,
  NOTE_TAPE_PROPERTY_TYPE_LAND,
  NOTE_TAPE_PROPERTY_TYPE_COMMERCIAL,
  NOTE_TAPE_PROPERTY_TYPE_BUSINESS,
  NOTE_TAPE_PROPERTY_TYPE_OTHER,
];

const OCCUPANCY_STATUS_OPTIONS = [
  NOTE_TAPE_OCCUPANCY_OCCUPIED,
  NOTE_TAPE_OCCUPANCY_VACANT,
  NOTE_TAPE_OCCUPANCY_UNKNOWN,
];

const LEGAL_STATUS_OPTIONS = [
  NOTE_TAPE_LEGAL_STATUS_BANKRUPTCY,
  NOTE_TAPE_LEGAL_STATUS_FORECLOSURE,
  NOTE_TAPE_LEGAL_STATUS_NONE,
];

const NoteScreenEditDetails = React.memo(() => {
  const { note, showOverview: showListing, stacked } = React.useContext(ManageListingOverlayContext);
  const { showOverview } = React.useContext(NoteScreenContext);

  return (
    <Formik
      initialValues={{
        id: note.id,
        accountId: note.accountId,
        noteType: get(note, 'noteType', ''),
        lienPosition: get(note, 'lienPosition', ''),
        interestOnlyLoan: get(note, 'interestOnlyLoan', false),
        interestRatePercent: get(note, 'interestRatePercent', ''),
        performance: get(note, 'performance', ''),
        upb: get(note, 'upb', 0),
        streetAddress1: get(note, 'streetAddress1', ''),
        streetAddress2: get(note, 'streetAddress2', ''),
        city: get(note, 'city', ''),
        state: get(note, 'state', ''),
        zip: get(note, 'zip', ''),
        country: get(note, 'country', 'US'),
        county: get(note, 'county', ''),
        propertyType: get(note, 'propertyType', ''),
        occupancyStatus: get(note, 'occupancyStatus', ''),
        legalStatus: get(note, 'legalStatus', ''),
        principalAndInterestPayment: get(note, 'principalAndInterestPayment', 0),
        escrowImpounds: get(note, 'escrowImpounds', 0),
        totalMonthlyLoanPayment: get(note, 'totalMonthlyLoanPayment', 0),
        accruedLateCharges: get(note, 'accruedLateCharges', 0),
        originalBalance: get(note, 'originalBalance', 0),
        totalPayoff: get(note, 'totalPayoff', 0),
        originationDate: get(note, 'originationDate', ''),
        nextPaymentDate: get(note, 'nextPaymentDate', ''),
        maturityDate: get(note, 'maturityDate', ''),
        lastPaymentReceivedDate: get(note, 'lastPaymentReceivedDate', ''),
      }}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        const payload = Object.keys(values).reduce((payload, key) => {
          payload[key] = typeof values[key] === 'number' && isNaN(values[key]) ? 0 : values[key];
          return payload;
        }, {});
        serverDispatch({ action: UPDATE_NOTE, payload })
          .then(showOverview)
          .catch((error) => {
            setStatus({ errorMessage: error.message });
            setSubmitting(false);
          });
      }}
      validationSchema={noteListingSchemaPartial}
      children={(formik) => (
        <Form>
          <DescriptionGrid
            sx={{
              bg: 'gray.1',
              border: 'none',
              borderTop: 'default',
              borderBottom: 'default',
              borderRadius: 0,
            }}
          >
            <DescriptionGridCell bg="highlight">
              <Flex alignItems="center">
                <LinkButton onClick={showListing}>Listing</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                <LinkButton onClick={showOverview}>Note</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                Details
              </Flex>
            </DescriptionGridCell>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['noteType']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="noteType" component={Select}>
                  {!formik.values.noteType && <option value="">Choose one...</option>}
                  {NOTE_TYPE_OPTIONS.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="noteType" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['lienPosition']} as="label">
                <Box maxWidth={100}>
                  <Field name="lienPosition" component={NumberInput} />
                </Box>
                <ErrorMessage name="lienPosition" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['interestRatePercent']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Box maxWidth={150}>
                  <Field name="interestRatePercent" component={PercentInput} />
                </Box>
                <ErrorMessage name="interestRatePercent" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['interestOnlyLoan']} as="label">
                <Box mt={3}>
                  <Field name="interestOnlyLoan" component={Checkbox} checked={formik.values.interestOnlyLoan} />{' '}
                  {notePropertyLabelsMap['interestOnlyLoan']}
                  <ErrorMessage name="interestOnlyLoan" component={ErrorText} />
                </Box>
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['performance']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="performance" component={Select}>
                  {!formik.values.performance && <option value="">Choose one...</option>}
                  {PERFORMANCE_OPTIONS.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="performance" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['upb']} as="label">
                <Field name="upb" component={UsdInput} />
                <ErrorMessage name="upb" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term="Property Street Address"
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="streetAddress1" component={Input} />
                <ErrorMessage name="streetAddress1" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term="Address Line 2" as="label" sx={{ flex: stacked ? '1 1 0' : '0 0 170px' }}>
                <Field name="streetAddress2" component={Input} />
                <Description color="darkBlue">Apt, Suite, Unit, etc</Description>
                <ErrorMessage name="streetAddress2" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['city']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="city" component={Input} />
                <ErrorMessage name="city" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell
                term={notePropertyLabelsMap['state']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="state" component={Select}>
                  <option value="">Choose a state...</option>
                  {getStates().map(({ code, name }) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="state" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell
                term={notePropertyLabelsMap['zip']}
                as="label"
                sx={{ flex: stacked ? '1 1 0' : '0 0 150px' }}
              >
                <Field name="zip" component={Input} inputMode="numeric" />
                <ErrorMessage name="zip" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['county']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="county" component={Input} />
                <ErrorMessage name="county" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['propertyType']} as="label">
                <Field name="propertyType" component={Select}>
                  {!formik.values.propertyType && <option value="">Choose one...</option>}
                  {PROPERTY_TYPE_OPTIONS.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="propertyType" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['occupancyStatus']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="occupancyStatus" component={Select}>
                  {!formik.values.occupancyStatus && <option value="">Choose one...</option>}
                  {OCCUPANCY_STATUS_OPTIONS.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="occupancyStatus" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['legalStatus']} as="label">
                <Field name="legalStatus" component={Select}>
                  {!formik.values.legalStatus && <option value="">Choose one...</option>}
                  {LEGAL_STATUS_OPTIONS.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="legalStatus" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['principalAndInterestPayment']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="principalAndInterestPayment" component={UsdInput} />
                <ErrorMessage name="principalAndInterestPayment" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell
                term={notePropertyLabelsMap['escrowImpounds']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="escrowImpounds" component={UsdInput} />
                <Description color="darkBlue">
                  The portion of the Total Monthly Payment allocated to the escrow account. Input $0 if you do not
                  utilize an escrow account.
                </Description>
                <ErrorMessage name="escrowImpounds" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['totalMonthlyLoanPayment']} as="label">
                <Field name="totalMonthlyLoanPayment" component={UsdInput} />
                <ErrorMessage name="totalMonthlyLoanPayment" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['accruedLateCharges']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="accruedLateCharges" component={UsdInput} />
                <Description color="darkBlue">
                  Total sum of late fees owed by the borrower. Input $0 if there are presently no outstanding late fees.
                </Description>
                <ErrorMessage name="accruedLateCharges" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell
                term={notePropertyLabelsMap['originalBalance']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="originalBalance" component={UsdInput} />
                <ErrorMessage name="originalBalance" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['totalPayoff']} as="label">
                <Field name="totalPayoff" component={UsdInput} />
                <ErrorMessage name="totalPayoff" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['originationDate']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="originationDate" component={DateSelect} />
                <ErrorMessage name="originationDate" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['nextPaymentDate']} as="label">
                <Field name="nextPaymentDate" component={DateSelect} />
                <ErrorMessage name="nextPaymentDate" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['maturityDate']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="maturityDate" component={DateSelect} />
                <ErrorMessage name="maturityDate" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['lastPaymentReceivedDate']} as="label">
                <Field name="lastPaymentReceivedDate" component={DateSelect} />
                <ErrorMessage name="lastPaymentReceivedDate" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>
            <DescriptionGridCell sx={{ borderBottom: 'none' }}>
              {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
              <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>
                Update Details
              </Button>
              <Button variant="default" onClick={showOverview}>
                Cancel
              </Button>
            </DescriptionGridCell>
          </DescriptionGrid>
        </Form>
      )}
    />
  );
});

NoteScreenEditDetails.propTypes = {};

NoteScreenEditDetails.defaultProps = {};

NoteScreenEditDetails.displayName = 'NoteScreenEditDetails';

export default NoteScreenEditDetails;
