var generateDateId = function (date) {
    if (date === void 0) { date = new Date(); }
    var prefix = date.toISOString().replace(/[^0-9]/g, '');
    var suffix = Math.floor(Math.random() * 90000) + 10000;
    return "".concat(prefix).concat(suffix);
};
var incrementDateId = function (id, difference) {
    if (difference === void 0) { difference = 1; }
    var tailSize = 6;
    var head = id.substr(0, id.length - tailSize);
    var tail = id.substr(id.length - tailSize);
    tail = +tail + difference;
    tail = tail + '';
    tail = tail.padStart(tailSize, '0');
    return "".concat(head).concat(tail);
};
var dateIdIncrementor = function (currentId) {
    if (currentId === void 0) { currentId = generateDateId(); }
    return function () {
        var nextId = incrementDateId(currentId);
        var newId = generateDateId();
        currentId = nextId < newId ? newId : nextId;
        return currentId;
    };
};
export var getDateFromId = function (id) {
    var year = id.slice(0, 4);
    var month = id.slice(4, 6);
    var day = id.slice(6, 8);
    var hour = id.slice(8, 10);
    var minutes = id.slice(10, 12);
    var seconds = id.slice(12, 14);
    return new Date("".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minutes, ":").concat(seconds));
};
export default dateIdIncrementor();
