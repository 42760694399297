export default function dateid() {
  var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
  var prefix = date.toISOString().replace(/[^0-9]/g, '');
  var suffix = Math.floor(Math.random() * 90000) + 10000;
  return "".concat(prefix).concat(suffix);
}
export var nextId = function nextId(id) {
  var difference = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var tailSize = 6;
  var head = id.substr(0, id.length - tailSize);
  var tail = id.substr(id.length - tailSize);
  tail = +tail + difference;
  tail = tail + '';
  tail = tail.padStart(tailSize, '0');
  return "".concat(head).concat(tail);
};
export var prevId = function prevId(id) {
  var difference = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return nextId(id, difference * -1);
};
export var idIncrementor = function idIncrementor() {
  var currentId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : dateid();
  return function () {
    currentId = nextId(currentId);
    return currentId;
  };
};
export var getDateFromId = function getDateFromId(id) {
  var year = id.slice(0, 4);
  var month = id.slice(4, 6);
  var day = id.slice(6, 8);
  var hour = id.slice(8, 10);
  var minutes = id.slice(10, 12);
  var seconds = id.slice(12, 14);
  return new Date("".concat(year, "-").concat(month, "-").concat(day, " ").concat(hour, ":").concat(minutes, ":").concat(seconds));
};