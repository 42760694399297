import get from 'lodash/get';

export default (items, sortBy, sortDirection = 'asc') => {
  return items.sort((itemA, itemB) => {
    const direction = sortDirection === 'asc' ? 1 : -1;
    const valueA = Array.isArray(get(itemA, sortBy)) ? get(itemA, sortBy).length : get(itemA, sortBy);
    const valueB = Array.isArray(get(itemB, sortBy)) ? get(itemB, sortBy).length : get(itemB, sortBy);
    if (valueA === valueB) {
      return 0;
    }
    if (typeof valueA === 'undefined') {
      return -1 * direction;
    }
    if (typeof valueB === 'undefined') {
      return direction;
    }
    return valueA < valueB ? -1 * direction : direction;
  });
};
