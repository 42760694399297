import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import useFirestoreSubscribe from '@paperstac/common/lib/hooks/useFirestoreSubscribe';
import {
  investmentToBalancePercent,
  investmentToValuePercent,
  itbToPurchasePrice,
  itvToPurchasePrice,
  purchasePriceToYieldStrict,
  round,
  yieldToPurchasePrice,
} from '@paperstac/helpers/lib/math';
import { centsToShortUsdString, centsToUsdString, getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import { NOTE_TAPE_PERFORMANCE_NON_PERFORMING, NOTE_TAPE_PERFORMANCE_PERFORMING } from '@paperstac/constants';
import { TRANSACTION_STATUS, transactionsByNoteIdRef } from '@paperstac/firestore-collections/lib/transactions';
import Box from '@paperstac/ui/lib/Box';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import Description from '@paperstac/ui/lib/form/Description';
import FormGroup from '@paperstac/ui/lib/form/FormGroup';
import Label from '@paperstac/ui/lib/form/Label';
import PercentInput from '@paperstac/ui/lib/form/PercentInput';
import UsdInput from '@paperstac/ui/lib/form/UsdInput';
import ListGrid from '@paperstac/ui/lib/ListGrid';
import Pill from '@paperstac/ui/lib/Pill';
import Text from '@paperstac/ui/lib/Text';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import AccountAcceptancePill from '../AccountAcceptancePill';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';

const PriceScreenSingleSection = React.memo(({ currentPrice, initialPrice, note, setFieldValue }) => {
  const { currentAccountId } = React.useContext(AccountsContext);
  const { stacked } = React.useContext(ManageListingOverlayContext);
  const { id: noteId, propertyValue, upb } = note;
  const [itb, setItb] = React.useState(investmentToBalancePercent(currentPrice, upb, 4) || '');
  const [itv, setItv] = React.useState(investmentToValuePercent(currentPrice, propertyValue, 4) || '');
  const [yieldPercent, setYieldPercent] = React.useState(
    !!currentPrice
      ? purchasePriceToYieldStrict(
          currentPrice,
          note.paymentsRemaining,
          note.principalAndInterestPayment,
          note.upb,
          !!note.interestOnlyLoan
        ) || ''
      : ''
  );
  const [noteTransactions] = useFirestoreSubscribe(
    transactionsByNoteIdRef(note.id).where('sellerId', '==', currentAccountId),
    [currentAccountId, note.id]
  );
  const transactions = React.useMemo(() => {
    if (!noteTransactions) return [];
    return noteTransactions
      .filter(({ status }) => [TRANSACTION_STATUS.CLOSING, TRANSACTION_STATUS.NEGOTIATIONS].includes(status))
      .map((transaction) => {
        const diff = transaction.notePrices[note.id] - currentPrice;
        return {
          Parties: (
            <>
              <AccountAcceptancePill
                accepts={transaction.sellerAcceptsPricing}
                avatar={transaction.seller.avatar}
                displayName={transaction.seller.displayName}
                mr={2}
              />
              <AccountAcceptancePill
                accepts={transaction.buyerAcceptsPricing}
                avatar={transaction.buyer.avatar}
                displayName={transaction.buyer.displayName}
              />
            </>
          ),
          'Current Price': (
            <>
              {centsToShortUsdString(transaction.notePrices[note.id])}
              {diff > 0 && (
                <Text variant="italic" ml={2} fontSize={1} color="green">
                  {centsToUsdString(diff)} higher
                </Text>
              )}
              {diff < 0 && (
                <Text variant="italic" ml={2} fontSize={1} color="red">
                  {centsToUsdString(diff * -1)} lower
                </Text>
              )}
            </>
          ),
        };
      });
  }, [currentPrice, note.id, noteTransactions]);

  const priceDescription = React.useMemo(() => {
    const oldPrice = isNaN(initialPrice) ? 0 : +initialPrice;
    const newPrice = isNaN(currentPrice) ? 0 : +currentPrice;
    const diffCents = newPrice - oldPrice;
    const absoluteDiffCents = diffCents < 0 ? diffCents * -1 : diffCents;
    const percentChange = oldPrice && newPrice ? round((absoluteDiffCents / oldPrice) * 100, 2) : 0;
    const percentChangeString = percentChange ? `(${percentChange}%)` : '';
    if (diffCents === 0) return null;
    return (
      <Text sx={{ color: 'blue' }}>
        {diffCents > 0 ? 'Raising' : 'Dropping'} price by {centsToUsdString(absoluteDiffCents)} {percentChangeString}
      </Text>
    );
  }, [currentPrice, initialPrice]);

  const handlePriceChange = React.useCallback(
    ({ target: { value } }) => {
      setFieldValue(`notePrices.${noteId}`, value);
      setItb(investmentToBalancePercent(value, upb, 4) || '');
      setItv(investmentToValuePercent(value, propertyValue, 4) || '');
      setYieldPercent(
        !!value
          ? purchasePriceToYieldStrict(
              value,
              note.paymentsRemaining,
              note.principalAndInterestPayment,
              note.upb,
              !!note.interestOnlyLoan
            ) || ''
          : ''
      );
    },
    [note, noteId, propertyValue, setFieldValue, upb]
  );

  const handleItbChange = React.useCallback(
    ({ target: { value } }) => {
      const price = itbToPurchasePrice(value, upb);
      setFieldValue(`notePrices.${noteId}`, price);
      setItb(value);
      setItv(investmentToValuePercent(price, propertyValue, 4) || '');
      setYieldPercent(
        !!price
          ? purchasePriceToYieldStrict(
              price,
              note.paymentsRemaining,
              note.principalAndInterestPayment,
              note.upb,
              !!note.interestOnlyLoan
            ) || ''
          : ''
      );
    },
    [note, noteId, propertyValue, setFieldValue, upb]
  );

  const handleItvChange = React.useCallback(
    ({ target: { value } }) => {
      const price = itvToPurchasePrice(value, propertyValue);
      setFieldValue(`notePrices.${noteId}`, price);
      setItb(investmentToBalancePercent(price, upb, 4) || '');
      setItv(value);
      setYieldPercent(
        !!price
          ? purchasePriceToYieldStrict(
              price,
              note.paymentsRemaining,
              note.principalAndInterestPayment,
              note.upb,
              !!note.interestOnlyLoan
            ) || ''
          : ''
      );
    },
    [note, noteId, propertyValue, setFieldValue, upb]
  );

  const handleYieldChange = React.useCallback(
    ({ target: { value } }) => {
      if (typeof note.paymentsRemaining !== 'number') return; // handle '??' as valid value for paymentsRemaining
      setYieldPercent(value);
      const price = yieldToPurchasePrice(
        +value,
        note.paymentsRemaining,
        note.principalAndInterestPayment,
        note.upb,
        note.interestOnlyLoan
      );
      setFieldValue(`notePrices.${noteId}`, price);
      setItb(investmentToBalancePercent(price, upb, 4) || '');
      setItv(investmentToValuePercent(price, propertyValue, 4) || '');
    },
    [note, noteId, propertyValue, setFieldValue, upb]
  );

  return (
    <>
      <Box mt={3}>
        <Flex alignItems={stacked ? 'flex-start' : 'center'} mb={2}>
          <Flex
            sx={{
              bg: note.lienPosition === 1 ? 'gray.4' : 'gray.7',
              color: note.lienPosition === 1 ? 'gray.9' : 'white',
              borderRadius: 'corner',
              fontSize: 1,
              textAlign: 'center',
              width: 36,
              py: 1,
              mr: 2,
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
            }}
          >
            {getOrdinal(note.lienPosition)}
          </Flex>
          <Flex flexDirection={stacked ? 'column' : 'row'} alignItems={stacked ? 'normal' : 'center'}>
            <Box fontSize={2} mr={stacked ? 0 : 2}>
              {note.streetAddress}
            </Box>
            <Box fontSize={1} color="gray.6">
              {note.cityStateZip}
            </Box>
          </Flex>
        </Flex>
        {note.performance === NOTE_TAPE_PERFORMANCE_PERFORMING && <Pill mr={1}>PL</Pill>}
        {note.performance === NOTE_TAPE_PERFORMANCE_NON_PERFORMING && <Pill mr={1}>NPL</Pill>}
        {!!upb && <Pill mr={1}>{centsToShortUsdString(upb)} UPB</Pill>}
        {!!propertyValue && <Pill mr={1}>{centsToShortUsdString(propertyValue)} Property</Pill>}
      </Box>
      <Box mt={4}>
        <FormGroup>
          <Label>Price:</Label>
          <Box maxWidth={180}>
            <Field name={`notePrices.${noteId}`} component={UsdInput} onChange={handlePriceChange} />
          </Box>
          {!!priceDescription && <Description>{priceDescription}</Description>}
          <ErrorMessage name={`notePrices.${noteId}`} component={ErrorText} />
        </FormGroup>
        {!!upb && (
          <Box sx={{ display: 'inline-block', mr: 5 }}>
            <FormGroup>
              <Label>Investment to Balance (ITB):</Label>
              <Box maxWidth={150}>
                <PercentInput value={itb} onChange={handleItbChange} />
              </Box>
            </FormGroup>
          </Box>
        )}
        {!!propertyValue && (
          <Box sx={{ display: 'inline-block' }}>
            <FormGroup>
              <Label>Investment to Value (ITV):</Label>
              <Box maxWidth={150}>
                <PercentInput value={itv} onChange={handleItvChange} />
              </Box>
            </FormGroup>
          </Box>
        )}
        {note.performance === NOTE_TAPE_PERFORMANCE_PERFORMING && typeof note.paymentsRemaining === 'number' && (
          <Box sx={{ display: 'inline-block' }}>
            <FormGroup>
              <Label>Yield:</Label>
              <Box maxWidth={150}>
                <PercentInput value={yieldPercent} onChange={handleYieldChange} />
              </Box>
            </FormGroup>
          </Box>
        )}
      </Box>
      {!!transactions.length && (
        <Box>
          <Box sx={{ fontWeight: 'bold', mb: 2 }}>Transaction Comparison:</Box>
          <Text sx={{ fontSize: 1, mb: 2 }}>
            {transactions.length === 1
              ? `This asset is in one of your active transactions. Here is that bid for comparison.`
              : `This asset is in ${transactions.length} of your active transactions. Here are your current bids for comparison.`}
          </Text>
          <ListGrid items={transactions} p={0} />
        </Box>
      )}
    </>
  );
});

PriceScreenSingleSection.propTypes = {
  currentPrice: PropTypes.any.isRequired,
  initialPrice: PropTypes.any.isRequired,
  note: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

PriceScreenSingleSection.defaultProps = {
  differentPriceActionText: 'Make Counter Offer',
  samePriceActionText: 'Accept Current Price',
};

PriceScreenSingleSection.displayName = 'PriceScreenSingleSection';

export default PriceScreenSingleSection;
