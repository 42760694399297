import { HIDE_ACCOUNT_SWITCHER, SHOW_ACCOUNT_SWITCHER } from '../actions/accountSwitcherActions';

const DEFAULT_STATE = false;

export default function teamsReducer(state = DEFAULT_STATE, { type }) {
  switch (type) {
    case SHOW_ACCOUNT_SWITCHER:
      return true;
    case HIDE_ACCOUNT_SWITCHER:
      return false;
    default:
      return state;
  }
}
