import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';

const Stat = React.memo(({ value, title, subtitle }) => {
  return (
    <Text textAlign="center">
      <Text fontSize={[40, 90]} lineHeight={1.2} fontWeight="bold" color="blue">
        {value}
      </Text>
      <Text fontSize={[16, 20]} lineHeight={1.2} fontWeight="bold" color="gray.9">
        {title}
      </Text>
      <Text fontSize={[14, 16]}>{subtitle}</Text>
    </Text>
  );
});

Stat.propTypes = {
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

Stat.defaultProps = {};

Stat.displayName = 'Stat';

export default Stat;
