import Box from '@paperstac/ui/lib/Box';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import ChevronDownIcon from '@paperstac/ui/lib/icons/ChevronDownIcon';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import Spinner from '@paperstac/ui/lib/Spinner';
import Text from '@paperstac/ui/lib/Text';
import get from 'lodash/get';
import React from 'react';
import styled from 'styled-components';
import {
  ESCROW,
  ESCROW_LEAD_TEXT,
  ESCROW_TITLE,
  SECTION_TITLE,
  TIMELINE,
  TIMELINE_LEAD_TEXT,
  TIMELINE_TITLE,
  TODOS,
  TODOS_LEAD_TEXT,
  TODOS_TITLE,
} from './ClosingsSection';

const TimelineMedia = React.lazy(() => import('./TimelineMedia'));
const TodosMedia = React.lazy(() => import('./TodosMedia'));
const EscrowMedia = React.lazy(() => import('./EscrowMedia'));

const ClosingsSectionMobile = React.memo(({ accordionState, setAccordionState }) => {
  const toggleTimelineAccordion = React.useCallback(() => {
    setAccordionState({ [TIMELINE]: !accordionState[TIMELINE] });
  }, [accordionState, setAccordionState]);
  const toggleTodosAccordion = React.useCallback(() => {
    setAccordionState({ [TODOS]: !accordionState[TODOS] });
  }, [accordionState, setAccordionState]);
  const toggleEscrowAccordion = React.useCallback(() => {
    setAccordionState({ [ESCROW]: !accordionState[ESCROW] });
  }, [accordionState, setAccordionState]);

  return (
    <Box bg="darkBlue" pt={40}>
      <Heading fontSize={20} mb={32} textAlign="center" color="white">
        {SECTION_TITLE}
      </Heading>

      <AccordionItem
        isOpen={get(accordionState, TIMELINE)}
        onToggle={toggleTimelineAccordion}
        title={TIMELINE_TITLE}
        leadText={TIMELINE_LEAD_TEXT}
      >
        <React.Suspense
          fallback={
            <Flex py={5} justifyContent="center">
              <Spinner />
            </Flex>
          }
        >
          <TimelineMedia />
        </React.Suspense>
      </AccordionItem>

      <AccordionItem
        isOpen={get(accordionState, TODOS)}
        onToggle={toggleTodosAccordion}
        title={TODOS_TITLE}
        leadText={TODOS_LEAD_TEXT}
      >
        <React.Suspense
          fallback={
            <Flex py={5} justifyContent="center">
              <Spinner />
            </Flex>
          }
        >
          <TodosMedia />
        </React.Suspense>
      </AccordionItem>

      <AccordionItem
        isOpen={get(accordionState, ESCROW)}
        onToggle={toggleEscrowAccordion}
        title={ESCROW_TITLE}
        leadText={ESCROW_LEAD_TEXT}
      >
        <React.Suspense
          fallback={
            <Flex py={5} justifyContent="center">
              <Spinner />
            </Flex>
          }
        >
          <EscrowMedia />
        </React.Suspense>
      </AccordionItem>
    </Box>
  );
});

const Trigger = styled(Flex)`
  border: none;
  cursor: pointer;
  width: 100%;
  background-color: #394c57;
  border-top: 1px solid #5a6a73;
  outline: none;
`;

const AccordionItem = (props) => (
  <Box>
    <Trigger as="button" py={2} px={3} alignItems="center" onClick={props.onToggle}>
      {!props.isOpen && <ChevronRightIcon color="gray.5" mr={3} />}
      {props.isOpen && <ChevronDownIcon color="white" mr={3} />}
      <Text color="white" fontSize={16} fontWeight={700}>
        {props.title}
      </Text>
    </Trigger>
    {props.isOpen && (
      <Container py={3}>
        <Text fontSize={14} color="white" mb={3}>
          {props.leadText}
        </Text>
        {props.children}
      </Container>
    )}
  </Box>
);

ClosingsSectionMobile.propTypes = {};

ClosingsSectionMobile.defaultProps = {};

ClosingsSectionMobile.displayName = 'ClosingsSectionMobile';

export default ClosingsSectionMobile;
