import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import shuffle from 'lodash/shuffle';
import React from 'react';
import Quote from './Quote';
import quotes from './quotes';
import Section from './Section';

const QUOTES = shuffle(quotes);

const IndustryInsights = React.memo((props) => {
  return (
    <Section title="Industry Insights" subtitle="(analysis from industry experts)" bg="white">
      <Flex flex="1" flexDirection={['column', 'column', 'column', 'row']}>
        <Box flex={1} mb={[40, 40, 64]} mr={[0, 0, 0, 32, 0]}>
          <Quote {...QUOTES[0]} />
        </Box>
        <Box flex={1} mb={[40, 40, 64]} ml={[0, 0, 0, 32, 0]}>
          <Quote {...QUOTES[1]} />
        </Box>
      </Flex>
      <Flex flex="1" flexDirection={['column', 'column', 'column', 'row']}>
        <Box flex={1} mb={[40, 40, 64]} mr={[0, 0, 0, 32, 0]}>
          <Quote {...QUOTES[2]} />
        </Box>
        <Box flex={1} ml={[0, 0, 0, 32, 0]}>
          <Quote {...QUOTES[3]} />
        </Box>
      </Flex>
    </Section>
  );
});

IndustryInsights.propTypes = {};

IndustryInsights.defaultProps = {};

IndustryInsights.displayName = 'IndustryInsights';

export default IndustryInsights;
