function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { dateToTime } from '@paperstac/helpers/lib/dateHelpers';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import distanceInWordsToNow from 'date-fns/formatDistanceToNow';
import React from 'react';
var FIVE_SECS_IN_MS = 5000;
var ONE_MINUTE_IN_SECS = 60;
var ONE_MINUTE_IN_MS = ONE_MINUTE_IN_SECS * 1000;
var ONE_HOUR_IN_SECS = ONE_MINUTE_IN_SECS * 60;
var ONE_HOUR_IN_MS = ONE_HOUR_IN_SECS * 1000;
var ONE_DAY_IN_SECS = ONE_HOUR_IN_SECS * 24;
var timeToRelativeDate = function timeToRelativeDate(time) {
  if (!time) return '';
  return distanceInWordsToNow(new Date(time), {
    includeSeconds: true,
    addSuffix: true
  });
};
export default (function (dateOrFirestoreTimestamp) {
  var time = dateToTime(dateOrFirestoreTimestamp);
  var _React$useState = React.useState(timeToRelativeDate(time)),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    relativeDate = _React$useState2[0],
    setRelativeDate = _React$useState2[1];
  var _React$useState3 = React.useState(null),
    _React$useState4 = _slicedToArray(_React$useState3, 2),
    relativeDateRefreshed = _React$useState4[0],
    setRelativeDateRefreshed = _React$useState4[1];
  var refreshRelativeDate = React.useCallback(function () {
    setRelativeDate(timeToRelativeDate(time));
    setRelativeDateRefreshed(new Date().getTime());
  }, [time]);
  React.useEffect(function () {
    if (!time) return;
    var diffInSecs = differenceInSeconds(new Date(), new Date(time));
    if (diffInSecs < ONE_MINUTE_IN_SECS) {
      var to = setTimeout(refreshRelativeDate, FIVE_SECS_IN_MS);
      return function () {
        return clearTimeout(to);
      };
    } else if (diffInSecs < ONE_HOUR_IN_SECS) {
      var _to = setTimeout(refreshRelativeDate, ONE_MINUTE_IN_MS);
      return function () {
        return clearTimeout(_to);
      };
    } else if (diffInSecs < ONE_DAY_IN_SECS) {
      var _to2 = setTimeout(refreshRelativeDate, ONE_HOUR_IN_MS);
      return function () {
        return clearTimeout(_to2);
      };
    }
  }, [time, relativeDateRefreshed]);
  return relativeDate;
});