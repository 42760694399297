import Box from '@paperstac/ui/lib/Box';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import styled from 'styled-components';
import { display } from 'styled-system';
import FacebookShareButton from '../../FacebookShareButton';
import { metadata } from './MarketReport2020';

const IntroSection = React.memo((props) => {
  return (
    <Box bg="primary" py={5}>
      <Container>
        <Text textAlign="center" fontSize={[14, 16, 18, 24]}>
          <StyledText color="white" textAlign="center" display={['inline', 'block']}>
            This report contains anonymized trade data based on hundreds of <Bold>Paperstac.com</Bold>{' '}
          </StyledText>
          <StyledText color="white" textAlign="center" display={['inline', 'block']}>
            note closings during <Bold>2020</Bold>. Comparison percentages reference <Bold>2019</Bold> sales data.
          </StyledText>
        </Text>
        <Flex alignContent="center" justifyContent="center" mt={3}>
          <FacebookShareButton url={metadata.url} />
        </Flex>
      </Container>
    </Box>
  );
});

const StyledText = styled(Text)`
  ${display}
`;

const Bold = (props) => <StyledText fontWeight={700} display="inline" {...props} />;

IntroSection.propTypes = {};

IntroSection.defaultProps = {};

IntroSection.displayName = 'IntroSection';

export default IntroSection;
