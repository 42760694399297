import { BASE_URL } from '@paperstac/env';
import getPath from '@paperstac/routes/lib/getPath';
import { BLOG_POSTS_SINGLE } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import Image from '@paperstac/ui/lib/Image';
import Link from '@paperstac/ui/lib/Link';
import PageSubtitle from '@paperstac/ui/lib/PageSubtitle';
import PageTitle from '@paperstac/ui/lib/PageTitle';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import FacebookComments from '../../common/FacebookComments';
import BlogPostLayout from '../../layouts/BlogPostLayout';

const slug = 'how-to-buy-mortgage-notes-from-a-self-directed-ira';
export const metadata = {
  postTitle: 'How to Buy Mortgage Notes From a Self-Directed IRA',
  metaTitle: 'How to Buy Mortgage Notes From a Self-Directed IRA',
  metaDescription:
    "Buying mortgage notes from a Self-Directed IRA account just got a lot easier. We've created push-button integrations to multiple Self-Directed IRA custodians.",
  slug,
  url: `${BASE_URL}${getPath(BLOG_POSTS_SINGLE, slug)}`,
  publishDate: '2019-09-10 08:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1568042882/website-assets/how-to-buy-mortgage-notes-from-a-self-directed-irected-ira-features.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1568042882/website-assets/how-to-buy-mortgage-notes-from-a-self-directed-irected-ira-social.png',
  tags: ['sdira'],
};

const HowToBuyMortgageNotesFromASelfDirectedIRA = React.memo(() => {
  return (
    <BlogPostLayout metadata={metadata}>
      <Container maxWidth={800}>
        <PageTitle my={[40, 40, 40, 64]} maxWidth={600}>
          How to Buy Mortgage Notes From a Self-Directed IRA
        </PageTitle>
        <Text as="p" mb={4}>
          26% of the deals on Paperstac are funded with Self-Directed IRA (SDIRA) accounts{' '}
          <Text
            as="span"
            sx={{
              display: 'inline',
              fontStyle: 'italic',
            }}
          >
            (according to our{' '}
            <Link to={getPath(BLOG_POSTS_SINGLE, '2019-mid-year-market-report')}>2019 Mid-Year Market Report</Link>)
          </Text>
          . However, we noticed a trend where these deals were getting stalled for almost 2 weeks during the funding
          stage due to all of the back-and-forth between the Buyer, Seller and SDIRA Custodian. So, several months back
          we began working on an integration that would streamline the process by linking Paperstac transactions
          directly with the SDIRA Custodian providing an easy-to-follow process that will get us closer to the goal of
          same-day funding from our SDIRA accounts.
        </Text>
        <Text as="p" mb={2} fontWeight="bold">
          Today we are excited to announce the launch of this new feature with 14 different SDIRA Custodian partners!
        </Text>
        <Flex as="ul" mb={6}>
          <Box width="50%">
            <Box as="li">
              <Link href="https://advantaira.com/" target="_blank" rel="noopener noreferrer">
                Advanta IRA
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://www.altoira.com/" target="_blank" rel="noopener noreferrer">
                Alto IRA
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://www.camaplan.com/" target="_blank" rel="noopener noreferrer">
                CamaPlan
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://www.theentrustgroup.com/" target="_blank" rel="noopener noreferrer">
                Entrust Group
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://www.trustetc.com/" target="_blank" rel="noopener noreferrer">
                Equity Trust
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://preferredtrustcompany.com" target="_blank" rel="noopener noreferrer">
                iManagement
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://www.iraservices.com/" target="_blank" rel="noopener noreferrer">
                IRA Services Trust
              </Link>
            </Box>
          </Box>
          <Box width="50%">
            <Box as="li">
              <Link href="https://www.madisontrust.com/" target="_blank" rel="noopener noreferrer">
                Madison Trust
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://www.mainstartrust.com/" target="_blank" rel="noopener noreferrer">
                Mainstar Trust
              </Link>
            </Box>
            <Box as="li">
              <Link href="http://www.midatlanticira.com/" target="_blank" rel="noopener noreferrer">
                Mid Atlantic IRA
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://mountainwestira.com/" target="_blank" rel="noopener noreferrer">
                Mountain West IRA
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://www.nuviewtrust.com/" target="_blank" rel="noopener noreferrer">
                NuView
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://udirectira.com/" target="_blank" rel="noopener noreferrer">
                uDirect IRA Services
              </Link>
            </Box>
            <Box as="li">
              <Link href="https://www.questtrustcompany.com/" target="_blank" rel="noopener noreferrer">
                Quest
              </Link>
            </Box>
          </Box>
        </Flex>
        <PageSubtitle mb={4}>How It Works...</PageSubtitle>
        <Text as="p" mb={4}>
          After you come to terms on a Paperstac deal, you’ll have a To-Do requesting that you add your vesting
          information for the closing. When you create your vesting profile, you’ll want to complete the “Link to
          Self-Directed IRA Account” section to add your SDIRA account.
        </Text>
        <Image
          src="https://res.cloudinary.com/paperstac/image/upload/v1568062420/website-assets/vesting-profile-link-sdira.png"
          alt="Screenshot of Paperstac create a vesting profile form"
          width={800}
          mb={6}
        />
        <Text as="p" mb={4}>
          Once you are done providing your vesting information, Paperstac will add new To-Do items enabling the Buyer
          and Seller to easily provide all of the documents that the SDIRA Custodian requires for funding.
        </Text>
        <Image
          src="https://res.cloudinary.com/paperstac/image/upload/v1568062420/website-assets/required-for-funding-todo-section.png"
          alt="Screenshot of Paperstac To-Do list with Required for Funding section highlighted"
          width={800}
          mb={6}
        />
        <Text as="p" mb={4}>
          Click on the To-Do item to provide required documents and interact with your SDIRA Custodian.
        </Text>
        <Image
          src="https://res.cloudinary.com/paperstac/image/upload/v1568062420/website-assets/required-for-funding-overlay.png"
          alt="Screenshot of Requirements for SDIRA Funding section on Paperstac"
          width={800}
          mb={6}
        />
        <Text as="p" mb={6}>
          That’s it! With our new integration you are able to seamlessly fund your deal with your SDIRA account without
          having to leave your Paperstac transaction!
        </Text>
        <Heading mb={3}>Leave us a comment and let us know what you think.</Heading>
        <FacebookComments url={metadata.url} />
      </Container>
    </BlogPostLayout>
  );
});

HowToBuyMortgageNotesFromASelfDirectedIRA.propTypes = {};

HowToBuyMortgageNotesFromASelfDirectedIRA.defaultProps = {};

HowToBuyMortgageNotesFromASelfDirectedIRA.displayName = 'HowToBuyMortgageNotesFromASelfDirectedIRA';

export default HowToBuyMortgageNotesFromASelfDirectedIRA;
