import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { ADD_NOTE_FILES } from '@paperstac/common/lib/serverDispatchActionTypes';
import dateid from '@paperstac/common/lib/services/dateid';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { VISIBILITY_PRIVATE, VISIBILITY_PUBLIC } from '@paperstac/constants';
import storage from '@paperstac/firebase/lib/storage';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import Dropzone from '@paperstac/ui/lib/Dropzone';
import Flex from '@paperstac/ui/lib/Flex';
import PrefixedInput from '@paperstac/ui/lib/form/PrefixedInput';
import Radio from '@paperstac/ui/lib/form/Radio';
import Heading from '@paperstac/ui/lib/Heading';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import FileExtIcon from '@paperstac/ui/lib/icons/FileExtIcon';
import XIcon from '@paperstac/ui/lib/icons/XIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import UnstyledButton from '@paperstac/ui/lib/UnstyledButton';
import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const NoteScreenAddFiles = React.memo(() => {
  const { uid } = React.useContext(IdentityContext);
  const { currentAccountId } = React.useContext(AccountsContext);
  const { note, showOverview: showListing } = React.useContext(ManageListingOverlayContext);
  const { showOverview } = React.useContext(NoteScreenContext);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [files, dispatch] = React.useReducer((state, { type, payload }) => {
    switch (type) {
      case 'ADD':
        return [
          ...state,
          ...payload.map((file) => ({
            id: dateid(),
            file,
            name: file.name,
            visibility: VISIBILITY_PUBLIC,
          })),
        ];
      case 'REMOVE':
        return state.filter((file) => file.id !== payload);
      case 'UPDATE_NAME':
        return state.map((file) => (file.id !== payload.id ? file : { ...file, name: payload.name }));
      case 'UPDATE_VISIBILITY':
        return state.map((file) => (file.id !== payload.id ? file : { ...file, visibility: payload.visibility }));
      default:
        throw new Error('Action type is not supported');
    }
  }, []);

  const handleAddFiles = React.useCallback((files) => dispatch({ type: 'ADD', payload: files }), []);

  const handleNameChange = React.useCallback(
    (id, name) =>
      dispatch({
        type: 'UPDATE_NAME',
        payload: { id, name },
      }),
    []
  );

  const handleVisibilityChange = React.useCallback(
    (id, visibility) =>
      dispatch({
        type: 'UPDATE_VISIBILITY',
        payload: { id, visibility },
      }),
    []
  );

  const handleRemoveFile = React.useCallback((id) => dispatch({ type: 'REMOVE', payload: id }), []);

  const handleSubmit = React.useCallback(async () => {
    try {
      if (isSubmitting) return;
      setSubmitting(true);
      setErrorMessage(null);
      serverDispatch({
        action: ADD_NOTE_FILES,
        payload: {
          accountId: note.accountId,
          noteId: note.id,
          files: await Promise.all(
            files.map((file) => {
              const filePath = `user-uploads/${uid}/${currentAccountId}/notes/${note.id}/files/${file.id}-${file.name}`;
              return storage
                .child(filePath)
                .put(file.file, { cacheControl: 'public,max-age=31536000' })
                .then(() => ({ id: file.id, name: file.name, visibility: file.visibility, filePath }));
            })
          ),
        },
      }).then(showOverview);
    } catch (error) {
      setSubmitting(false);
      setErrorMessage(error.message);
    }
  }, [currentAccountId, files, note.accountId, note.id, isSubmitting, showOverview, uid]);

  return (
    <DescriptionGrid
      sx={{
        bg: 'gray.1',
        border: 'none',
        borderTop: 'default',
        borderBottom: 'default',
        borderRadius: 0,
      }}
    >
      <DescriptionGridCell bg="highlight">
        <Flex alignItems="center">
          <LinkButton onClick={showListing}>Listing</LinkButton>
          <ChevronRightIcon size={12} mx={2} color="gray.6" />
          <LinkButton onClick={showOverview}>Note</LinkButton>
          <ChevronRightIcon size={12} mx={2} color="gray.6" />
          Add Files
        </Flex>
      </DescriptionGridCell>
      <DescriptionGridCell>
        <Dropzone onFiles={handleAddFiles} mb={5} />
        {!!files.length && (
          <Box>
            {!!errorMessage && <Box sx={{ mb: 3, color: 'red' }}>{errorMessage}</Box>}
            <Heading mb={3} fontSize={3}>
              Files to Upload
            </Heading>
            <Box fontSize={1} mb={3}>
              <Text variant="bold">Public</Text> files are visible once a buyer starts a transaction with you.{' '}
              <Text variant="bold">Private</Text> files are not visible until you explicitly share them with a buyer.
            </Box>
            {files.map((file) => (
              <File
                key={file.id}
                file={file}
                onNameChange={handleNameChange}
                onVisibilityChange={handleVisibilityChange}
                onRemove={handleRemoveFile}
              />
            ))}
            <Box mt={4}>
              {!!errorMessage && <Box sx={{ mb: 3, color: 'red' }}>{errorMessage}</Box>}
              <Button variant="primary" mr={3} onClick={handleSubmit} busy={isSubmitting}>
                Upload {files.length === 1 ? 'File' : 'Files'}
              </Button>
              <Button variant="default" onClick={showOverview}>
                Cancel
              </Button>
            </Box>
          </Box>
        )}
      </DescriptionGridCell>
    </DescriptionGrid>
  );
});

const File = React.memo(({ file, onNameChange, onVisibilityChange, onRemove, ...props }) => {
  const handleNameChange = React.useCallback((evt) => onNameChange(file.id, evt.target.value), [file.id, onNameChange]);
  const handleVisibilityChange = React.useCallback(
    (evt) => onVisibilityChange(file.id, evt.target.value),
    [file.id, onVisibilityChange]
  );
  const handleRemove = React.useCallback((evt) => onRemove(file.id), [file.id, onRemove]);

  return (
    <Box mb={3}>
      <Flex alignItems="center" mb={1}>
        <Box sx={{ flex: '1 1 0', mr: 3 }}>
          <PrefixedInput
            value={file.name}
            onChange={handleNameChange}
            prefix={<FileExtIcon filePath={file.file.path} />}
          />
        </Box>
        <UnstyledButton
          onClick={handleRemove}
          sx={{
            cursor: 'pointer',
            color: 'gray.6',
            '&:hover,&:focus': { color: 'red' },
          }}
        >
          <XIcon />
        </UnstyledButton>
      </Flex>
      <Flex fontSize={1}>
        <Flex as="label" sx={{ alignItems: 'center', mr: 3 }}>
          <Radio
            value={VISIBILITY_PUBLIC}
            checked={file.visibility === VISIBILITY_PUBLIC}
            onChange={handleVisibilityChange}
          />
          Public
        </Flex>
        <Flex as="label" sx={{ alignItems: 'center' }}>
          <Radio
            value={VISIBILITY_PRIVATE}
            checked={file.visibility === VISIBILITY_PRIVATE}
            onChange={handleVisibilityChange}
          />
          Private
        </Flex>
      </Flex>
    </Box>
  );
});

NoteScreenAddFiles.propTypes = {};

NoteScreenAddFiles.defaultProps = {};

NoteScreenAddFiles.displayName = 'NoteScreenAddFiles';

export default NoteScreenAddFiles;
