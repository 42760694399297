import * as ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_TRACKING_ID } from '@paperstac/env';

const analyticsSupported = () => {
  return !!GOOGLE_ANALYTICS_TRACKING_ID;
};

if (analyticsSupported()) {
  ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
}

export const trackPage = (page, options = {}) => {
  if (!analyticsSupported()) {
    return;
  }
  window.fbq && window.fbq('track', 'PageView');
};

export const fireRegisteredEvent = () => {
  if (!analyticsSupported()) {
    return;
  }
  ReactGA.event({
    category: 'User',
    action: 'Registered',
  });
  window.fbq && window.fbq('track', 'CompleteRegistration');
};

export const setUserId = (userId) => {
  if (!analyticsSupported()) {
    return;
  }
  ReactGA.set({ userId });
};
