import useFirestoreSubscribe from '@paperstac/common/lib/hooks/useFirestoreSubscribe';
import useRelativeDate from '@paperstac/common/lib/hooks/useRelativeDate';
import { noteAdjustmentsByAccountAndNoteIdRef } from '@paperstac/firestore-collections/lib/noteAdjustments';
import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import Link from '@paperstac/ui/lib/Link';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingMessage from '../../common/LoadingMessage';
import PaymentHistoryProvider from './PaymentHistoryProvider';
import PaymentHistorySpreadsheet from './PaymentHistorySpreadsheet';

const PaymentHistorySection = React.memo(
  ({ noteAccountId, noteId, paymentHistoryFileDate, paymentHistoryFileUrl, ...props }) => {
    const relativeDate = useRelativeDate(paymentHistoryFileDate);
    const [adjustments] = useFirestoreSubscribe(
      noteAdjustmentsByAccountAndNoteIdRef(noteAccountId, noteId).orderBy('transactionDate', 'desc'),
      [noteAccountId, noteId]
    );

    if (!Array.isArray(adjustments)) return <LoadingMessage />;

    return (
      <Box {...props}>
        <Heading
          sx={{
            mb: 2,
            fontSize: 2,
            textTransform: 'uppercase',
          }}
        >
          Payment History
        </Heading>

        {!paymentHistoryFileUrl && !adjustments.length && (
          <Box>
            <Text variant="italic">Payment history not provided...</Text>
          </Box>
        )}

        {!!paymentHistoryFileUrl && (
          <Box mb={3}>
            <Link href={paymentHistoryFileUrl} target="_blank" mr={1}>
              Download Payment History
            </Link>
            <Text variant="italic">- added {relativeDate}</Text>
          </Box>
        )}

        <PaymentHistoryProvider adjustments={adjustments}>
          <PaymentHistorySpreadsheet maxHeight="375px" />
        </PaymentHistoryProvider>
      </Box>
    );
  }
);

PaymentHistorySection.propTypes = {
  noteAccountId: PropTypes.string.isRequired,
  noteId: PropTypes.string.isRequired,
  paymentHistoryFileDate: PropTypes.object,
  paymentHistoryFileUrl: PropTypes.string,
};

PaymentHistorySection.defaultProps = {};

PaymentHistorySection.displayName = 'PaymentHistorySection';

export default PaymentHistorySection;
