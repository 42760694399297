import { SEND_PASSWORD_RESET_EMAIL } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { sendPasswordResetEmailDispatchValidator } from '@paperstac/firestore-collections/lib/identities';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import Text from '@paperstac/ui/lib/Text';
import { Form, Formik } from 'formik';
import React from 'react';
import { AUTH_OVERLAY_VIEWS, AuthOverlayContext } from './AuthOverlayProvider';
import ErrorMessage from './ErrorMessage';
import LinkButton from './LinkButton';
import TitleSubtext from './TitleSubtext';
import ViewTitle from './ViewTitle';

const ForgotPasswordView = React.memo((props) => {
  const { setView } = React.useContext(AuthOverlayContext);
  const [sentToEmail, setSentToEmail] = React.useState(null);

  return !!sentToEmail ? (
    <Box>
      <ViewTitle>Email Sent!</ViewTitle>
      <TitleSubtext>
        We sent a password reset email to{' '}
        <Text display="inline" fontWeight="bold">
          {sentToEmail}
        </Text>
        . You should be receiving it within a minute or two.
      </TitleSubtext>
      <Box mt={40} textAlign="center">
        I did not receive an email. <LinkButton onClick={() => setSentToEmail(null)}>Retry</LinkButton>.
      </Box>
    </Box>
  ) : (
    <Formik
      initialValues={{ email: '', url: window.location.href }}
      validationSchema={sendPasswordResetEmailDispatchValidator}
      onSubmit={async (payload, { setSubmitting, setStatus }) => {
        serverDispatch({ action: SEND_PASSWORD_RESET_EMAIL, payload })
          .then(() => setSentToEmail(payload.email))
          .catch((error) => {
            setSubmitting(false);
            setStatus({ errorMessage: error.message });
          });
      }}
      render={({ isSubmitting, status }) => (
        <Form>
          <ViewTitle>Forgot Your Password?</ViewTitle>
          <TitleSubtext>
            Enter the email associated with your
            <br />
            Paperstac Identity and we will send you a password reset email.
          </TitleSubtext>
          <InputFormGroup name="email" label="Email Address:" type="email" required />
          {status && status.errorMessage && <ErrorMessage>{status.errorMessage}</ErrorMessage>}
          <Button type="submit" variant="primary" block={true} busy={isSubmitting}>
            Send Reset Email
          </Button>
          <Box mt={40} textAlign="center">
            I remembered my password. <LinkButton onClick={() => setView(AUTH_OVERLAY_VIEWS.LOGIN)}>Sign In</LinkButton>
            .
          </Box>
        </Form>
      )}
    />
  );
});

ForgotPasswordView.propTypes = {};

ForgotPasswordView.defaultProps = {};

ForgotPasswordView.displayName = 'ForgotPasswordView';

export default ForgotPasswordView;
