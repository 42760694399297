import { FOR_SALE_ARCHIVE } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import Checkbox from '@paperstac/ui/lib/form/Checkbox';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import Link from '@paperstac/ui/lib/Link';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import { Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';

const StatusScreen = React.memo(() => {
  const { listing, setVerifying, showOverview, updateListing, verificationRequiredToList } =
    React.useContext(ManageListingOverlayContext);

  return (
    <Formik
      initialValues={{ id: listing.id, isActive: !!listing.isActive, isPrivate: !!listing.isPrivate }}
      onSubmit={(payload, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        updateListing(payload)
          .then(showOverview)
          .catch((error) => setStatus({ errorMessage: error.message }))
          .finally(() => setSubmitting(false));
      }}
      children={(formik) => (
        <Form>
          <DescriptionGrid
            sx={{
              bg: 'gray.1',
              border: 'none',
              borderTop: 'default',
              borderBottom: 'default',
              borderRadius: 0,
            }}
          >
            <DescriptionGridCell bg="highlight">
              <Flex alignItems="center">
                <LinkButton onClick={showOverview}>Listing</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                Status
              </Flex>
            </DescriptionGridCell>
            <DescriptionGridCell term="Active">
              <Flex as="label" sx={{ alignItems: 'center', fontWeight: 'bold', my: 2 }}>
                {verificationRequiredToList && !formik.values.isActive ? (
                  <Field type="checkbox" checked={false} component={Checkbox} onClick={() => setVerifying(true)} />
                ) : (
                  <Field name="isActive" type="checkbox" checked={formik.values.isActive} component={Checkbox} />
                )}
                Listing is Active
              </Flex>
              <Box fontSize={1}>A listing must be active in order to start a transaction with a buyer.</Box>
            </DescriptionGridCell>
            <DescriptionGridCell term="Privacy">
              <Flex as="label" sx={{ alignItems: 'center', fontWeight: 'bold', my: 2 }}>
                <Field name="isPrivate" type="checkbox" checked={formik.values.isPrivate} component={Checkbox} />
                Make Private
              </Flex>
              <Box fontSize={1}>
                Private listings are not visible on our <Link to={FOR_SALE_ARCHIVE}>For Sale</Link> page. You will need
                to share your listing's link to any potential buyers.
              </Box>
            </DescriptionGridCell>
            <DescriptionGridCell>
              {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
              <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>
                Update Status
              </Button>
              <Button variant="default" onClick={showOverview}>
                Cancel
              </Button>
            </DescriptionGridCell>
          </DescriptionGrid>
        </Form>
      )}
    />
  );
});

StatusScreen.propTypes = {};

StatusScreen.defaultProps = {};

StatusScreen.displayName = 'StatusScreen';

export default StatusScreen;
