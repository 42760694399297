import Box from '@paperstac/ui/lib/Box';
import Container from '@paperstac/ui/lib/Container';
import Heading from '@paperstac/ui/lib/Heading';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';

const Section = React.memo(({ title, subtitle, bg, children }) => {
  return (
    <Box bg={bg} py={[40, 64]}>
      <Container>
        {title && (
          <Heading as="h2" fontSize={[20, 32]} mb={2} textAlign="center">
            {title}
          </Heading>
        )}
        {subtitle && (
          <Text fontSize={[14, 16]} mb={[40, 64]} textAlign="center">
            {subtitle}
          </Text>
        )}
        {children}
      </Container>
    </Box>
  );
});

Section.propTypes = {
  bg: PropTypes.string,
  children: PropTypes.any.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

Section.defaultProps = {
  bg: 'gray.2',
};

Section.displayName = 'Section';

export default Section;
