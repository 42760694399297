import { connectHits } from 'react-instantsearch-dom';

const filterHits = (hits, filteringStarredListings, activeStarredListings) => {
  if (filteringStarredListings) {
    return hits.filter((listing) => activeStarredListings.find((sl) => sl.listingId === listing.id));
  }
  return hits;
};

const AlgoliaResults = ({ hits, children, filteringStarredListings, activeStarredListings }) =>
  children(filterHits(hits, filteringStarredListings, activeStarredListings));

export default connectHits(AlgoliaResults);
