import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import Flex from '@paperstac/ui/lib/Flex';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import React from 'react';
import LoadingMessage from '../LoadingMessage';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import NoteScreenAddFiles from './NoteScreenAddFiles';
import NoteScreenAddPaymentHistory from './NoteScreenAddPaymentHistory';
import NoteScreenEditDetails from './NoteScreenEditDetails';
import NoteScreenEditPropertyValue from './NoteScreenEditPropertyValue';
import NoteScreenEditSeniorDetails from './NoteScreenEditSeniorDetails';
import NoteScreenEditUnderlyingLiens from './NoteScreenEditUnderlyingLiens';
import NoteScreenManageAdjustment from './NoteScreenManageAdjustment';
import NoteScreenOverview from './NoteScreenOverview';

export const SECTIONS = {
  ADD_ADJUSTMENT: 'ADD_ADJUSTMENT',
  ADD_FILES: 'ADD_FILES',
  ADD_PAYMENT_HISTORY: 'ADD_PAYMENT_HISTORY',
  DETAILS: 'DETAILS',
  EDIT_ADJUSTMENT: 'EDIT_ADJUSTMENT',
  OVERVIEW: 'OVERVIEW',
  PROPERTY_VALUE: 'PROPERTY_VALUE',
  SENIOR_DETAILS: 'SENIOR_DETAILS',
};

const NoteScreenContext = React.createContext();
const { Provider } = NoteScreenContext;

const NoteScreen = React.memo(() => {
  const { note, showOverview: showListing } = React.useContext(ManageListingOverlayContext);
  const [section, setSection] = React.useState(SECTIONS.OVERVIEW);
  const [adjustment, setAdjustment] = React.useState(null);
  const showAddAdjustment = React.useCallback(() => setSection(SECTIONS.ADD_ADJUSTMENT), []);
  const showAddFiles = React.useCallback(() => setSection(SECTIONS.ADD_FILES), []);
  const showAddPaymentHistory = React.useCallback(() => setSection(SECTIONS.ADD_PAYMENT_HISTORY), []);
  const showAddUnderlyingLiens = React.useCallback(() => setSection(SECTIONS.UNDERLYING_LIENS), []);
  const showDetails = React.useCallback(() => setSection(SECTIONS.DETAILS), []);
  const showEditAdjustment = React.useCallback((adjustment) => {
    setAdjustment(adjustment);
    setSection(SECTIONS.EDIT_ADJUSTMENT);
  }, []);
  const showOverview = React.useCallback(() => setSection(SECTIONS.OVERVIEW), []);
  const showPropertyValue = React.useCallback(() => setSection(SECTIONS.PROPERTY_VALUE), []);
  const showSeniorDetails = React.useCallback(() => setSection(SECTIONS.SENIOR_DETAILS), []);

  const providerValue = React.useMemo(
    () => ({
      adjustment,
      showAddAdjustment,
      showAddFiles,
      showAddPaymentHistory,
      showDetails,
      showEditAdjustment,
      showOverview,
      showPropertyValue,
      showSeniorDetails,
      showAddUnderlyingLiens,
    }),
    [
      adjustment,
      showAddAdjustment,
      showAddFiles,
      showAddPaymentHistory,
      showDetails,
      showEditAdjustment,
      showOverview,
      showPropertyValue,
      showSeniorDetails,
      showAddUnderlyingLiens,
    ]
  );

  if (!note)
    return (
      <DescriptionGrid
        sx={{
          bg: 'gray.1',
          border: 'none',
          borderTop: 'default',
          borderBottom: 'default',
          borderRadius: 0,
        }}
      >
        <DescriptionGridCell bg="highlight">
          <Flex alignItems="center">
            <LinkButton onClick={showListing}>Listing</LinkButton>
            <ChevronRightIcon size={12} mx={2} color="gray.6" />
            Note
          </Flex>
        </DescriptionGridCell>
        <DescriptionGridCell>
          <LoadingMessage />
        </DescriptionGridCell>
      </DescriptionGrid>
    );

  return (
    <Provider value={providerValue}>
      {section === SECTIONS.ADD_ADJUSTMENT && <NoteScreenManageAdjustment />}
      {section === SECTIONS.ADD_FILES && <NoteScreenAddFiles />}
      {section === SECTIONS.ADD_PAYMENT_HISTORY && <NoteScreenAddPaymentHistory />}
      {section === SECTIONS.DETAILS && <NoteScreenEditDetails />}
      {section === SECTIONS.EDIT_ADJUSTMENT && <NoteScreenManageAdjustment />}
      {section === SECTIONS.OVERVIEW && <NoteScreenOverview />}
      {section === SECTIONS.PROPERTY_VALUE && <NoteScreenEditPropertyValue />}
      {section === SECTIONS.SENIOR_DETAILS && <NoteScreenEditSeniorDetails />}
      {section === SECTIONS.UNDERLYING_LIENS && <NoteScreenEditUnderlyingLiens />}
    </Provider>
  );
});

NoteScreen.propTypes = {};

NoteScreen.defaultProps = {};

NoteScreen.displayName = 'NoteScreen';

export default NoteScreen;

export { NoteScreenContext };
