import { ALGOLIA_ID } from '@paperstac/env';
import algoliasearch from 'algoliasearch/lite';
import React from 'react';
export default (function (searchOnlyApiKey, cache) {
  return React.useMemo(function () {
    var options = {};
    if (cache) options.responsesCache = cache;
    if (cache) options.requestsCache = cache;
    return algoliasearch(ALGOLIA_ID, searchOnlyApiKey, options);
  }, [ALGOLIA_ID, searchOnlyApiKey]);
});