import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { FilterCollectionContext } from '@paperstac/common/lib/components/FilterCollectionProvider';
import { SortCollectionContext } from '@paperstac/common/lib/components/SortCollectionProvider';
import useFirestoreSubscribe from '@paperstac/common/lib/hooks/useFirestoreSubscribe';
import { normalizeDate } from '@paperstac/helpers/lib/dateHelpers';
import { getReasonsForUnlistable } from '@paperstac/common/lib/services/listingsHelper';
import {
  TRANSACTION_STATUS_CLOSING,
  TRANSACTION_STATUS_COMPLETE,
  TRANSACTION_STATUS_NEGOTIATIONS,
} from '@paperstac/constants';
import { withRouter } from 'react-router-dom';
import { listingsByAccountIdRef } from '@paperstac/firestore-collections/lib/listings';
import { transactionsByStatusesQuery } from '@paperstac/firestore-collections/lib/transactions';
import getPath from '@paperstac/routes/lib/getPath';
import { FOR_SALE_SINGLE, IMPORTS_ARCHIVE } from '@paperstac/routes/lib/webRoutes';
import PropTypes from 'prop-types';
import React from 'react';
import { settingsRef } from '@paperstac/firestore-collections/lib/settings';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { flatfileImporter } from '@flatfile/sdk';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { FLATFILE_IMPORT_COMPLETE, RETRIEVE_FLATFILE_TOKEN } from '@paperstac/common/lib/serverDispatchActionTypes';
import { FLATFILE_MY_LISTINGS_EMBED_ID } from '@paperstac/env';

export const LISTING_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  NEEDS_ATTENTION: 'Needs Attention',
};

export const LISTING_SORTS = {
  'Street Address': ['addresses', 'ASC', 'Street Address'],
  'Last Update': ['updatedDate', 'DESC', 'Last Update'],
  Transactions: ['transactionCount', 'DESC', 'Transactions'],
  Popularity: ['popularity', 'ASC', 'Popularity'],
  Newest: ['id', 'DESC', 'Newest'],
  Oldest: ['id', 'ASC', 'Oldest'],
};

const MyListingsPageContext = React.createContext();
const { Provider } = MyListingsPageContext;

const MyListingsPageProvider = React.memo(({ children, history }) => {
  const { identity } = React.useContext(IdentityContext);
  const [settings] = useFirestoreSubscribe(settingsRef());
  const { currentAccountId } = React.useContext(AccountsContext);
  const { filterCollection, lastUpdated: filtersUpdated } = React.useContext(FilterCollectionContext);
  const { sortCollection, lastUpdated: sortUpdated } = React.useContext(SortCollectionContext);
  const [listings, setListings] = React.useState(null);
  const [manageListingId, setManageListingId] = React.useState(null);
  const [showImporter, setShowImporter] = React.useState(false);
  const verificationRequiredToActivate = !!settings?.requireVerificationToList && !identity?.isVerified;
  const listingToManage = React.useMemo(() => {
    if (!listings || !manageListingId) return null;
    return listings.find(({ id }) => id === manageListingId);
  }, [listings, manageListingId]);
  const hideManageListing = React.useCallback(() => setManageListingId(null), []);
  const [transactionsToView, setTransactionsToView] = React.useState([]);
  const [createVisible, setCreateVisible] = React.useState(false);
  const showCreate = React.useCallback(() => setCreateVisible(true), []);
  const hideCreate = React.useCallback(() => setCreateVisible(false), []);
  const [verifyVisible, setVerifyVisible] = React.useState(false);
  const showVerify = React.useCallback(() => setVerifyVisible(true), []);
  const hideVerify = React.useCallback(() => setVerifyVisible(false), []);
  const [importToken, setImportToken] = React.useState('');
  const startImport = React.useCallback(async () => {
    if (!importToken) return;
    const importer = flatfileImporter(importToken);
    importer.on('error', ({ error }) => {
      console.log(`Importer returned an error: ${error.message}`);
    });
    importer.on('complete', ({ batchId }) => {
      console.log(`Importer batch ${batchId} has completed.`);
      serverDispatch({
        action: FLATFILE_IMPORT_COMPLETE,
        payload: { accountId: currentAccountId, batchId, embedId: FLATFILE_MY_LISTINGS_EMBED_ID },
      })
        .then(() => history.push(IMPORTS_ARCHIVE))
        .catch((error) => {
          alert(error.message);
        });
    });
    await importer.launch();
  }, [currentAccountId, history, importToken]);
  const [accountListings] = useFirestoreSubscribe(listingsByAccountIdRef(currentAccountId), [currentAccountId]);
  const transactionsQuery = React.useMemo(
    () =>
      transactionsByStatusesQuery([
        TRANSACTION_STATUS_CLOSING,
        TRANSACTION_STATUS_COMPLETE,
        TRANSACTION_STATUS_NEGOTIATIONS,
      ]).where('sellerId', '==', currentAccountId),
    [currentAccountId]
  );
  const [transactions] = useFirestoreSubscribe(transactionsQuery, [transactionsQuery]);

  const resetTransactionsToView = React.useCallback(() => setTransactionsToView([]), []);

  React.useEffect(() => {
    if (!accountListings) return;
    const notDeleted = accountListings.filter(({ _deleted }) => !_deleted);
    transformListings(notDeleted, transactions).then((transformed) => {
      const filtered = filterCollection(transformed);
      const sorted = sortCollection(filtered);
      setListings(sorted);
    });
  }, [accountListings, filterCollection, filtersUpdated, sortCollection, sortUpdated, transactions]);

  React.useEffect(() => {
    serverDispatch({ action: RETRIEVE_FLATFILE_TOKEN, payload: { embedId: FLATFILE_MY_LISTINGS_EMBED_ID } })
      .then(({ data }) => setImportToken(data))
      .catch((error) => {
        console.log(`Error returned when retrieving import token: ${error.message}`);
      });
  }, []);

  const providerValue = React.useMemo(
    () => ({
      createVisible,
      hideCreate,
      hideManageListing,
      hideVerify,
      listings,
      listingToManage,
      resetTransactionsToView,
      setManageListingId,
      setShowImporter,
      setTransactionsToView,
      showCreate,
      showImporter,
      showVerify,
      startImport,
      transactionsToView,
      verificationRequiredToActivate,
      verifyVisible,
    }),
    [
      createVisible,
      hideCreate,
      hideManageListing,
      hideVerify,
      listings,
      listingToManage,
      resetTransactionsToView,
      showCreate,
      showImporter,
      showVerify,
      startImport,
      transactionsToView,
      verificationRequiredToActivate,
      verifyVisible,
    ]
  );

  return <Provider value={providerValue}>{children}</Provider>;
});

export const transformListing = async (listing, allTransactions) => {
  const transactions = allTransactions ? allTransactions.filter(({ listingId }) => listingId === listing.id) : [];
  const notes = listing.notes.sort((a, b) => (a.streetAddress < b.streetAddress ? -1 : 1));
  const needsAttention = await getReasonsForUnlistable(listing);
  return {
    ...listing,
    notes,
    status: listing.isActive
      ? LISTING_STATUS.ACTIVE
      : !!needsAttention.count
        ? LISTING_STATUS.NEEDS_ATTENTION
        : LISTING_STATUS.INACTIVE,
    addresses: notes.map(({ fullAddress }) => fullAddress).join('|'),
    createdDate: listing.createdDate ? normalizeDate(listing.createdDate) : null,
    updatedDate: listing.updatedDate ? normalizeDate(listing.updatedDate) : null,
    listingUrl: getPath(FOR_SALE_SINGLE, listing.id),
    transactions,
    transactionCount: transactions.length,
    needsAttention,
  };
};

const transformListings = (listings, allTransactions) => {
  return Promise.all(listings.map((listing) => transformListing(listing, allTransactions)));
};

MyListingsPageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

MyListingsPageProvider.defaultProps = {};

MyListingsPageProvider.displayName = 'MyListingsPageProvider';

export default withRouter(MyListingsPageProvider);

export { MyListingsPageContext };
