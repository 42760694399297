import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { IGNORE_IDENTITY_VERIFICATION } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import get from 'lodash/get';
import React from 'react';
import { withRouter } from 'react-router-dom';
import AuthOverlay from './AuthOverlay/AuthOverlay';
import { AUTH_OVERLAY_VIEWS } from './AuthOverlay/AuthOverlayProvider';

const IdentityManagerContext = React.createContext();
const { Provider } = IdentityManagerContext;

const IdentityManager = React.memo((props) => {
  const { uid, claims, identity } = React.useContext(IdentityContext);
  const { currentAccountId, accounts, currentAccount } = React.useContext(AccountsContext);

  const [showLogin, setShowLogin] = React.useState(false);
  const [showRegister, setShowRegister] = React.useState(false);
  const [showAccountChooser, setShowAccountChooser] = React.useState(false);

  const state = React.useMemo(() => {
    return {
      showLogin: () => setShowLogin(true),
      hideLogin: () => setShowLogin(false),
      showRegister: () => setShowRegister(true),
      hideRegister: () => setShowRegister(false),
      showAccountChooser: () => setShowAccountChooser(true),
      hideAccountChooser: () => setShowAccountChooser(false),
      ignoreVerification: () =>
        serverDispatch({ action: IGNORE_IDENTITY_VERIFICATION }).catch((error) => alert(error.message)),
    };
  }, []);

  React.useEffect(() => {
    if (!!uid) setShowLogin(false);
  }, [uid]);

  let view;
  if (!uid && showLogin) {
    view = AUTH_OVERLAY_VIEWS.LOGIN;
  } else if (!identity && showRegister) {
    view = AUTH_OVERLAY_VIEWS.CREATE_IDENTITY;
  } else if (!!identity && Array.isArray(accounts) && !accounts.length) {
    view = AUTH_OVERLAY_VIEWS.CREATE_ACCOUNT;
  } else if (!!identity && !!currentAccount && !get(claims, 'admin') && typeof currentAccount.isBroker !== 'boolean') {
    view = AUTH_OVERLAY_VIEWS.CREATE_ACCOUNT;
  } else if (!!identity && Array.isArray(accounts) && accounts.length && (!currentAccountId || showAccountChooser)) {
    view = AUTH_OVERLAY_VIEWS.CHOOSE_ACCOUNT;
  }

  return (
    <Provider value={state}>
      {view ? (
        <>
          {props.children}
          <AuthOverlay view={view} />
        </>
      ) : (
        props.children
      )}
    </Provider>
  );
});

IdentityManager.propTypes = {};

IdentityManager.defaultProps = {};

IdentityManager.displayName = 'IdentityManager';

export default withRouter(IdentityManager);

export { IdentityManagerContext };
