import React from 'react';
import report from './report';
import Section from './Section';
import StatGrid from './StatGrid';

const AveragePrice = React.memo((props) => {
  return (
    <Section title="Average Sale Prices" subtitle="(per individual asset)" bg="white">
      <StatGrid
        stat1={report.averagePrice[0]}
        stat2={report.averagePrice[1]}
        stat3={report.averagePrice[2]}
        stat4={report.averagePrice[3]}
      />
    </Section>
  );
});

AveragePrice.propTypes = {};

AveragePrice.defaultProps = {};

AveragePrice.displayName = 'AveragePrice';

export default AveragePrice;
