import React from 'react';
import { Helmet } from 'react-helmet';
import IdentityManager from './common/IdentityManager';
import Toaster from './Toaster';

const BaseLayout = React.memo(({ children }) => (
  <>
    <Helmet>
      <title>Sell or Buy Mortgage Notes on a Mortgage Note Trading Platform</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="Description"
        content="Paperstac - the digital mortgage note transaction engine. Sell mortgage notes faster, buy mortgage notes easier. View mortgage notes for sale or list for free."
      />
      <meta
        itemProp="description"
        content="Paperstac - the digital mortgage note transaction engine. Sell mortgage notes faster, buy mortgage notes easier. View mortgage notes for sale or list for free."
      />
    </Helmet>
    <Toaster />
    <IdentityManager>{children}</IdentityManager>
  </>
));

BaseLayout.propTypes = {};

BaseLayout.defaultProps = {};

BaseLayout.displayName = 'BaseLayout';

export default BaseLayout;
