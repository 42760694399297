import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { GET_STRIPE_VERIFICATION_SESSION_CLIENT_SECRET } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Spinner from '@paperstac/ui/lib/Spinner';
import React from 'react';
import { MergeIdentitiesContext } from '../MergeIdentities/MergeIdentitiesProvider';
import useBoolean from '@paperstac/hooks/lib/useBoolean';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '@paperstac/env';
import ErrorMessage from '../AuthOverlay/ErrorMessage';
import InfoMessage from '../AuthOverlay/InfoMessage';
import LinkButton from '../AuthOverlay/LinkButton';
import { intercomShow } from '@paperstac/common/lib/services/intercomMessenger';

const handleSupportRequest = () => intercomShow();

const ValidateIdentityStripe = React.memo(() => {
  const { identity } = React.useContext(IdentityContext);
  const { showMergeIdentities } = React.useContext(MergeIdentitiesContext);
  const [stripe, setStripe] = React.useState(null);
  const [isVerifying, setVerifying, setNotVerifying] = useBoolean();

  const handleVerify = React.useCallback(async () => {
    if (isVerifying) return;
    setVerifying();
    const clientSecret = await serverDispatch({ action: GET_STRIPE_VERIFICATION_SESSION_CLIENT_SECRET })
      .then((response) => response.data)
      .catch((err) => {
        alert(err.message);
        setNotVerifying();
      });
    const { error } = await stripe.verifyIdentity(clientSecret);
    if (error) console.log(error);
  }, [isVerifying, setNotVerifying, setVerifying, stripe]);

  React.useEffect(() => {
    loadStripe(STRIPE_PUBLIC_KEY).then((stripe) => setStripe(stripe));
  }, []);

  if (!stripe) return null;

  if (identity.isMatchingIdentity)
    return (
      <Box textAlign="center">
        <ErrorMessage>
          We have found an identity that matches the details you provided. A person is only able to have a single
          identity on Paperstac.
        </ErrorMessage>
        <InfoMessage>
          If you have multiple Paperstac Identities (logins), you can combine them using our{' '}
          <LinkButton onClick={showMergeIdentities} fontSize={1} fontWeight="bold">
            Merge Identity Tool
          </LinkButton>
          . If you do not have multiple Paperstac Identities, and feel you've received this error by mistake, please{' '}
          <LinkButton onClick={handleSupportRequest} fontSize={1} fontWeight="bold">
            Contact Paperstac Support
          </LinkButton>
          .
        </InfoMessage>
      </Box>
    );

  if (identity.verificationSessionStatus === 'processing')
    return (
      <Box textAlign="center">
        <Spinner />
        <Box color="blue">Processing Results</Box>
        <Box mt={3} mb={3} fontSize={1}>
          Results might take a minute or two to complete. This screen will update once your verification result is done
          processing.
        </Box>
      </Box>
    );

  if (identity.verificationSessionStatus === 'requires_input' && !!identity.validationErrorMessage)
    return (
      <Box textAlign="center">
        <ErrorMessage>{identity.validationErrorMessage}</ErrorMessage>
        <Button onClick={handleVerify} variant="primary" block mb={3} busy={isVerifying}>
          Retry Verification
        </Button>
      </Box>
    );

  return (
    <Box textAlign="center">
      <Button onClick={handleVerify} variant="primary" block busy={isVerifying}>
        Begin Verification
      </Button>
    </Box>
  );
});

ValidateIdentityStripe.propTypes = {};

ValidateIdentityStripe.defaultProps = {};

ValidateIdentityStripe.displayName = 'ValidateIdentityStripe';

export default ValidateIdentityStripe;
