import { BASE_URL } from '@paperstac/env';
import getPath from '@paperstac/routes/lib/getPath';
import { BLOG_POSTS_SINGLE } from '@paperstac/routes/lib/webRoutes';
import React from 'react';
import FacebookComments from '../../common/FacebookComments';
import BlogPostLayout from '../../layouts/BlogPostLayout';
import IndustryInsights from './IndustryInsights';
import DealOverview from './DealOverview';
import HeroSection from './HeroSection';
import IntroSection from './IntroSection';
import Itb from './Itb';
import Itv from './Itv';
import Price from './Price';
import Section from './Section';

const slug = '2021-mid-year-market-report';
export const metadata = {
  postTitle: "Paperstac's 2021 Mid Year Market Report",
  metaTitle: "Paperstac's 2021 Mid Year Market Report",
  metaDescription:
    'See what mortgage notes sold for on the Paperstac marketplace during the first half of 2021 in our market report.',
  slug,
  url: `${BASE_URL}${getPath(BLOG_POSTS_SINGLE, slug)}`,
  publishDate: '2021-07-21 08:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1626879228/website-assets/2021-mid-year-market-report-featured.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1626879618/website-assets/2021-mid-year-market-report-social.png',
  tags: ['report'],
};

const MidYearMarketReport2021 = React.memo(() => {
  return (
    <BlogPostLayout metadata={metadata}>
      <HeroSection postTitle={metadata.postTitle} />
      <IntroSection />
      <Itb />
      <Itv />
      <Price />
      <DealOverview />
      <IndustryInsights />
      <Section title="Comments" subtitle="Let us know what you think...">
        <FacebookComments url={metadata.url} />
      </Section>
    </BlogPostLayout>
  );
});

MidYearMarketReport2021.propTypes = {};

MidYearMarketReport2021.defaultProps = {};

MidYearMarketReport2021.displayName = 'MidYearMarketReport2021';

export default MidYearMarketReport2021;
