import React from 'react';
import CloudinaryImage from './CloudinaryImage';

export default ({ size, user, ...props }) => (
  <CloudinaryImage
    url={user.avatar}
    alt={user.displayName}
    width={size || 40}
    height={size || 40}
    crop
    circle
    {...props}
  />
);
