import {
  HIDE_NOTE_FILES_OVERLAY,
  HIDE_NOTE_HISTORY_OVERLAY,
  HIDE_NOTE_LISTING_OVERLAY,
  HIDE_NOTE_PHOTOS_OVERLAY,
  HIDE_NOTE_POOL_LISTING_OVERLAY,
  SHOW_NOTE_FILES_OVERLAY,
  SHOW_NOTE_HISTORY_OVERLAY,
  SHOW_NOTE_LISTING_OVERLAY,
  SHOW_NOTE_PHOTOS_OVERLAY,
  SHOW_NOTE_POOL_LISTING_OVERLAY,
} from '../actions/overlayActions';

const DEFAULT_STATE = {};

export default function currentUserReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case HIDE_NOTE_FILES_OVERLAY:
      return { ...state, noteFiles: false };
    case SHOW_NOTE_FILES_OVERLAY:
      return { ...state, noteFiles: true };
    case HIDE_NOTE_HISTORY_OVERLAY:
      return { ...state, noteHistory: false };
    case SHOW_NOTE_HISTORY_OVERLAY:
      return { ...state, noteHistory: true };
    case HIDE_NOTE_LISTING_OVERLAY:
      return { ...state, noteListing: false };
    case SHOW_NOTE_LISTING_OVERLAY:
      return { ...state, noteListing: true };
    case HIDE_NOTE_POOL_LISTING_OVERLAY:
      return { ...state, notePoolListing: false };
    case SHOW_NOTE_POOL_LISTING_OVERLAY:
      return { ...state, notePoolListing: true };
    case HIDE_NOTE_PHOTOS_OVERLAY:
      return { ...state, notePhotos: false };
    case SHOW_NOTE_PHOTOS_OVERLAY:
      return { ...state, notePhotos: true };
    default:
      return state;
  }
}

export const isShowingNoteFilesOverlay = (state) => {
  return state.noteFiles;
};

export const isShowingNoteHistoryOverlay = (state) => {
  return state.noteHistory;
};

export const isShowingNoteListingOverlay = (state) => {
  return state.noteListing;
};

export const isShowingNotePoolListingOverlay = (state) => {
  return state.notePoolListing;
};

export const isShowingNotePhotosOverlay = (state) => {
  return state.notePhotos;
};
