import useAlgoliaActiveFilterCount from '@paperstac/common/lib/hooks/useAlgoliaActiveFilterCount';
import AlgoliaClearRefinementsButton from '@paperstac/ui/lib/AlgoliaClearRefinementsButton';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import XIcon from '@paperstac/ui/lib/icons/XIcon';
import Overlay from '@paperstac/ui/lib/Overlay';
import Text from '@paperstac/ui/lib/Text';
import UnstyledButton from '@paperstac/ui/lib/UnstyledButton';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { ListingsSearchContext } from './ListingsSearchProvider';

const LockBodyScrollCss = createGlobalStyle`
html, body {
  height: 100%;
  overflow: hidden;
}
`;

const SlideInKeyframesCss = createGlobalStyle`
@keyframes slidein {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}
`;

const FiltersDrawer = React.memo(({ children, isOpen, onClose }) => {
  const { resultCount, starredFilterEnabled } = React.useContext(ListingsSearchContext);
  const algoliaActiveFilterCount = useAlgoliaActiveFilterCount();
  const activeFilterCount = starredFilterEnabled ? algoliaActiveFilterCount + 1 : algoliaActiveFilterCount;

  return (
    <Overlay onClose={onClose} skipBodyLock={true} sx={{ display: isOpen ? 'block' : 'none' }}>
      {isOpen && <LockBodyScrollCss />}
      <SlideInKeyframesCss />
      <Flex
        sx={{
          bg: 'background',
          height: '100vh',
          width: '100%',
          maxWidth: '360px',
          flexDirection: 'column',
          animation: isOpen ? '0.3s ease-out slidein both' : '',
        }}
      >
        <Box
          sx={{
            bg: 'white',
            position: 'relative',
            p: 3,
            borderBottom: 'default',
          }}
        >
          <Heading as="h3" fontSize={18} mb={3} textAlign="center">
            Search Filters
          </Heading>
          <Flex bg="gray.1">
            <AlgoliaClearRefinementsButton variant="secondary" size="small" block mr={3}>
              Clear Filters
              <CountPill color="secondary">{activeFilterCount}</CountPill>
            </AlgoliaClearRefinementsButton>
            <Button variant="primary" size="small" block onClick={onClose}>
              Results
              <CountPill color="primary">{resultCount}</CountPill>
            </Button>
          </Flex>
          <UnstyledButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 1,
              right: 2,
              color: 'gray.6',
              cursor: 'pointer',
              '&:hover,&:active': { color: 'red' },
            }}
          >
            <XIcon />
          </UnstyledButton>
        </Box>
        <Box
          sx={{
            px: 3,
            pb: 110, // Add bottom spacer to avoid bottom mobile browser navbar overlap
            flex: '1 1 0',
            overflowY: 'auto',
          }}
        >
          {children}
        </Box>
      </Flex>
    </Overlay>
  );
});

const CountPill = ({ color, ...props }) => (
  <Text
    {...props}
    sx={{
      ml: 2,
      borderRadius: 'corner',
      display: 'inline-block',
      px: 1,
      bg: 'white',
      color,
      fontSize: 0,
      lineHeight: '1.2',
      opacity: 0.8,
    }}
  />
);

FiltersDrawer.propTypes = {};

FiltersDrawer.defaultProps = {};

FiltersDrawer.displayName = 'FiltersDrawer';

export default FiltersDrawer;
