import { getDomains } from './domain';
export default (function (_ref) {
  var id = _ref.id,
    displayName = _ref.displayName,
    domain = _ref.domain,
    avatar = _ref.avatar,
    isBroker = _ref.isBroker;
  return {
    id: id || '',
    displayName: displayName || '',
    avatar: avatar || '',
    isBroker: isBroker || '',
    domain: domain || getDomains().PAPERSTAC
  };
});