import React from 'react';
import { usePlaidLink } from 'react-plaid-link';
import useSyncIdentityVerification from './useSyncIdentityVerification';

function useIdentityVerificationSession({ createIdentityVerificationLinkToken }) {
  const [linkToken, setLinkToken] = React.useState('');
  const syncIdentityVerification = useSyncIdentityVerification();

  const start = React.useCallback(
    async (forceReset) => {
      createIdentityVerificationLinkToken(forceReset)
        .then((linkToken) => setLinkToken(linkToken))
        .catch((e) => alert(e.message));
    },
    [createIdentityVerificationLinkToken, setLinkToken]
  );

  const syncResult = React.useCallback(
    (linkSessionId) => {
      setLinkToken('');
      syncIdentityVerification(linkSessionId);
    },
    [syncIdentityVerification]
  );

  const handleSuccess = React.useCallback(
    (publicToken, metadata) => {
      syncResult(metadata.link_session_id);
    },
    [syncResult]
  );

  const handleExit = React.useCallback(
    (error, metadata) => {
      if (error) {
        alert(error.display_message);
      } else {
        syncResult(metadata.link_session_id);
      }
    },
    [syncResult]
  );

  return React.useMemo(
    () => ({
      element: linkToken ? <PlaidLink onExit={handleExit} onSuccess={handleSuccess} token={linkToken} /> : null,
      start,
    }),
    [start, handleExit, handleSuccess, linkToken]
  );
}

export default useIdentityVerificationSession;

const PlaidLink = (props) => {
  const { open, ready } = usePlaidLink(props);
  React.useEffect(() => {
    if (ready) open();
  }, [open, ready]);
  return null;
};
PlaidLink.displayName = 'PlaidLink';
