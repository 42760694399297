import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { REM, SPACER } from '../../../theme';
import BlogMediaBox from '../../BlogMediaBox';
import BlogPostAuthorBrettBurky from '../../BlogPostAuthorBrettBurky';
import BlogPostLayout from '../../BlogPostLayout';
import CloudinaryImage from '../../CloudinaryImage';

export const metadata = {
  postTitle: 'New Features to Make You Better at Buying & Selling Mortgage Notes',
  metaTitle: 'New Features to Make You Better at Buying & Selling Mortgage Notes',
  metaDescription:
    'We have been working hard over here at Paperstac pumping out new features we know the mortgage note industry will enjoy. New features like Servicing Transfer, new sorting features, starring listings and broker features. Click to learn more',
  slug: 'february-feature-release',
  publishDate: '2019-02-26 10:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1551121552/Papestac%20Blog/February%20Features%202019/feb_features-2019.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1551121896/Papestac%20Blog/February%20Features%202019/feb_release_social_image.png',
  tags: ['broker', 'how-to', 'feature releases'],
};

const TitleComponent = () => (
  <h1 style={{ marginTop: 0, marginBottom: SPACER(2) }}>
    <CloudinaryImage
      url="https://res.cloudinary.com/paperstac/image/upload/v1551125246/Papestac%20Blog/February%20Features%202019/febFeatureHero.png"
      alt={metadata.postTitle}
    />
  </h1>
);

const FebruaryFeatureRelease = () => (
  <BlogPostLayout metadata={metadata} AuthorComponent={BlogPostAuthorBrettBurky} TitleComponent={TitleComponent}>
    <h2>Recent Updates</h2>

    <p>
      Between heading to the IMN conference in Ft. Lauderdale and scaling Paperstac, we've been able to release a number
      of features that we know the industry was wanting to see. Like always, we're listening to your suggestions and
      adding them along the way. We've been pushing hard on CFDs and trying to systemize that closing. We've also been
      working hard to finish out our partners portal for all of our current partners and future partners. Here is what
      we've been able to roll out this month.
    </p>

    <ul>
      <li>
        <p>
          <strong>Servicing Transfer Requests:</strong> We now initiate Servicing Transfer Requests (STRs) on behalf of
          users. Once you've completed a deal on Paperstac, we will send emails to both the buyer and seller servicer
          alerting them of the servicing transfer and providing all of the details required to make the transfer process
          as simple and efficient as possible.
        </p>
        <BlogMediaBox>
          <CloudinaryImage
            url="https://res.cloudinary.com/paperstac/image/upload/v1551123654/Papestac%20Blog/February%20Features%202019/servicingTransfer.png"
            width={548}
          />
        </BlogMediaBox>
      </li>
      <li>
        <p>
          <strong>New Sorting Features for Listings:</strong> When you are browsing Paperstac's "notes for sale," you
          can now sort the listings based on "Popularity," "Newly Listed," "Lowest Price" and "Highest Price."
        </p>
        <BlogMediaBox>
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) =>
              isVisible ? (
                <video style={{ width: REM(718), height: REM(404) }} autoPlay loop muted playsInline>
                  <source
                    src="https://res.cloudinary.com/paperstac/video/upload/w_718,h_404/v1551123908/Papestac%20Blog/February%20Features%202019/sortingFeature.webm"
                    type="video/webm"
                  />
                  <source
                    src="https://res.cloudinary.com/paperstac/video/upload/w_718,h_404/v1551123908/Papestac%20Blog/February%20Features%202019/sortingFeature.mp4"
                    type="video/mp4"
                  />
                  <source
                    src="https://res.cloudinary.com/paperstac/video/upload/w_718,h_404/v1551123908/Papestac%20Blog/February%20Features%202019/sortingFeature.ogv"
                    type="video/ogg"
                  />
                </video>
              ) : (
                <div style={{ width: REM(718), height: REM(404) }}>&nbsp;</div>
              )
            }
          </VisibilitySensor>
        </BlogMediaBox>
      </li>
      <li>
        <p>
          <strong>Starred Listings:</strong> You can now "star" listings that you want to save. This gives you an easy
          way to bookmark certain listings that you want to come back to.
        </p>
        <BlogMediaBox>
          <VisibilitySensor partialVisibility={true}>
            {({ isVisible }) =>
              isVisible ? (
                <video style={{ width: REM(718), height: REM(404) }} autoPlay loop muted playsInline>
                  <source
                    src="https://res.cloudinary.com/paperstac/video/upload/w_718,h_404/v1551124178/Papestac%20Blog/February%20Features%202019/starListings.webm"
                    type="video/webm"
                  />
                  <source
                    src="https://res.cloudinary.com/paperstac/video/upload/w_718,h_404/v1551124178/Papestac%20Blog/February%20Features%202019/starListings.mp4"
                    type="video/mp4"
                  />
                  <source
                    src="https://res.cloudinary.com/paperstac/video/upload/w_718,h_404/v1551124178/Papestac%20Blog/February%20Features%202019/starListings.ogv"
                    type="video/ogg"
                  />
                </video>
              ) : (
                <div style={{ width: REM(718), height: REM(404) }}>&nbsp;</div>
              )
            }
          </VisibilitySensor>
        </BlogMediaBox>
      </li>
      <li>
        <p>
          <strong>New Broker Features:</strong> We have added the ability for brokers to add their fee to a transaction.
          This enables Paperstac to collect the fee on the broker's behalf during closing to help guarantee they do not
          get shut out of the deal.{' '}
          <u>
            We suggest that you reach out to Paperstac Support for more information on how this powerful new feature
            works.
          </u>
        </p>
        <BlogMediaBox>
          <CloudinaryImage
            url="https://res.cloudinary.com/paperstac/image/upload/v1551125010/Papestac%20Blog/February%20Features%202019/brokerFeature.png"
            width={548}
          />
        </BlogMediaBox>
      </li>
    </ul>
    <p>
      To our users who have provided input for all others to benefit from, we thank you for your unselfishness and great
      ideas. Keep’em coming!
    </p>
  </BlogPostLayout>
);

export default FebruaryFeatureRelease;
