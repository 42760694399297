import { SortCollectionContext } from '@paperstac/common/lib/components/SortCollectionProvider';
import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import React from 'react';
import { WindowScroller } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';
import { createGlobalStyle } from 'styled-components';
import ListingItem from './ListingItem';

const SerpListCss = createGlobalStyle`
.listings-serp-list { height: 100% !important; }
`;

const SerpList = React.memo(({ onFilters, onSort }) => {
  const listRef = React.useRef();
  const { isWideTabletOrLarger, isWideDesktop } = useBreakpoints();
  const { collection } = React.useContext(SortCollectionContext);
  const itemHeight = isWideDesktop ? 158 : isWideTabletOrLarger ? 140 : 116;
  const gutterSize = 16;

  const handleScroll = ({ scrollTop }) => {
    if (listRef.current) listRef.current.scrollTo(scrollTop);
  };

  const Row = ({ index, style }) => <ListingItem listing={collection[index]} style={style} />;

  return (
    <>
      <SerpListCss />
      <WindowScroller onScroll={handleScroll}>{() => <div />}</WindowScroller>
      <List
        ref={listRef}
        height={window.innerHeight}
        width="100%"
        itemCount={collection.length}
        itemSize={itemHeight + gutterSize}
        className="listings-serp-list"
      >
        {Row}
      </List>
    </>
  );
});

SerpList.propTypes = {};

SerpList.defaultProps = {};

SerpList.displayName = 'SerpList';

export default SerpList;
