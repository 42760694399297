import React from 'react';
import BlogPostAuthor from './BlogPostAuthor';

const BlogPostAuthorMikeMcLin = ({ publishDate }) => (
  <BlogPostAuthor
    name="Mike McLin"
    avatar="https://res.cloudinary.com/paperstac/image/upload/v1539343830/website-assets/mike-mclin.png"
    publishDate={publishDate}
  />
);

export default BlogPostAuthorMikeMcLin;
