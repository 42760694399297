import firestore from '@paperstac/firebase/lib/firestore';
export var starredListingsRef = function starredListingsRef() {
  return firestore.collection('starredListings');
};
export var starredListingByIdRef = function starredListingByIdRef(starredListingId) {
  return starredListingId ? starredListingsRef().doc(starredListingId) : null;
};
export var starredListingsByListingIdQuery = function starredListingsByListingIdQuery(listingId) {
  return listingId ? starredListingsRef().where('listingId', '==', listingId) : null;
};
export var starredListingsByAccountIdQuery = function starredListingsByAccountIdQuery(accountId) {
  return accountId ? starredListingsRef().where('accountId', '==', accountId) : null;
};
export var starredListingsByAccountIdListingIdQuery = function starredListingsByAccountIdListingIdQuery(accountId, listingId) {
  return accountId && listingId ? starredListingsRef().where('accountId', '==', accountId).where('listingId', '==', listingId) : null;
};