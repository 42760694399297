import React from 'react';
import XIcon from './icons/XIcon';
import './Toast.css';
import Unbutton from './Unbutton';

export default ({ toast: { message, theme = '' }, onRemove }) => (
  <div className={`Toast ${theme ? `Toast--${theme}` : ''}`}>
    {message}
    <span className="Toast__remove">
      <Unbutton onClick={onRemove}>
        <XIcon size={13} />
      </Unbutton>
    </span>
  </div>
);
