import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import Section from './Section';
import UpdateEmailForm from './UpdateEmailForm';
import UpdatePasswordForm from './UpdatePasswordForm';
import { MergeIdentitiesContext } from '../MergeIdentities/MergeIdentitiesProvider';

const DEFAULT_VIEW = 'DEFAULT_VIEW';
const EDIT_EMAIL_VIEW = 'EDIT_EMAIL_VIEW';
const EDIT_PASSWORD_VIEW = 'EDIT_PASSWORD_VIEW';

const LoginDetailsSection = React.memo((props) => {
  const { identity } = React.useContext(IdentityContext);
  const { showMergeIdentities } = React.useContext(MergeIdentitiesContext);
  const [view, setView] = React.useState(DEFAULT_VIEW);
  const showDefaultView = React.useCallback(() => setView(DEFAULT_VIEW), []);
  const showEditEmail = React.useCallback(() => setView(EDIT_EMAIL_VIEW), []);
  const showEditPassword = React.useCallback(() => setView(EDIT_PASSWORD_VIEW), []);

  return (
    <Section>
      <Heading fontSize={4} mb={3}>
        Login Details
      </Heading>
      {view === DEFAULT_VIEW && (
        <Box>
          <Text fontWeight="bold">Email Address:</Text>
          <Box mb={3}>{identity.email}</Box>
          <Box mb={3}>
            <LinkButton onClick={showEditEmail}>Change Email Address</LinkButton>
          </Box>
          <Box mb={3}>
            <LinkButton onClick={showEditPassword}>Change Password</LinkButton>
          </Box>
          <Box mb={3}>
            <Box fontSize={1}>Have multiple logins?</Box>
            <LinkButton onClick={showMergeIdentities}>Merge Identities</LinkButton>
          </Box>
        </Box>
      )}
      {view === EDIT_EMAIL_VIEW && (
        <Box>
          <FormHeading>Update Email Address</FormHeading>
          <UpdateEmailForm onCancel={showDefaultView} />
        </Box>
      )}
      {view === EDIT_PASSWORD_VIEW && (
        <Box>
          <FormHeading>Update Your Password</FormHeading>
          <UpdatePasswordForm onCancel={showDefaultView} />
        </Box>
      )}
    </Section>
  );
});

const FormHeading = (props) => (
  <Heading
    sx={{
      fontSize: 1,
      color: 'secondary',
      textTransform: 'uppercase',
      mb: 3,
    }}
    {...props}
  />
);

LoginDetailsSection.propTypes = {};

LoginDetailsSection.defaultProps = {};

LoginDetailsSection.displayName = 'LoginDetailsSection';

export default LoginDetailsSection;
