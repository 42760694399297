import { END_SESSION } from '../actions/authActions';
import { SET_CREDIT_CARDS } from '../actions/creditCardActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';

const DEFAULT_STATE = [];

export default function creditCardsReducer(state = DEFAULT_STATE, { type, payload = [] }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_CREDIT_CARDS:
      return payload;
    default:
      return state;
  }
}
