import { END_SESSION } from '../actions/authActions';
import { SET_SERVICER_PROFILES } from '../actions/servicerProfileActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';

const DEFAULT_STATE = [];

export default function servicerProfilesReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_SERVICER_PROFILES:
      return payload || DEFAULT_STATE;
    default:
      return state;
  }
}
