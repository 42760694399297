const polished = require('polished');

const SPACER_UNIT = 20;

module.exports = {
  WHITE: '#ffffff',
  LIGHT_GRAY: '#f5f8fa',
  LIGHT_GRAY_BORDER: polished.darken(0.1, '#f5f8fa'),
  LIGHT_GRAY_FILL: polished.mix(0.7, '#f5f8fa', '#fff'),
  GRAY: '#a2a4a6',
  DARK_GRAY: '#323333',
  BLACK: '#141414',
  BLUE: '#047bb7',
  PRIMARY: '#047bb7',
  DARK_PRIMARY: '#233844',
  ACCENT: '#ee684d',
  GREEN: '#5cb85c',
  SUCCESS: '#5cb85c',
  INFO: '#5bc0de',
  WARNING: '#f0ad4e',
  RED: '#d9534f',
  ERROR: '#d9534f',
  HIGHLIGHT: '#fffce9',
  FONT: '-apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  RADIUS: polished.rem(3),
  MIN_CONTENT_WIDTH: polished.rem(1200),
  MAX_CONTENT_WIDTH: polished.rem(1200),
  HEADER_HEIGHT: 80,
  SPACER_UNIT: SPACER_UNIT,
  SPACER: (multiplier = 1) => {
    return polished.rem(SPACER_UNIT * multiplier);
  },
  REM: polished.rem,
  EM: polished.em,
};
