import { BASE_URL } from '@paperstac/env';
import getPath from '@paperstac/routes/lib/getPath';
import { BLOG_POSTS_SINGLE } from '@paperstac/routes/lib/webRoutes';
import React from 'react';
import FacebookComments from '../../common/FacebookComments';
import BlogPostLayout from '../../layouts/BlogPostLayout';
import AverageITB from './AverageITB';
import AverageITV from './AverageITV';
import AveragePrice from './AveragePrice';
import DealOverview from './DealOverview';
import HeroSection from './HeroSection';
import IndustryInsights from './IndustryInsights';
import IntroSection from './IntroSection';
import Section from './Section';

const slug = '2019-mid-year-market-report';
export const metadata = {
  postTitle: "Paperstac's 2019 Mid-Year Market Report",
  metaTitle: "Paperstac's 2019 Mid-Year Market Report",
  metaDescription:
    'See what mortgage notes sold for on the Paperstac marketplace for the first half of 2019 in our market report.',
  slug,
  url: `${BASE_URL}${getPath(BLOG_POSTS_SINGLE, slug)}`,
  publishDate: '2019-08-06 08:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1564693002/website-assets/2019-mid-year-market-report-featured.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1564693002/website-assets/2019-mid-year-market-report-social.png',
  tags: ['report'],
};

const MidYearMarketReport2019 = React.memo(() => {
  return (
    <BlogPostLayout metadata={metadata}>
      <HeroSection postTitle={metadata.postTitle} />
      <IntroSection />
      <AverageITB />
      <AverageITV />
      <AveragePrice />
      <DealOverview />
      <IndustryInsights />
      <Section title="Comments" subtitle="Let us know what you think...">
        <FacebookComments url={metadata.url} />
      </Section>
    </BlogPostLayout>
  );
});

MidYearMarketReport2019.propTypes = {};

MidYearMarketReport2019.defaultProps = {};

MidYearMarketReport2019.displayName = 'MidYearMarketReport2019';

export default MidYearMarketReport2019;
