import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';

const getButtonClasses = (props) => {
  const classes = ['Button'];
  if (props.className) {
    classes.push(props.className);
  }
  if (props.block) {
    classes.push('Button--block');
  }
  if (props.tiny) {
    classes.push('Button--tiny');
  }
  if (props.small) {
    classes.push('Button--small');
  }
  if (props.large) {
    classes.push('Button--large');
  }
  if (props.round) {
    classes.push('Button--round');
  }
  if (props.busy) {
    classes.push('Button--busy');
  }
  if (props.squareLeft) {
    classes.push('Button--squareLeft');
  }
  if (props.primary) {
    classes.push('Button--primary');
  }
  if (props.secondary) {
    classes.push('Button--secondary');
  }
  if (props.accent) {
    classes.push('Button--accent');
  }
  if (props.red) {
    classes.push('Button--red');
  }
  if (props.whiteOutline) {
    classes.push('Button--whiteOutline');
  }
  if (props.primaryOutline) {
    classes.push('Button--primaryOutline');
  }
  if (props.accentOutline) {
    classes.push('Button--accentOutline');
  }
  if (props.greenOutline) {
    classes.push('Button--greenOutline');
  }
  if (props.redOutline) {
    classes.push('Button--redOutline');
  }
  if (props.disabledOutline) {
    classes.push('Button--disabledOutline');
  }
  return classes.join(' ');
};

const handleClick = (evt, { busy, onClick }) => {
  if (busy) {
    evt.preventDefault();
    return;
  }
  onClick && onClick(evt);
};

export default (props) => (
  <React.Fragment>
    {!props.url && !props.route && (
      <button
        type={props.type || 'button'}
        className={getButtonClasses(props)}
        onClick={(evt) => handleClick(evt, props)}
      >
        <span className="Button__content">{props.children}</span>
      </button>
    )}
    {!!props.url && (
      <a href={props.url} target={props.target} className={getButtonClasses(props)}>
        <span className="Button__content">{props.children}</span>
      </a>
    )}
    {!!props.route && (
      <Link to={props.route} target={props.target} className={getButtonClasses(props)}>
        <span className="Button__content">{props.children}</span>
      </Link>
    )}
  </React.Fragment>
);
