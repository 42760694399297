import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import AlgoliaSearchStateProvider from '@paperstac/common/lib/components/AlgoliaSearchStateProvider';
import useAlgoliaClient from '@paperstac/common/lib/hooks/useAlgoliaClient';
import useAlgoliaUrlSync from '@paperstac/common/lib/hooks/useAlgoliaUrlSync';
import { getIndexName } from '@paperstac/common/lib/services/algoliaHelpers';
import { ALGOLIA_PUBLIC_SEARCH_KEY } from '@paperstac/env';
import PropTypes from 'prop-types';
import React from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import { withRouter } from 'react-router-dom';
import './AlgoliaSearch.css';

const AlgoliaSearch = React.memo(({ cache, location, history, syncUrl, indexName, searchKey, children }) => {
  const { currentAccount } = React.useContext(AccountsContext);
  const searchOnlyApiKey = searchKey
    ? searchKey
    : currentAccount && currentAccount.algoliaSearchKey
      ? currentAccount.algoliaSearchKey
      : ALGOLIA_PUBLIC_SEARCH_KEY;
  const searchClient = useAlgoliaClient(searchOnlyApiKey, cache);
  const { searchState, onSearchStateChange, createURL } = useAlgoliaUrlSync(location, history);
  const extraProps = syncUrl ? { searchState, onSearchStateChange, createURL } : {};
  const [isRefreshing, setRefreshing] = React.useState(false);
  const handleRefresh = React.useCallback(() => setRefreshing(true), []);

  React.useEffect(() => {
    if (isRefreshing) setRefreshing(false);
  }, [isRefreshing]);

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={getIndexName(indexName)}
      refresh={isRefreshing}
      {...extraProps}
    >
      <AlgoliaSearchStateProvider
        apiKey={searchOnlyApiKey}
        isRefreshing={isRefreshing}
        onRefresh={handleRefresh}
        setSearchState={onSearchStateChange}
      >
        {children}
      </AlgoliaSearchStateProvider>
    </InstantSearch>
  );
});

AlgoliaSearch.propTypes = {
  indexName: PropTypes.string.isRequired,
  syncUrl: PropTypes.bool,
};

AlgoliaSearch.defaultProps = {
  syncUrl: true,
};

AlgoliaSearch.displayName = 'AlgoliaSearch';

export default withRouter(AlgoliaSearch);
