export const ABOUT = '/about';
export const ACCEPT_TEAM_INVITE = '/accept-team-invite';
export const ACTION = '/action';
export const ADMIN = '/admin/users';
export const ADMIN_CAMPAIGNS = '/admin/campaigns';
export const ADMIN_IMPERSONATE_USER = '/admin/impersonate';
export const ADMIN_MODERATE_EVENTS = '/admin/moderate-events';
export const ADMIN_PPP = '/admin/ppp';
export const ADMIN_RESTORE_POINTS = '/admin/restore-points';
export const ADMIN_SHORT_URLS = '/admin/short-urls';
export const ADMIN_TRANSACTIONS_IN_CLOSING = '/admin/transactions-in-closing';
export const AUDIT_ORDERS_ARCHIVE = '/audit-orders';
export const AUDIT_ORDERS_SINGLE = '/audit-orders/:id';
export const BANK_ACCOUNTS_ARCHIVE = '/bank-accounts';
export const BLOG_POSTS_SINGLE = '/blog/:id/';
export const DASHBOARD = '/dashboard';
export const ESCROW_ACCOUNT_BALANCE = '/escrow-account-balance';
export const ESCROW_ORDERS_ARCHIVE = '/escrow-orders';
export const ESCROW_ORDERS_SINGLE = '/escrow-orders/:id';
export const FOR_SALE_ARCHIVE = '/for-sale';
export const FOR_SALE_SINGLE = '/for-sale/:id';
export const HOME = '/';
export const IMPORT_SINGLE = '/my-imports/:id';
export const IMPORTS_ARCHIVE = '/my-imports';
export const LISTINGS_ARCHIVE = '/my-listings';
export const LOGIN = '/login';
export const MANAGE_ACCOUNT = '/manage-account';
export const MANAGE_COMMUNITY = '/manage-community/:id';
export const MANAGE_COMMUNITY_INVITES = '/manage-community/:id/invites';
export const MY_BLACKLIST = '/my-blacklist';
export const MY_COMMUNITIES_ARCHIVE = '/my-communities';
export const NOTE_FILES = '/my-notes/:id/files';
export const NOTE_HISTORY = '/my-notes/:id/history';
export const NOTE_PHOTOS = '/my-notes/:id/photos';
export const NOTE_SINGLE = '/my-notes/:id';
export const NOTES_ARCHIVE = '/my-notes';
export const PRICING = '/pricing';
export const PRIVACY_POLICY = '/privacy-policy';
export const REGISTER = '/register';
export const SELLER_ONBOARDING = '/seller-onboarding';
export const SHIPPING_ADDRESSES_ARCHIVE = '/shipping-addresses';
export const TEAMS_ARCHIVE = '/teams';
export const TERMS = '/terms';
export const TRANSACTIONS_ARCHIVE = '/my-transactions';
export const TRANSACTIONS_SINGLE = '/my-transactions/:id';
export const TRANSACTIONS_SINGLE_TAB = '/my-transactions/:id/:tab';
export const TRANSACTIONS_SINGLE_NOTE = '/my-transactions/:id/notes/:noteId';
export const USER_ACTIONS = '/user-actions';
export const VESTING_PROFILES_ARCHIVE = '/vesting-profiles';

export const getPath = (route, id = {}) => {
  let url = route;
  const params = typeof id === 'object' ? id : { id };
  Object.keys(params).forEach((property) => {
    url = url.replace(`:${property}`, params[property]);
  });
  return url;
};
