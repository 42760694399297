import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { CREATE_IDENTITY_QUESTION_SET, VERIFY_IDENTITY } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import useFullStory from '@paperstac/common/lib/hooks/useFullStory';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import DelayRender from '@paperstac/ui/lib/DelayRender';
import Flex from '@paperstac/ui/lib/Flex';
import Label from '@paperstac/ui/lib/form/Label';
import Radio from '@paperstac/ui/lib/form/Radio';
import Heading from '@paperstac/ui/lib/Heading';
import Spinner from '@paperstac/ui/lib/Spinner';
import { Form, Formik } from 'formik';
import React from 'react';
import ViewTitle from '../AuthOverlay/ViewTitle';
import TitleSubtext from '../AuthOverlay/TitleSubtext';
import ErrorMessage from '../AuthOverlay/ErrorMessage';
import CompleteBySection from '../AuthOverlay/CompleteBySection';
import { intercomShow } from '@paperstac/common/lib/services/intercomMessenger';

const VerifyIdentityView = React.memo((props) => {
  const FullStory = useFullStory();
  const { uid } = React.useContext(IdentityContext);
  const [questionSetId, setQuestionSetId] = React.useState(null);
  const [questions, setQuestions] = React.useState(null);
  const [failedMessage, setFailedMessage] = React.useState(null);

  const fetchQuestionSet = React.useCallback(() => {
    setQuestionSetId(null);
    setQuestions(null);
    serverDispatch({ action: CREATE_IDENTITY_QUESTION_SET })
      .then((response) => {
        setQuestionSetId(response.data.id);
        setQuestions(response.data.questions);
      })
      .catch((error) => setFailedMessage(error.message));
  }, [setQuestionSetId, setQuestions]);

  const handleRetry = React.useCallback(() => {
    setFailedMessage(null);
    fetchQuestionSet();
  }, [setFailedMessage, fetchQuestionSet]);

  React.useEffect(() => {
    fetchQuestionSet();
  }, [fetchQuestionSet]);

  const fullStoryEvent = React.useCallback(
    (eventName, eventProperties = {}) => {
      FullStory.event(eventName, { uid_str: uid, ...eventProperties });
    },
    [FullStory, uid]
  );

  React.useEffect(() => {
    fullStoryEvent('Identity Verification View Loaded');
  }, [fullStoryEvent]);

  return (
    <Box maxWidth={500} mx="auto">
      <ViewTitle>Verify Your Identity</ViewTitle>
      <TitleSubtext>Please answer the following questions to verify your identity.</TitleSubtext>
      {!!failedMessage && (
        <ErrorMessage fontSize={2} textAlign="center">
          {failedMessage}
        </ErrorMessage>
      )}
      {!!failedMessage && (
        <Box mb={4} textAlign="center">
          <Button variant="default" size="small" mr={2} onClick={handleRetry}>
            Retry
          </Button>
          <Button variant="default" size="small" onClick={intercomShow}>
            Contact Support
          </Button>
        </Box>
      )}
      {!questions && !failedMessage && (
        <DelayRender>
          <Box textAlign="center">
            <Spinner />
          </Box>
        </DelayRender>
      )}
      {!!questions && !failedMessage && (
        <Formik
          initialValues={{
            id: questionSetId,
            answers: questions.map(({ id }) => ({ question_id: id, answer_id: null })),
          }}
          onSubmit={async (payload, { setSubmitting, setStatus }) => {
            fullStoryEvent('Submitted Identity for Verification');
            serverDispatch({ action: VERIFY_IDENTITY, payload })
              .then((response) => {
                if (!response.data.isVerified) {
                  const message =
                    'The answers that you submitted were not correct. We were unable to verify your identity.';
                  setFailedMessage(message);
                  setSubmitting(false);
                  fullStoryEvent('Identity Verification Failed', { message_str: message });
                } else {
                  fullStoryEvent('Identity Verified');
                }
              })
              .catch((error) => {
                setSubmitting(false);
                setStatus({ errorMessage: error.message });
                fullStoryEvent('Identity Verification Failed', { code_str: error.code, message_str: error.message });
              });
          }}
          render={({ isSubmitting, status, values, setFieldValue }) => (
            <Form>
              {questions.map((question) => (
                <Box key={question.id} mb={4}>
                  <Heading fontSize={1} mb={2}>
                    {question.question}
                  </Heading>
                  {question.answers.map(({ id, answer }) => (
                    <Answer
                      key={id}
                      answerId={id}
                      questionId={question.id}
                      label={answer}
                      checked={
                        values['answers'].filter((answer) => answer.question_id === question.id)[0].answer_id === id
                      }
                      onAnswer={(questionId, answerId) =>
                        setFieldValue(
                          'answers',
                          values['answers'].map((answer) => {
                            return answer.question_id === questionId ? { ...answer, answer_id: answerId } : answer;
                          })
                        )
                      }
                    />
                  ))}
                </Box>
              ))}
              {status && status.errorMessage && <ErrorMessage>{status.errorMessage}</ErrorMessage>}
              <Button type="submit" variant="primary" block={true} busy={isSubmitting}>
                Submit Answers
              </Button>
            </Form>
          )}
        />
      )}
      <CompleteBySection />
    </Box>
  );
});

VerifyIdentityView.propTypes = {};

VerifyIdentityView.defaultProps = {};

VerifyIdentityView.displayName = 'VerifyIdentityView';

export default VerifyIdentityView;

const Answer = ({ questionId, answerId, label, checked, onAnswer }) => (
  <Flex key={`${questionId}:${answerId}`} alignItems="center">
    <Box mr={1}>
      <Radio
        name={`question${questionId}`}
        id={`q${questionId}:a${answerId}`}
        onChange={() => onAnswer(questionId, answerId)}
        checked={checked}
      />
    </Box>
    <Box>
      <Label
        htmlFor={`q${questionId}:a${answerId}`}
        fontSize={1}
        fontWeight="normal"
        color={checked ? 'secondary' : 'text'}
      >
        {label}
      </Label>
    </Box>
  </Flex>
);
