import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import CheckmarkCircleIcon from '@paperstac/ui/lib/icons/CheckmarkCircleIcon';
import XCircleIcon from '@paperstac/ui/lib/icons/XCircleIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import React from 'react';
import Section from './Section';
import useIdentityVerify from './useIdentityVerify';

const VerifiedSection = React.memo((props) => {
  const { identity } = React.useContext(IdentityContext);
  const { plaidLink, handleStart, handleRestart } = useIdentityVerify(identity);

  return (
    <Section>
      {identity.isValid && (
        <Heading fontSize={3} mb={2}>
          {identity.displayName}
        </Heading>
      )}
      {identity.isVerified ? (
        <>
          <Box sx={{ fontWeight: 'bold', fontSize: 4 }}>{identity.displayName}</Box>
          <Box
            sx={{
              bg: 'green',
              color: 'white',
              borderRadius: 'corner',
              py: 1,
              px: 2,
              lineHeight: 1,
              display: 'inline-block',
            }}
          >
            <CheckmarkCircleIcon mr={1} /> Verified
          </Box>
          <Box mt={3}>
            <LinkButton onClick={handleRestart}>Reverify</LinkButton>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ fontWeight: 'bold', fontSize: 4 }}>Unverified Identity</Box>
          <Box
            sx={{
              bg: 'red',
              color: 'white',
              borderRadius: 'corner',
              py: 1,
              px: 2,
              lineHeight: 1,
              display: 'inline-block',
            }}
          >
            <XCircleIcon mr={1} /> Not Verified
          </Box>
          <Box mt={3}>
            <LinkButton onClick={handleStart}>Verify Your Identity</LinkButton>
          </Box>
        </>
      )}
      {plaidLink}
    </Section>
  );
});

VerifiedSection.propTypes = {};

VerifiedSection.defaultProps = {};

VerifiedSection.displayName = 'VerifiedSection';

export default VerifiedSection;
