import { notePropertyLabelsMap, transformNotePropertyValue } from '@paperstac/common/lib/services/notesHelper';
import Box from '@paperstac/ui/lib/Box';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import Flex from '@paperstac/ui/lib/Flex';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import React from 'react';
import EditButton from './EditButton';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const NOTE_DETAIL_PROPS = [
  'updatedDate',
  'noteType',
  'lienPosition',
  'performance',
  'interestRatePercent',
  'interestOnlyLoan',
  'upb',
  'streetAddress',
  'cityStateZip',
  'county',
  'propertyType',
  'occupancyStatus',
  'legalStatus',
  'principalAndInterestPayment',
  'escrowImpounds',
  'totalMonthlyLoanPayment',
  'accruedLateCharges',
  'originalBalance',
  'totalPayoff',
  'originationDate',
  'nextPaymentDate',
  'maturityDate',
  'lastPaymentReceivedDate',
  'paymentsRemaining',
];

const ALWAYS_SHOW_PROPS = ['updatedDate', 'noteType', 'performance', 'upb', 'lastPaymentReceivedDate'];

const NoteScreenViewDetails = React.memo(() => {
  const { note, stacked } = React.useContext(ManageListingOverlayContext);
  const { showDetails } = React.useContext(NoteScreenContext);
  const [isShowingAll, setShowingAll] = React.useState(false);
  const showAll = React.useCallback(() => setShowingAll(true), []);

  return (
    <DescriptionGridCell term="Note Details">
      <Flex alignItems="center" mt={3}>
        <Box flex={'1 1 0'} mr={stacked ? 3 : 5}>
          {!isShowingAll &&
            ALWAYS_SHOW_PROPS.map((property) => (
              <Flex key={property} justifyContent="space-between" mt={1} fontSize={stacked ? 1 : 2}>
                <Box>{notePropertyLabelsMap[property]}:</Box>
                <Box fontWeight="bold">{transformNotePropertyValue(property, note[property])}</Box>
              </Flex>
            ))}
          {!isShowingAll && (
            <Box mt={2}>
              <LinkButton onClick={showAll} fontSize={1}>
                Show All Details
              </LinkButton>
            </Box>
          )}
          {isShowingAll &&
            NOTE_DETAIL_PROPS.map((property) => (
              <Flex key={property} justifyContent="space-between" mt={1} fontSize={stacked ? 1 : 2}>
                <Box>{notePropertyLabelsMap[property]}:</Box>
                <Box fontWeight="bold">{transformNotePropertyValue(property, note[property])}</Box>
              </Flex>
            ))}
        </Box>
        <Box>
          <EditButton onClick={showDetails} />
        </Box>
      </Flex>
    </DescriptionGridCell>
  );
});

NoteScreenViewDetails.propTypes = {};

NoteScreenViewDetails.defaultProps = {};

NoteScreenViewDetails.displayName = 'NoteScreenViewDetails';

export default NoteScreenViewDetails;
