import firestore from '@paperstac/firebase/lib/firestore';
import useFirestoreSubscribe from '@paperstac/hooks/lib/useFirestoreSubscribe';
import * as Yup from 'yup';
export var savedSearchesRef = function savedSearchesRef() {
  return firestore.collection('savedSearches');
};
export var savedSearchByIdRef = function savedSearchByIdRef(savedSearchId) {
  return savedSearchId ? savedSearchesRef().doc(savedSearchId) : null;
};
export var savedSearchesByAccountIdRef = function savedSearchesByAccountIdRef() {
  var accountId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return savedSearchesRef().where('accountId', '==', accountId);
};
export var savedSearchesByNotifyQuery = function savedSearchesByNotifyQuery(notify) {
  return savedSearchesRef().where('notify', '==', notify);
};
export var savedSearchesByStarQuery = function savedSearchesByStarQuery(star) {
  return savedSearchesRef().where('star', '==', star);
};
export var savedSearchValidator = function savedSearchValidator() {
  return Yup.object().shape({
    id: Yup.string().length(22).required(),
    accountId: Yup.string().required(),
    title: Yup.string().min(1).required(),
    notify: Yup.bool().required(),
    star: Yup.bool().required()
  });
};
export var useSavedSearches = function useSavedSearches(accountId) {
  return useFirestoreSubscribe(savedSearchesByAccountIdRef(accountId), [accountId]);
};