import { UPDATE_NOTE } from '@paperstac/common/lib/serverDispatchActionTypes';
import { notePropertyLabelsMap } from '@paperstac/common/lib/services/notesHelper';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { NOTE_TAPE_PERFORMANCE_NON_PERFORMING, NOTE_TAPE_PERFORMANCE_PERFORMING } from '@paperstac/constants';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import PercentInput from '@paperstac/ui/lib/form/PercentInput';
import Select from '@paperstac/ui/lib/form/Select';
import UsdInput from '@paperstac/ui/lib/form/UsdInput';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const PERFORMANCE_OPTIONS = [NOTE_TAPE_PERFORMANCE_PERFORMING, NOTE_TAPE_PERFORMANCE_NON_PERFORMING];

const NoteScreenEditSeniorDetails = React.memo(() => {
  const { note, showOverview: showListing, stacked } = React.useContext(ManageListingOverlayContext);
  const { showOverview } = React.useContext(NoteScreenContext);

  return (
    <Formik
      initialValues={{
        id: note.id,
        accountId: note.accountId,
        seniorPerformance: get(note, 'seniorPerformance', ''),
        seniorUpb: get(note, 'seniorUpb', ''),
        seniorInterestRatePercent: get(note, 'seniorInterestRatePercent', ''),
        seniorTotalMonthlyLoanPayment: get(note, 'seniorTotalMonthlyLoanPayment', ''),
      }}
      onSubmit={async (payload, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        serverDispatch({ action: UPDATE_NOTE, payload })
          .then(showOverview)
          .catch((error) => {
            setStatus({ errorMessage: error.message });
            setSubmitting(false);
          });
      }}
      children={(formik) => (
        <Form>
          <DescriptionGrid
            sx={{
              bg: 'gray.1',
              border: 'none',
              borderTop: 'default',
              borderBottom: 'default',
              borderRadius: 0,
            }}
          >
            <DescriptionGridCell bg="highlight">
              <Flex alignItems="center">
                <LinkButton onClick={showListing}>Listing</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                <LinkButton onClick={showOverview}>Note</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                Senior Details
              </Flex>
            </DescriptionGridCell>
            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['seniorPerformance']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="seniorPerformance" component={Select}>
                  {!formik.values.seniorPerformance && <option value="">Choose one...</option>}
                  {PERFORMANCE_OPTIONS.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="seniorPerformance" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell term={notePropertyLabelsMap['seniorUpb']} as="label">
                <Field name="seniorUpb" component={UsdInput} />
                <ErrorMessage name="seniorUpb" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>

            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['seniorInterestRatePercent']}
                as="label"
                sx={{ flex: stacked ? '1 1 0' : '0 0 152px' }}
              >
                <Field name="seniorInterestRatePercent" component={PercentInput} />
                <ErrorMessage name="seniorInterestRatePercent" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell
                term={notePropertyLabelsMap['seniorTotalMonthlyLoanPayment']}
                as="label"
                sx={{ borderRight: stacked ? 'none' : 'default' }}
              >
                <Field name="seniorTotalMonthlyLoanPayment" component={UsdInput} />
                <ErrorMessage name="seniorTotalMonthlyLoanPayment" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>

            <DescriptionGridCell sx={{ borderBottom: 'none' }}>
              {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
              <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>
                Update Senior Details
              </Button>
              <Button variant="default" onClick={showOverview}>
                Cancel
              </Button>
            </DescriptionGridCell>
          </DescriptionGrid>
        </Form>
      )}
    />
  );
});

NoteScreenEditSeniorDetails.propTypes = {};

NoteScreenEditSeniorDetails.defaultProps = {};

NoteScreenEditSeniorDetails.displayName = 'NoteScreenEditSeniorDetails';

export default NoteScreenEditSeniorDetails;
