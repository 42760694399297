import React from 'react';
import report from './report';
import Section from './Section';
import StatGrid from './StatGrid';

const AverageITV = React.memo((props) => {
  return (
    <Section title="Average Investment to Value" subtitle="(sale price / property value)" bg="gray.1">
      <StatGrid
        stat1={report.averageITV[0]}
        stat2={report.averageITV[1]}
        stat3={report.averageITV[2]}
        stat4={report.averageITV[3]}
      />
    </Section>
  );
});

AverageITV.propTypes = {};

AverageITV.defaultProps = {};

AverageITV.displayName = 'AverageITV';

export default AverageITV;
