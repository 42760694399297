import React from 'react';
import MergeIdentitiesScreen from './MergeIdentitiesScreen';

const MergeIdentitiesContext = React.createContext();
const { Provider } = MergeIdentitiesContext;

const MergeIdentitiesProvider = React.memo((props) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const showMergeIdentities = React.useCallback(() => setIsVisible(true), []);
  const hideMergeIdentities = React.useCallback(() => setIsVisible(false), []);

  const providerValue = React.useMemo(
    () => ({
      showMergeIdentities,
      hideMergeIdentities,
    }),
    [showMergeIdentities, hideMergeIdentities]
  );

  return <Provider value={providerValue}>{isVisible ? <MergeIdentitiesScreen /> : props.children}</Provider>;
});

MergeIdentitiesProvider.propTypes = {};

MergeIdentitiesProvider.defaultProps = {};

MergeIdentitiesProvider.displayName = 'MergeIdentitiesProvider';

export default MergeIdentitiesProvider;

export { MergeIdentitiesContext };
