import firestore from '@paperstac/firebase/lib/firestore';
import * as Yup from 'yup';
export var noteAdjustmentByIdRef = function noteAdjustmentByIdRef(adjustmentId) {
  return adjustmentId ? firestore.doc("noteAdjustments/".concat(adjustmentId)) : null;
};
export var noteAdjustmentsByAccountAndNoteIdRef = function noteAdjustmentsByAccountAndNoteIdRef(accountId, noteId) {
  return accountId && noteId ? firestore.collection('noteAdjustments').where('accountId', '==', accountId).where('noteId', '==', noteId) : null;
};
export var createNoteAdjustmentValidator = function createNoteAdjustmentValidator() {
  return Yup.object().shape({
    accountId: Yup.string().required(),
    noteId: Yup.string().required(),
    transactionDate: Yup.string().required(),
    description: Yup.string().required(),
    transactionAmount: Yup.string().required()
  });
};