import { HOME } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Branding } from '../../../images/paperstac.svg';

const Footer = React.memo((props) => {
  return (
    <Box {...props}>
      <Link to={HOME}>
        <Branding height={30} />
      </Link>
    </Box>
  );
});

Footer.propTypes = {};

Footer.defaultProps = {};

Footer.displayName = 'Footer';

export default Footer;
