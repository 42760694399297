import React from 'react';
import { LIGHT_GRAY_BORDER, REM, SPACER } from '../theme';

const BlogMediaBox = ({ children, style }) => (
  <div
    style={{
      border: `${REM(1)} solid ${LIGHT_GRAY_BORDER}`,
      textAlign: 'center',
      padding: SPACER(),
      borderRadius: REM(4),
      marginBottom: SPACER(),
      ...style,
    }}
  >
    {children}
  </div>
);

BlogMediaBox.defaultProps = { style: {} };

export default BlogMediaBox;
