import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { UPDATE_NOTE } from '@paperstac/common/lib/serverDispatchActionTypes';
import { dateToIso } from '@paperstac/helpers/lib/dateHelpers';
import { notePropertyLabelsMap } from '@paperstac/common/lib/services/notesHelper';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import {
  PROPERTY_VALUE_TYPE_APPRAISAL,
  PROPERTY_VALUE_TYPE_BPO,
  PROPERTY_VALUE_TYPE_OTHER,
} from '@paperstac/constants';
import storage from '@paperstac/firebase/lib/storage';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import DateSelect from '@paperstac/ui/lib/form/DateSelect';
import FileInputButton from '@paperstac/ui/lib/form/FileInputButton';
import Select from '@paperstac/ui/lib/form/Select';
import UsdInput from '@paperstac/ui/lib/form/UsdInput';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import ExternalLinkIcon from '@paperstac/ui/lib/icons/ExternalLinkIcon';
import Link from '@paperstac/ui/lib/Link';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import * as Yup from 'yup';
import dateid from '../../../utils/dateid';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const VALUE_TYPE_OPTIONS = [PROPERTY_VALUE_TYPE_BPO, PROPERTY_VALUE_TYPE_APPRAISAL, PROPERTY_VALUE_TYPE_OTHER];

const validationSchema = Yup.object().shape({
  sellerProvidedPropertyValue: Yup.string().required(),
  sellerProvidedPropertyValueType: Yup.string().required(),
  sellerProvidedPropertyValueDate: Yup.string().required(),
  sellerProvidedPropertyValueUrl: Yup.string().required(),
});

const NoteScreenEditPropertyValue = React.memo(() => {
  const { uid } = React.useContext(IdentityContext);
  const { currentAccountId } = React.useContext(AccountsContext);
  const { note, showOverview: showListing, stacked } = React.useContext(ManageListingOverlayContext);
  const { showOverview } = React.useContext(NoteScreenContext);
  const [isUploading, setUploading] = React.useState(false);

  const handleUpdate = React.useCallback(
    (payload) => {
      return serverDispatch({ action: UPDATE_NOTE, payload }).then(showOverview);
    },
    [showOverview]
  );

  const handleDelete = React.useCallback(() => {
    handleUpdate({
      id: note.id,
      accountId: note.accountId,
      sellerProvidedPropertyValue: null,
      sellerProvidedPropertyValueType: null,
      sellerProvidedPropertyValueDate: null,
      sellerProvidedPropertyValueUrl: null,
    }).catch((err) => alert(err.message));
  }, [handleUpdate, note.accountId, note.id]);

  const handleFiles = React.useCallback(
    (files, setFieldValue) => {
      if (isUploading) return;
      setUploading(true);
      const [file] = files;
      const filePath = `user-uploads/${uid}/${currentAccountId}/notes/${note.id}/files/${dateid()}-${file.name}`;
      return storage
        .child(filePath)
        .put(file, { cacheControl: 'public,max-age=31536000' })
        .then(() => setFieldValue('sellerProvidedPropertyValueUrl', filePath))
        .catch((err) => alert(err.message))
        .finally(() => setUploading(false));
    },
    [currentAccountId, isUploading, note.id, uid]
  );

  return (
    <Formik
      initialValues={{
        id: note.id,
        accountId: note.accountId,
        sellerProvidedPropertyValue: get(note, 'sellerProvidedPropertyValue', ''),
        sellerProvidedPropertyValueType: get(note, 'sellerProvidedPropertyValueType', ''),
        sellerProvidedPropertyValueDate: note.sellerProvidedPropertyValueDate
          ? dateToIso(note.sellerProvidedPropertyValueDate)
          : '',
        sellerProvidedPropertyValueUrl: get(note, 'sellerProvidedPropertyValueUrl', ''),
      }}
      validationSchema={validationSchema}
      onSubmit={async (payload, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        handleUpdate(payload).catch((error) => {
          setStatus({ errorMessage: error.message });
          setSubmitting(false);
        });
      }}
      children={(formik) => (
        <Form>
          <DescriptionGrid
            sx={{
              bg: 'gray.1',
              border: 'none',
              borderTop: 'default',
              borderBottom: 'default',
              borderRadius: 0,
            }}
          >
            <DescriptionGridCell bg="highlight">
              <Flex alignItems="center">
                <LinkButton onClick={showListing}>Listing</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                <LinkButton onClick={showOverview}>Note</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                Property Value
              </Flex>
            </DescriptionGridCell>

            <DescriptionGridCell term="Property Value" as="label">
              <Field name="sellerProvidedPropertyValue" component={UsdInput} sx={{ width: 200 }} />
              <ErrorMessage name="sellerProvidedPropertyValue" component={ErrorText} />
            </DescriptionGridCell>

            <Flex flexDirection={stacked ? 'column' : 'row'}>
              <DescriptionGridCell
                term={notePropertyLabelsMap['sellerProvidedPropertyValueType']}
                as="label"
                sx={{
                  borderRight: stacked ? 'none' : 'default',
                  flexGrow: 3,
                }}
              >
                <Field name="sellerProvidedPropertyValueType" component={Select}>
                  {!formik.values.sellerProvidedPropertyValueType && <option value="">Choose one...</option>}
                  {VALUE_TYPE_OPTIONS.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="sellerProvidedPropertyValueType" component={ErrorText} />
              </DescriptionGridCell>
              <DescriptionGridCell
                term={notePropertyLabelsMap['sellerProvidedPropertyValueDate']}
                as="label"
                flexGrow={4}
              >
                <Field name="sellerProvidedPropertyValueDate" component={DateSelect} />
                <ErrorMessage name="sellerProvidedPropertyValueDate" component={ErrorText} />
              </DescriptionGridCell>
            </Flex>

            <DescriptionGridCell term="Valuation Document" as="label">
              {!!note.sellerProvidedPropertyValueUrl &&
                note.sellerProvidedPropertyValueUrl === formik.values.sellerProvidedPropertyValueUrl && (
                  <Box mt={3}>
                    <Link href={note.sellerProvidedPropertyValueUrl} target="_blank">
                      View Valuation Document <ExternalLinkIcon />
                    </Link>
                  </Box>
                )}
              <Box mt={3}>
                <FileInputButton
                  variant="secondary"
                  onFiles={(files) => handleFiles(files, formik.setFieldValue)}
                  busy={isUploading}
                >
                  {!!formik.values.sellerProvidedPropertyValueUrl ? 'Replace Document' : 'Upload Document'}
                </FileInputButton>
              </Box>
              <ErrorMessage name="sellerProvidedPropertyValueUrl" component={ErrorText} />
            </DescriptionGridCell>

            <DescriptionGridCell sx={{ borderBottom: 'none' }}>
              {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
              <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>
                Save Valuation
              </Button>
              <Button variant="default" onClick={showOverview}>
                Cancel
              </Button>
              {!!note.sellerProvidedPropertyValueUrl && (
                <Box mt={3}>
                  <LinkButton onClick={handleDelete} fontSize={1} color="red">
                    Delete Valuation
                  </LinkButton>
                </Box>
              )}
            </DescriptionGridCell>
          </DescriptionGrid>
        </Form>
      )}
    />
  );
});

NoteScreenEditPropertyValue.propTypes = {};

NoteScreenEditPropertyValue.defaultProps = {};

NoteScreenEditPropertyValue.displayName = 'NoteScreenEditPropertyValue';

export default NoteScreenEditPropertyValue;
