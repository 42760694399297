"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
    ALGOLIA_ID: 'TU2LR3US5N',
    ALGOLIA_INDEX_PREFIX: 'paperstac-prod',
    ALGOLIA_PUBLIC_SEARCH_KEY: 'YWYxOTQ3OTZjNjIzOGM4NGQ4NzBlODk0MTBhOWRkNjIyYjRlNDY3MDIyY2JlYjRhYWU0OWMyNGIxMTJkMTUzOGZpbHRlcnM9dmlld2FibGVCeSUzQWF1ZGllbmNlX0FMTA==',
    API_ENDPOINT_URL: 'https://paperstac.com/api/v1',
    API_ENDPOINT_URL_NOTECLOSINGS: 'https://api.noteclosings.com/v1',
    API_ENDPOINT_URL_PAPERSTAC: 'https://api.paperstac.com/v1',
    APP_ENV: 'production',
    APP_NAME: 'Paperstac',
    ACTIVATE_CAMPAIGN_LIST_ID: '1',
    AUDIT_ACCOUNT_ID: 'paperstac',
    BASE_URL: 'https://paperstac.com',
    BASE_AUDITS_URL: 'https://paperstac.com',
    BASE_NOTECLOSINGS_URL: 'https://noteclosings.com',
    BASE_PARTNERS_URL: 'https://partners.paperstac.com',
    BASE_SHORT_URL: 'https://pstac.co',
    BASE_WIREPLEDGE_URL: 'https://wirepledge.com',
    ESCROW_ACCOUNT_ID: 'prioritytitle',
    FACEBOOK_PAGE_URL: 'https://www.facebook.com/paperstac',
    FEDEX_USER_ICON: 'https://res.cloudinary.com/paperstac/image/upload/v1548703123/website-assets/fedex.png',
    FIREBASE_APP_ID: '1:449239537223:web:cbd7239db0cfcf5a',
    FIREBASE_API_KEY: 'AIzaSyDtwFZ-LNBX1yRXKd2H5iTTZsubd_K_VBw',
    FIREBASE_AUTH_DOMAIN: 'paperstac-prod.firebaseapp.com',
    FIREBASE_DATABASE_URL: 'https://paperstac-prod.firebaseio.com',
    FIREBASE_FUNCTIONS_URL: 'https://us-central1-paperstac-prod.cloudfunctions.net',
    FIREBASE_PROJECT_ID: 'paperstac-prod',
    FIREBASE_STORAGE_BUCKET: 'paperstac-prod.appspot.com',
    FIREBASE_MEASURE_ID: 'G-LT5DX7C9N9',
    FIREBASE_MESSAGING_SENDER_ID: '449239537223',
    FLATFILE_MY_LISTINGS_EMBED_ID: '32bb76fb-738c-42d0-ae9f-3364a90a2da3',
    FULLSTORY_ORG_ID: 'TNTFD',
    GITHUB_REPO_ID: 111620644,
    GOOGLE_ANALYTICS_TRACKING_ID: 'UA-69768990-3',
    HELP_SCOUT_NOTECLOSINGS_BEACON_ID: '41cecca6-2025-44ad-bb06-820422e73a52',
    HELP_SCOUT_NOTECLOSINGS_MAILBOX_ID: 284226,
    HELP_SCOUT_PAPERSTAC_BEACON_ID: '1b78f579-fb83-432f-becb-056751a561d7',
    HELP_SCOUT_PAPERSTAC_MAILBOX_ID: 284078,
    INBOUND_EMAIL_DOMAIN: 'inbound.paperstac.com',
    INTERCOM_NOTECLOSINGS_APP_ID: 'bgtyjcmh',
    INTERCOM_PAPERSTAC_ADMIN_ID: 874396,
    INTERCOM_PAPERSTAC_APP_ID: 'zdns3vw2',
    MAIL_FROM_EMAIL: 'support@paperstac.com',
    MAIL_FROM_NAME: 'Paperstac',
    MAIL_TRANSPORT: 'mailgun',
    MAILCHIMP_PAPERSTAC_USERS_LIST_ID: '6aa677ff90',
    NOTE_CLOSINGS_TENANT_ID: 'noteclosings-uhvl8',
    PAPERSTAC_SUPPORT_URL: 'https://support.paperstac.com',
    PAPERSTAC_USER_ICON: 'https://res.cloudinary.com/paperstac/image/upload/v1528581106/public-assets/concierge.png',
    PAYPAL_CLIENT_ID: 'ASraGkJd2M-tcE8PbqvFysjA_C46pDGcn2I7XRwblJrvUj-a1ocm0j_uGvqHj1kPEflv59swuJdXbw_f',
    PAYPAL_EMAIL: 'admin@paperstac.com',
    PLAID_CLIENT_ID: '5c950a876338fe001522c8cb',
    PLAID_NOTECLOSINGS_TEMPLATE_ID: 'idvtmp_eytanhyYfi6Uga',
    PLAID_PAPERSTAC_TEMPLATE_ID: 'idvtmp_emSxK3kXY9ineV',
    PLAID_PUBLIC_KEY: '9155d2df26dc8b33ded2eaef33f88c',
    RECAPTCHA_SITE_KEY: '6LcULTwUAAAAACPs7X02Q4QOtUMJH9tj4GVN55Jn',
    SENTRY_DSN_PUBLIC: 'https://eb8dfbca4baa4fc5b82449b923c52195@sentry.io/258282',
    SENTRY_ORG_SLUG: 'paperstac',
    STRIPE_PUBLIC_KEY: 'pk_live_DC0E8LWZkxw6xrcKanaixjMd',
    TWITTER_PROFILE_URL: 'https://twitter.com/paper_stac',
    USPS_USER_ICON: 'https://res.cloudinary.com/paperstac/image/upload/v1538493678/website-assets/usps.png',
    YOUTUBE_CHANNEL_URL: 'https://www.youtube.com/channel/UCoVIHmimYOoImMocDhkyg8Q',
};
exports.default = config;
