import { centsToUsdString, getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import PencilIcon from '@paperstac/ui/lib/icons/PencilIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import ListGrid from '@paperstac/ui/lib/ListGrid';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';
import useMedia from 'use-media';

const NegotiateNotePricingTable = React.memo(({ notes, notePrices, onEdit, updatedNotePrices, ...props }) => {
  const stacked = useMedia({ maxWidth: 400 });

  const getNotePriceText = React.useCallback(
    (noteId) => {
      if (updatedNotePrices && updatedNotePrices[noteId]) return centsToUsdString(updatedNotePrices[noteId]);
      if (notePrices && notePrices[noteId]) return centsToUsdString(notePrices[noteId]);
      return 'No price set';
    },
    [notePrices, updatedNotePrices]
  );

  const getPriceDiffComponent = React.useCallback(
    (noteId) => {
      if (!updatedNotePrices || !updatedNotePrices[noteId] || !notePrices || !notePrices[noteId]) return null;
      const diffCents = updatedNotePrices[noteId] - notePrices[noteId];
      if (diffCents === 0) return null;
      return (
        <Text
          sx={{
            display: 'inline',
            ml: 2,
            fontSize: 0,
            color: diffCents > 0 ? 'green' : 'red',
          }}
        >
          ({centsToUsdString(diffCents)})
        </Text>
      );
    },
    [notePrices, updatedNotePrices]
  );

  const items = React.useMemo(() => {
    return notes.map((note) => ({
      Lien: getOrdinal(note.lienPosition),
      Address: note.streetAddress,
      Price: (
        <>
          <LinkButton onClick={() => onEdit(note.id)}>
            <PencilIcon size={12} mr={1} />
            {getNotePriceText(note.id)}
          </LinkButton>
          {getPriceDiffComponent(note.id)}
        </>
      ),
    }));
  }, [getNotePriceText, getPriceDiffComponent, notes, onEdit]);

  return (
    <ListGrid
      items={items}
      sx={{
        fontSize: 1,
        mx: [-4, 0],
        borderRadius: [0, 'corner'],
        borderLeft: ['none', 'default'],
        borderRight: ['none', 'default'],
      }}
      stacked={stacked}
      {...props}
    />
  );
});

NegotiateNotePricingTable.propTypes = {
  notes: PropTypes.array.isRequired,
  notePrices: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
  updateNotePrices: PropTypes.object,
};

NegotiateNotePricingTable.defaultProps = {};

NegotiateNotePricingTable.displayName = 'NegotiateNotePricingTable';

export default NegotiateNotePricingTable;
