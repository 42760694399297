import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { CREATE_LISTING_SUCCESS, DELETE_LISTING_SUCCESS } from '../actions/listingActions';
import { SET_NOTES, UNSUBSCRIBE_NOTES } from '../actions/noteActions';

const DEFAULT_STATE = {
  items: [],
  lastUpdated: null,
};

// Number of items to keep after unsubscribing
const CACHE_SIZE = 12;

export default function notesReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case UNSUBSCRIBE_NOTES:
      return {
        ...DEFAULT_STATE,
        items: state.items.slice(0, CACHE_SIZE),
        lastUpdated: new Date().toISOString(),
      };
    case SET_NOTES:
      return {
        ...state,
        items: payload,
        lastUpdated: new Date().toISOString(),
      };
    case CREATE_LISTING_SUCCESS:
      return {
        ...state,
        items: state.items.map((note) => {
          if (payload.noteIds.includes(note.id)) {
            return payload.isPool
              ? { ...note, poolListingId: payload.id, hasPoolListing: true }
              : { ...note, listingId: payload.id, hasListing: true };
          }
          return note;
        }),
        lastUpdated: new Date().toISOString(),
      };
    case DELETE_LISTING_SUCCESS:
      return {
        ...state,
        items: state.items.map((note) => {
          if (payload.noteIds.includes(note.id)) {
            return payload.isPool
              ? { ...note, poolListingId: null, hasPoolListing: false }
              : { ...note, listingId: null, hasListing: false };
          }
          return note;
        }),
        lastUpdated: new Date().toISOString(),
      };
    default:
      return state;
  }
}

export const getNotes = (state) => {
  return state.items;
};

export const getUnlistedNotes = (state) => {
  return getNotes(state).filter((note) => !note.hasListing);
};

export const getPoolUnlistedNotes = (state) => {
  return getNotes(state).filter((note) => !note.hasPoolListing);
};

export const notesLastUpdated = (state) => {
  return state.lastUpdated;
};
