import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import SortCollectionProvider from '@paperstac/common/lib/components/SortCollectionProvider';
import useAlgoliaResults from '@paperstac/common/lib/hooks/useAlgoliaResults';
import useFirestoreSubscribe from '@paperstac/common/lib/hooks/useFirestoreSubscribe';
import { CREATE_STARRED_LISTING, DELETE_STARRED_LISTING } from '@paperstac/common/lib/serverDispatchActionTypes';
import { normalizeDate } from '@paperstac/helpers/lib/dateHelpers';
import { getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { getNameFromCode } from '@paperstac/common/lib/services/states';
import { LISTING_NEGOTIATION_TYPE } from '@paperstac/firestore-collections/lib/listings';
import { starredListingsByAccountIdQuery } from '@paperstac/firestore-collections/lib/starredListings';
import getPath from '@paperstac/routes/lib/getPath';
import { FOR_SALE_SINGLE } from '@paperstac/routes/lib/webRoutes';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import React from 'react';

const ListingsSearchContext = React.createContext();
const { Provider } = ListingsSearchContext;

const ListingsSearchProvider = React.memo((props) => {
  const [starredFilterEnabled, setStarredFilterEnabled] = React.useState(props.starredFilterEnabled);
  const { currentAccountId } = React.useContext(AccountsContext);
  const [starredListings] = useFirestoreSubscribe(starredListingsByAccountIdQuery(currentAccountId), [
    currentAccountId,
  ]);
  const algoliaResults = useAlgoliaResults();
  const results = starredFilterEnabled
    ? algoliaResults.filter(
        ({ id }) => Array.isArray(starredListings) && starredListings.find((sl) => get(sl, 'listingId') === id)
      )
    : algoliaResults;
  const providerValue = React.useMemo(
    () => ({
      toggleStarredFilter: () => setStarredFilterEnabled(!starredFilterEnabled),
      disableStarredFilter: () => setStarredFilterEnabled(false),
      starredFilterEnabled,
      getStarredListingByListingId: Array.isArray(starredListings)
        ? (listingId) => starredListings.find((sl) => get(sl, 'listingId') === listingId)
        : () => null,
      inactiveStarredListings: Array.isArray(starredListings)
        ? starredListings.filter((sl) => !get(sl, 'listing.isActive'))
        : [],
      starListing: (listing) => {
        return serverDispatch({
          action: CREATE_STARRED_LISTING,
          payload: { accountId: currentAccountId, listing },
        }).catch((error) => alert(`We experienced an error: ${error.message}`));
      },
      unstarListing: (starredListing) => {
        return serverDispatch({
          action: DELETE_STARRED_LISTING,
          payload: { id: starredListing.id },
        }).catch((error) => alert(`We experienced an error: ${error.message}`));
      },
      resultCount: results.length || 0,
    }),
    [results, starredListings, starredFilterEnabled, currentAccountId]
  );
  return (
    <SortCollectionProvider
      collection={transformResults(results, starredListings)}
      sortBy=""
      sortDirection="ASC"
      sortLabel="Popularity Rank"
    >
      <Provider value={providerValue} {...props} />
    </SortCollectionProvider>
  );
});

const transformResults = (results, starredListings) => {
  return results.map((listing) => {
    const starredListing = Array.isArray(starredListings)
      ? starredListings.find((sl) => get(sl, 'listingId') === listing.id)
      : null;
    const starredListingId = starredListing ? starredListing.id : null;
    return {
      hardestHitFund:
        uniq(get(listing, 'noteMetaAgg.isHardestHitFundState')).length === 1
          ? get(listing, 'noteMetaAgg.isHardestHitFundState')[0]
            ? 'Yes'
            : 'No'
          : 'Yes & No',
      id: get(listing, 'id'),
      interestRate: get(listing, 'noteMetaAgg.interestRatePercent'),
      starredListingId,
      itb: get(listing, 'investmentMetaAgg.investmentToBalancePercent'),
      itv: get(listing, 'investmentMetaAgg.investmentToValuePercent'),
      judicialState:
        uniq(get(listing, 'noteMetaAgg.isJudicialState')).length === 1
          ? get(listing, 'noteMetaAgg.isJudicialState')[0]
            ? 'Yes'
            : 'No'
          : 'Yes & No',
      legalStatus:
        uniq(get(listing, 'noteMetaAgg.legalStatus')).length === 1
          ? get(listing, 'noteMetaAgg.legalStatus')[0]
          : 'Various',
      lienPosition: uniq(get(listing, 'noteMetaAgg.lienPosition')).length
        ? getOrdinal(get(listing, 'noteMetaAgg.lienPosition')[0])
        : uniq(get(listing, 'noteMetaAgg.lienPosition'))
            .map((val) => getOrdinal(val))
            .join(', '),
      listPrice: get(listing, 'listPrice'),
      listedDate: normalizeDate(get(listing, 'listedDate')),
      listingType: get(listing, 'isPool') === 'true' ? `Asset Pool (${listing.noteCount})` : 'Single Asset',
      ltv: get(listing, 'noteMetaAgg.loanToValuePercent'),
      negotiationType: get(listing, 'negotiationType') || LISTING_NEGOTIATION_TYPE.BEST_OFFER,
      nonJudicialState:
        uniq(get(listing, 'noteMetaAgg.isNonJudicialState')).length === 1
          ? get(listing, 'noteMetaAgg.isNonJudicialState')[0]
            ? 'Yes'
            : 'No'
          : 'Yes & No',
      noteTypes: get(listing, 'noteTypes'),
      paymentsRemaining: get(listing, 'noteMetaAgg.paymentsRemaining'),
      pendingSale: get(listing, 'pendingSale'),
      performance:
        uniq(get(listing, 'noteMetaAgg.performance')).length === 1
          ? get(listing, 'noteMetaAgg.performance')[0]
          : 'PL & NPL',
      photoUrl: get(listing, 'photoUrl'),
      propertyValue: get(listing, 'noteMetaAgg.propertyValue'),
      seller: get(listing, 'seller.displayName'),
      sellerComments: get(listing, 'sellerComments'),
      state: uniq(get(listing, 'noteMetaAgg.state'))
        .map((code) => getNameFromCode(code))
        .sort(),
      title: get(listing, 'title'),
      upb: get(listing, 'noteMetaAgg.upb'),
      url: getPath(FOR_SALE_SINGLE, get(listing, 'id')),
      yieldPercent: get(listing, 'investmentMetaAgg.yieldPercent'),
    };
  });
};

ListingsSearchProvider.propTypes = {};

ListingsSearchProvider.defaultProps = {
  starredFilterEnabled: false,
};

ListingsSearchProvider.displayName = 'ListingsSearchProvider';

export default ListingsSearchProvider;

export { ListingsSearchContext };
