import { APP_ENV } from '@paperstac/env';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

const configureStore = (initialState) => {
  const middleware =
    APP_ENV === 'development' ? [require('redux-immutable-state-invariant').default(), thunk] : [thunk];

  return createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
};

export default configureStore;
