import { setLocale } from 'yup';
setLocale({
  mixed: {
    "default": 'Invalid',
    required: 'Required',
    oneOf: 'Must be one of the following values: ${values}',
    notOneOf: 'Must not be one of the following values: ${values}',
    notType: function notType(_ref) {
      var path = _ref.path,
        type = _ref.type,
        value = _ref.value,
        originalValue = _ref.originalValue;
      var isCast = originalValue != null && originalValue !== value;
      var msg = "Must be a `".concat(type, "` type, ") + "but the final value was: `".concat(printValue(value, true), "`") + (isCast ? " (cast from the value `".concat(printValue(originalValue, true), "`).") : '.');
      if (value === null) {
        msg += "\n If \"null\" is intended as an empty value be sure to mark the schema as `.nullable()`";
      }
      return msg;
    }
  },
  string: {
    length: 'Must be exactly ${length} characters',
    min: 'Must be at least ${min} characters',
    max: 'Must be at most ${max} characters',
    matches: 'Invalid format',
    email: 'Invalid email',
    url: 'Invalid URL'
  },
  number: {
    min: 'Must be greater than or equal to ${min}',
    max: 'Must be less than or equal to ${max}',
    lessThan: 'Must be less than ${less}',
    moreThan: 'Must be greater than ${more}',
    notEqual: 'Must be not equal to ${notEqual}',
    positive: 'Must be a positive number',
    negative: 'Must be a negative number',
    integer: 'Must be an integer'
  },
  "boolean": {},
  object: {
    noUnknown: '${path} field cannot have keys not specified in the object shape'
  },
  date: {
    min: '${path} field must be later than ${min}',
    max: '${path} field must be at earlier than ${max}'
  },
  array: {
    min: 'Must have at least ${min} items',
    max: 'Must have less than or equal to ${max} items'
  }
});