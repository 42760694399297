import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_SAVED_SEARCHES } from '../actions/savedSearchActions';

const DEFAULT_STATE = [];

export default function savedSearchesReducer(state = DEFAULT_STATE, { type, payload = [] }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_SAVED_SEARCHES:
      return payload;
    default:
      return state;
  }
}

export const getSavedSearches = (state) => {
  return state;
};
