export var lastCharacter = function lastCharacter(value) {
  var str = value + '';
  return str[str.length - 1];
};
export var camelToTitle = function camelToTitle(camelCase) {
  return camelCase ? camelCase.replace(/([A-Z])/g, function (match) {
    return " ".concat(match);
  }).replace(/^./, function (match) {
    return match.toUpperCase();
  }) : '';
};
export var toTitle = function toTitle(str) {
  return str ? str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }) : '';
};
export var uppercaseFirstLetter = function uppercaseFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export var toExcerpt = function toExcerpt(str, maxLength) {
  var trailing = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '…';
  return typeof str !== 'string' || str.length <= maxLength || !maxLength ? str : "".concat(str.substring(0, maxLength - trailing.length).trim()).concat(trailing);
};