import AlgoliaRadioRefinement from '@paperstac/ui/lib/AlgoliaRadioRefinement';
import React from 'react';
import FilterContainer from './FilterContainer';

const AvailabilityFilter = React.memo((props) => (
  <FilterContainer title="Availability">
    <AlgoliaRadioRefinement
      attribute="pendingSale"
      itemsOverride={[
        { label: 'Available', value: 'false' },
        { label: 'Pending Sale', value: 'true' },
      ]}
    />
  </FilterContainer>
));

AvailabilityFilter.propTypes = {};

AvailabilityFilter.defaultProps = {};

AvailabilityFilter.displayName = 'AvailabilityFilter';

export default AvailabilityFilter;
