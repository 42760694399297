import React from 'react';
import BaseLayout from './BaseLayout';

export default ({ children, title }) => (
  <BaseLayout title={title}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div style={{ textAlign: 'center' }}>{children}</div>
    </div>
  </BaseLayout>
);
