import { Component } from 'react';

class DelayComponent extends Component {
  state = { show: false };

  componentDidMount() {
    this.timeoutId = setTimeout(() => {
      this.setState({ show: true });
    }, this.props.delay);
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    return this.state.show ? this.props.children : null;
  }
}

DelayComponent.defaultProps = {
  delay: 1000,
};

export default DelayComponent;
