import { BASE_URL } from '@paperstac/env';
import getPath from '@paperstac/routes/lib/getPath';
import { BLOG_POSTS_SINGLE } from '@paperstac/routes/lib/webRoutes';
import React from 'react';
import FacebookComments from '../../common/FacebookComments';
import BlogPostLayout from '../../layouts/BlogPostLayout';
import DealOverview from './DealOverview';
import HeroSection from './HeroSection';
import IntroSection from './IntroSection';
import Itb from './Itb';
import Itv from './Itv';
import Price from './Price';
import Section from './Section';

const slug = '2022-mid-year-market-report';
export const metadata = {
  postTitle: "Paperstac's 2022 Mid Year Market Report",
  metaTitle: "Paperstac's 2022 Mid Year Market Report",
  metaDescription:
    'See what mortgage notes sold for on the Paperstac marketplace during the first half of 2022 in our market report.',
  slug,
  url: `${BASE_URL}${getPath(BLOG_POSTS_SINGLE, slug)}`,
  publishDate: '2022-07-27 08:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1658895023/website-assets/2022-mid-year-market-report-feature.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1658895022/website-assets/2022-mid-year-market-report-social.png',
  tags: ['report'],
};

const MidYearMarketReport2022 = React.memo(() => {
  return (
    <BlogPostLayout metadata={metadata}>
      <HeroSection postTitle={metadata.postTitle} />
      <IntroSection />
      <Itb />
      <Itv />
      <Price />
      <DealOverview />
      <Section title="Comments" subtitle="Let us know what you think...">
        <FacebookComments url={metadata.url} />
      </Section>
    </BlogPostLayout>
  );
});

MidYearMarketReport2022.propTypes = {};

MidYearMarketReport2022.defaultProps = {};

MidYearMarketReport2022.displayName = 'MidYearMarketReport2022';

export default MidYearMarketReport2022;
