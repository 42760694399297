import { REFRESH_SERVICE_WORKER } from '../actions/serviceWorkerActions';

const DEFAULT_STATE = false;

export default function serviceWorkerReducer(state = DEFAULT_STATE, { type }) {
  switch (type) {
    case REFRESH_SERVICE_WORKER:
      return true;
    default:
      return state;
  }
}

export const serviceWorkerNeedsRefresh = (state) => {
  return state;
};
