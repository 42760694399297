import React from 'react';
import { Helmet } from 'react-helmet';
import IdentityManager from '../common/IdentityManager';
import ServiceWorkerRefresh from '../common/ServiceWorkerRefresh';
import LegacyToaster from '../Toaster';
import { Toaster } from '@paperstac/ui/lib/Toast';

const BaseLayout = React.memo(({ children, title = 'Untitled Page | Paperstac' }) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta
        name="Description"
        content="Paperstac - the digital mortgage note transaction engine. Sell mortgage notes faster, buy mortgage notes easier. View mortgage notes for sale or list for free."
      />
      <meta
        itemProp="description"
        content="Paperstac - the digital mortgage note transaction engine. Sell mortgage notes faster, buy mortgage notes easier. View mortgage notes for sale or list for free."
      />
    </Helmet>
    <LegacyToaster />
    <Toaster />
    <ServiceWorkerRefresh />
    <IdentityManager>{children}</IdentityManager>
  </>
));

BaseLayout.propTypes = {};

BaseLayout.defaultProps = {};

BaseLayout.displayName = 'BaseLayout';

export default BaseLayout;
