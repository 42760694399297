import { BASE_URL } from '@paperstac/env';
import { createAccountDispatchValidator } from '@paperstac/firestore-collections/lib/accounts';
import { getDomainFromBaseUrl } from '@paperstac/helpers/lib/domain';
import Button from '@paperstac/ui/lib/Button';
import FileUploadGroup from '@paperstac/ui/lib/form/FileUploadGroup';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import SelectFormGroup from '@paperstac/ui/lib/form/SelectFormGroup';
import ExternalLinkIcon from '@paperstac/ui/lib/icons/ExternalLinkIcon';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorMessage from './ErrorMessage';

const accept = 'image/png, image/jpg, image/jpeg, image/gif';

const CreateAccountForm = React.memo(({ identityId, initialValues, onSubmit, submitText }) => (
  <Formik
    initialValues={{
      displayName: '',
      avatar: '',
      isBroker: '',
      ...initialValues,
      domain: getDomainFromBaseUrl(BASE_URL),
    }}
    validationSchema={createAccountDispatchValidator}
    onSubmit={onSubmit}
    children={(formik) => (
      <Form>
        <InputFormGroup name="displayName" label="Display Name:" required />
        <FileUploadGroup
          name="avatar"
          label="Profile Pic:"
          uploadPath={`user-uploads/${identityId}`}
          uploadButtonProps={{ variant: 'secondaryOutline', size: 'small', accept, children: 'Upload Image' }}
          replaceButtonProps={{ variant: 'secondaryOutline', size: 'small', accept, children: 'Replace Image' }}
          viewButtonProps={{
            variant: 'outline',
            size: 'small',
            children: (
              <>
                Preview{' '}
                <ExternalLinkIcon
                  style={{
                    position: 'relative',
                    top: '-2px',
                  }}
                />
              </>
            ),
          }}
        />
        <SelectFormGroup name="isBroker" label="Will you broker deals with this account?" required>
          <option value="">Select one...</option>
          <option value="true">Yes, I will broker deals</option>
          <option value="false">No, I will not broker deals</option>
        </SelectFormGroup>
        {formik.status && formik.status.errorMessage && <ErrorMessage>{formik.status.errorMessage}</ErrorMessage>}
        <Button type="submit" variant="primary" block={true} busy={formik.isSubmitting}>
          {submitText}
        </Button>
      </Form>
    )}
  />
));

CreateAccountForm.propTypes = {
  identityId: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string,
};

CreateAccountForm.defaultProps = {
  submitText: 'Create Account',
};

CreateAccountForm.displayName = 'CreateAccountForm';

export default CreateAccountForm;
