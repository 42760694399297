import React from 'react';
import BaseLayout from './BaseLayout';
import Footer from './common/Footer/Footer';
import Header from './common/Header/Header';
import './DefaultLayout.css';

const DefaultLayout = ({ children, title, description, showHeader, showFooter }) => (
  <BaseLayout title={title} description={description}>
    <div className="DefaultLayout">
      {showHeader && <Header />}
      <main className="DefaultLayout__main">{children}</main>
      {showFooter && <Footer />}
    </div>
  </BaseLayout>
);

DefaultLayout.defaultProps = {
  showHeader: true,
  showFooter: true,
};

export default DefaultLayout;
