import { centsToThousandUsdString } from '@paperstac/helpers/lib/numberHelpers';
import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';

const Stat = React.memo(({ avg, currency, max, min, oldAvg, title, subtitle }) => {
  const avgText = currency ? centsToThousandUsdString(avg * 100, 0) : `${Math.round(avg)}%`;
  const minText = currency ? centsToThousandUsdString(min * 100, 0) : `${Math.round(min)}%`;
  const maxText = currency ? centsToThousandUsdString(max * 100, 0) : `${Math.round(max)}%`;
  const change = Math.round(((avg - oldAvg) / oldAvg) * 100);
  const changeText = `${Math.abs(change)}%`;

  return (
    <Text textAlign="center" px={3} mb={[5, 0]}>
      <Flex alignItems="center" color="gray.6" width={['100%', 'auto']}>
        <Box>
          <Text fontSize={[16, 18]} lineHeight={1} fontWeight="bold" color="blue">
            {minText}
          </Text>
          <Text fontSize={[14, 16]}>Min</Text>
        </Box>
        <Box mx={3}>
          <Text fontSize={[40, 60]} lineHeight={1} fontWeight="bold" color="blue">
            {avgText}
          </Text>
          <Text fontSize={[14, 16]}>Average</Text>
        </Box>
        <Box>
          <Text fontSize={[16, 18]} lineHeight={1} fontWeight="bold" color="blue">
            {maxText}
          </Text>
          <Text fontSize={[14, 16]}>Max</Text>
        </Box>
      </Flex>
      <Box mb={2} fontSize={14} color={change < 0 ? 'red' : 'green'}>
        {changeText} {change < 0 ? 'lower' : 'higher'} than previous
      </Box>
      <Text fontSize={[16, 20]} lineHeight={1.2} fontWeight="bold" color="gray.9">
        {title}
      </Text>
      <Text fontSize={[14, 16]}>{subtitle}</Text>
    </Text>
  );
});

Stat.propTypes = {
  avg: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  oldAvg: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

Stat.defaultProps = {};

Stat.displayName = 'Stat';

export default Stat;
