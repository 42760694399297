import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_EVENT_MODERATION_QUEUE = 'SET_EVENT_MODERATION_QUEUE';

const transformCollection = (querySnapshot) => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = (docSnap) => {
  return docSnap.exists ? transformFirestoreTimestamps(docSnap) : null;
};

export const setEventModerationQueue = (payload) => {
  return { type: SET_EVENT_MODERATION_QUEUE, payload };
};

export const deleteEventInModeration = (eventId) => {
  return (dispatch) => {
    return firestore.doc(`eventModerationQueue/${eventId}`).delete();
  };
};

export const subscribeEventModerationQueue = () => {
  return (dispatch) => {
    return firestore
      .collection('eventModerationQueue')
      .limit(50)
      .onSnapshot((querySnapshot) => {
        dispatch(setEventModerationQueue(transformCollection(querySnapshot)));
      });
  };
};
