import { COMMUNITY_SELLER_POLICY_ALL, COMMUNITY_VISIBILITY_PRIVATE } from '@paperstac/constants';
import { firestore, TIMESTAMP } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_COMMUNITY = 'SET_COMMUNITY';
export const SET_MY_COMMUNITIES = 'SET_MY_COMMUNITIES';
export const SET_SELLING_COMMUNITIES = 'SET_SELLING_COMMUNITIES';

const unsubscribes = {};

const transformCollection = (querySnapshot) => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = (docSnap) => {
  return docSnap.exists ? transformFirestoreTimestamps(docSnap) : null;
};

export const setCommunity = (payload) => {
  return { type: SET_COMMUNITY, payload };
};

export const setMyCommunities = (payload) => {
  return { type: SET_MY_COMMUNITIES, payload };
};

export const setSellingCommunities = (payload) => {
  return { type: SET_SELLING_COMMUNITIES, payload };
};

export const createCommunityRequest = (data) => {
  const payload = {
    id: dateid(),
    visibility: COMMUNITY_VISIBILITY_PRIVATE,
    ...data,
  };
  return (dispatch) => {
    return firestore.doc(`communities/${payload.id}`).set(payload);
  };
};

export const updateCommunityRequest = (data) => {
  const payload = { ...data, updatedDate: TIMESTAMP };
  return (dispatch) => {
    return firestore.doc(`communities/${payload.id}`).update(payload);
  };
};

export const subscribeCommunity = (communityId) => {
  return (dispatch) => {
    if (unsubscribes[communityId]) {
      unsubscribes[communityId]();
    }
    unsubscribes[communityId] = firestore.doc(`communities/${communityId}`).onSnapshot((docSnapshot) => {
      dispatch(setCommunity({ [communityId]: transformDoc(docSnapshot) }));
    });
  };
};

export const unsubscribeCommunity = (communityId) => {
  return (dispatch) => {
    if (unsubscribes[communityId]) {
      unsubscribes[communityId]();
      unsubscribes[communityId] = null;
    }
  };
};

export const subscribeMyCommunities = (accountId) => {
  return (dispatch) => {
    if (unsubscribes.myCommunities) {
      unsubscribes.myCommunities();
    }
    unsubscribes.myCommunities = firestore
      .collection('communities')
      .where('memberIds', 'array-contains', accountId)
      .orderBy('name')
      .onSnapshot((querySnapshot) => {
        dispatch(setMyCommunities(transformCollection(querySnapshot)));
      });
  };
};

export const unsubscribeMyCommunities = () => {
  return (dispatch) => {
    if (unsubscribes.myCommunities) {
      unsubscribes.myCommunities();
      unsubscribes.myCommunities = null;
    }
  };
};

export const subscribeSellingCommunities = (accountId) => {
  return (dispatch) => {
    if (unsubscribes.sellingCommunites) {
      unsubscribes.sellingCommunites();
    }
    unsubscribes.sellingCommunites = firestore
      .collection('communities')
      .where('memberIds', 'array-contains', accountId)
      .orderBy('name')
      .onSnapshot((querySnapshot) => {
        const sellingCommunities = transformCollection(querySnapshot).filter((community) => {
          return community.sellerPolicy === COMMUNITY_SELLER_POLICY_ALL || community.sellerIds.includes(accountId);
        });
        dispatch(setSellingCommunities(sellingCommunities));
      });
  };
};

export const unsubscribeSellingCommunities = () => {
  return (dispatch) => {
    if (unsubscribes.sellingCommunites) {
      unsubscribes.sellingCommunites();
      unsubscribes.sellingCommunites = null;
    }
  };
};
