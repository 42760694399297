import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import React from 'react';
import { firestore } from '../../../services/firebaseClient';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import NoteUnlimitedReports from './NoteUnlimitedReports';

const DueDiligenceSection = React.memo(({ noteId, parentWidth, salePrice, ...props }) => {
  const { uid } = React.useContext(IdentityContext);
  const [nuIntegrations, setNuIntegrations] = React.useState([]);
  React.useEffect(() => {
    firestore
      .collection('integrations')
      .where('identityId', '==', uid)
      .onSnapshot((querySnap) => {
        setNuIntegrations(
          querySnap.docs.map((docSnap) => docSnap.data()).filter((integration) => integration.type === 'NOTEUNLIMITED')
        );
      });
  }, [setNuIntegrations, uid]);

  if (!nuIntegrations) return null;

  return (
    <Box {...props}>
      <Heading
        sx={{
          mb: 2,
          fontSize: 2,
          textTransform: 'uppercase',
        }}
      >
        Due Diligence
      </Heading>

      {nuIntegrations.map((integration) => (
        <NoteUnlimitedReports
          key={integration.id}
          integration={integration}
          noteId={noteId}
          salePrice={salePrice}
          sx={{ mt: 3 }}
        />
      ))}
    </Box>
  );
});

DueDiligenceSection.defaultProps = {};

DueDiligenceSection.displayName = 'DueDiligenceSection';

export default DueDiligenceSection;
