import React from 'react';
import { trpc } from '../../../services/trpc';

export default function useSyncIdentityVerification() {
  const { mutateAsync } = trpc.plaid.syncIdentityVerification.useMutation();

  return React.useCallback(
    (identityVerificationId) => {
      return mutateAsync({ identityVerificationId });
    },
    [mutateAsync]
  );
}
