import AlgoliaHasRangeRefinement from '@paperstac/ui/lib/AlgoliaHasRangeRefinement';
import AlgoliaRangeInput from '@paperstac/ui/lib/AlgoliaRangeInput';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const UnpaidPrincipalBalanceFilter = React.memo((props) => (
  <AlgoliaHasRangeRefinement attribute="noteMetaAgg.upb">
    <CollapsibleFilterContainer title="Unpaid Principal Balance (UPB)">
      <AlgoliaRangeInput attribute="noteMetaAgg.upb" format="thousandUsd" />
    </CollapsibleFilterContainer>
  </AlgoliaHasRangeRefinement>
));

UnpaidPrincipalBalanceFilter.propTypes = {};

UnpaidPrincipalBalanceFilter.defaultProps = {};

UnpaidPrincipalBalanceFilter.displayName = 'UnpaidPrincipalBalanceFilter';

export default UnpaidPrincipalBalanceFilter;
