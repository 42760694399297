import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_NOTE_FILES } from '../actions/noteFileActions';

const DEFAULT_STATE = { items: {} };

export default function noteFilesReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_NOTE_FILES:
      return { ...state, items: { ...state.items, [payload.id]: payload.items } };
    default:
      return state;
  }
}

export const getNoteFiles = (state, noteId) => {
  return state.items[noteId] || [];
};
