import React from 'react';
import { REM } from '../../theme';

const PlusIcon = ({ size, fill, style, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={REM(size)}
    height={REM(size)}
    style={{ width: REM(size), height: REM(size), ...style }}
    fill={fill}
    {...props}
  >
    <path d="M15.5 6H10V.5c0-.3-.2-.5-.5-.5h-3c-.3 0-.5.2-.5.5V6H.5c-.3 0-.5.2-.5.5v3c0 .3.2.5.5.5H6v5.5c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5V10h5.5c.3 0 .5-.2.5-.5v-3c0-.3-.2-.5-.5-.5z" />
  </svg>
);

PlusIcon.defaultProps = { size: 16, fill: 'currentColor', style: {} };

export default PlusIcon;
