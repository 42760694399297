import { REMOVE_NOTE_FROM_LISTING } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import useBoolean from '@paperstac/hooks/lib/useBoolean';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import DescriptionGridTerm from '@paperstac/ui/lib/DescriptionGridTerm';
import Flex from '@paperstac/ui/lib/Flex';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import InfoCircleIcon from '@paperstac/ui/lib/icons/InfoCircleIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import NeedsAttentionList from './NeedsAttentionList';
import { NoteScreenContext } from './NoteScreen';
import NoteScreenFileList from './NoteScreenFileList';
import NoteScreenPhotos from './NoteScreenPhotos';
import NoteScreenViewDetails from './NoteScreenViewDetails';
import NoteScreenUnderlyingLiens from './NoteScreenUnderlyingLiens';
import NoteScreenViewPaymentHistory from './NoteScreenViewPaymentHistory';
import NoteScreenViewPropertyValue from './NoteScreenViewPropertyValue';
import NoteScreenViewSeniorDetails from './NoteScreenViewSeniorDetails';

const NoteScreenOverview = React.memo(() => {
  const { listing, note, showOverview: showListing, stacked } = React.useContext(ManageListingOverlayContext);
  const { showAddFiles } = React.useContext(NoteScreenContext);
  const [isRemoving, setRemoving, unsetRemoving] = useBoolean(false);
  const handleRemove = React.useCallback(() => {
    if (isRemoving) return;
    setRemoving();
    serverDispatch({
      action: REMOVE_NOTE_FROM_LISTING,
      payload: { listingId: listing.id, noteId: note.id },
    })
      .then(showListing)
      .catch((e) => {
        alert(e.message);
        unsetRemoving();
      });
  }, [isRemoving, listing.id, note.id, setRemoving, showListing, unsetRemoving]);

  return (
    <DescriptionGrid
      sx={{
        bg: 'gray.1',
        border: 'none',
        borderTop: 'default',
        borderBottom: 'default',
        borderRadius: 0,
      }}
    >
      <DescriptionGridCell bg="highlight">
        <Flex alignItems="center">
          <LinkButton onClick={showListing}>Listing</LinkButton>
          <ChevronRightIcon size={12} mx={2} color="gray.6" />
          Note
        </Flex>
      </DescriptionGridCell>
      <DescriptionGridCell>
        {listing.isPool && (
          <Box mb={3} textAlign="right">
            <Button variant="redOutline" size="small" onClick={handleRemove} busy={isRemoving}>
              Remove Note
            </Button>
          </Box>
        )}
        <Flex alignItems={stacked ? 'flex-start' : 'center'}>
          <Flex
            sx={{
              bg: note.lienPosition === 1 ? 'gray.4' : 'gray.7',
              color: note.lienPosition === 1 ? 'gray.9' : 'white',
              borderRadius: 'corner',
              fontSize: 1,
              textAlign: 'center',
              width: 36,
              py: 1,
              mr: 2,
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
            }}
          >
            {getOrdinal(note.lienPosition)}
          </Flex>
          <Flex flexDirection={stacked ? 'column' : 'row'} alignItems={stacked ? 'normal' : 'center'}>
            <Box fontSize={2} mr={stacked ? 0 : 2}>
              {note.streetAddress}
            </Box>
            <Box fontSize={1} color="gray.6">
              {note.cityStateZip}
            </Box>
          </Flex>
        </Flex>
        <NeedsAttentionList reasons={listing.needsAttention.notes[note.id]} mt={1} />
      </DescriptionGridCell>
      <NoteScreenViewDetails />
      <NoteScreenUnderlyingLiens />
      {note.lienPosition > 1 && <NoteScreenViewSeniorDetails />}
      <NoteScreenPhotos />
      <DescriptionGridCell>
        <DescriptionGridTerm mb={3}>
          Related Files (
          <LinkButton onClick={showAddFiles} sx={{ textTransform: 'none' }}>
            <Text variant="bold">+</Text> Add Files
          </LinkButton>
          )
        </DescriptionGridTerm>
        {!note.files.length && (
          <Box fontSize={1}>
            <InfoCircleIcon sx={{ color: 'blue', position: 'relative', top: '-2px' }} /> <Text variant="bold">TIP</Text>
            : Save time by adding your note-related files here, instead of uploading the same files over and over again
            in your transactions.
          </Box>
        )}
        <NoteScreenFileList />
      </DescriptionGridCell>
      <NoteScreenViewPropertyValue />
      <NoteScreenViewPaymentHistory />
    </DescriptionGrid>
  );
});

NoteScreenOverview.propTypes = {};

NoteScreenOverview.defaultProps = {};

NoteScreenOverview.displayName = 'NoteScreenOverview';

export default NoteScreenOverview;
