import { END_SESSION } from '../actions/authActions';
import {
  SET_COLUMN_SETTINGS,
  SET_MANAGE_NOTES_COLUMN_SETTINGS,
  SET_VIEW_NOTES_COLUMN_SETTINGS,
} from '../actions/columnSettingsActions';
import { UNSUBSCRIBE_CURRENT_USER } from '../actions/currentUserActions';

const DEFAULT_STATE = {};

export default function currentUserReducer(state = DEFAULT_STATE, { type, payload }) {
  let key, settings, columns;
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_USER:
      return DEFAULT_STATE;
    case SET_COLUMN_SETTINGS:
      ({ key, settings, columns } = payload);
      return { ...state, [key]: { settings, columns }, [`${key}LastUpdated`]: new Date().toISOString() };
    case SET_MANAGE_NOTES_COLUMN_SETTINGS:
      ({ settings, columns } = payload);
      return { ...state, manageNotes: { settings, columns }, manageNotesLastUpdated: new Date().toISOString() };
    case SET_VIEW_NOTES_COLUMN_SETTINGS:
      ({ settings, columns } = payload);
      return { ...state, viewNotes: { settings, columns }, viewNotesLastUpdated: new Date().toISOString() };
    default:
      return state;
  }
}

export const getColumnSettings = (state, key) => {
  return state[key] ? state[key] : { settings: {}, columns: [] };
};

export const columnSettingsLastUpdated = (state, key) => {
  return state[`${key}LastUpdated`];
};

export const getManageNotesColumnSettings = (state) => {
  return state.manageNotes ? state.manageNotes : { settings: {}, columns: [] };
};

export const manageNotesColumnSettingsLastUpdated = (state) => {
  return state.manageNotesLastUpdated;
};

export const getViewNotesColumnSettings = (state) => {
  return state.viewNotes ? state.viewNotes : { settings: {}, columns: [] };
};

export const viewNotesColumnSettingsLastUpdated = (state) => {
  return state.viewNotesLastUpdated;
};
