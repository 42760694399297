import './setupFirebaseClient';
import { getFirebase } from '@paperstac/firebase/lib/firebase';

const firebase = getFirebase();

export default firebase;

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const TIMESTAMP = firebase.firestore.FieldValue.serverTimestamp();
export const DELETE = firebase.firestore.FieldValue.delete();
