import { IS_TOUCH_DEVICE } from '../actions/deviceActions';

const DEFAULT_STATE = { isTouchDevice: false };

export default function deviceReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case IS_TOUCH_DEVICE:
      return { ...state, isTouchDevice: true };
    default:
      return state;
  }
}

export const getIsTouchDevice = (state) => {
  return state.isTouchDevice;
};
