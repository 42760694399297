import firestore from '@paperstac/firebase/lib/firestore';
import { normalizeDate } from '@paperstac/helpers/lib/dateHelpers';
export var transactionsRef = function transactionsRef() {
  return firestore.collection('transactions');
};
export var transactionsByNoteIdRef = function transactionsByNoteIdRef(noteId) {
  return transactionsRef().where('noteIds', 'array-contains', noteId);
};
export var transactionByIdRef = function transactionByIdRef(transactionId) {
  return transactionId ? transactionsRef().doc(transactionId) : null;
};
export var transactionsByStatusQuery = function transactionsByStatusQuery(status) {
  return transactionsRef().where('status', '==', status);
};
export var transactionsByStatusesQuery = function transactionsByStatusesQuery(statuses) {
  if (!statuses || Array.isArray(statuses) && !statuses.length) return transactionsRef();
  if (!Array.isArray(statuses)) return transactionsByStatusQuery(statuses);
  if (statuses.length === 1) return transactionsByStatusQuery(statuses[0]);
  return transactionsRef().where('status', 'in', statuses);
};
export var transactionsByListingIdQuery = function transactionsByListingIdQuery(listingId) {
  return listingId ? transactionsRef().where('listingId', '==', listingId) : null;
};
export var transactionsByBuyerIdQuery = function transactionsByBuyerIdQuery(buyerId) {
  return buyerId ? transactionsRef().where('buyerId', '==', buyerId) : null;
};
export var transactionsBySellerIdQuery = function transactionsBySellerIdQuery(sellerId) {
  return sellerId ? transactionsRef().where('sellerId', '==', sellerId) : null;
};
export var transactionsByBuyerIdListingIdQuery = function transactionsByBuyerIdListingIdQuery(buyerId, listingId) {
  return buyerId && listingId ? transactionsRef().where('buyerId', '==', buyerId).where('listingId', '==', listingId) : null;
};
export var TRANSACTION_STATUS = {
  ABANDONED: 'Abandoned',
  NEGOTIATIONS: 'Negotiations',
  CLOSING: 'Closing',
  COMPLETE: 'Complete'
};
var canNudge = function canNudge(nudgerLastEventDate, nudgeeLastEventDate) {
  // Nudger has not authored an event, bail
  if (!nudgerLastEventDate) return false;
  var nudgerDate = normalizeDate(nudgerLastEventDate);
  var nudgeeDate = nudgeeLastEventDate ? normalizeDate(nudgeeLastEventDate) : null;
  // Nudgee's last event occurred after nudger's last event, bail
  if (nudgeeDate && nudgeeDate.getTime() > nudgerDate.getTime()) return false;
  var comparisonDate = new Date();
  var SUNDAY = 0;
  var SATURDAY = 6;
  // Subtract days from the comparison date
  switch (comparisonDate.getDay()) {
    case SUNDAY:
      comparisonDate.setDate(comparisonDate.getDate() - 4);
      break;
    case SATURDAY:
      comparisonDate.setDate(comparisonDate.getDate() - 3);
      break;
    default:
      comparisonDate.setDate(comparisonDate.getDate() - 2);
  }
  // If comparison date is now a weekend, subtract another 2 days
  if ([SUNDAY, SATURDAY].includes(comparisonDate.getDay())) comparisonDate.setDate(comparisonDate.getDate() - 2);
  // Did nudger's last date occur prior to comparison date?
  return nudgerDate.getTime() < comparisonDate.getTime();
};
export var canBuyerNudge = function canBuyerNudge(transaction) {
  return canNudge(transaction.buyerLastEventDate, transaction.sellerLastEventDate);
};
export var canSellerNudge = function canSellerNudge(transaction) {
  return canNudge(transaction.sellerLastEventDate, transaction.buyerLastEventDate);
};