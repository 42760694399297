import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import listingTitleGenerator from '@paperstac/common/lib/services/listingTitleGenerator';
import Heading from '@paperstac/ui/lib/Heading';
import StarButton from '@paperstac/ui/lib/StarButton';
import React from 'react';
import { ListingPageContext } from './ListingPageProvider';
import Section from './Section';

const TitleSection = React.memo(() => {
  const { currentAccountId } = React.useContext(AccountsContext);
  const { listing, isStarred, toggleStarred, toggleStarredBusy, width } = React.useContext(ListingPageContext);
  const smallTitle = width < 640;

  return (
    <Section>
      <Heading
        as="h1"
        sx={{ fontSize: smallTitle ? 'mobileTitle' : 'title', textAlign: smallTitle ? 'center' : 'left' }}
      >
        {currentAccountId && (
          <StarButton
            onClick={() => toggleStarred()}
            active={isStarred}
            busy={toggleStarredBusy}
            size={smallTitle ? 20 : 32}
            sx={{
              mr: smallTitle ? 1 : 2,
              position: 'relative',
              top: smallTitle ? '1px' : '2px',
            }}
          />
        )}
        {listingTitleGenerator(listing)}
      </Heading>
    </Section>
  );
});

TitleSection.propTypes = {};

TitleSection.defaultProps = {};

TitleSection.displayName = 'TitleSection';

export default TitleSection;
