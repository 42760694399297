// @ts-ignore
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { intercomBoot, intercomLogin, intercomLogout } from '@paperstac/common/lib/services/intercomMessenger';
import { INTERCOM_PAPERSTAC_APP_ID } from '@paperstac/env';
import { getDomains } from '@paperstac/helpers/lib/domain';
import { IdentityFirestoreData } from '@paperstac/types/lib/Identity';
import * as React from 'react';
import { trpc } from '../services/trpc';

const ConfigureIntercomMessenger = () => {
  const { identity } = React.useContext<{ identity: IdentityFirestoreData | null }>(IdentityContext);
  const [hasBooted, setBooted] = React.useState<boolean>(false);
  const [isIdentified, setIdentified] = React.useState<boolean>(false);
  const { mutateAsync } = trpc.retrieveIntercomUserHash.useMutation();

  const boot = React.useCallback(() => {
    if (hasBooted) return;
    setBooted(true);
    intercomBoot(INTERCOM_PAPERSTAC_APP_ID);
  }, [hasBooted, setBooted]);

  const identify = React.useCallback(
    (identity: IdentityFirestoreData) => {
      setIdentified(true);
      mutateAsync({ domain: getDomains().PAPERSTAC }).then((userHash) => {
        intercomLogin({
          createdUnixTimestamp: identity.createdDate.seconds,
          email: identity.email,
          name: identity.isVerified ? identity.displayName : identity.email,
          uid: identity.id,
          userHash,
        });
      });
    },
    [mutateAsync]
  );

  const forget = React.useCallback(() => {
    setIdentified(false);
    intercomLogout();
  }, []);

  React.useEffect(() => {
    boot();
    if (identity && !isIdentified) identify(identity);
    if (!identity && isIdentified) forget();
  }, [boot, identity, forget, identify, isIdentified]);

  return null;
};

ConfigureIntercomMessenger.displayName = 'ConfigureIntercomMessenger';

export default React.memo(ConfigureIntercomMessenger);
