import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_TRANSACTION_DOCUMENTS } from '../actions/transactionDocumentsActions';

const DEFAULT_STATE = {};

export default function transactionDocumentsReducer(state = DEFAULT_STATE, { type, payload = [] }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_TRANSACTION_DOCUMENTS:
      return {
        ...state,
        [payload.transactionId]: payload.documents,
      };
    default:
      return state;
  }
}

export const getTransactionDocuments = (state, transactionId) => {
  return state[transactionId] || [];
};

export const getTransactionDocument = (state, transactionId, documentId) => {
  return state[transactionId].find((td) => td.id === documentId) || {};
};
