export var AUDIT_EVENT_COMMENT = 'COMMENT';
export var AUDIT_EVENT_CREATED = 'CREATED';
export var AUDIT_EVENT_READY_TO_SHIP = 'READY_TO_SHIP';
export var AUDIT_EVENT_REPORT_SUBMITTED = 'REPORT_SUBMITTED';
export var AUDIT_EVENT_SHIPPING_INFO_PROVIDED = 'SHIPPING_INFO_PROVIDED';
export var AUDIT_EVENT_SHIPPING_LABELS_GENERATED = 'SHIPPING_LABELS_GENERATED';
export var AUDIT_STATUS_NEW_ORDER = 'New Order';
export var AUDIT_STATUS_AUDITED = 'Audited';
export var AUDIT_STATUS_READY_TO_SHIP = 'Ready to Ship';
export var AUDIT_STATUS_SHIPPED = 'Shipped';
export var AUDIT_STATUS_COMPLETE = 'Complete';
export var BUYER_AUDIENCE_ALL = 'ALL';
export var BUYER_AUDIENCE_NON_BROKERS = 'NON_BROKERS';
export var BUYER_AUDIENCE_BROKERS = 'BROKERS';
export var BUYER_AUDIENCE_COMMUNITY = 'COMMUNITY';
export var CLOSING_SCHEDULE_ESCROW_AUDIT = 'ESCROW_AUDIT';
export var CLOSING_SCHEDULE_ESCROW_AUDIT_DESCRIPTION = 'Buyer sends full payment to escrow. Seller ships collateral to auditor for report. Upon approval, Allonge(s) and Assignment(s) are executed and then funds are wired to seller and collateral is shipped to buyer simultaneously.';
export var CLOSING_SCHEDULE_ESCROW_AUDIT_LABEL = 'Escrow and Audit';
export var CLOSING_SCHEDULE_DEPOSIT_AUDIT_PAYMENT = 'DEPOSIT_AUDIT_PAYMENT';
export var CLOSING_SCHEDULE_DEPOSIT_AUDIT_PAYMENT_DESCRIPTION = '';
export var CLOSING_SCHEDULE_DEPOSIT_AUDIT_PAYMENT_LABEL = '50% Deposit to Seller and Audit';
export var CLOSING_SCHEDULE_PAYMENT_AUDIT = 'PAYMENT_AUDIT';
export var CLOSING_SCHEDULE_PAYMENT_AUDIT_DESCRIPTION = 'Buyer sends full payment to seller. Seller ships collateral to auditor for report. Upon approval, Allonge(s) and Assignment(s) are executed and collateral is shipped from seller to buyer.';
export var CLOSING_SCHEDULE_PAYMENT_AUDIT_LABEL = 'Full Payment to Seller and Audit';
export var CLOSING_SCHEDULE_PAYMENT = 'PAYMENT';
export var CLOSING_SCHEDULE_PAYMENT_DESCRIPTION = 'Buyer sends full payment to seller. Allonge(s) and Assignment(s) are executed and collateral is shipped from seller to buyer.';
export var CLOSING_SCHEDULE_PAYMENT_LABEL = 'Full Payment to Seller';
export var COMMUNITY_SELLER_POLICY_ALL = 'All Community Members';
export var COMMUNITY_SELLER_POLICY_SELECT = 'Select Community Members';
export var COMMUNITY_VISIBILITY_PUBLIC = 'Public';
export var COMMUNITY_VISIBILITY_PRIVATE = 'Private';
export var CUTOFF_DATE_DESCRIPTION = 'The final day that the seller is entitled to borrower payments and the date which the unpaid principal balance will be based.';
export var DOCUMENT_TYPE_ALLONGE = 'Allonge';
export var DOCUMENT_TYPE_ASSIGNMENT = 'Assignment';
export var DOCUMENT_TYPE_ASSIGNMENT_OF_DEED_OF_TRUST = 'Assignment of Deed of Trust';
export var DOCUMENT_TYPE_ASSIGNMENT_OF_MORTGAGE = 'Assignment of Mortgage';
export var DOCUMENT_TYPE_BUYER_INVOICE = 'Buyer Invoice';
export var DOCUMENT_TYPE_DISBURSEMENT_AGREEMENT = 'Disbursement Agreement';
export var DOCUMENT_TYPE_PURCHASE_SALE_AGREEMENT = 'Purchase Sale Agreement';
export var DOCUMENT_TYPE_RECEIPT_OF_FUNDS = 'Receipt of Funds';
export var ESCROW_EVENT_COMMENT = 'COMMENT';
export var ESCROW_EVENT_CREATED = 'CREATED';
export var ESCROW_EVENT_DELIVERABLES_APPROVED = 'DELIVERABLES_APPROVED';
export var ESCROW_EVENT_FEE_PAID = 'FEE_PAID';
export var ESCROW_EVENT_PAYMENT_RECEIVED = 'PAYMENT_RECEIVED';
export var ESCROW_EVENT_PAYOUT_DISBURSED = 'PAYOUT_DISBURSED';
export var ESCROW_FEE = 25000;
export var ESCROW_INCLUDED_THRESHOLD = 10000000;
export var ESCROW_STATUS_AWAITING_PAYMENT = 'Awaiting Payment';
export var ESCROW_STATUS_FUNDED = 'Funded';
export var ESCROW_STATUS_READY_TO_DISBURSE = 'Ready to Disburse';
export var ESCROW_STATUS_COMPLETE = 'Complete';
export var ESCROW_STATUS_REFUND_REQUESTED = 'Refund Requested';
export var ESCROW_STATUS_REFUND_COMPLETE = 'Refund Complete';
export var FLAG_EVENT_CONTACT_INFO_SHARED = 'Contact info shared';
export var FLAG_EVENT_OFFENSIVE = 'Inappropriate or offensive';
export var FLAG_EVENT_EXTERNAL_COMMUNICATION = 'Attempting to communicate outside of Paperstac';
export var IMPORT_STATUS_FAILED = 'FAILED';
export var IMPORT_STATUS_PROCESSING = 'PROCESSING';
export var IMPORT_STATUS_SUCCESS = 'SUCCESS';
export var LISTING_DURATION_DAYS = 30;
export var LISTING_POPULARITY_TRANSACTION_VALUE = 5;
export var LISTING_POPULARITY_VIEW_VALUE = 1;
export var NOTE_TAPE_LEGAL_STATUS_BANKRUPTCY = 'Bankruptcy';
export var NOTE_TAPE_LEGAL_STATUS_FORECLOSURE = 'Foreclosure';
export var NOTE_TAPE_LEGAL_STATUS_NONE = 'None';
export var NOTE_TAPE_OCCUPANCY_OCCUPIED = 'Occupied';
export var NOTE_TAPE_OCCUPANCY_UNKNOWN = 'Unknown';
export var NOTE_TAPE_OCCUPANCY_VACANT = 'Vacant';
export var NOTE_TAPE_PERFORMANCE_NON_PERFORMING = 'Non-Performing';
export var NOTE_TAPE_PERFORMANCE_PERFORMING = 'Performing';
export var NOTE_TAPE_PROPERTY_TYPE_BUSINESS = 'Business';
export var NOTE_TAPE_PROPERTY_TYPE_COMMERCIAL = 'Commercial';
export var NOTE_TAPE_PROPERTY_TYPE_CONDO = 'Condominium';
export var NOTE_TAPE_PROPERTY_TYPE_LAND = 'Land';
export var NOTE_TAPE_PROPERTY_TYPE_MULTI_FAMILY = 'Multi-Family';
export var NOTE_TAPE_PROPERTY_TYPE_OTHER = 'Other';
export var NOTE_TAPE_PROPERTY_TYPE_PUD = 'PUD';
export var NOTE_TAPE_PROPERTY_TYPE_SINGLE_FAMILY = 'Single Family';
export var PAPERSTAC_AVATAR = 'https://res.cloudinary.com/paperstac/image/upload/v1528581106/public-assets/concierge.png';
export var PROPERTY_VALUE_SOURCE_PARTNER = 'Partner';
export var PROPERTY_VALUE_SOURCE_SELLER = 'Seller';
export var PROPERTY_VALUE_SOURCE_ZILLOW = 'Zillow';
export var PROPERTY_VALUE_TYPE_APPRAISAL = 'Appraisal';
export var PROPERTY_VALUE_TYPE_BPO = 'BPO';
export var PROPERTY_VALUE_TYPE_OTHER = 'Other';
export var PROPERTY_VALUE_TYPE_RENT_ZESTIMATE = 'Rent Zestimate';
export var PROPERTY_VALUE_TYPE_ZESTIMATE = 'Zestimate';
export var SENSITIVITY_REVIEW_TYPE_LISTING_SELLER_COMMENTS = 'Listing Seller Comments';
export var SENSITIVITY_REVIEW_TYPE_LISTING_TITLE = 'Listing Title';
export var SENSITIVITY_REVIEW_TYPE_NOTE_PUBLIC_FILE = 'Public Note File';
export var SENSITIVITY_REVIEW_TYPE_NOTE_PHOTO = 'Note Photo';
export var SENSITIVITY_REVIEW_TYPE_NOTE_SELLER_PROVIDED_CUSTOM_DATA = 'Seller Provided Custom Note Data';
export var SHIPPO_TRACKING_STATUS_UNKNOWN = 'UNKNOWN';
export var SHIPPO_TRACKING_STATUS_PRE_TRANSIT = 'PRE_TRANSIT';
export var SHIPPO_TRACKING_STATUS_TRANSIT = 'TRANSIT';
export var SHIPPO_TRACKING_STATUS_DELIVERED = 'DELIVERED';
export var SHIPPO_TRACKING_STATUS_FAILURE = 'FAILURE';
export var SHIPPO_TRACKING_STATUS_RETURNED = 'RETURNED';
export var TRANSACTION_EVENT_ABANDON = 'ABANDON';
export var TRANSACTION_EVENT_ACCEPT_PRICE = 'ACCEPT_PRICE';
export var TRANSACTION_EVENT_ADD_PHONE_REQUEST = 'ADD_PHONE_REQUEST';
export var TRANSACTION_EVENT_APPROVE_CLOSING = 'APPROVE_CLOSING';
export var TRANSACTION_EVENT_APPROVED_CUSTOM_DOCUMENT = 'APPROVED_CUSTOM_DOCUMENT';
export var TRANSACTION_EVENT_AUDITOR_NOTIFIED_TO_SHIP = 'AUDITOR_NOTIFIED_TO_SHIP';
export var TRANSACTION_EVENT_AUDITOR_SHIPPED_COLLATERAL = 'AUDITOR_SHIPPED_COLLATERAL';
export var TRANSACTION_EVENT_AUDITS_COMPLETE = 'AUDITS_COMPLETE';
export var TRANSACTION_EVENT_AUDITS_ORDERED = 'AUDITS_ORDERED';
export var TRANSACTION_EVENT_COMPLETE = 'COMPLETE';
export var TRANSACTION_EVENT_COUNTER_OFFER = 'COUNTER_OFFER';
export var TRANSACTION_EVENT_DELETE_SHARED_FILE = 'DELETE_SHARED_FILE';
export var TRANSACTION_EVENT_DOCUMENT_GENERATED = 'DOCUMENT_GENERATED';
export var TRANSACTION_EVENT_ESCROW_DISBURSEMENT = 'ESCROW_DISBURSEMENT';
export var TRANSACTION_EVENT_ESCROW_NOTIFIED_TO_DISBURSE = 'ESCROW_NOTIFIED_TO_DISBURSE';
export var TRANSACTION_EVENT_ESCROW_ORDERED = 'ESCROW_ORDERED';
export var TRANSACTION_EVENT_EXECUTED_DOCUMENT = 'EXECUTED_DOCUMENT';
export var TRANSACTION_EVENT_EXPORTED_TRANSACTION = 'EXPORTED_TRANSACTION';
export var TRANSACTION_EVENT_IMPORTED_TRANSACTION = 'IMPORTED_TRANSACTION';
export var TRANSACTION_EVENT_MESSAGE = 'MESSAGE';
export var TRANSACTION_EVENT_MODIFY_CLOSING_SCHEDULE = 'MODIFY_CLOSING_SCHEDULE';
export var TRANSACTION_EVENT_MODIFY_CUTOFF_DATE = 'MODIFY_CUTOFF_DATE';
export var TRANSACTION_EVENT_NOTE_ADDED = 'NOTE_ADDED';
export var TRANSACTION_EVENT_NOTE_BLOCKED = 'NOTE_BLOCKED';
export var TRANSACTION_EVENT_NOTE_REMOVED = 'NOTE_REMOVED';
export var TRANSACTION_EVENT_NOTE_UNBLOCKED = 'NOTE_UNBLOCKED';
export var TRANSACTION_EVENT_PAID_FEES = 'PAID_FEES';
export var TRANSACTION_EVENT_PAID_INVOICE = 'PAID_INVOICE';
export var TRANSACTION_EVENT_PHONE_CALL = 'PHONE_CALL';
export var TRANSACTION_EVENT_PROVIDED_BANK_ACCOUNT = 'PROVIDED_BANK_ACCOUNT';
export var TRANSACTION_EVENT_PROVIDED_SHIPPING_ADDRESS = 'PROVIDED_SHIPPING_ADDRESS';
export var TRANSACTION_EVENT_REMOVED_CUSTOM_DOCUMENT = 'REMOVED_CUSTOM_DOCUMENT';
export var TRANSACTION_EVENT_ROLLBACK_TO_NEGOTIATIONS = 'ROLLBACK_TO_NEGOTIATIONS';
export var TRANSACTION_EVENT_SELLER_PROVIDED_SHIPPING_INFO = 'SELLER_PROVIDED_SHIPPING_INFO';
export var TRANSACTION_EVENT_SELLER_SHIPPED_COLLATERAL = 'SELLER_SHIPPED_COLLATERAL';
export var TRANSACTION_EVENT_SERVICERS_NOTIFIED_ABOUT_TRANSFER = 'SERVICERS_NOTIFIED_ABOUT_TRANSFER';
export var TRANSACTION_EVENT_SERVICING_INFORMATION_PROVIDED = 'SERVICING_INFORMATION_PROVIDED';
export var TRANSACTION_EVENT_SHARE_FILE = 'SHARE_FILE';
export var TRANSACTION_EVENT_SHIPMENT_DELIVERED = 'SHIPMENT_DELIVERED';
export var TRANSACTION_EVENT_SHIPPING_LABEL_GENERATED = 'SHIPPING_LABEL_GENERATED';
export var TRANSACTION_EVENT_UPDATED_MORTGAGE_RECORDING = 'UPDATED_MORTGAGE_RECORDING';
export var TRANSACTION_EVENT_UPLOADED_CUSTOM_DOCUMENT = 'UPLOADED_CUSTOM_DOCUMENT';
export var TRANSACTION_EVENT_VESTING_INFORMATION_PROVIDED = 'VESTING_INFORMATION_PROVIDED';
export var TRANSACTION_EVENT_VIA_WEBSITE = 'website';
export var TRANSACTION_EVENT_VIA_EMAIL = 'email';
export var TRANSACTION_EVENT_VOICEMAIL = 'VOICEMAIL';
export var TRANSACTION_FEE_TYPE_BUYER_ADDED = 'BUYER_ADDED';
export var TRANSACTION_FEE_TYPE_COMMUNITY = 'COMMUNITY';
export var TRANSACTION_FEE_TYPE_ESCROW = 'ESCROW';
export var TRANSACTION_FEE_TYPE_PAPERSTAC = 'PAPERSTAC';
export var TRANSACTION_FEE_TYPE_SELLER_ADDED = 'SELLER_ADDED';
export var TRANSACTION_STATUS_ABANDONED = 'Abandoned';
export var TRANSACTION_STATUS_NEGOTIATIONS = 'Negotiations';
export var TRANSACTION_STATUS_CLOSING = 'Closing';
export var TRANSACTION_STATUS_COMPLETE = 'Complete';
export var TODO_ID_SELLER_ACCEPTED_PRICE = 'SELLER_ACCEPTED_PRICE';
export var TODO_ID_BUYER_ACCEPTED_PRICE = 'BUYER_ACCEPTED_PRICE';
export var TODO_ID_SELLER_APPROVED_CLOSING = 'SELLER_APPROVED_CLOSING';
export var TODO_ID_BUYER_APPROVED_CLOSING = 'BUYER_APPROVED_CLOSING';
export var TODO_ID_SELLER_PAID_FEES = 'SELLER_PAID_FEES';
export var TODO_ID_BUYER_PAID_FEES = 'BUYER_PAID_FEES';
export var TODO_ID_SELLER_PROVIDED_VESTING_INFORMATION = 'SELLER_PROVIDED_VESTING_INFORMATION';
export var TODO_ID_BUYER_PROVIDED_VESTING_INFORMATION = 'BUYER_PROVIDED_VESTING_INFORMATION';
export var TODO_ID_SELLER_VERIFIED_UPB = 'SELLER_VERIFIED_UPB';
export var TODO_ID_SELLER_PROVIDED_SERVICING_INFORMATION = 'SELLER_PROVIDED_SERVICING_INFORMATION';
export var TODO_ID_BUYER_PROVIDED_SERVICING_INFORMATION = 'BUYER_PROVIDED_SERVICING_INFORMATION';
export var TODO_ID_SELLER_PROVIDED_BANK_ACCOUNT = 'SELLER_PROVIDED_BANK_ACCOUNT';
export var TODO_ID_BUYER_PROVIDED_SHIPPING_ADDRESS = 'BUYER_PROVIDED_SHIPPING_ADDRESS';
export var TODO_ID_SELLER_EXECUTED_SALE_AGREEMENT = 'SELLER_EXECUTED_SALE_AGREEMENT';
export var TODO_ID_BUYER_EXECUTED_SALE_AGREEMENT = 'BUYER_EXECUTED_SALE_AGREEMENT';
export var TODO_ID_SELLER_APPROVED_SALE_AGREEMENT = 'SELLER_APPROVED_SALE_AGREEMENT';
export var TODO_ID_BUYER_APPROVED_SALE_AGREEMENT = 'BUYER_APPROVED_SALE_AGREEMENT';
export var TODO_ID_SELLER_APPROVED_CLOSING_DATA = 'SELLER_APPROVED_CLOSING_DATA';
export var TODO_ID_BUYER_APPROVED_CLOSING_DATA = 'BUYER_APPROVED_CLOSING_DATA';
export var TODO_ID_PARTNER_APPROVED_FUNDING_TRANSACTION = 'PARTNER_APPROVED_FUNDING_TRANSACTION';
export var TODO_ID_BUYER_PAID_ESCROW = 'BUYER_PAID_ESCROW';
export var TODO_ID_BUYER_PAID_SELLER = 'BUYER_PAID_SELLER';
export var TODO_ID_SELLER_EXECUTED_RECEIPT = 'SELLER_EXECUTED_RECEIPT';
export var TODO_ID_SELLER_PROVIDED_SHIPPING_INFORMATION = 'SELLER_PROVIDED_SHIPPING_INFORMATION';
export var TODO_ID_SELLER_SHIPPED_COLLATERAL = 'SELLER_SHIPPED_COLLATERAL';
export var TODO_ID_AUDITOR_PROVIDED_REPORT = 'AUDITOR_PROVIDED_REPORT';
export var TODO_ID_BUYER_APPROVED_COLLATERAL = 'BUYER_APPROVED_COLLATERAL';
export var TODO_ID_BUYER_EXECUTED_DISBURSEMENT_AGREEMENT = 'BUYER_EXECUTED_DISBURSEMENT_AGREEMENT';
export var TODO_ID_SELLER_EXECUTED_ASSIGNMENTS = 'SELLER_EXECUTED_ASSIGNMENTS';
export var TODO_ID_BUYER_APPROVED_ASSIGNMENTS = 'BUYER_APPROVED_ASSIGNMENTS';
export var TODO_ID_SELLER_EXECUTED_QUIT_CLAIM_DEEDS = 'SELLER_EXECUTED_QUIT_CLAIM_DEEDS';
export var TODO_ID_BUYER_APPROVED_QUIT_CLAIM_DEEDS = 'BUYER_APPROVED_QUIT_CLAIM_DEEDS';
export var TODO_ID_SELLER_EXECUTED_ALLONGES = 'SELLER_EXECUTED_ALLONGES';
export var TODO_ID_BUYER_APPROVED_ALLONGES = 'BUYER_APPROVED_ALLONGES';
export var TODO_ID_SELLER_EXECUTED_DISBURSEMENT_AGREEMENT = 'SELLER_EXECUTED_DISBURSEMENT_AGREEMENT';
export var TODO_ID_ESCROW_PAID_SELLER = 'ESCROW_PAID_SELLER';
export var TODO_ID_AUDITOR_SHIPPED_COLLATERAL = 'AUDITOR_SHIPPED_COLLATERAL';
export var TODO_ID_COLLATERAL_DELIVERED_TO_BUYER = 'COLLATERAL_DELIVERED_TO_BUYER';
export var TODO_ID_COLLATERAL_TRANSFERRED_TO_BUYER = 'COLLATERAL_TRANSFERRED_TO_BUYER';
export var VISIBILITY_PUBLIC = 'PUBLIC';
export var VISIBILITY_PRIVATE = 'PRIVATE';