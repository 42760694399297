import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { SortCollectionContext } from '@paperstac/common/lib/components/SortCollectionProvider';
import { EXPORT_LISTINGS_TO_CSV } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import Box from '@paperstac/ui/lib/Box';
import Link from '@paperstac/ui/lib/Link';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Spinner from '@paperstac/ui/lib/Spinner';
import React from 'react';
import { IdentityManagerContext } from '../../common/IdentityManager';

const ExportToCsv = React.memo((props) => {
  const submittingRef = React.useRef(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [csvUrl, setCsvUrl] = React.useState(null);
  const { uid } = React.useContext(IdentityContext);
  const { showLogin } = React.useContext(IdentityManagerContext);
  const { collection } = React.useContext(SortCollectionContext);
  const collectionList = collection.map(({ id }) => id).join(',');
  const [listingIds, setListingIds] = React.useState(collection || []);

  const handleSubmit = React.useCallback(() => {
    if (submittingRef.current) return;
    submittingRef.current = true;
    setIsSubmitting(true);
    serverDispatch({
      action: EXPORT_LISTINGS_TO_CSV,
      payload: { listingIds },
    })
      .then((response) => {
        if (!submittingRef.current) return;
        submittingRef.current = false;
        setIsSubmitting(false);
        setCsvUrl(response.data);
      })
      .catch((error) => {
        if (!submittingRef.current) return;
        alert(error.message);
        submittingRef.current = false;
        setIsSubmitting(false);
      });
  }, [listingIds]);

  React.useEffect(() => {
    submittingRef.current = false;
    setIsSubmitting(false);
    setCsvUrl(null);
    setListingIds(!collectionList ? [] : collectionList.split(','));
  }, [collectionList]);

  if (!listingIds.length) return null;

  return (
    <Box display="inline" {...props}>
      {!uid && <LinkButton onClick={showLogin}>Login to export</LinkButton>}
      {!!uid && (
        <>
          {!isSubmitting && !csvUrl && <LinkButton onClick={handleSubmit}>Export to CSV</LinkButton>}
          {!!isSubmitting && !csvUrl && (
            <Box display="inline">
              <Spinner size="tiny" /> Building CSV
            </Box>
          )}
          {!isSubmitting && !!csvUrl && (
            <Link href={csvUrl} target="_blank" rel="noreferrer noopener">
              Download CSV
            </Link>
          )}
        </>
      )}
    </Box>
  );
});

ExportToCsv.propTypes = {};

ExportToCsv.defaultProps = {};

ExportToCsv.displayName = 'ExportToCsv';

export default ExportToCsv;
