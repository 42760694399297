function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { getPerformance } from '@paperstac/firebase/lib/performance';
import React from 'react';
var DEFAULT_STATE = [null, null, null]; // data, lastUpdate, errorMessage

var useFirestoreSubscribe = function useFirestoreSubscribe(reference) {
  var inputs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var firebasePerformance = arguments.length > 2 ? arguments[2] : undefined;
  var logger = arguments.length > 3 ? arguments[3] : undefined;
  var _React$useState = React.useState(DEFAULT_STATE),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    state = _React$useState2[0],
    setState = _React$useState2[1];
  var logPerformanceRef = React.useRef(!!firebasePerformance);
  var referencePath = reference && reference.path || 'Query';
  var performanceTrace;
  if (logPerformanceRef.current) {
    try {
      performanceTrace = getPerformance().trace('useFirestoreSubscribe');
      performanceTrace.putAttribute('referencePath', referencePath.substr(0, 100));
      performanceTrace.start();
    } catch (e) {
      console.error(e);
    }
  }
  React.useEffect(function () {
    logger && logger("React.useEffect() firing ".concat(referencePath, "..."));
    return reference ? reference.onSnapshot(function (snapshot) {
      var data = snapshot.docs ? snapshot.docs.map(function (docSnap) {
        return docSnap.data();
      }) : snapshot.data();
      setState([data, new Date().toISOString(), null]);
      logger && logger("React.useEffect() successfully set data for ".concat(referencePath, "."));
      if (logPerformanceRef.current) {
        try {
          performanceTrace.stop();
          logPerformanceRef.current = false;
        } catch (e) {
          console.error(e);
        }
      }
    }, function (error) {
      logger && logger("React.useEffect() handled error: ".concat(error.message));
      console.log("[useFirestoreSubscribe] React.useEffect() handled error: ".concat(error.message));
      setState([null, new Date().toISOString(), error.message]);
      if (logPerformanceRef.current) {
        try {
          performanceTrace.stop();
          logPerformanceRef.current = false;
        } catch (e) {
          console.error(e);
        }
      }
    }) : setState(DEFAULT_STATE);
  }, [referencePath].concat(_toConsumableArray(inputs)));
  return state;
};
export default useFirestoreSubscribe;