import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_STARRED_LISTINGS } from '../actions/starredListingActions';

const DEFAULT_STATE = {
  starredListings: [],
};

export default function blacklistsReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_STARRED_LISTINGS:
      return {
        ...state,
        starredListings: payload,
      };
    default:
      return state;
  }
}

export const getStarredListings = (state) => {
  return state.starredListings;
};

export const getActiveStarredListings = (state) => {
  return getStarredListings(state).filter((sl) => sl.listing.isActive);
};

export const getInactiveStarredListings = (state) => {
  return getStarredListings(state).filter((sl) => !sl.listing.isActive);
};
