import Box from '@paperstac/ui/lib/Box';
import Card from '@paperstac/ui/lib/Card';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import styled from 'styled-components';
import { top } from 'styled-system';
import { ReactComponent as CurrentTriangleSvg } from '../../../images/homepage-tab-indicator.svg';
import { ESCROW, ESCROW_TITLE, TIMELINE, TIMELINE_TITLE, TODOS, TODOS_TITLE } from './ClosingsSection';

const ClosingsSectionTabs = React.memo(({ currentTab, setCurrentTab }) => {
  return (
    <Container>
      <Tabs top={[-38, -38, -54, -65]} mt={[38, 38, 54, 65]} ml="auto" mr="auto">
        <Tab
          isCurrent={currentTab === TIMELINE}
          title={TIMELINE_TITLE}
          text="An audit trail of everything that has happened in your deal"
          style={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
          onClick={() => setCurrentTab(TIMELINE)}
        />
        <Tab
          isCurrent={currentTab === TODOS}
          title={TODOS_TITLE}
          text="A simple To-Do list that guides you each step of the way"
          style={{ borderLeft: 'none' }}
          onClick={() => setCurrentTab(TODOS)}
        />
        <Tab
          isCurrent={currentTab === ESCROW}
          title={ESCROW_TITLE}
          text="Our process reduces risk while adding transparency for all"
          style={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px', borderLeft: 'none' }}
          onClick={() => setCurrentTab(ESCROW)}
        />
      </Tabs>
    </Container>
  );
});

const StyledTabs = styled(Flex)`
  position: relative;
  max-width: 898px;
  ${top}
`;

const Tabs = (props) => <StyledTabs {...props} />;

const StyledTab = styled(Card)`
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: ${(props) => (props.isCurrent ? null : props.theme.colors.highlight)};
  }
`;

const CurrentTabIndicator = () => (
  <Box
    as={CurrentTriangleSvg}
    width={32}
    height={15}
    style={{
      position: 'absolute',
      bottom: '-15px',
      left: 'calc(50% - 16px)',
    }}
  />
);

const Tab = ({ isCurrent, title, text, ...props }) => (
  <StyledTab
    py={[2, 2, 3, 4]}
    px={[2, 2, 3, 4]}
    border="default"
    borderColor={isCurrent ? 'secondary' : null}
    flex={1}
    bg={isCurrent ? 'secondary' : 'white'}
    isCurrent={isCurrent}
    {...props}
  >
    <Heading
      fontSize={[16, 16, 20, 20]}
      mb={[1, 1, 2, 2]}
      color={isCurrent ? 'white' : 'gray.9'}
      textAlign="center"
      children={title}
    />
    <Text fontSize={[12, 12, 14, 16]} color={isCurrent ? 'white' : 'gray.8'} textAlign="center" children={text} />
    {isCurrent && <CurrentTabIndicator />}
  </StyledTab>
);

ClosingsSectionTabs.propTypes = {};

ClosingsSectionTabs.defaultProps = {};

ClosingsSectionTabs.displayName = 'ClosingsSectionTabs';

export default ClosingsSectionTabs;
