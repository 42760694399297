import { TRANSACTION_STATUS_CLOSING, TRANSACTION_STATUS_NEGOTIATIONS } from '@paperstac/constants';
import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const REMOVE_TRANSACTION_NOTE = 'REMOVE_TRANSACTION_NOTE';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const SET_TRANSACTION_OTHERS = 'SET_TRANSACTION_OTHERS';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const SET_TRANSACTIONS_IN_CLOSING = 'SET_TRANSACTIONS_IN_CLOSING';
export const SET_TRANSACTION_PAGE_ACTION = 'SET_TRANSACTION_PAGE_ACTION';
export const SUBSCRIBE_TRANSACTION = 'SUBSCRIBE_TRANSACTION';
export const SUBSCRIBE_TRANSACTIONS = 'SUBSCRIBE_TRANSACTIONS';
export const UNSUBSCRIBE_TRANSACTION = 'UNSUBSCRIBE_TRANSACTION';
export const UNSUBSCRIBE_TRANSACTIONS = 'UNSUBSCRIBE_TRANSACTIONS';

const unsubscribes = {};

const transformQuerySnapshot = (querySnapshot) => {
  return querySnapshot.docs.map((docSnap) => transformFirestoreTimestamps(docSnap));
};

export const setTransaction = (payload) => {
  return { type: SET_TRANSACTION, payload };
};

export const setTransactionOthers = (id, items) => {
  return { type: SET_TRANSACTION_OTHERS, payload: { id, items } };
};

export const setTransactions = (items, role) => {
  return { type: SET_TRANSACTIONS, payload: { items, role } };
};

export const setTransactionsInClosing = (payload) => {
  return { type: SET_TRANSACTIONS_IN_CLOSING, payload };
};

export const setTransactionPageAction = (actionName, actionProps) => {
  return { type: SET_TRANSACTION_PAGE_ACTION, payload: { actionName, actionProps } };
};

export const subscribeTransactions = (accountId) => {
  return (dispatch) => {
    if (!unsubscribes['seller']) {
      dispatch({ type: SUBSCRIBE_TRANSACTIONS });
      unsubscribes['seller'] = firestore
        .collection('transactions')
        .where('sellerId', '==', accountId)
        .onSnapshot((querySnapshot) => {
          dispatch(setTransactions(transformQuerySnapshot(querySnapshot), 'seller'));
        });
    }
    if (!unsubscribes['buyer']) {
      dispatch({ type: SUBSCRIBE_TRANSACTIONS });
      unsubscribes['buyer'] = firestore
        .collection('transactions')
        .where('buyerId', '==', accountId)
        .onSnapshot((querySnapshot) => {
          dispatch(setTransactions(transformQuerySnapshot(querySnapshot), 'buyer'));
        });
    }
  };
};

export const unsubscribeTransactions = () => {
  return (dispatch) => {
    if (unsubscribes['seller']) {
      unsubscribes['seller']();
      delete unsubscribes['seller'];
      dispatch({ type: UNSUBSCRIBE_TRANSACTIONS, role: 'seller' });
    }
    if (unsubscribes['buyer']) {
      unsubscribes['buyer']();
      delete unsubscribes['buyer'];
      dispatch({ type: UNSUBSCRIBE_TRANSACTIONS, role: 'buyer' });
    }
  };
};

export const subscribeTransactionsInClosing = () => {
  return (dispatch) => {
    return firestore
      .collection('transactions')
      .where('status', '==', TRANSACTION_STATUS_CLOSING)
      .onSnapshot((querySnapshot) => {
        dispatch(setTransactionsInClosing(transformQuerySnapshot(querySnapshot)));
      });
  };
};

export const updateTransaction = (id, payload) => {
  return (dispatch) => {
    return firestore.doc(`transactions/${id}`).update(payload);
  };
};

export const subscribeTransaction = (transactionId) => {
  return (dispatch) => {
    if (!unsubscribes[transactionId]) {
      dispatch({ type: SUBSCRIBE_TRANSACTION, transactionId });
      unsubscribes[transactionId] = firestore.doc(`transactions/${transactionId}`).onSnapshot((docSnapshot) => {
        const data = transformFirestoreTimestamps(docSnapshot);
        if (data) {
          dispatch(subscribeToOtherTransactions(transactionId, data.buyerId, data.sellerId));
        }
        dispatch(setTransaction({ id: transactionId, data }));
      });
    }
  };
};

const subscribeToOtherTransactions = (transactionId, buyerId, sellerId) => {
  return (dispatch) => {
    if (!unsubscribes[`${transactionId}:others`]) {
      unsubscribes[`${transactionId}:others`] = firestore
        .collection('transactions')
        .where('buyerId', '==', buyerId)
        .where('sellerId', '==', sellerId)
        .onSnapshot((querySnapshot) => {
          dispatch(
            setTransactionOthers(
              transactionId,
              querySnapshot.docs
                .map(transformFirestoreTimestamps)
                .filter(({ id }) => id !== transactionId)
                .filter(({ status }) => status === TRANSACTION_STATUS_NEGOTIATIONS)
            )
          );
        });
    }
  };
};

export const unsubscribeTransaction = (transactionId) => {
  return (dispatch) => {
    if (unsubscribes[transactionId]) {
      unsubscribes[transactionId]();
      delete unsubscribes[transactionId];
      dispatch({ type: UNSUBSCRIBE_TRANSACTION });
    }
    if (unsubscribes[`${transactionId}:others`]) {
      unsubscribes[`${transactionId}:others`]();
      delete unsubscribes[`${transactionId}:others`];
    }
  };
};

export const subscribeBuyerTransactionForListing = (buyerId, listingId) => {
  return (dispatch) => {
    if (!unsubscribes[`${buyerId}${listingId}`]) {
      unsubscribes[`${buyerId}${listingId}`] = firestore
        .collection('transactions')
        .where('buyerId', '==', buyerId)
        .where('listingId', '==', listingId)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            const transaction = querySnapshot.docs[0].data();
            dispatch(setTransaction({ id: transaction.id, data: transaction }));
          }
        });
    }
  };
};

export const unsubscribeBuyerTransactionForListing = (buyerId, listingId) => {
  return () => {
    if (unsubscribes[`${buyerId}${listingId}`]) {
      unsubscribes[`${buyerId}${listingId}`]();
      delete unsubscribes[`${buyerId}${listingId}`];
    }
  };
};

export const removeTransactionNote = (transactionId, noteId) => {
  return {
    type: REMOVE_TRANSACTION_NOTE,
    payload: { transactionId, noteId },
  };
};

export const fetchAvailableNotesToAddRequest = (currentAccountId, sellerId, transactionNoteIds) => {
  return (dispatch) => {
    return currentAccountId === sellerId
      ? firestore
          .collection(`accounts/${sellerId}/notes`)
          .get()
          .then((querySnapshot) => {
            return querySnapshot.docs
              .map((docRef) => docRef.data())
              .filter((note) => note.isListable !== false)
              .reduce((notes, noteDoc) => {
                return Object.values(
                  [...notes, noteDoc].reduce((notesObj, note) => {
                    notesObj[note.id] = note;
                    return notesObj;
                  }, {})
                );
              }, [])
              .filter((note) => !transactionNoteIds.includes(note.id));
          })
      : firestore
          .collection('listings')
          .where('accountId', '==', sellerId)
          .where('isActive', '==', true)
          .get()
          .then((querySnapshot) => {
            return querySnapshot.docs
              .map((docRef) => docRef.data())
              .reduce((notes, transaction) => {
                return Object.values(
                  [...notes, ...transaction.notes].reduce((notesObj, note) => {
                    notesObj[note.id] = note;
                    return notesObj;
                  }, {})
                );
              }, [])
              .filter((note) => !transactionNoteIds.includes(note.id));
          });
  };
};

export const createTransactionSharedAction = (payload) => {
  return (dispatch) => {
    return firestore.doc(`transactions/${payload.transactionId}/sharedActions/${payload.id}`).set(payload);
  };
};
