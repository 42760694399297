import { AUDIT_ORDERS_ARCHIVE, ESCROW_ORDERS_ARCHIVE } from '@paperstac/routes/lib/webRoutes';
import React from 'react';
import { withRouter } from 'react-router-dom';
import DefaultLayout from '../../layouts/DefaultLayout';
import ClosingsSection from './ClosingsSection';
import FeaturesSection from './FeaturesSection';
import HeroSection from './HeroSection';
import TestimonialsSection from './TestimonialsSection';
import TrendingSection from './TrendingSection';

const HomePage = React.memo(({ history }) => {
  React.useEffect(() => {
    if (window.location.host.includes('wirepledge') && window.location.pathname === '/') {
      history.push(ESCROW_ORDERS_ARCHIVE);
    }
    if (window.location.host.includes('audits') && window.location.pathname === '/') {
      history.push(AUDIT_ORDERS_ARCHIVE);
    }
  }, [history]);

  return (
    <DefaultLayout title="Sell or Buy Mortgage Notes on a Mortgage Note Trading Platform">
      <HeroSection />
      <FeaturesSection />
      <ClosingsSection />
      <TrendingSection />
      <TestimonialsSection />
    </DefaultLayout>
  );
});

HomePage.propTypes = {};

HomePage.defaultProps = {};

HomePage.displayName = 'HomePage';

export default withRouter(HomePage);
