export const SHOW_ACCOUNT_SWITCHER = 'SHOW_ACCOUNT_SWITCHER';
export const HIDE_ACCOUNT_SWITCHER = 'HIDE_ACCOUNT_SWITCHER';

export const showAccountSwitcher = () => {
  return { type: SHOW_ACCOUNT_SWITCHER };
};

export const hideAccountSwitcher = () => {
  return { type: HIDE_ACCOUNT_SWITCHER };
};
