import AlgoliaHasRangeRefinement from '@paperstac/ui/lib/AlgoliaHasRangeRefinement';
import AlgoliaRangeInput from '@paperstac/ui/lib/AlgoliaRangeInput';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const InterestRateFilter = React.memo((props) => (
  <AlgoliaHasRangeRefinement attribute="noteMetaAgg.interestRatePercent">
    <CollapsibleFilterContainer title="Interest Rate">
      <AlgoliaRangeInput attribute="noteMetaAgg.interestRatePercent" suffix="%" />
    </CollapsibleFilterContainer>
  </AlgoliaHasRangeRefinement>
));

InterestRateFilter.propTypes = {};

InterestRateFilter.defaultProps = {};

InterestRateFilter.displayName = 'InterestRateFilter';

export default InterestRateFilter;
