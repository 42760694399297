import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import Avatar from './Avatar';
import './BlogPostAuthor.css';

const BlogPostAuthor = ({ name, avatar, publishDate }) => (
  <div className="BlogPostAuthor">
    <div className="BlogPostAuthor__media">
      <Avatar user={{ displayName: name, avatar }} size={50} />
    </div>
    <div className="BlogPostAuthor__body">
      <div className="BlogPostAuthor__author">Author: {name}</div>
      <div className="BlogPostAuthor__date">Published on {format(parseISO(publishDate), 'MMMM d, yyyy')}</div>
    </div>
  </div>
);

export default BlogPostAuthor;
