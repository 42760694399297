import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import useFirestoreSubscribe from '@paperstac/common/lib/hooks/useFirestoreSubscribe';
import {
  CREATE_TRANSACTION,
  FETCH_LISTING,
  REFRESH_ZILLOW_DATA,
} from '@paperstac/common/lib/serverDispatchActionTypes';
import dateid from '@paperstac/common/lib/services/dateid';
import getPublicProfile from '@paperstac/helpers/lib/getPublicProfile';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import FirestoreTransactionManager from '@paperstac/common/lib/services/FirestoreTransactionManager';
import { listingByIdRef } from '@paperstac/firestore-collections/lib/listings';
import { listingViewByIdRef } from '@paperstac/firestore-collections/lib/listingViews';
import {
  starredListingByIdRef,
  starredListingsByAccountIdListingIdQuery,
} from '@paperstac/firestore-collections/lib/starredListings';
import { transactionsByBuyerIdListingIdQuery } from '@paperstac/firestore-collections/lib/transactions';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import useDimensions from '@paperstac/ui/lib/hooks/useDimensions';

const ListingPageContext = React.createContext();
const { Provider } = ListingPageContext;

const ListingPageProvider = React.memo(({ children, listingId, ...props }) => {
  const [wrapperRef, wrapperRect] = useDimensions();
  const [mainWidth, setMainWidth] = React.useState(null);
  const [asideWidth, setAsideWidth] = React.useState(null);
  const { identity } = React.useContext(IdentityContext);
  const { currentAccountId } = React.useContext(AccountsContext);
  const [listing, setListing] = React.useState(null);
  const [fetchError, setFetchError] = React.useState(null);
  const [toggleStarredBusy, setToggleStarredBusy] = React.useState(null);
  const [starredListings] = useFirestoreSubscribe(
    starredListingsByAccountIdListingIdQuery(currentAccountId, listingId),
    [currentAccountId, listingId]
  );
  const starredListingId = React.useMemo(
    () => (Array.isArray(starredListings) && starredListings[0] ? starredListings[0].id : null),
    [starredListings]
  );
  const [transactions] = useFirestoreSubscribe(transactionsByBuyerIdListingIdQuery(currentAccountId, listingId), [
    currentAccountId,
    listingId,
  ]);
  const transactionId = React.useMemo(
    () => (Array.isArray(transactions) && transactions[0] ? transactions[0].id : null),
    [transactions]
  );
  const [isMessageVisible, setIsMessageVisible] = React.useState(false);
  const [isOfferVisible, setIsOfferVisible] = React.useState(false);
  const [isGalleryVisible, setIsGalleryVisible] = React.useState(false);
  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const isSeller = React.useMemo(
    () => !!currentAccountId && currentAccountId === get(listing, 'accountId'),
    [currentAccountId, listing]
  );

  const listingPhotos = React.useMemo(
    () =>
      !listing
        ? []
        : listing.notes.reduce(
            (listingPhotos, note) => [
              ...listingPhotos,
              ...note.photos
                .filter(({ fileUrl }) => !!fileUrl)
                .sort((a, b) => (a.order <= b.order ? -1 : 1))
                .map(({ fileUrl }) => ({
                  src: fileUrl,
                  alt: `Photo of ${note.fullAddress}`,
                })),
            ],
            []
          ),
    [listing]
  );

  const toggleStarred = React.useCallback(() => {
    if (toggleStarredBusy) return;
    setToggleStarredBusy(true);
    if (starredListingId) {
      FirestoreTransactionManager.withManager((manager) => manager.delete(starredListingByIdRef(starredListingId)));
    } else {
      const payload = {
        id: dateid(),
        listing,
        listingId,
        accountId: currentAccountId,
      };
      FirestoreTransactionManager.withManager((manager) => manager.set(starredListingByIdRef(payload.id), payload));
    }
  }, [currentAccountId, listing, listingId, starredListingId, toggleStarredBusy]);

  const createTransaction = React.useCallback(
    (eventPayload) =>
      serverDispatch({
        action: CREATE_TRANSACTION,
        payload: {
          accountId: currentAccountId,
          listingId: listing ? listing.id : null,
          event: eventPayload,
        },
      }),
    [currentAccountId, listing]
  );

  React.useEffect(() => setToggleStarredBusy(false), [starredListingId]);

  React.useEffect(() => {
    if (!listingId || !identity) return;
    listingViewByIdRef(listingId, identity.id)
      .set(getPublicProfile(identity))
      .catch((error) => console.error(error.message));
  }, [listingId, identity]);

  React.useEffect(() => {
    if (isSeller) return listingByIdRef(listingId).onSnapshot((docSnap) => setListing(docSnap.data()));

    serverDispatch({ action: FETCH_LISTING, payload: { listingId } })
      .then(({ data }) => setListing(data))
      .catch((error) => setFetchError(error.message));
  }, [isSeller, listingId]);

  React.useEffect(() => {
    if (!listing || !listing.notes) return;
    const paths = listing.notes.map(({ id }) => `accounts/${listing.accountId}/notes/${id}`);
    serverDispatch({ action: REFRESH_ZILLOW_DATA, payload: { paths } }).catch((error) => console.error(error.message));
  }, [listing]);

  const providerValue = React.useMemo(
    () => ({
      asideWidth,
      createTransaction,
      fetchError,
      hideEdit: () => setIsEditVisible(false),
      hideGallery: () => setIsGalleryVisible(false),
      hideMessage: () => setIsMessageVisible(false),
      hideOffer: () => setIsOfferVisible(false),
      isEditVisible,
      isGalleryVisible,
      isMessageVisible,
      isOfferVisible,
      isSeller,
      isStarred: !!starredListingId,
      listing,
      listingPhotos,
      mainWidth,
      setAsideWidth,
      setMainWidth,
      showEdit: () => setIsEditVisible(true),
      showGallery: () => setIsGalleryVisible(true),
      showMessage: () => setIsMessageVisible(true),
      showOffer: () => setIsOfferVisible(true),
      toggleStarred,
      toggleStarredBusy,
      transactionId,
      width: wrapperRect.width,
    }),
    [
      asideWidth,
      createTransaction,
      fetchError,
      isEditVisible,
      isGalleryVisible,
      isMessageVisible,
      isOfferVisible,
      isSeller,
      listing,
      listingPhotos,
      mainWidth,
      starredListingId,
      toggleStarred,
      toggleStarredBusy,
      transactionId,
      wrapperRect.width,
    ]
  );

  return (
    <Provider value={providerValue} {...props}>
      <div ref={wrapperRef}>{children}</div>
    </Provider>
  );
});

ListingPageProvider.propTypes = {};

ListingPageProvider.defaultProps = {
  listingId: PropTypes.string.isRequired,
};

ListingPageProvider.displayName = 'ListingPageProvider';

export default ListingPageProvider;

export { ListingPageContext };
