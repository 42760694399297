export var getNameFromCode = function getNameFromCode(code) {
  return PROVINCES[code.toUpperCase()];
};
export var getCanadianProvinces = function getCanadianProvinces(code) {
  return Object.keys(PROVINCES).map(function (code) {
    return {
      code: code,
      name: PROVINCES[code]
    };
  });
};
var PROVINCES = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  YT: 'Yukon'
};