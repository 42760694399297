import { useCallback, useLayoutEffect, useState } from 'react';
function getDimensionObject(node) {
  const rect = node.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
    top: 'x' in rect ? rect.x : rect.top,
    left: 'y' in rect ? rect.y : rect.left,
    x: 'x' in rect ? rect.x : rect.left,
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom
  };
}
function useDimensions() {
  let {
    liveMeasure = true
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const [dimensions, setDimensions] = useState({});
  const [node, setNode] = useState(null);
  const ref = useCallback(node => {
    setNode(node);
  }, []);
  useLayoutEffect(() => {
    if (node) {
      const measure = () => window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
      measure();
      if (liveMeasure) {
        window.addEventListener('resize', measure);
        window.addEventListener('scroll', measure);
        return () => {
          window.removeEventListener('resize', measure);
          window.removeEventListener('scroll', measure);
        };
      }
    }
  }, [liveMeasure, node]);
  return [ref, dimensions, node];
}
export default useDimensions;