import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import React from 'react';
import Image from '@paperstac/ui/lib/Image';
import ListGroup from '@paperstac/ui/lib/ListGroup';
import ListGroupItem from '@paperstac/ui/lib/ListGroupItem';
import Button from '@paperstac/ui/lib/Button';
import { firestore } from '../../../services/firebaseClient';
import Pill from '@paperstac/ui/lib/Pill';
import Link from '@paperstac/ui/lib/Link';
import { ExternalLinkIcon } from '@paperstac/ui/lib/icons';
import { trpc } from '../../../services/trpc';
import toast from '@paperstac/ui/lib/Toast';

const NoteUnlimitedReports = React.memo(({ integration, noteId, salePrice, ...props }) => {
  const [reports, setReports] = React.useState(undefined);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { mutateAsync: generateAsync } = trpc.noteUnlimited.generateReportsForAccountNote.useMutation();
  const { mutateAsync: checkAsync } = trpc.noteUnlimited.checkTaskStatus.useMutation();

  const generateReports = React.useCallback(() => {
    if (isSubmitting) return;
    setSubmitting(true);
    generateAsync({
      integrationId: integration.id,
      noteId: noteId,
      salePrice,
    })
      .catch((e) => toast.error(e.message))
      .finally(() => {
        setSubmitting(false);
      });
  }, [integration.id, isSubmitting, generateAsync, noteId, salePrice]);

  React.useEffect(() => {
    firestore
      .collection('noteUnlimitedReports')
      .where('integrationId', '==', integration.id)
      .where('linkedResourceId', '==', noteId)
      .onSnapshot((querySnap) => {
        setReports(querySnap.docs.length ? querySnap.docs.map((docSnap) => docSnap.data())[0] : undefined);
      });
  }, [integration.id, noteId, setReports]);

  React.useEffect(() => {
    if (!reports || reports.status === 'COMPLETE') return;

    const interval = setInterval(() => {
      checkAsync({ noteUnlimitedReportsId: reports.id }).catch((e) => console.error(e.message));
    }, 1000 * 10);
    return () => clearInterval(interval);
  }, [checkAsync, reports]);

  return (
    <Box {...props}>
      <ListGroup sx={{ border: 'default', borderRadius: 'corner', bg: 'white', maxWidth: 408 }}>
        <ListGroupItem>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box mr={3}>
              <Image
                src="https://res.cloudinary.com/paperstac/image/upload/v1698255867/website-assets/noteunlimited.png"
                size={56}
                borderRadius="corner"
                sx={{ display: 'block' }}
              />
            </Box>
            <Box sx={{ flex: '1 1 0' }}>
              <Heading as="h3" fontSize={3} mb={1}>
                NoteUnlimited
              </Heading>
              <Box sx={{ fontSize: 1, color: 'gray.6' }}>{integration.title}</Box>
            </Box>
          </Box>
        </ListGroupItem>
        {!reports && (
          <ListGroupItem>
            <Button onClick={generateReports} variant="primary" size="small" busy={isSubmitting}>
              Generate Reports
            </Button>
          </ListGroupItem>
        )}
        {!!reports &&
          reports.reports.map((report, index) => (
            <ListGroupItem key={index}>
              <Link href={report.url} target="_blank">
                {report.title} <ExternalLinkIcon sx={{ mt: '-3px' }} />
              </Link>
              {reports.status === 'PENDING' && <Pill ml={2}>Pending</Pill>}
            </ListGroupItem>
          ))}
      </ListGroup>
    </Box>
  );
});

NoteUnlimitedReports.defaultProps = {};

NoteUnlimitedReports.displayName = 'NoteUnlimitedReports';

export default NoteUnlimitedReports;
