import { END_SESSION } from '../actions/authActions';
import { SET_BLACKLISTED_BY, SET_MY_BLACKLIST } from '../actions/blacklistActions';
import { SET_COMMUNITY } from '../actions/communityActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';

const DEFAULT_STATE = {
  myBlacklist: [],
  blacklistedBy: [],
};

export default function blacklistsReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_COMMUNITY:
      return {
        ...state,
        entities: { ...state.entities, ...payload },
      };
    case SET_MY_BLACKLIST:
      return {
        ...state,
        myBlacklist: payload,
      };
    case SET_BLACKLISTED_BY:
      return {
        ...state,
        blacklistedBy: payload,
      };
    default:
      return state;
  }
}

export const getMyBlacklist = (state) => {
  return state.myBlacklist;
};

export const getAllBlacklistAccountIds = (state) => {
  return [...state.myBlacklist.map((b) => b.blacklisteeId), ...state.blacklistedBy.map((b) => b.blacklisterId)];
};
