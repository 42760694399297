import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import Box from '@paperstac/ui/lib/Box';
import Carousel from '@paperstac/ui/lib/Carousel';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import ChevronLeftIcon from '@paperstac/ui/lib/icons/ChevronLeftIcon';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import Image from '@paperstac/ui/lib/Image';
import Text from '@paperstac/ui/lib/Text';
import UnstyledButton from '@paperstac/ui/lib/UnstyledButton';
import shuffle from 'lodash/shuffle';
import React from 'react';
import styled from 'styled-components';
import { left, maxWidth, position, right, top } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import testimonials from '../../../data/testimonials';

const TESTIMONIALS = shuffle(testimonials);

const TestimonialsSection = React.memo((props) => {
  const reactSlickRef = React.useRef(null);
  const handlePrevious = React.useCallback(() => {
    reactSlickRef.current && reactSlickRef.current.slickPrev();
  }, []);
  const handleNext = React.useCallback(() => {
    reactSlickRef.current && reactSlickRef.current.slickNext();
  }, []);
  const { isMobile, isTablet, isWideDesktop } = useBreakpoints();
  const iconSize = isMobile || isTablet ? 30 : isWideDesktop ? 90 : 60;

  return (
    <Box py={[40, 40, 40, 64, 96]} bg="white">
      <Container>
        <Wrapper alignItems="center" maxWidth={isWideDesktop ? 1272 : 848} ml="auto" mr="auto">
          {!isMobile && (
            <Box>
              <NavControl
                ariaLabel="Previous comment"
                onClick={handlePrevious}
                position="absolute"
                left={0}
                top={[
                  'calc(50% - 15px)',
                  'calc(50% - 15px)',
                  'calc(50% - 30px)',
                  'calc(50% - 30px)',
                  'calc(50% - 45px)',
                ]}
              >
                <ChevronLeftIcon size={iconSize} />
              </NavControl>
            </Box>
          )}
          <Box mx={[0, 60, 90, 90, 120]}>
            <Carousel ref={reactSlickRef} arrows={false} adaptiveHeight={true} slidesToShow={1}>
              {TESTIMONIALS.map((testimonial, i) => (
                <Testimonial key={i} {...TESTIMONIALS[i]} onPrevious={handlePrevious} onNext={handleNext} />
              ))}
            </Carousel>
          </Box>
          {!isMobile && (
            <Box>
              <NavControl
                ariaLabel="Next comment"
                onClick={handleNext}
                position="absolute"
                right={0}
                top={[
                  'calc(50% - 15px)',
                  'calc(50% - 15px)',
                  'calc(50% - 30px)',
                  'calc(50% - 30px)',
                  'calc(50% - 45px)',
                ]}
              >
                <ChevronRightIcon size={iconSize} />
              </NavControl>
            </Box>
          )}
        </Wrapper>
      </Container>
    </Box>
  );
});

const Wrapper = styled(Box)`
  ${maxWidth}
  position: relative;
`;

const NavControl = styled(UnstyledButton)`
  ${position}
  ${top}
  ${left}
  ${right}
  color: ${themeGet('colors.gray.3')};
  cursor: pointer;
  &:hover {
    color: ${themeGet('colors.secondary')};
  }
`;

const Testimonial = ({ name, title, avatar, comment, onPrevious, onNext }) => {
  const { isMobile, isWideDesktop } = useBreakpoints();

  return (
    <Box>
      <Text fontFamily="serif" textAlign="center" mb={[24, 24, 24, 28, 36]} fontSize={[16, 18, 20, 24, 30]}>
        &ldquo;{comment}&rdquo;
      </Text>
      <Flex alignItems="center" justifyContent="center" mb={[2, 2, 2, 2, 12]}>
        {isMobile && (
          <NavControl ariaLabel="Previous comment" mr={3} onClick={onPrevious}>
            <ChevronLeftIcon size={30} />
          </NavControl>
        )}
        <Image
          src={avatar}
          width={isWideDesktop ? 150 : 100}
          height={isWideDesktop ? 150 : 100}
          borderRadius="50%"
          alt={`Photo of ${name}`}
        />
        {isMobile && (
          <NavControl ariaLabel="Next comment" ml={3} onClick={onNext}>
            <ChevronRightIcon size={30} />
          </NavControl>
        )}
      </Flex>
      <Heading fontSize={[16, 16, 16, 16, 24]} mb={[1, 1, 1, 1, '6px']} textAlign="center">
        {name}
      </Heading>
      <Text fontSize={[14, 14, 14, 14, 21]} color="primary" textAlign="center">
        {title}
      </Text>
    </Box>
  );
};

TestimonialsSection.propTypes = {};

TestimonialsSection.defaultProps = {};

TestimonialsSection.displayName = 'TestimonialsSection';

export default TestimonialsSection;
