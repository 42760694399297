import React from 'react';
import { REM } from '../../theme';

const XIcon = ({ size, fill, style, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    width={REM(size)}
    height={REM(size)}
    style={{ width: REM(size), height: REM(size), ...style }}
    fill={fill}
    {...props}
  >
    <path d="M8.4 7l5.3-5.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0L7 5.6 1.7.3C1.3-.1.7-.1.3.3s-.4 1 0 1.4L5.6 7 .3 12.3c-.4.4-.4 1 0 1.4.2.2.4.3.7.3s.5-.1.7-.3L7 8.4l5.3 5.3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L8.4 7z" />
  </svg>
);

XIcon.defaultProps = { size: 16, fill: 'currentColor', style: {} };

export default XIcon;
