import { BASE_URL } from '@paperstac/env';
import getPath from '@paperstac/routes/lib/getPath';
import { BLOG_POSTS_SINGLE } from '@paperstac/routes/lib/webRoutes';
import React from 'react';
import FacebookComments from '../../common/FacebookComments';
import BlogPostLayout from '../../layouts/BlogPostLayout';
import DealOverview from './DealOverview';
import HeroSection from './HeroSection';
import IntroSection from './IntroSection';
import Itb from './Itb';
import Itv from './Itv';
import Price from './Price';
import Section from './Section';

const slug = '2020-market-report';
export const metadata = {
  postTitle: "Paperstac's 2020 Market Report",
  metaTitle: "Paperstac's 2020 Market Report",
  metaDescription: 'See what mortgage notes sold for on the Paperstac marketplace during 2020 in our market report.',
  slug,
  url: `${BASE_URL}${getPath(BLOG_POSTS_SINGLE, slug)}`,
  publishDate: '2021-03-03 08:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1614697536/website-assets/2020-market-report-featured.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1614697536/website-assets/2020-market-report-social.png',
  tags: ['report'],
};

const MarketReport2020 = React.memo(() => {
  return (
    <BlogPostLayout metadata={metadata}>
      <HeroSection postTitle={metadata.postTitle} />
      <IntroSection />
      <Itb />
      <Itv />
      <Price />
      <DealOverview />
      <Section title="Comments" subtitle="Let us know what you think...">
        <FacebookComments url={metadata.url} />
      </Section>
    </BlogPostLayout>
  );
});

MarketReport2020.propTypes = {};

MarketReport2020.defaultProps = {};

MarketReport2020.displayName = 'MarketReport2020';

export default MarketReport2020;
