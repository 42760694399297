import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import {
  ADD_NOTE_PHOTOS,
  DELETE_NOTE_PHOTO,
  REORDER_NOTE_PHOTOS,
} from '@paperstac/common/lib/serverDispatchActionTypes';
import { idIncrementor } from '@paperstac/common/lib/services/dateid';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import storage from '@paperstac/firebase/lib/storage';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import DescriptionGridTerm from '@paperstac/ui/lib/DescriptionGridTerm';
import Flex from '@paperstac/ui/lib/Flex';
import FileInputButton from '@paperstac/ui/lib/form/FileInputButton';
import ExternalLinkIcon from '@paperstac/ui/lib/icons/ExternalLinkIcon';
import InfoCircleIcon from '@paperstac/ui/lib/icons/InfoCircleIcon';
import XIcon from '@paperstac/ui/lib/icons/XIcon';
import Image from '@paperstac/ui/lib/Image';
import Link from '@paperstac/ui/lib/Link';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import arrayMove from 'array-move';
import React from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';

const dateId = idIncrementor();

const NoteScreenPhotos = React.memo(() => {
  const { uid } = React.useContext(IdentityContext);
  const { currentAccountId } = React.useContext(AccountsContext);
  const { note } = React.useContext(ManageListingOverlayContext);
  const [photos, setPhotos] = React.useState(note.photos);
  const [isUploading, setUploading] = React.useState(false);
  const [deletedIds, setDeletedIds] = React.useState([]);

  const handleAdd = React.useCallback(
    async (fileList) => {
      if (isUploading) return;
      setUploading(true);
      const files = [];
      for (let i = 0; i < fileList.length; i++) files.push(fileList[i]);
      const photos = await Promise.all(
        files.map((file) => {
          const id = dateId();
          const filePath = `user-uploads/${uid}/${currentAccountId}/notes/${note.id}/photos/${id}-${file.name}`;
          return storage
            .child(filePath)
            .put(file, { cacheControl: 'public,max-age=31536000' })
            .then(() => ({ id, filePath }));
        })
      );
      serverDispatch({
        action: ADD_NOTE_PHOTOS,
        payload: { accountId: note.accountId, noteId: note.id, photos },
      })
        .catch((e) => alert(e.message))
        .finally(() => setUploading(false));
    },
    [currentAccountId, isUploading, note.accountId, note.id, uid]
  );

  const handleSort = React.useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) return;
      const sortedPhotos = arrayMove(photos, oldIndex, newIndex).filter(({ id }) => !deletedIds.includes(id));
      setPhotos(sortedPhotos);
      serverDispatch({
        action: REORDER_NOTE_PHOTOS,
        payload: {
          accountId: note.accountId,
          noteId: note.id,
          order: sortedPhotos.map(({ id }) => id),
        },
      }).catch((e) => alert(e.message));
    },
    [deletedIds, note.accountId, note.id, photos]
  );

  const handleDelete = React.useCallback(
    (photoId) => {
      setDeletedIds([...deletedIds, photoId]);
      setPhotos(note.photos.filter(({ id }) => ![...deletedIds, photoId].includes(id)));
      serverDispatch({
        action: DELETE_NOTE_PHOTO,
        payload: { accountId: note.accountId, noteId: note.id, photoId },
      }).catch((e) => alert(e.message));
    },
    [deletedIds, note.accountId, note.id, note.photos]
  );

  React.useEffect(() => {
    setPhotos(note.photos.filter(({ id }) => !deletedIds.includes(id)));
  }, [deletedIds, note.photos]);

  return (
    <DescriptionGridCell>
      <DescriptionGridTerm mb={3}>
        Property Photos (
        {isUploading ? (
          <Box sx={{ textTransform: 'none', display: 'inline', color: 'primary' }}>Uploading...</Box>
        ) : (
          <FileInputButton
            controlComponent={LinkButton}
            onFiles={handleAdd}
            multiple={true}
            accept="image/png,image/gif,image/jpeg"
            sx={{ textTransform: 'none' }}
          >
            <Text variant="bold">+</Text> Add Photos
          </FileInputButton>
        )}
        )
      </DescriptionGridTerm>
      {!photos.length && (
        <Box fontSize={1}>
          <InfoCircleIcon sx={{ color: 'blue', position: 'relative', top: '-2px' }} /> <Text variant="bold">TIP</Text>:
          Notes with property photos sell quicker and for more money!
        </Box>
      )}
      <SortableList photos={photos} onDelete={handleDelete} axis="xy" onSortEnd={handleSort} useDragHandle={true} />
    </DescriptionGridCell>
  );
});

const DragHandle = SortableHandle(({ fileUrl }) => (
  <Image
    src={fileUrl}
    width={90}
    height={60}
    sx={{
      display: 'block',
      bg: 'gray.9',
      cursor: 'move',
    }}
  />
));

const SortableItem = SortableElement(({ photo, onDelete }) => (
  <Box
    sx={{
      mr: 2,
      mt: 2,
      display: 'inline-block',
      zIndex: 9999,
    }}
  >
    <DragHandle fileUrl={photo.fileUrl} />
    <Flex>
      <Button
        as={Link}
        href={photo.fileUrl}
        target="_blank"
        flex="1 1 0"
        variant="default"
        size="small"
        sx={{ borderRadius: 0 }}
      >
        <ExternalLinkIcon size={11} />
      </Button>
      <Button flex="1 1 0" variant="red" size="small" sx={{ borderRadius: 0 }} onClick={() => onDelete(photo.id)}>
        <XIcon size={9} />
      </Button>
    </Flex>
  </Box>
));

const SortableList = SortableContainer(({ photos, onDelete }) => (
  <div>
    {photos.map((photo, index) => (
      <SortableItem key={photo.id} index={index} photo={photo} onDelete={onDelete} />
    ))}
  </div>
));

NoteScreenPhotos.propTypes = {};

NoteScreenPhotos.defaultProps = {};

NoteScreenPhotos.displayName = 'NoteScreenPhotos';

export default NoteScreenPhotos;
