import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import NoteScreenUnderlyingLienItem from './NoteScreenUnderlyingLienItem';

const NoteScreenUnderlyingLienList = React.memo(() => {
  const { note } = React.useContext(ManageListingOverlayContext);
  const { wraps } = note;

  if (!wraps || !Object.keys(wraps).length) return null;

  return (
    <>
      {Object.values(wraps).map((wrap, i) => (
        <NoteScreenUnderlyingLienItem
          key={wrap.id}
          wrap={wrap}
          sx={{
            py: 2,
            borderTop: i === 0 ? 'none' : 'default',
          }}
        />
      ))}
    </>
  );
});

NoteScreenUnderlyingLienList.propTypes = {};

NoteScreenUnderlyingLienList.defaultProps = {};

NoteScreenUnderlyingLienList.displayName = 'NoteScreenUnderlyingLienList';

export default NoteScreenUnderlyingLienList;
