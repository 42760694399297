import Container from '@paperstac/ui/lib/Container';
import PropTypes from 'prop-types';
import React from 'react';

const FacebookComments = React.memo(({ url, ...props }) => (
  <Container {...props}>
    <div className="fb-comments" data-href={url} data-width="100%" data-numposts="10" />
  </Container>
));

FacebookComments.propTypes = {
  url: PropTypes.string.isRequired,
};

FacebookComments.defaultProps = {
  maxWidth: 800,
};

FacebookComments.displayName = 'FacebookComments';

export default FacebookComments;
