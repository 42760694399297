import React from 'react';
import { trpc } from '../../../services/trpc';

export default function useCreateIdentityVerificationLinkToken({ domain, email, identityId, templateId }) {
  const { mutateAsync } = trpc.plaid.createIdentityVerificationLinkToken.useMutation();

  return React.useCallback(
    (forceReset) => {
      return mutateAsync({
        domain,
        email,
        forceReset: !!forceReset,
        identityId,
        templateId,
      });
    },
    [domain, email, identityId, mutateAsync, templateId]
  );
}
