import firestore from '@paperstac/firebase/lib/firestore';
export var accountNotesRef = function accountNotesRef(accountId) {
  return accountId ? firestore.collection("accounts/".concat(accountId, "/notes")) : null;
};
export var accountNoteByIdRef = function accountNoteByIdRef(accountId, accountNoteId) {
  return accountId && accountNoteId ? accountNotesRef(accountId).doc(accountNoteId) : null;
};
export var accountNotesByIsListable = function accountNotesByIsListable(accountId, isListable) {
  return accountId ? accountNotesRef(accountId).where('isListable', '==', isListable) : null;
};
export var accountNotesByFingerprintQuery = function accountNotesByFingerprintQuery(accountId, fingerprint) {
  return accountId ? accountNotesRef(accountId).where('fingerprint', '==', fingerprint) : null;
};
export var NOTE_TYPE = {
  MORTGAGE: 'Mortgage',
  DEED_OF_TRUST: 'Deed of Trust',
  CFD: 'Contract For Deed (CFD)'
};