import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { SortCollectionContext } from '@paperstac/common/lib/components/SortCollectionProvider';
import { centsToUsdString } from '@paperstac/helpers/lib/numberHelpers';
import { LISTING_NEGOTIATION_TYPE } from '@paperstac/firestore-collections/lib/listings';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Cell from '@paperstac/ui/lib/spreadsheet/Cell';
import CurrencyCell from '@paperstac/ui/lib/spreadsheet/CurrencyCell';
import DateCell from '@paperstac/ui/lib/spreadsheet/DateCell';
import EmailCell from '@paperstac/ui/lib/spreadsheet/EmailCell';
import Spreadsheet from '@paperstac/ui/lib/spreadsheet/Spreadsheet';
import SpreadsheetManager from '@paperstac/ui/lib/spreadsheet/SpreadsheetManager';
import TextCell from '@paperstac/ui/lib/spreadsheet/TextCell';
import StarButton from '@paperstac/ui/lib/StarButton';
import Text from '@paperstac/ui/lib/Text';
import get from 'lodash/get';
import React from 'react';
import { ListingDetailsContext } from './ListingDetailsProvider';
import { ListingsSearchContext } from './ListingsSearchProvider';

const SerpSpreadsheet = React.memo(() => {
  const { uid } = React.useContext(IdentityContext);
  const { collection, lastUpdated } = React.useContext(SortCollectionContext);
  const { starListing, unstarListing } = React.useContext(ListingsSearchContext);
  const { showListing } = React.useContext(ListingDetailsContext);
  const handleStarClick = React.useCallback(
    (listing, starredListingId) => {
      !starredListingId ? starListing(listing) : unstarListing({ id: starredListingId });
    },
    [starListing, unstarListing]
  );

  const cellRenderer = ({ style, value, row: { negotiationType }, column: { prop } }) => {
    const map = {
      date: () => <DateCell style={style} date={value} />,
      email: () => <EmailCell style={style} email={value} />,
      id: () => (
        <Cell style={style}>
          <LinkButton onClick={() => showListing(value)} fontFamily="monospace">
            {value}
          </LinkButton>
        </Cell>
      ),
      listPrice: () => (
        <Cell style={style} flexDirection="column" justifyContent="center" alignItems="flex-start">
          <ListPrice negotiationType={negotiationType} listPrice={value} />
        </Cell>
      ),
      propertyValue: () => <CurrencyCell style={style} cents={value} showCents={false} />,
      starredListingId: () => (
        <Cell style={style} justifyContent="center">
          <StarButton active={!!value.active} busy={value.busy} onClick={value.handleClick} size={24} />
        </Cell>
      ),
      upb: () => <CurrencyCell style={style} cents={value} showCents={false} />,
    };
    return map[prop] ? map[prop]() : <TextCell style={style}>{value}</TextCell>;
  };

  return (
    <SpreadsheetManager
      headerCells={[
        { prop: 'starredListingId', label: '', width: 50 },
        { prop: 'id', label: 'Listing ID', width: 220 },
        { prop: 'listPrice', label: 'List Price', width: 130 },
        { prop: 'listingType', label: 'Listing Type', width: 120 },
        { prop: 'lienPosition', label: 'Lien Position', width: 80 },
        { prop: 'performance', label: 'Performance', width: 140 },
        { prop: 'noteTypes', label: 'Note Type', width: 140 },
        { prop: 'upb', label: 'Principal Balance', width: 100 },
        { prop: 'yieldPercent', label: 'Yield', width: 70 },
        { prop: 'interestRate', label: 'Interest Rate', width: 80 },
        { prop: 'propertyValue', label: 'Property Value', width: 100 },
        { prop: 'itb', label: 'ITB', width: 70 },
        { prop: 'itv', label: 'ITV', width: 70 },
        { prop: 'ltv', label: 'LTV', width: 70 },
        { prop: 'paymentsRemaining', label: 'Payments Remaining', width: 100 },
        { prop: 'legalStatus', label: 'Legal Status', width: 120 },
        { prop: 'state', label: 'State', width: 140 },
        { prop: 'hardestHitFund', label: 'Hardest Hit Fund', width: 85 },
        { prop: 'judicialState', label: 'Judicial State', width: 80 },
        { prop: 'nonJudicialState', label: 'Non-Judicial State', width: 115 },
        { prop: 'seller', label: 'Seller', width: 140 },
        { prop: 'sellerComments', label: 'Seller Comments', width: 400 },
      ]}
      rowsLastUpdated={lastUpdated}
      rows={collection.map((listing) => ({
        hardestHitFund: get(listing, 'hardestHitFund'),
        id: get(listing, 'id'),
        interestRate: `${get(listing, 'interestRate')}%`,
        itb: get(listing, 'itb') ? `${get(listing, 'itb')}%` : 'N/A',
        itv: get(listing, 'itv') ? `${get(listing, 'itv')}%` : 'N/A',
        judicialState: get(listing, 'judicialState'),
        legalStatus: get(listing, 'legalStatus'),
        listPrice: get(listing, 'listPrice'),
        lienPosition: get(listing, 'lienPosition'),
        listingType: get(listing, 'listingType'),
        ltv: get(listing, 'ltv') ? `${get(listing, 'ltv')}%` : 'N/A',
        negotiationType: get(listing, 'negotiationType'),
        nonJudicialState: get(listing, 'nonJudicialState'),
        noteTypes: get(listing, 'noteTypes').join(', '),
        paymentsRemaining: get(listing, 'paymentsRemaining'),
        performance: get(listing, 'performance'),
        propertyValue: get(listing, 'propertyValue'),
        seller: !!uid ? get(listing, 'seller') : 'Login to view...',
        sellerComments: get(listing, 'sellerComments'),
        starredListingId: {
          active: !!get(listing, 'starredListingId'),
          handleClick: () => handleStarClick(listing, get(listing, 'starredListingId')),
        },
        state: get(listing, 'state'),
        upb: get(listing, 'upb'),
        yieldPercent: get(listing, 'yieldPercent') ? `${get(listing, 'yieldPercent')}%` : 'N/A',
      }))}
    >
      <Spreadsheet cellRenderer={cellRenderer} />
    </SpreadsheetManager>
  );
});

const ListPrice = ({ listPrice, negotiationType }) => {
  if (negotiationType === LISTING_NEGOTIATION_TYPE.FIRM) {
    return (
      <>
        <Text fontSize={2} fontWeight="bold" color="primary">
          {listPrice ? centsToUsdString(listPrice, false) : null}
        </Text>
        <Text fontSize={0}>Firm</Text>
      </>
    );
  }
  if (negotiationType === LISTING_NEGOTIATION_TYPE.MAKE_OFFER) {
    return (
      <Text fontSize={2} fontWeight="bold" color="primary">
        Make an Offer
      </Text>
    );
  }
  return (
    <>
      <Text fontSize={2} fontWeight="bold" color="primary">
        {listPrice ? centsToUsdString(listPrice, false) : null}
      </Text>
      <Text fontSize={0}>or Best Offer</Text>
    </>
  );
};

SerpSpreadsheet.propTypes = {};

SerpSpreadsheet.defaultProps = {};

SerpSpreadsheet.displayName = 'SerpSpreadsheet';

export default SerpSpreadsheet;
