import * as React from 'react';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import Sentry from '../services/sentry';

const ConfigureSentry = () => {
  const { identity } = React.useContext(IdentityContext);
  React.useEffect(() => {
    !!identity
      ? Sentry.setUser({ id: identity.id, email: identity.email })
      : Sentry.configureScope((scope) => scope.setUser(null));
  }, [identity]);

  return null;
};

ConfigureSentry.displayName = 'ConfigureSentry';

export default React.memo(ConfigureSentry);
