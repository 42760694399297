import classNames from 'classnames';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import IdentityManager from './common/IdentityManager';
import XIcon from './icons/XIcon';
import './Overlay.css';

const ESCAPE_KEY = 27;

class Overlay extends Component {
  constructor(props) {
    super(props);
    this.state = { inDom: false };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.overlayContainer = document.createElement('div');
    document.body.appendChild(this.overlayContainer);
    document.addEventListener('keydown', this.handleKeyDown, false);
    this.setState({ inDom: true });
  }

  componentWillUnmount() {
    document.body.removeChild(this.overlayContainer);
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown(event) {
    if (event.keyCode === ESCAPE_KEY) {
      event.preventDefault();
      this.handleClose();
    }
  }

  handleClose() {
    this.props.onClose && this.props.onClose();
  }

  render() {
    const Overlay = (
      <IdentityManager>
        <div className={classNames('Overlay', this.props.className)}>
          {this.props.onClose && (
            <button className="Overlay__close" onClick={this.handleClose}>
              <XIcon size={24} />
              <span>ESC</span>
            </button>
          )}
          <div className="Overlay__backdrop">
            <div className="Overlay__content">{this.props.children}</div>
          </div>
        </div>
      </IdentityManager>
    );
    return this.state.inDom ? ReactDOM.createPortal(Overlay, this.overlayContainer) : <div />;
  }
}

export default Overlay;
