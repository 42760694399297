import AlgoliaRadioRefinement from '@paperstac/ui/lib/AlgoliaRadioRefinement';
import React from 'react';
import FilterContainer from './FilterContainer';

const LienPositionFilter = React.memo((props) => (
  <FilterContainer title="Lien Position">
    <AlgoliaRadioRefinement
      attribute="noteMetaAgg.lienPosition"
      itemsOverride={[
        { label: '1st', value: '1' },
        { label: '2nd', value: '2' },
      ]}
    />
  </FilterContainer>
));

LienPositionFilter.propTypes = {};

LienPositionFilter.defaultProps = {};

LienPositionFilter.displayName = 'LienPositionFilter';

export default LienPositionFilter;
