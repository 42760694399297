import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { getPersistenceType, PERSISTENCE_TYPES } from '@paperstac/common/lib/services/statePersistence';
import auth from '@paperstac/firebase/lib/auth';
import { getFirebase } from '@paperstac/firebase/lib/firebase';
import { loginValidator } from '@paperstac/firestore-collections/lib/identities';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Flex from '@paperstac/ui/lib/Flex';
import Checkbox from '@paperstac/ui/lib/form/Checkbox';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import Label from '@paperstac/ui/lib/form/Label';
import UiLinkButton from '@paperstac/ui/lib/LinkButton';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { AUTH_OVERLAY_VIEWS, AuthOverlayContext } from './AuthOverlayProvider';
import ErrorMessage from './ErrorMessage';
import LinkButton from './LinkButton';
import ViewTitle from './ViewTitle';

const FIREBASE_AUTH_PERSISTENCE = getFirebase().auth.Auth.Persistence;

const LoginView = React.memo((props) => {
  const { setView } = React.useContext(AuthOverlayContext);
  const { setPersistenceType } = React.useContext(IdentityContext);

  return (
    <Formik
      initialValues={{ email: '', password: '', stayLoggedIn: false }}
      validationSchema={loginValidator}
      onSubmit={async (payload, { setSubmitting, setStatus }) => {
        try {
          const previousPersistenceType = getPersistenceType();
          setPersistenceType(payload.stayLoggedIn ? PERSISTENCE_TYPES.LOCAL : PERSISTENCE_TYPES.SESSION);
          await auth.setPersistence(
            payload.stayLoggedIn ? FIREBASE_AUTH_PERSISTENCE.LOCAL : FIREBASE_AUTH_PERSISTENCE.SESSION
          );
          await auth.signInWithEmailAndPassword(payload.email, payload.password);
          if (previousPersistenceType !== getPersistenceType()) window.location.reload();
        } catch (error) {
          const errorMessages = {
            'auth/user-not-found': 'Email does not exist in our system.',
            'auth/wrong-password': 'Password is incorrect.',
          };
          setStatus({ errorMessage: errorMessages[error.code] || 'We experienced a system error.' });
          setSubmitting(false);
        }
      }}
      render={({ isSubmitting, status }) => (
        <Form>
          <ViewTitle>Get Started</ViewTitle>
          <InputFormGroup name="email" label="Email Address:" type="email" required />
          <InputFormGroup name="password" label="Password:" type="password" required />
          <Flex alignItems="center" justifyContent="space-between" mb={3}>
            <Box>
              <Flex alignItems="center">
                <Field id="stayLoggedIn" name="stayLoggedIn" component={Checkbox} />
                <Label htmlFor="stayLoggedIn" display="inline" fontWeight="normal" fontSize={1}>
                  Stay logged in
                </Label>
              </Flex>
            </Box>
            <Box>
              <UiLinkButton
                onClick={() => setView(AUTH_OVERLAY_VIEWS.FORGOT_PASSWORD)}
                sx={{
                  fontWeight: 'bold',
                  color: 'primary',
                  fontSize: 1,
                  ':hover,:focus': { color: 'gray.9' },
                }}
              >
                Forgot password?
              </UiLinkButton>
            </Box>
          </Flex>
          {status && status.errorMessage && <ErrorMessage>{status.errorMessage}</ErrorMessage>}
          <Button type="submit" variant="primary" block={true} busy={isSubmitting}>
            Sign In
          </Button>
          <Box mt={40} textAlign="center">
            Don't have an account?{' '}
            <LinkButton onClick={() => setView(AUTH_OVERLAY_VIEWS.CREATE_IDENTITY)}>Register</LinkButton>.
          </Box>
        </Form>
      )}
    />
  );
});

LoginView.propTypes = {};

LoginView.defaultProps = {};

LoginView.displayName = 'LoginView';

export default LoginView;
