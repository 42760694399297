import { END_SESSION } from '../actions/authActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';
import { SET_LISTING, SET_LISTINGS, UNSUBSCRIBE_LISTINGS } from '../actions/listingActions';

const DEFAULT_STATE = {
  items: [],
  lastUpdated: null,
};

// Number of items to keep after unsubscribing
const CACHE_SIZE = 12;

export default function listingsReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case UNSUBSCRIBE_LISTINGS:
      return {
        ...DEFAULT_STATE,
        items: state.items.slice(0, CACHE_SIZE),
        lastUpdated: new Date().toISOString(),
      };
    case SET_LISTING:
      if (!payload) {
        // Was deleted
        return state;
      }
      return {
        ...state,
        items: getListing(state, payload.id)
          ? state.items.map((listing) => {
              return listing.id === payload.id ? payload : listing;
            })
          : [...state.items, payload],
        lastUpdated: new Date().toISOString(),
      };
    case SET_LISTINGS:
      return {
        ...state,
        items: payload,
        lastUpdated: new Date().toISOString(),
      };
    default:
      return state;
  }
}

export const getListing = (state, listingId) => {
  return getListings(state).filter((listing) => listing.id === listingId)[0];
};

export const getListings = (state) => {
  return state.items;
};

export const listingsLastUpdated = (state) => {
  return state.lastUpdated;
};
