import React from 'react';
import report from './report';
import Section from './Section';
import StatGrid from './StatGrid';

const Itv = React.memo((props) => {
  return (
    <Section title="Investment to Value" subtitle="(sale price / property value)" bg="gray.1">
      <StatGrid stat1={report.itv[0]} stat2={report.itv[1]} stat3={report.itv[2]} stat4={report.itv[3]} />
    </Section>
  );
});

Itv.propTypes = {};

Itv.defaultProps = {};

Itv.displayName = 'AverageITV';

export default Itv;
