import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import PencilIcon from '@paperstac/ui/lib/icons/PencilIcon';
import ImageCarouselOverlay from '@paperstac/ui/lib/ImageCarouselOverlay';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import React from 'react';
import useDimensions from '@paperstac/ui/lib/hooks/useDimensions';
import ManageListingOverlay from '../../common/ManageListingOverlay/ManageListingOverlay';
import { transformListing } from '../MyListingsPage/MyListingsPageProvider';
import Aside from './Aside';
import FetchError from './FetchError';
import { ListingPageContext } from './ListingPageProvider';
import Loading from './Loading';
import MakeOfferOverlay from './MakeOfferOverlay';
import MessageSellerOverlay from './MessageSellerOverlay';
import NoteDetailsSection from './NoteDetailsSection';
import Section from './Section';
import SellerCommentsSection from './SellerCommentsSection';
import TitleSection from './TitleSection';

const Content = React.memo(() => {
  const { currentAccountId } = React.useContext(AccountsContext);
  const {
    asideWidth,
    fetchError,
    hideEdit,
    hideGallery,
    isEditVisible,
    isGalleryVisible,
    isMessageVisible,
    isOfferVisible,
    isSeller,
    listing,
    listingPhotos,
    mainWidth,
    setAsideWidth,
    setMainWidth,
    showEdit,
    width,
  } = React.useContext(ListingPageContext);
  const [mainRef, mainRect] = useDimensions();
  const [asideRef, asideRect] = useDimensions();
  const stacked = React.useMemo(() => width < 1064, [width]);
  const [transformedListing, setTransformedListing] = React.useState(null);

  React.useLayoutEffect(() => {
    if (mainRect.width !== mainWidth) setMainWidth(Math.round(mainRect.width));
  }, [mainRect.width, mainWidth, setMainWidth]);

  React.useLayoutEffect(() => {
    if (asideRect.width !== asideWidth) setAsideWidth(Math.round(asideRect.width));
  }, [asideRect.width, asideWidth, setAsideWidth]);

  React.useEffect(() => {
    if (isSeller) {
      transformListing(listing, []).then((transformedListing) => setTransformedListing(transformedListing));
    }
  }, [currentAccountId, isSeller, listing]);

  return (
    <>
      <Section>
        {!listing && !fetchError && <Loading />}
        {!!fetchError && <FetchError message={fetchError} />}
        {!!listing && (
          <>
            {isSeller && (
              <Box
                sx={{ border: 'default', bg: 'highlight', borderRadius: 'corner', mb: 3, p: 3, textAlign: 'center' }}
              >
                <LinkButton onClick={showEdit} mr={5}>
                  <PencilIcon
                    size={12}
                    sx={{
                      position: 'relative',
                      top: '-1px',
                    }}
                  />{' '}
                  Edit Listing
                </LinkButton>
              </Box>
            )}
            <TitleSection />
            <Flex sx={{ flexDirection: stacked ? 'column' : 'row' }}>
              <Aside
                ref={asideRef}
                sx={{
                  flex: stacked ? null : '0 0 360px',
                  order: stacked ? 1 : 2,
                  ml: stacked ? 0 : 6,
                }}
              />
              <Box ref={mainRef} sx={{ flex: stacked ? null : '1 1 0', order: stacked ? 2 : 1 }}>
                <SellerCommentsSection />
                <NoteDetailsSection />
              </Box>
            </Flex>
          </>
        )}
      </Section>
      {isGalleryVisible && <ImageCarouselOverlay images={listingPhotos} onClose={hideGallery} />}
      {isMessageVisible && <MessageSellerOverlay />}
      {isOfferVisible && <MakeOfferOverlay />}
      {isEditVisible && !!transformedListing && (
        <ManageListingOverlay listing={transformedListing} onClose={hideEdit} />
      )}
    </>
  );
});

Content.propTypes = {};

Content.defaultProps = {};

Content.displayName = 'Content';

export default Content;
