import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_TRANSACTION_EVENTS = 'SET_TRANSACTION_EVENTS';

const unsubscribes = {};

export const setTransactionEvents = (payload) => {
  return { type: SET_TRANSACTION_EVENTS, payload };
};

export const subscribeTransactionEvents = (transactionId) => {
  return (dispatch) => {
    if (!unsubscribes[transactionId]) {
      unsubscribes[transactionId] = firestore
        .collection(`transactions/${transactionId}/events`)
        .orderBy('id', 'desc')
        .onSnapshot((querySnapshot) => {
          dispatch(
            setTransactionEvents({
              transactionId,
              events: querySnapshot.docs.map(transformFirestoreTimestamps),
            })
          );
        });
    }
  };
};

export const unsubscribeTransactionEvents = (transactionId) => {
  return (dispatch) => {
    if (unsubscribes[transactionId]) {
      unsubscribes[transactionId]();
      delete unsubscribes[transactionId];
    }
  };
};
