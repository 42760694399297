function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { ALGOLIA_RANGE_INPUT_MAX, ALGOLIA_RANGE_INPUT_MIN } from '@paperstac/ui/lib/AlgoliaRangeInput';
import debounce from 'lodash/debounce';
import qs from 'qs';
import React from 'react';
export default (function (location, history) {
  var _React$useState = React.useState({}),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    searchState = _React$useState2[0],
    setSearchState = _React$useState2[1];
  React.useEffect(function () {
    var completeSearchState = queryStringToSearchState(location.search);
    if (searchState.page) completeSearchState.page = searchState.page;
    if (searchState.configure) completeSearchState.configure = searchState.configure;
    setSearchState(completeSearchState);
  }, [location.search, searchState.page, searchState.configure]);
  var onSearchStateChange = React.useCallback(function (newSearchState) {
    setSearchState(newSearchState);
    var filteredSearchState = filterSearchStateForUrl(newSearchState);
    var url = newSearchState ? "".concat(location.pathname).concat(searchStateToQueryString(filteredSearchState)) : '';
    updateUrl(history.replace, url, filteredSearchState);
  }, [history.replace, location.pathname]);
  var searchStateToQueryString = React.useCallback(function (searchState) {
    return qs.stringify(searchState, {
      addQueryPrefix: true
    });
  }, []);
  return {
    searchState: searchState,
    onSearchStateChange: onSearchStateChange,
    createURL: searchStateToQueryString
  };
});
var updateUrl = debounce(function (replace, url, state) {
  replace(url, state);
}, 400, {
  leading: true,
  trailing: true
});
var queryStringToSearchState = function queryStringToSearchState(queryString) {
  return qs.parse(queryString, {
    ignoreQueryPrefix: true
  });
};
var filterSearchStateForUrl = function filterSearchStateForUrl(searchState) {
  return Object.keys(searchState).reduce(function (filteredState, key) {
    switch (key) {
      case 'page':
      case 'configure':
        return filteredState;
      case 'refinementList':
        filteredState[key] = Object.keys(searchState[key]).reduce(function (filteredRefinementList, refinementKey) {
          if (searchState[key][refinementKey] === '') return filteredRefinementList;
          filteredRefinementList[refinementKey] = searchState[key][refinementKey];
          return filteredRefinementList;
        }, {});
        return filteredState;
      case 'range':
        filteredState[key] = Object.keys(searchState[key]).reduce(function (filteredRange, rangeKey) {
          filteredRange[rangeKey] = {};
          if (searchState[key][rangeKey].min !== ALGOLIA_RANGE_INPUT_MIN) {
            filteredRange[rangeKey].min = searchState[key][rangeKey].min;
          }
          if (searchState[key][rangeKey].max !== ALGOLIA_RANGE_INPUT_MAX) {
            filteredRange[rangeKey].max = searchState[key][rangeKey].max;
          }
          return filteredRange;
        }, {});
        return filteredState;
      default:
        filteredState[key] = searchState[key];
        return filteredState;
    }
  }, {});
};