import AlgoliaHasRangeRefinement from '@paperstac/ui/lib/AlgoliaHasRangeRefinement';
import AlgoliaRangeInput from '@paperstac/ui/lib/AlgoliaRangeInput';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const InvestmentToBalanceFilter = React.memo((props) => (
  <AlgoliaHasRangeRefinement attribute="investmentMetaAgg.investmentToBalancePercent">
    <CollapsibleFilterContainer title="Investment to Balance (ITB)">
      <AlgoliaRangeInput attribute="investmentMetaAgg.investmentToBalancePercent" suffix="%" />
    </CollapsibleFilterContainer>
  </AlgoliaHasRangeRefinement>
));

InvestmentToBalanceFilter.propTypes = {};

InvestmentToBalanceFilter.defaultProps = {};

InvestmentToBalanceFilter.displayName = 'InvestmentToBalanceFilter';

export default InvestmentToBalanceFilter;
