import React from 'react';
import { REM } from '../theme';
import Button from './Button';
import PlusIcon from './icons/PlusIcon';
import classNames from 'classnames';
import './PageTitle.css';

export default ({ title, handleAction, actionText, className, ActionIcon = PlusIcon }) => (
  <div className={classNames('PageTitle', className)}>
    <h1 className="PageTitle__text">{title}</h1>
    {handleAction && (
      <div className="PageTitle__action">
        <Button greenOutline small round onClick={handleAction}>
          <ActionIcon size={10} style={{ marginRight: REM(4) }} />
          {actionText}
        </Button>
      </div>
    )}
  </div>
);
