function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import useEventListener from '@use-it/event-listener';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
var createStorage = function createStorage(provider) {
  return {
    get: function get(key, defaultValue) {
      var json = provider.getItem(key);
      // eslint-disable-next-line no-nested-ternary
      return json === null ? typeof defaultValue === 'function' ? defaultValue() : defaultValue : JSON.parse(json);
    },
    set: function set(key, value) {
      provider.setItem(key, JSON.stringify(value));
    }
  };
};
var globalState = {};
var createGlobalState = function createGlobalState(key, thisCallback, initialValue) {
  if (!globalState[key]) {
    globalState[key] = {
      callbacks: [],
      value: initialValue
    };
  }
  globalState[key].callbacks.push(thisCallback);
  return {
    deregister: function deregister() {
      var arr = globalState[key].callbacks;
      var index = arr.indexOf(thisCallback);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    emit: function emit(value) {
      if (globalState[key].value !== value) {
        globalState[key].value = value;
        globalState[key].callbacks.forEach(function (callback) {
          if (thisCallback !== callback) {
            callback(value);
          }
        });
      }
    }
  };
};
var usePersistedState = function usePersistedState(initialState, key, _ref) {
  var get = _ref.get,
    set = _ref.set;
  var globalState = useRef(null);
  var _useState = useState(function () {
      return get(key, initialState);
    }),
    _useState2 = _slicedToArray(_useState, 2),
    state = _useState2[0],
    setState = _useState2[1];

  // subscribe to `storage` change events
  useEventListener('storage', function (_ref2) {
    var k = _ref2.key,
      newValue = _ref2.newValue;
    if (k === key) {
      var newState = JSON.parse(newValue);
      if (!isEqual(state, newState)) {
        setState(newState);
      }
    }
  });

  // only called on mount
  useEffect(function () {
    // register a listener that calls `setState` when another instance emits
    globalState.current = createGlobalState(key, setState, initialState);
    return function () {
      globalState.current.deregister();
    };
  }, []);

  // Only persist to storage if state changes.
  useEffect(function () {
    // persist to localStorage
    set(key, state);

    // inform all of the other instances in this tab
    globalState.current.emit(state);
  }, [state]);
  return [state, setState];
};
var createPersistedState = function createPersistedState(key) {
  var provider = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : global.localStorage;
  if (provider) {
    var storage = createStorage(provider);
    return function (initialState) {
      return usePersistedState(initialState, key, storage);
    };
  }
  return useState;
};
export default createPersistedState;