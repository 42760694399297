import { END_SESSION } from '../actions/authActions';
import { SET_COMMUNITY, SET_MY_COMMUNITIES, SET_SELLING_COMMUNITIES } from '../actions/communityActions';
import { UNSUBSCRIBE_CURRENT_ACCOUNT } from '../actions/currentAccountActions';

const DEFAULT_STATE = {
  entities: {},
  myCommunityIds: [],
  sellingCommunityIds: [],
};

export default function communitiesReducer(state = DEFAULT_STATE, { type, payload }) {
  switch (type) {
    case END_SESSION:
    case UNSUBSCRIBE_CURRENT_ACCOUNT:
      return DEFAULT_STATE;
    case SET_COMMUNITY:
      return {
        ...state,
        entities: { ...state.entities, ...payload },
      };
    case SET_MY_COMMUNITIES:
      return {
        ...state,
        entities: payload.reduce(
          (entities, entity) => {
            entities[entity.id] = entity;
            return entities;
          },
          { ...state.entities }
        ),
        myCommunityIds: payload.map((c) => c.id),
      };
    case SET_SELLING_COMMUNITIES:
      return {
        ...state,
        entities: payload.reduce(
          (entities, entity) => {
            entities[entity.id] = entity;
            return entities;
          },
          { ...state.entities }
        ),
        sellingCommunityIds: payload.map((c) => c.id),
      };
    default:
      return state;
  }
}

export const getCommunity = (state, communityId) => {
  return state.entities[communityId];
};

export const getMyCommunities = (state) => {
  return state.myCommunityIds.map((id) => state.entities[id]);
};

export const getSellingCommunities = (state) => {
  return state.sellingCommunityIds.map((id) => state.entities[id]);
};
