import { FIREBASE_PROJECT_ID } from '@paperstac/env';
import React from 'react';

const { Provider, Consumer } = React.createContext();

class LinkedInRegisterConversion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { trackingPixelShowing: false, onRegister: this.handleEvent };
  }

  handleEvent = () => {
    if (FIREBASE_PROJECT_ID === 'paperstac-prod') {
      console.log('[LinkedIn] Registered conversion fired!');
      this.setState({ trackingPixelShowing: true });
    } else {
      console.log('[LinkedIn] Registered conversion skipped');
    }
  };

  render() {
    return (
      <Provider value={this.state}>
        <>
          {this.props.children}
          {this.state.trackingPixelShowing ? (
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              alt="LinkedIn Tracking Pixel"
              src="https://dc.ads.linkedin.com/collect/?pid=532076&conversionId=664073&fmt=gif"
            />
          ) : null}
        </>
      </Provider>
    );
  }
}

export default LinkedInRegisterConversion;

export { Consumer };
