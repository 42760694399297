import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import React from 'react';
import PrivacyPolicyOverlay from '../PrivacyPolicy/PrivacyPolicyOverlay';
import { AUTH_OVERLAY_VIEWS, AuthOverlayContext } from './AuthOverlayProvider';
import ChooseAccountView from './ChooseAccountView';
import CreateAccountView from './CreateAccountView';
import CreateIdentityView from './CreateIdentityView';
import Footer from './Footer';
import ForgotPasswordView from './ForgotPasswordView';
import Header from './Header';
import LoginView from './LoginView';
import PasswordResetView from './PasswordResetView';
import Testimonial from './Testimonial';

const DEFAULT_SIDEBAR_WIDTH = 360;
const WIDE_SIDEBAR_WIDTH = 540;

const Layout = React.memo((props) => {
  const { hidePrivacyPolicy, showingPrivacyPolicy, view } = React.useContext(AuthOverlayContext);
  const wideSidebar = React.useMemo(() => {
    return [AUTH_OVERLAY_VIEWS.VALIDATE_IDENTITY, AUTH_OVERLAY_VIEWS.VERIFY_IDENTITY].includes(view);
  }, [view]);

  if (showingPrivacyPolicy) return <PrivacyPolicyOverlay onClose={hidePrivacyPolicy} />;

  return (
    <Flex sx={{ minHeight: '100vh' }}>
      <Flex
        sx={{
          bg: 'white',
          width: [
            '100%',
            '100%',
            wideSidebar ? '100%' : DEFAULT_SIDEBAR_WIDTH,
            wideSidebar ? WIDE_SIDEBAR_WIDTH : DEFAULT_SIDEBAR_WIDTH,
          ],
          py: 40,
          px: 4,
          flexDirection: 'column',
          height: '100vh',
          overflowY: 'auto',
        }}
      >
        <Box flex="1 1 0" mb={64}>
          <Header textAlign="center" mb={40} />
          {view === AUTH_OVERLAY_VIEWS.CHOOSE_ACCOUNT && <ChooseAccountView />}
          {view === AUTH_OVERLAY_VIEWS.CREATE_ACCOUNT && <CreateAccountView />}
          {view === AUTH_OVERLAY_VIEWS.CREATE_IDENTITY && <CreateIdentityView />}
          {view === AUTH_OVERLAY_VIEWS.FORGOT_PASSWORD && <ForgotPasswordView />}
          {view === AUTH_OVERLAY_VIEWS.LOGIN && <LoginView />}
          {view === AUTH_OVERLAY_VIEWS.PASSWORD_RESET && <PasswordResetView />}
        </Box>
        <Footer />
      </Flex>
      <Box
        sx={{
          display: ['none', 'none', wideSidebar ? 'none' : 'flex', 'flex'],
          bg: 'darkBlue',
          flex: '1 1 0',
          alignItems: 'center',
          justifyContent: 'center',
          py: 40,
          px: 4,
        }}
      >
        <Testimonial />
      </Box>
    </Flex>
  );
});

Layout.propTypes = {};

Layout.defaultProps = {};

Layout.displayName = 'Layout';

export default Layout;
