import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import Box from '@paperstac/ui/lib/Box';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import DescriptionGridTerm from '@paperstac/ui/lib/DescriptionGridTerm';
import InfoCircleIcon from '@paperstac/ui/lib/icons/InfoCircleIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import { NoteScreenContext } from './NoteScreen';
import NoteScreenUnderlyingLienList from './NoteScreenUnderlyingLienList';

const NoteScreenUnderlyingLiens = React.memo(() => {
  const { showAddUnderlyingLiens } = React.useContext(NoteScreenContext);
  const { note } = React.useContext(ManageListingOverlayContext);
  const wraps = note.wraps;

  return (
    <DescriptionGridCell>
      <DescriptionGridTerm mb={3}>
        Underlying Lien(s) (
        <LinkButton onClick={showAddUnderlyingLiens} sx={{ textTransform: 'none' }}>
          <Text variant="bold">+</Text> Add Lienholder
        </LinkButton>
        )
      </DescriptionGridTerm>
      {(!wraps || (wraps && !Object.keys(wraps).length)) && (
        <Box fontSize={1}>
          <InfoCircleIcon sx={{ color: 'blue', position: 'relative', top: '-2px' }} /> <Text variant="bold">TIP</Text>:
          Is your loan a wrap-around lien? If so, list any lienholders that will need to be paid upon sale of this note.
          Most loans on Paperstac do not contain wraps.
        </Box>
      )}
      <NoteScreenUnderlyingLienList />
    </DescriptionGridCell>
  );
});

NoteScreenUnderlyingLiens.propTypes = {};

NoteScreenUnderlyingLiens.defaultProps = {};

NoteScreenUnderlyingLiens.displayName = 'NoteScreenUnderlyingLiens';

export default NoteScreenUnderlyingLiens;
