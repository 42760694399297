export var intercomBoot = function (appId) {
    return window.Intercom('boot', { api_base: 'https://api-iam.intercom.io', app_id: appId });
};
export var intercomHide = function () { return window.Intercom('hide'); };
export var intercomLogin = function (payload) {
    return window.Intercom('update', {
        email: payload.email,
        created_at: payload.createdUnixTimestamp,
        name: payload.name,
        user_hash: payload.userHash,
        user_id: payload.uid,
    });
};
export var intercomLogout = function () { return window.Intercom('shutdown'); };
export var intercomShow = function () { return window.Intercom('show'); };
export var intercomShowMessages = function () { return window.Intercom('showMessages'); };
export var intercomShowNewMessage = function () { return window.Intercom('showNewMessage'); };
export var intercomTrackEvent = function (event, metadata) {
    return metadata ? window.Intercom('trackEvent', event, metadata) : window.Intercom('trackEvent', event);
};
