import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import Text from '@paperstac/ui/lib/Text';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

const NoteFilesSection = React.memo(({ note, ...props }) => {
  const filesCount = get(note, 'files', []).filter(({ fileUrl }) => !!fileUrl).length;

  return (
    <Box {...props}>
      <Heading
        sx={{
          mb: 2,
          fontSize: 2,
          textTransform: 'uppercase',
        }}
      >
        Note Files
      </Heading>

      {!filesCount && (
        <Box>
          <Text variant="italic">Seller has not shared any files for this note...</Text>
        </Box>
      )}

      {!!filesCount && (
        <Box mb={3} maxWidth={600}>
          <Text variant="italic">
            {filesCount} {filesCount === 1 ? 'file has' : 'files have'} been provided by the seller and will be visible
            from within a transaction. To start a transaction, message the seller or make an offer.
          </Text>
        </Box>
      )}
    </Box>
  );
});

NoteFilesSection.propTypes = {
  note: PropTypes.object.isRequired,
};

NoteFilesSection.defaultProps = {};

NoteFilesSection.displayName = 'NoteFilesSection';

export default NoteFilesSection;
