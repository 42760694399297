import Box from '@paperstac/ui/lib/Box';
import SwitchButton from '@paperstac/ui/lib/SwitchButton';
import React from 'react';
import BaseFilterContainer from './BaseFilterContainer';
import FilterTitle from './FilterTitle';
import { ListingsSearchContext } from './ListingsSearchProvider';

const StarredFilter = React.memo((props) => {
  const { starredFilterEnabled, toggleStarredFilter } = React.useContext(ListingsSearchContext);

  return (
    <BaseFilterContainer display="flex" alignItems="center">
      <FilterTitle flex="1 1 0" mb="0">
        My Starred Listings
      </FilterTitle>
      <Box>
        <SwitchButton active={starredFilterEnabled} onClick={toggleStarredFilter} size="small" display="block" />
      </Box>
    </BaseFilterContainer>
  );
});

StarredFilter.propTypes = {};

StarredFilter.defaultProps = {};

StarredFilter.displayName = 'StarredFilter';

export default StarredFilter;
