import { PING_IDENTITY } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import auth from '@paperstac/firebase/lib/auth';
import { getFirebase } from '@paperstac/firebase/lib/firebase';
import Button from '@paperstac/ui/lib/Button';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import InputFormGroup from '@paperstac/ui/lib/form/InputFormGroup';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const firebase = getFirebase();

const validationSchema = () =>
  Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

const UpdateEmailForm = React.memo(({ onCancel }) => {
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(payload, { setSubmitting, setStatus }) => {
        const authUser = auth.currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(authUser.email, payload.password);
        authUser
          .reauthenticateWithCredential(credential)
          .then(() => authUser.updateEmail(payload.email))
          .then(() => serverDispatch({ action: PING_IDENTITY }))
          .then(() => {
            setSubmitting(false);
            onCancel && onCancel();
          })
          .catch((error) => {
            setSubmitting(false);
            setStatus({
              errorMessage:
                error.code === 'auth/wrong-password' ? 'The password you entered is incorrect' : error.message,
            });
          });
      }}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <InputFormGroup name="email" label="Email Address:" type="email" required />
          <InputFormGroup
            name="password"
            label="Password:"
            type="password"
            description="* Please provide for security purposes"
            required
          />
          {status && status.errorMessage && <ErrorText mb={3}>{status.errorMessage}</ErrorText>}
          <Button type="submit" busy={isSubmitting} variant="primary" mr={3}>
            Update Email
          </Button>
          <Button type="button" variant="default" onClick={onCancel}>
            Cancel
          </Button>
        </Form>
      )}
    </Formik>
  );
});

UpdateEmailForm.propTypes = {};

UpdateEmailForm.defaultProps = {};

UpdateEmailForm.displayName = 'UpdateEmailForm';

export default UpdateEmailForm;
