import { get } from '../services/api';

export const SET_ACTIVE_LISTING = 'SET_ACTIVE_LISTING';
export const FETCH_ACTIVE_LISTING_FAILURE = 'FETCH_ACTIVE_LISTING_FAILURE';

export const setActiveListing = (payload) => {
  return { type: SET_ACTIVE_LISTING, payload };
};

export const fetchActiveListingRequest = (listingId) => {
  return (dispatch) => {
    return get(`active-listings/${listingId}`)
      .then((payload) => dispatch(setActiveListing(payload)))
      .catch((error) => {
        dispatch({ type: FETCH_ACTIVE_LISTING_FAILURE, payload: { id: listingId, message: error.message } });
      });
  };
};
