"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var config = {
// ALGOLIA_INDEX_PREFIX: '[your name]-local',
// API_ENDPOINT_URL: 'http://localhost:5001/paperstac-dev/us-central1/legacyApi/api/v1',
// API_ENDPOINT_URL_NOTECLOSINGS: 'http://localhost:5001/paperstac-dev/us-central1/api',
// API_ENDPOINT_URL_PAPERSTAC: 'http://localhost:5001/paperstac-dev/us-central1/api',
// APP_ENV: 'local',
// BASE_URL: 'http://localhost:3000',
// BASE_NOTECLOSINGS_URL: 'http://localhost:3001',
// FIREBASE_FUNCTIONS_URL: 'http://localhost:5001/paperstac-dev/us-central1',
// NOTION_TOKEN: '';
// OPENAI_API_KEY: '';
// PLAID_SANDBOX: true,
// PLAID_SECRET_OVERRIDE: '47b9f9d3bea705b0c4c2d2e46724b3',
// STRIPE_SIGNING_SECRET: '',
};
exports.default = config;
