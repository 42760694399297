import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { UPDATE_NOTE } from '@paperstac/common/lib/serverDispatchActionTypes';
import dateid from '@paperstac/common/lib/services/dateid';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import storage from '@paperstac/firebase/lib/storage';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import FileInputButton from '@paperstac/ui/lib/form/FileInputButton';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import React from 'react';
import getFileExtension from '../../../utils/getFileExtension';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import { NoteScreenContext } from './NoteScreen';

const NoteScreenAddPaymentHistory = React.memo(() => {
  const { uid } = React.useContext(IdentityContext);
  const { currentAccountId } = React.useContext(AccountsContext);
  const { note, showOverview: showListing } = React.useContext(ManageListingOverlayContext);
  const { showAddAdjustment, showOverview } = React.useContext(NoteScreenContext);
  const [isUploading, setUploading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleAddFiles = React.useCallback(
    async (files) => {
      try {
        if (isUploading) return;
        setUploading(true);
        setErrorMessage(null);
        const [file] = files;
        const fileExtension = getFileExtension(file.name);
        const userStoragePath = `user-uploads/${uid}/${currentAccountId}`;
        const filePath = `${userStoragePath}/notes/${note.id}/payment-history-${dateid()}.${fileExtension}`;
        serverDispatch({
          action: UPDATE_NOTE,
          payload: {
            accountId: note.accountId,
            id: note.id,
            paymentHistoryFilePath: await storage
              .child(filePath)
              .put(file, { cacheControl: 'public,max-age=31536000' })
              .then(() => filePath),
          },
        }).then(showOverview);
      } catch (error) {
        setUploading(false);
        setErrorMessage(error.message);
      }
    },
    [currentAccountId, isUploading, note.accountId, note.id, showOverview, uid]
  );

  return (
    <DescriptionGrid
      sx={{
        bg: 'gray.1',
        border: 'none',
        borderTop: 'default',
        borderBottom: 'default',
        borderRadius: 0,
      }}
    >
      <DescriptionGridCell bg="highlight">
        <Flex alignItems="center">
          <LinkButton onClick={showListing}>Listing</LinkButton>
          <ChevronRightIcon size={12} mx={2} color="gray.6" />
          <LinkButton onClick={showOverview}>Note</LinkButton>
          <ChevronRightIcon size={12} mx={2} color="gray.6" />
          Add Payment History
        </Flex>
      </DescriptionGridCell>
      <DescriptionGridCell>
        Payment History can be added as a single file upload, or you can enter each line item adjustment manually.
      </DescriptionGridCell>
      <DescriptionGridCell>
        {!!errorMessage && <ErrorText mb={3}>{errorMessage}</ErrorText>}
        <FileInputButton variant="primary" onFiles={handleAddFiles} busy={isUploading} mb={3} block>
          Upload Payment History
        </FileInputButton>
        <Button variant="secondary" onClick={showAddAdjustment} mb={3} block>
          Add Adjustment Item
        </Button>
        <Button variant="default" onClick={showOverview} block>
          Cancel
        </Button>
      </DescriptionGridCell>
    </DescriptionGrid>
  );
});

NoteScreenAddPaymentHistory.propTypes = {};

NoteScreenAddPaymentHistory.defaultProps = {};

NoteScreenAddPaymentHistory.displayName = 'NoteScreenAddPaymentHistory';

export default NoteScreenAddPaymentHistory;
