/**
 * https://github.com/rebassjs/rebass/blob/master/packages/reflexbox/src/index.js
 */

import styled from 'styled-components';
import { compose, space, layout, typography, color, flexbox } from 'styled-system';
import css, { get } from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
const sx = props => css(props.sx)(props.theme);
const base = props => css(props.__css)(props.theme);
const variant = _ref => {
  let {
    theme,
    variant,
    tx = 'variants'
  } = _ref;
  return css(get(theme, tx + '.' + variant, get(theme, variant)))(theme);
};
export const Box = styled('div', {
  shouldForwardProp
}).withConfig({
  displayName: "reflexbox__Box",
  componentId: "sc-1t9br4q-0"
})({
  boxSizing: 'border-box',
  margin: 0,
  minWidth: 0
}, base, variant, sx, props => props.css, compose(space, layout, typography, color, flexbox));
export const Flex = styled(Box).withConfig({
  displayName: "reflexbox__Flex",
  componentId: "sc-1t9br4q-1"
})({
  display: 'flex'
});