import React from 'react';
import RatioGrid from './RatioGrid';
import report from './report';
import Section from './Section';

const DealOverview = React.memo((props) => {
  return (
    <Section title="Deal and Asset Overview" subtitle="(what is selling and how deals are getting done)" bg="gray.1">
      <RatioGrid
        ratio1={report.dealOverview[0]}
        ratio2={report.dealOverview[1]}
        ratio3={report.dealOverview[2]}
        ratio4={report.dealOverview[3]}
        ratio5={report.dealOverview[4]}
        ratio6={report.dealOverview[5]}
      />
    </Section>
  );
});

DealOverview.propTypes = {};

DealOverview.defaultProps = {};

DealOverview.displayName = 'DealOverview';

export default DealOverview;
