import useFirestoreSubscribe from '@paperstac/common/lib/hooks/useFirestoreSubscribe';
import { UPDATE_LISTING } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import { accountNoteByIdRef, accountNotesRef } from '@paperstac/firestore-collections/lib/accountNotes';
import Box from '@paperstac/ui/lib/Box';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import Overlay from '@paperstac/ui/lib/Overlay';
import PropTypes from 'prop-types';
import React from 'react';
import useDimensions from '@paperstac/ui/lib/hooks/useDimensions';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { settingsRef } from '@paperstac/firestore-collections/lib/settings';
import VerifyIdentityOverlay from '../VerifyIdentityOverlay/VerifyIdentityOverlay';

const MAX_DRAWER_WIDTH = 600;

export const SCREENS = {
  DESCRIPTION: 'DESCRIPTION',
  NOTE: 'NOTE',
  OVERVIEW: 'OVERVIEW',
  PRICE: 'PRICE',
  SELECT_NOTES: 'SELECT_NOTES',
  STATUS: 'STATUS',
};

const ManageListingOverlayContext = React.createContext();
const { Provider } = ManageListingOverlayContext;

const ManageListingOverlayProvider = React.memo(({ children, initialScreen, listing, onClose }) => {
  const [ref, { width }] = useDimensions();
  const stacked = React.useMemo(() => width < MAX_DRAWER_WIDTH, [width]);
  const { identity } = React.useContext(IdentityContext);
  const [settings] = useFirestoreSubscribe(settingsRef());
  const verificationRequiredToList = !!settings?.requireVerificationToList && !identity?.isVerified;
  const [isVerifying, setVerifying] = React.useState(false);
  const [editNoteId, setEditNoteId] = React.useState(null);
  const [note] = useFirestoreSubscribe(accountNoteByIdRef(listing.accountId, editNoteId), [
    listing.accountId,
    editNoteId,
  ]);
  const [notes] = useFirestoreSubscribe(accountNotesRef(listing.accountId), [listing.accountId]);
  const [screen, setScreen] = React.useState(initialScreen);
  const showDescription = React.useCallback(() => setScreen(SCREENS.DESCRIPTION), []);
  const showNote = React.useCallback((noteId) => {
    setEditNoteId(noteId);
    setScreen(SCREENS.NOTE);
  }, []);
  const showOverview = React.useCallback(() => setScreen(SCREENS.OVERVIEW), []);
  const showPrice = React.useCallback(() => setScreen(SCREENS.PRICE), []);
  const showSelectNotes = React.useCallback(() => setScreen(SCREENS.SELECT_NOTES), []);
  const showStatus = React.useCallback(() => setScreen(SCREENS.STATUS), []);
  const updateListing = React.useCallback(
    (payload) =>
      serverDispatch({
        action: UPDATE_LISTING,
        payload: { ...payload, id: listing.id },
      }),
    [listing.id]
  );

  const providerValue = React.useMemo(
    () => ({
      listing,
      note,
      notes,
      onClose,
      screen,
      setVerifying,
      showDescription,
      showNote,
      showOverview,
      showPrice,
      showSelectNotes,
      showStatus,
      stacked,
      updateListing,
      verificationRequiredToList,
    }),
    [
      listing,
      note,
      notes,
      onClose,
      screen,
      setVerifying,
      showDescription,
      showNote,
      showOverview,
      showPrice,
      showSelectNotes,
      showStatus,
      stacked,
      updateListing,
      verificationRequiredToList,
    ]
  );

  return (
    <Provider value={providerValue}>
      {!!isVerifying && <VerifyIdentityOverlay onClose={() => setVerifying(false)} />}
      {!isVerifying && (
        <Overlay onClose={onClose}>
          <Drawer ref={ref} width={MAX_DRAWER_WIDTH}>
            <DrawerHeader text="Manage Listing" onClose={onClose} px={3} />
            <Box sx={{ overflowY: 'auto', flex: '1 1 auto' }}>{children}</Box>
          </Drawer>
        </Overlay>
      )}
    </Provider>
  );
});

ManageListingOverlayProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialScreen: PropTypes.string,
  listing: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

ManageListingOverlayProvider.defaultProps = {
  initialScreen: SCREENS.OVERVIEW,
};

ManageListingOverlayProvider.displayName = 'ManageListingOverlayProvider';

export default ManageListingOverlayProvider;

export { ManageListingOverlayContext };
