import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { CREATE_ACCOUNT } from '@paperstac/common/lib/serverDispatchActionTypes';
import serverDispatch from '@paperstac/common/lib/services/serverDispatch';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import Heading from '@paperstac/ui/lib/Heading';
import Overlay from '@paperstac/ui/lib/Overlay';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';
import CreateAccountForm from './AuthOverlay/CreateAccountForm';
import { IdentityManagerContext } from './IdentityManager';

const CreateAccountOverlay = React.memo(({ onClose }) => {
  const { identity } = React.useContext(IdentityContext);
  const { currentAccount } = React.useContext(AccountsContext);
  const { showAccountChooser } = React.useContext(IdentityManagerContext);
  const [success, setSuccess] = React.useState(false);
  const handleAccountSwitch = React.useCallback(() => {
    showAccountChooser();
    onClose();
  }, [onClose, showAccountChooser]);

  return (
    <Overlay onClose={onClose}>
      <Drawer width={360}>
        <DrawerHeader text="Create Account" onClose={onClose} />
        <Box sx={{ overflowY: 'auto', flex: '1 1 auto', p: 4 }}>
          {!success && (
            <CreateAccountForm
              identityId={identity.id}
              onSubmit={async (payload, { setSubmitting, setStatus }) => {
                payload.isBroker =
                  typeof payload.isBroker === 'string' ? payload.isBroker === 'true' : payload.isBroker;
                setSubmitting(true);
                serverDispatch({ action: CREATE_ACCOUNT, payload })
                  .then(() => setSuccess(true))
                  .catch((error) => {
                    setSubmitting(false);
                    setStatus({ errorMessage: error.message });
                  });
              }}
            />
          )}

          {success && (
            <>
              <Heading mb={3}>Account Created</Heading>
              <Text fontSize={1} mb={5}>
                You have successfully created another account. You are currently still in your{' '}
                <Text variant="bold">{currentAccount.displayName}</Text> account. You can switch accounts anytime by
                clicking your account profile picture in the website header, and choosing{' '}
                <Text variant="italic">"Switch Accounts"</Text> from the dropdown.
              </Text>
              <Button mb={2} variant="secondary" block onClick={handleAccountSwitch}>
                Switch Accounts
              </Button>
              <Button variant="default" block onClick={onClose}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Drawer>
    </Overlay>
  );
});

CreateAccountOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
};

CreateAccountOverlay.defaultProps = {};

CreateAccountOverlay.displayName = 'CreateAccountOverlay';

export default CreateAccountOverlay;
