import React, { Component } from 'react';
import { trackPage } from '../services/analytics';

// Bing ads
window.uetq = window.uetq || [];

const withTracker = (WrappedComponent, options = {}, userEmail = null) => {
  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page, options, userEmail);
    }

    componentDidUpdate(prevProps) {
      const currentPage = prevProps.location.pathname + prevProps.location.search;
      const nextPage = this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage, options, userEmail);
        // Bing ads
        window.uetq.push('event', 'page_view', { page_path: nextPage });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
