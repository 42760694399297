import { DELETE, firestore } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_COMMUNITY_INVITES = 'SET_COMMUNITY_INVITES';

const unsubscribes = {};

const transformCollection = (querySnapshot) => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = (docSnap) => {
  return docSnap.exists ? transformFirestoreTimestamps(docSnap) : null;
};

export const setCommunityInvites = (communityId, invites) => {
  return { type: SET_COMMUNITY_INVITES, payload: { communityId, invites } };
};

export const createCommunityInviteRequest = (data) => {
  const payload = {
    id: dateid(),
    ...data,
  };
  return (dispatch) => {
    return firestore.doc(`communities/${payload.communityId}/invites/${payload.id}`).set(payload);
  };
};

export const resendCommunityInviteRequest = (data) => {
  const payload = { ...data, lastSendDate: DELETE };
  return (dispatch) => {
    return firestore.doc(`communities/${payload.communityId}/invites/${payload.id}`).update(payload);
  };
};

export const deleteCommunityInviteRequest = (payload) => {
  return (dispatch) => {
    return firestore.doc(`communities/${payload.communityId}/invites/${payload.id}`).delete();
  };
};

export const subscribeCommunityInvites = (communityId) => {
  return (dispatch) => {
    if (unsubscribes[communityId]) {
      unsubscribes[communityId]();
    }
    unsubscribes[communityId] = firestore
      .collection(`communities/${communityId}/invites`)
      .onSnapshot((querySnapshot) => {
        dispatch(setCommunityInvites(communityId, transformCollection(querySnapshot)));
      });
  };
};

export const unsubscribeCommunityInvites = (communityId) => {
  return (dispatch) => {
    if (unsubscribes[communityId]) {
      unsubscribes[communityId]();
      unsubscribes[communityId] = null;
    }
  };
};
