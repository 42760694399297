import Cookies from 'js-cookie';
import debounce from 'lodash/debounce';
var SESSION_KEY = '__session';
var SAVE_COOKIE_DEBOUNCE_MS = 200;
var session = Cookies.getJSON(SESSION_KEY) || {};
export var setSessionToken = function setSessionToken(token) {
  session.token = token;
  persist();
};
export var setSessionCurrentAccountId = function setSessionCurrentAccountId(currentAccountId) {
  session.currentAccountId = currentAccountId;
  persist();
};
export var setSessionCurrentPartnerId = function setSessionCurrentPartnerId(currentPartnerId) {
  session.currentPartnerId = currentPartnerId;
  persist();
};
export var getSession = function getSession() {
  return session;
};
export var getSessionCurrentAccountId = function getSessionCurrentAccountId() {
  return getSession().currentAccountId;
};
export var getSessionCurrentPartnerId = function getSessionCurrentPartnerId() {
  return getSession().currentPartnerId;
};
export var flushSession = function flushSession() {
  session = {};
  Cookies.remove(SESSION_KEY);
};
var persist = debounce(function () {
  Cookies.set(SESSION_KEY, session);
}, SAVE_COOKIE_DEBOUNCE_MS);