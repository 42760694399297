import { withIdentity } from '@paperstac/common/lib/components/IdentityProvider';
import { HOME } from '@paperstac/routes/lib/webRoutes';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { functions } from '../services/firebaseClient';
import { SPACER, WHITE } from '../theme';
import BaseLayout from './BaseLayout';
import Button from './Button';
import LoadingPage from './LoadingPage';
import PageTitle from './PageTitle';
import Table from './Table';
import { intercomShow } from '@paperstac/common/lib/services/intercomMessenger';

const logIpAddressForAccount = functions.httpsCallable('logIpAddressForAccount');

class FrozenPage extends Component {
  componentDidMount() {
    if (this.props.currentAccount) {
      logIpAddressForAccount(this.props.currentAccount.id);
    }
  }

  contactSupport = (e) => {
    e.preventDefault();
    intercomShow();
  };

  shouldDoRedirectToHome = () => {
    return !this.props.currentAccount.isFrozen;
  };

  render() {
    if (!this.props.currentAccount) {
      return <LoadingPage />;
    }
    return (
      <BaseLayout>
        {this.shouldDoRedirectToHome() && <Redirect to={HOME} />}
        <div style={{ margin: `${SPACER(2)} ${SPACER(3)}` }}>
          <PageTitle title="Please Contact Paperstac Support" />
          <p>We need to speak with you regarding the following Paperstac Identity...</p>
          <Table bordered style={{ backgroundColor: WHITE }}>
            <tr>
              <th>Identity ID</th>
              <td>{this.props.uid}</td>
            </tr>
            <tr>
              <th>Display Name</th>
              <td>{this.props.identity.displayName}</td>
            </tr>
          </Table>

          <h3 style={{ marginTop: SPACER(3) }}>Paperstac Contact Information</h3>
          <Table bordered style={{ backgroundColor: WHITE }}>
            <tr>
              <th>Phone</th>
              <td>407-930-9749</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>support@paperstac.com</td>
            </tr>
            <tr>
              <th>Chat</th>
              <td>
                <Button primary small onClick={this.contactSupport}>
                  Open Chat
                </Button>
              </td>
            </tr>
          </Table>
        </div>
      </BaseLayout>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
  };
}

export default withRouter(withIdentity(connect(mapStateToProps)(FrozenPage)));
