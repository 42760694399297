import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import Footer from '../common/Footer/Footer';
import Header from '../common/Header/Header';
import BaseLayout from './BaseLayout';

const DefaultLayout = React.memo(({ children, title }) => (
  <BaseLayout title={title}>
    <Flex flexDirection="column" minHeight="100vh" bg="gray.2">
      <Header />
      <Box as="main" flex="1 1 0" width="100%">
        {children}
      </Box>
      <Footer />
    </Flex>
  </BaseLayout>
));

DefaultLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

DefaultLayout.defaultProps = {};

DefaultLayout.displayName = 'DefaultLayout';

export default DefaultLayout;
