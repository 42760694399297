import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_TRANSACTION_FEES = 'SET_TRANSACTION_FEES';

const unsubscribes = {};

export const setTransactionFees = (payload) => {
  return { type: SET_TRANSACTION_FEES, payload };
};

export const subscribeTransactionFees = (accountId, transactionId) => {
  return (dispatch) => {
    if (!unsubscribes[transactionId]) {
      unsubscribes[transactionId] = firestore
        .collection(`transactions/${transactionId}/fees`)
        .where('accountId', '==', accountId)
        .onSnapshot((querySnapshot) => {
          dispatch(setTransactionFees({ transactionId, fees: querySnapshot.docs.map(transformFirestoreTimestamps) }));
        });
    }
  };
};

export const unsubscribeTransactionFees = (transactionId) => {
  return (dispatch) => {
    if (unsubscribes[transactionId]) {
      unsubscribes[transactionId]();
      delete unsubscribes[transactionId];
    }
  };
};

export const addFeeRequest = (payload) => {
  return (dispatch) => {
    return firestore.doc(`transactions/${payload.transactionId}/fees/${payload.id}`).set(payload);
  };
};

export const removeFeeRequest = (transactionId, feeId) => {
  return (dispatch) => {
    return firestore.doc(`transactions/${transactionId}/fees/${feeId}`).delete();
  };
};
