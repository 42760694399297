import Box from '@paperstac/ui/lib/Box';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import Overlay from '@paperstac/ui/lib/Overlay';
import React from 'react';
import AccountsSection from './AccountsSection';
import LoginDetailsSection from './LoginDetailsSection';
import VerifiedSection from './VerifiedSection';

const ManageIdentityOverlay = React.memo(({ onClose, ...props }) => {
  return (
    <>
      <Overlay onClose={onClose}>
        <Drawer>
          <DrawerHeader text="Manage Identity" onClose={onClose} />
          <Box sx={{ overflowY: 'auto', flex: '1 1 auto' }}>
            <VerifiedSection />
            <LoginDetailsSection />
            <AccountsSection />
          </Box>
        </Drawer>
      </Overlay>
    </>
  );
});

ManageIdentityOverlay.propTypes = {};

ManageIdentityOverlay.defaultProps = {};

ManageIdentityOverlay.displayName = 'ManageIdentityOverlay';

export default ManageIdentityOverlay;
