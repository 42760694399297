import AlgoliaCheckboxBooleanRefinement from '@paperstac/ui/lib/AlgoliaCheckboxBooleanRefinement';
import AlgoliaHasListRefinement from '@paperstac/ui/lib/AlgoliaHasListRefinement';
import React from 'react';
import CollapsibleFilterContainer from './CollapsibleFilterContainer';

const StateClassificationsFilter = React.memo((props) => (
  <AlgoliaHasListRefinement
    attribute={['noteMetaAgg.isHardestHitFundState', 'noteMetaAgg.isJudicialState', 'noteMetaAgg.isNonJudicialState']}
  >
    <CollapsibleFilterContainer title="State Classifications">
      <AlgoliaCheckboxBooleanRefinement attribute="noteMetaAgg.isJudicialState" label="Judicial State" />
      <AlgoliaCheckboxBooleanRefinement attribute="noteMetaAgg.isNonJudicialState" label="Non-Judicial State" />
    </CollapsibleFilterContainer>
  </AlgoliaHasListRefinement>
));

StateClassificationsFilter.propTypes = {};

StateClassificationsFilter.defaultProps = {};

StateClassificationsFilter.displayName = 'StateClassificationsFilter';

export default StateClassificationsFilter;
