import AccountsProvider from '@paperstac/common/lib/components/AccountsProvider';
import BrowserSupportAlert from '@paperstac/common/lib/components/BrowserSupportAlert';
import FullStoryIdentifier from '@paperstac/common/lib/components/FullStoryIdentifier';
import IdentityProvider from '@paperstac/common/lib/components/IdentityProvider';
import ThemeProvider from '@paperstac/ui/lib/ThemeProvider';
import React from 'react';
import { connect } from 'react-redux';
import { setAsTouchDevice } from './actions/deviceActions';
import './App.css';
import FrozenIdentityScreen from './components/common/AuthOverlay/FrozenIdentityScreen';
import BetaProvider from './components/common/BetaProvider';
import MergeIdentitiesProvider from './components/common/MergeIdentities/MergeIdentitiesProvider';
import LinkedInRegisterConversion from './components/LinkedInRegisterConversion';
import PaperstacRoutes from './PaperstacRoutes';
import { setUserId } from './services/analytics';
import Sentry from './services/sentry';
import ConfigureSentry from './components/ConfigureSentry';
import TrpcProvider from './components/TrpcProvider';
import ConfigureIntercomMessenger from './components/ConfigureIntercomMessenger';
import { ApolloProvider } from '@apollo/client';
import client from './apollo';

const App = React.memo(({ dispatch }) => {
  const onIdentityChange = React.useCallback(({ uid }) => {
    !!uid && setUserId(uid);
  }, []);

  React.useEffect(() => {
    const onFirstTouch = () => {
      dispatch(setAsTouchDevice());
      window.removeEventListener('touchstart', onFirstTouch, false);
    };
    window.addEventListener('touchstart', onFirstTouch, false);
    return window.removeEventListener('touchstart', onFirstTouch, false);
  }, [dispatch]);

  return (
    <Sentry.ErrorBoundary
      fallback={
        <p>
          We have experienced an error. Back to <a href="https://paperstac.com">homepage</a>.
        </p>
      }
    >
      <BrowserSupportAlert />
      <ThemeProvider>
        <TrpcProvider>
          <MergeIdentitiesProvider>
            <IdentityProvider onChange={onIdentityChange}>
              <BetaProvider>
                <FullStoryIdentifier>
                  <AccountsProvider>
                    <FrozenIdentityScreen>
                      <LinkedInRegisterConversion>
                        <ApolloProvider client={client}>
                          <>
                            <ConfigureIntercomMessenger />
                            <ConfigureSentry />
                            <PaperstacRoutes />
                          </>
                        </ApolloProvider>
                      </LinkedInRegisterConversion>
                    </FrozenIdentityScreen>
                  </AccountsProvider>
                </FullStoryIdentifier>
              </BetaProvider>
            </IdentityProvider>
          </MergeIdentitiesProvider>
        </TrpcProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
});

App.propTypes = {};

App.defaultProps = {};

App.displayName = 'App';

export default connect()(App);
