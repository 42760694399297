import distanceInWordsToNow from 'date-fns/formatDistanceToNow';
export var normalizeDate = function (dateOrFirestoreTimestamp) {
    if (!dateOrFirestoreTimestamp)
        return dateOrFirestoreTimestamp;
    if (typeof dateOrFirestoreTimestamp === 'string')
        return isoToDate(dateOrFirestoreTimestamp);
    if (typeof dateOrFirestoreTimestamp !== 'object')
        return dateOrFirestoreTimestamp;
    if (dateOrFirestoreTimestamp.toDate)
        return dateOrFirestoreTimestamp.toDate();
    if (dateOrFirestoreTimestamp.seconds)
        return new Date(dateOrFirestoreTimestamp.seconds * 1000);
    if (dateOrFirestoreTimestamp._seconds)
        return new Date(dateOrFirestoreTimestamp._seconds * 1000);
    return dateOrFirestoreTimestamp;
};
export var isoToDate = function (iso) {
    if (typeof iso !== 'string')
        return iso;
    var date = iso.split('T')[0];
    if (!/^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])$/.test(date))
        return iso;
    var _a = date.split('-'), year = _a[0], month = _a[1], day = _a[2];
    return new Date(+year, +month - 1, +day);
};
export var dateToIso = function (dateOrFirestoreTimestamp) {
    if (typeof dateOrFirestoreTimestamp === 'string')
        return dateOrFirestoreTimestamp.split('T')[0];
    if (!dateOrFirestoreTimestamp || typeof dateOrFirestoreTimestamp !== 'object')
        return dateOrFirestoreTimestamp;
    return normalizeDate(dateOrFirestoreTimestamp).toISOString().split('T')[0];
};
export var dateToTime = function (dateOrFirestoreTimestamp) {
    if (!dateOrFirestoreTimestamp || typeof dateOrFirestoreTimestamp !== 'object')
        return dateOrFirestoreTimestamp;
    return normalizeDate(dateOrFirestoreTimestamp).getTime();
};
export var dateToUnix = function (dateOrFirestoreTimestamp) {
    var time = dateToTime(dateOrFirestoreTimestamp);
    return Math.round(time / 1000);
};
export var unixToDate = function (unixTimestamp) { return new Date(unixTimestamp * 1000); };
export var relativeDate = function (dateOrFirestoreTimestamp) {
    return distanceInWordsToNow(normalizeDate(dateOrFirestoreTimestamp), {
        includeSeconds: true,
        addSuffix: true,
    });
};
export var isFirstMinuteOfHour = function (d) { return normalizeDate(d).getMinutes() === 0; };
export var get24HourEST = function (d) {
    var string = normalizeDate(d).toLocaleString('en-US', { timeZone: 'America/New_York', hour12: false });
    return +string.split(':').shift().split(' ').pop();
};
export var isFirstHourOfDayEST = function (d) { return [0, 24].includes(get24HourEST(d)); };
export var isFirstMinuteOfDayEST = function (d) { return isFirstMinuteOfHour(d) && isFirstHourOfDayEST(d); };
export var secondsToTimeString = function (seconds) { return new Date(1000 * seconds).toISOString().substr(11, 8); };
export var secondsToShortTimeString = function (seconds) {
    var timeString = secondsToTimeString(seconds);
    var timeParts = timeString.split(':');
    if (timeParts[0] !== '00')
        return "".concat(+timeParts[0], ":").concat(timeParts[1], ":").concat(timeParts[2]);
    if (timeParts[1] !== '00')
        return "".concat(+timeParts[1], ":").concat(timeParts[2]);
    return ":".concat(timeParts[2]);
};
