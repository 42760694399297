import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import { getOrdinal } from '@paperstac/helpers/lib/numberHelpers';
import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import Label from '@paperstac/ui/lib/form/Label';
import Select from '@paperstac/ui/lib/form/Select';
import Heading from '@paperstac/ui/lib/Heading';
import InfoCircleIcon from '@paperstac/ui/lib/icons/InfoCircleIcon';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import { ListingPageContext } from './ListingPageProvider';
import NoteMeta from './NoteMeta';
import Section from './Section';

const NoteDetailsSection = React.memo(() => {
  const { uid } = React.useContext(IdentityContext);
  const { listing, mainWidth, width } = React.useContext(ListingPageContext);
  const [currentNoteId, setCurrentNoteId] = React.useState('');
  const [currentNote, setCurrentNote] = React.useState(null);
  const smallTitle = width < 640;

  const getNoteLabel = React.useCallback(
    (note) => {
      if (smallTitle) return `${uid ? note.streetAddress : note.cityStateZip} (${getOrdinal(note.lienPosition)})`;
      return `${uid ? note.fullAddress : note.cityStateZip} (${getOrdinal(note.lienPosition)} position)`;
    },
    [smallTitle, uid]
  );

  const handleChange = React.useCallback(({ target: { value } }) => setCurrentNoteId(value), []);

  React.useEffect(() => setCurrentNoteId(listing.notes[0].id), [listing.notes]);

  React.useEffect(
    () => setCurrentNote(listing.notes.find(({ id }) => id === currentNoteId)),
    [currentNoteId, listing.notes]
  );

  return (
    <Section>
      <Heading
        as="h2"
        sx={{
          fontSize: smallTitle ? 3 : 'subtitle',
          textAlign: smallTitle ? 'center' : 'left',
          mb: 3,
        }}
      >
        Note Details
      </Heading>
      {listing.notes.length > 1 && (
        <Box mb={4}>
          <Flex alignItems="center" color="blue" mb={3}>
            <InfoCircleIcon mr={1} />
            <Text> This listing contains a pool of ({listing.notes.length}) notes</Text>
          </Flex>
          <Label htmlFor="currentNoteId">View details for:</Label>
          <Select id="currentNoteId" width="auto" value={currentNoteId} onChange={handleChange}>
            {listing.notes.map((note) => (
              <option key={note.id} value={note.id}>
                {getNoteLabel(note)}
              </option>
            ))}
          </Select>
        </Box>
      )}
      {currentNote && (
        <NoteMeta
          parentWidth={mainWidth}
          note={currentNote}
          showFiles={true}
          salePrice={listing.notePrices?.[currentNote.id]}
        />
      )}
    </Section>
  );
});

NoteDetailsSection.propTypes = {};

NoteDetailsSection.defaultProps = {};

NoteDetailsSection.displayName = 'NoteDetailsSection';

export default NoteDetailsSection;
