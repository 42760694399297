export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const addToast = (message, lifespan = null, theme = 'default') => {
  return (dispatch) => {
    const id = Math.floor(Math.random() * 10);
    dispatch({ type: ADD_TOAST, payload: { id, message, theme } });
    if (lifespan) {
      setTimeout(() => {
        dispatch(removeToast(id));
      }, lifespan);
    }
  };
};

export const removeToast = (id) => {
  return { type: REMOVE_TOAST, payload: { id } };
};
