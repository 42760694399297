import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import Input from '@paperstac/ui/lib/form/Input';
import Textarea from '@paperstac/ui/lib/form/Textarea';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import { Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';

const DescriptionScreen = React.memo(() => {
  const { listing, showOverview, updateListing } = React.useContext(ManageListingOverlayContext);

  return (
    <Formik
      initialValues={{
        id: listing.id,
        title: get(listing, 'title', ''),
        sellerComments: get(listing, 'sellerComments', ''),
      }}
      onSubmit={(payload, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        updateListing(payload)
          .then(showOverview)
          .catch((error) => setStatus({ errorMessage: error.message }))
          .finally(() => setSubmitting(false));
      }}
      children={(formik) => (
        <Form>
          <DescriptionGrid
            sx={{
              bg: 'gray.1',
              border: 'none',
              borderTop: 'default',
              borderBottom: 'default',
              borderRadius: 0,
            }}
          >
            <DescriptionGridCell bg="highlight">
              <Flex alignItems="center">
                <LinkButton onClick={showOverview}>Listing</LinkButton>
                <ChevronRightIcon size={12} mx={2} color="gray.6" />
                Status
              </Flex>
            </DescriptionGridCell>
            <DescriptionGridCell term="Subtitle" as="label">
              <Field name="title" component={Input} />
            </DescriptionGridCell>
            <DescriptionGridCell term="Description" as="label">
              <Field name="sellerComments" component={Textarea} rows={10} />
            </DescriptionGridCell>
            <DescriptionGridCell>
              {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
              <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>
                Update Description
              </Button>
              <Button variant="default" onClick={showOverview}>
                Cancel
              </Button>
            </DescriptionGridCell>
          </DescriptionGrid>
        </Form>
      )}
    />
  );
});

DescriptionScreen.propTypes = {};

DescriptionScreen.defaultProps = {};

DescriptionScreen.displayName = 'DescriptionScreen';

export default DescriptionScreen;
