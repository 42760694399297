import {
  LISTING_NEGOTIATION_TYPE,
  LISTING_NEGOTIATION_TYPE_LABEL,
} from '@paperstac/firestore-collections/lib/listings';
import Button from '@paperstac/ui/lib/Button';
import DescriptionGrid from '@paperstac/ui/lib/DescriptionGrid';
import DescriptionGridCell from '@paperstac/ui/lib/DescriptionGridCell';
import ErrorText from '@paperstac/ui/lib/ErrorText';
import Flex from '@paperstac/ui/lib/Flex';
import Description from '@paperstac/ui/lib/form/Description';
import Select from '@paperstac/ui/lib/form/Select';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { object, string } from 'yup';
import { ManageListingOverlayContext } from './ManageListingOverlayProvider';
import PriceScreenMultiSection from './PriceScreenMultiSection';
import PriceScreenSingleSection from './PriceScreenSingleSection';

export const validator = () =>
  object().shape({
    message: string().required(),
  });

const PriceScreen = React.memo(() => {
  const { listing, showOverview, updateListing } = React.useContext(ManageListingOverlayContext);
  const [noteIdToEdit, setNoteIdToEdit] = React.useState(null);
  const noteToEdit = React.useMemo(
    () => (listing.isPool ? listing.notes.find(({ id }) => id === noteIdToEdit) : null),
    [listing.isPool, listing.notes, noteIdToEdit]
  );
  const [priceAtEditStart, setPriceAtEditStart] = React.useState(0);
  const startEdit = React.useCallback((noteId, currentPrice = 0) => {
    setPriceAtEditStart(currentPrice);
    setNoteIdToEdit(noteId);
  }, []);
  const stopEdit = React.useCallback(() => setNoteIdToEdit(null), []);
  const cancelEdit = React.useCallback(
    (setFieldValueHandler) => {
      setFieldValueHandler(`notePrices.${noteIdToEdit}`, priceAtEditStart);
      stopEdit();
    },
    [noteIdToEdit, priceAtEditStart, stopEdit]
  );

  return (
    <Formik
      initialValues={{
        negotiationType: listing.negotiationType || LISTING_NEGOTIATION_TYPE.BEST_OFFER,
        notePrices: listing.notePrices,
      }}
      validate={({ negotiationType, notePrices }) => {
        const errors = {};
        if (!negotiationType) errors.negotiationType = 'Negotiation Type is required';
        if (negotiationType !== LISTING_NEGOTIATION_TYPE.MAKE_OFFER) {
          Object.values(notePrices).forEach((price) => {
            if (!price || isNaN(price) || !(+price > 0)) {
              errors.notePrices = listing.isPool
                ? 'A positive price is required for each asset'
                : 'A positive price is required';
            }
          });
        }
        return errors;
      }}
      onSubmit={async (payload, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        updateListing(payload)
          .then(showOverview)
          .catch((error) => {
            setStatus({ errorMessage: error.message });
            setSubmitting(false);
          });
      }}
      children={(formik) => (
        <Form>
          <DescriptionGrid
            sx={{
              bg: 'gray.1',
              border: 'none',
              borderTop: 'default',
              borderBottom: 'default',
              borderRadius: 0,
            }}
          >
            {!noteToEdit && (
              <DescriptionGridCell bg="highlight">
                <Flex alignItems="center">
                  <LinkButton onClick={showOverview}>Listing</LinkButton>
                  <ChevronRightIcon size={12} mx={2} color="gray.6" />
                  Price
                </Flex>
              </DescriptionGridCell>
            )}
            {!!noteToEdit && (
              <DescriptionGridCell bg="highlight">
                <Flex alignItems="center">
                  <LinkButton onClick={showOverview}>Listing</LinkButton>
                  <ChevronRightIcon size={12} mx={2} color="gray.6" />
                  <LinkButton onClick={() => cancelEdit(formik.setFieldValue)}>Price</LinkButton>
                  <ChevronRightIcon size={12} mx={2} color="gray.6" />
                  Note Price
                </Flex>
              </DescriptionGridCell>
            )}
            {!noteToEdit && (
              <DescriptionGridCell term="Negotiation Type" as="label">
                <Field name="negotiationType" component={Select} width="auto" my={2}>
                  <option value={LISTING_NEGOTIATION_TYPE.BEST_OFFER}>
                    {LISTING_NEGOTIATION_TYPE_LABEL.BEST_OFFER}
                  </option>
                  <option value={LISTING_NEGOTIATION_TYPE.FIRM}>{LISTING_NEGOTIATION_TYPE_LABEL.FIRM}</option>
                  <option value={LISTING_NEGOTIATION_TYPE.MAKE_OFFER}>
                    {LISTING_NEGOTIATION_TYPE_LABEL.MAKE_OFFER}
                  </option>
                </Field>
                {formik.values.negotiationType === LISTING_NEGOTIATION_TYPE.BEST_OFFER && (
                  <Description color="darkBlue">Allow counter-offers from buyer.</Description>
                )}
                {formik.values.negotiationType === LISTING_NEGOTIATION_TYPE.FIRM && (
                  <Description color="darkBlue">Do not allow counter-offers from buyer.</Description>
                )}
                {formik.values.negotiationType === LISTING_NEGOTIATION_TYPE.MAKE_OFFER && (
                  <Description color="darkBlue">No list price. Buyer makes initial offer.</Description>
                )}
              </DescriptionGridCell>
            )}
            {formik.values.negotiationType !== LISTING_NEGOTIATION_TYPE.MAKE_OFFER && (
              <>
                {!listing.isPool && (
                  <DescriptionGridCell term="Note Price">
                    <PriceScreenSingleSection
                      currentPrice={formik.values.notePrices[listing.noteIds[0]]}
                      initialPrice={listing.notePrices[listing.noteIds[0]]}
                      note={listing.notes[0]}
                      setFieldValue={formik.setFieldValue}
                    />
                  </DescriptionGridCell>
                )}
                {listing.isPool && !noteToEdit && (
                  <DescriptionGridCell term="Note Prices">
                    <PriceScreenMultiSection
                      currentPrices={formik.values.notePrices}
                      initialPrices={listing.notePrices}
                      notes={listing.notes}
                      onEdit={(noteId) => startEdit(noteId, formik.values.notePrices[noteId])}
                    />
                  </DescriptionGridCell>
                )}
                {!!noteToEdit && (
                  <>
                    <DescriptionGridCell term="Note Price">
                      <PriceScreenSingleSection
                        currentPrice={formik.values.notePrices[noteToEdit.id]}
                        initialPrice={listing.notePrices[noteToEdit.id]}
                        note={noteToEdit}
                        setFieldValue={formik.setFieldValue}
                      />
                    </DescriptionGridCell>
                    <DescriptionGridCell>
                      {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
                      <Button variant="primary" mr={2} onClick={stopEdit}>
                        Update Note Price
                      </Button>
                      <Button variant="default" onClick={() => cancelEdit(formik.setFieldValue)}>
                        Cancel
                      </Button>
                    </DescriptionGridCell>
                  </>
                )}
              </>
            )}
            {!noteToEdit && (
              <DescriptionGridCell>
                <ErrorMessage name="notePrices" component={ErrorText} mb={3} />
                {get(formik, 'status.errorMessage') && <ErrorText mb={3}>{formik.status.errorMessage}</ErrorText>}
                <Button type="submit" variant="primary" busy={formik.isSubmitting} mr={2}>
                  Update Pricing
                </Button>
                <Button variant="default" onClick={showOverview}>
                  Cancel
                </Button>
              </DescriptionGridCell>
            )}
          </DescriptionGrid>
        </Form>
      )}
    />
  );
});

PriceScreen.propTypes = {};

PriceScreen.defaultProps = {};

PriceScreen.displayName = 'PriceScreen';

export default PriceScreen;
