import { lastCharacter } from './string';
var numberToWords = require('number-to-words');
export var centsToWords = function (amount) {
    var dollars = Math.floor(amount / 100);
    var cents = amount.toString().slice(-2);
    return "".concat(numberToWords.toWords(dollars), " dollars and ").concat(numberToWords.toWords(cents), " cents");
};
export var getOrdinal = function (i) {
    var j = i % 10, k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
};
export var formattedNumber = function (value, currency) {
    if (typeof value !== 'number')
        return '';
    var options = currency ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : {};
    return new Intl.NumberFormat('en-US', options).format(value);
};
export var formattedBytes = function (bytes, decimals) {
    if (decimals === void 0) { decimals = 1; }
    if (bytes === 0)
        return '0 Bytes';
    var k = 1024;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(i > 1 ? dm : 0)) + ' ' + sizes[i];
};
export var stripNonNumeric = function (value) { return value.replace(/[^\d.]/g, ''); };
export var onlyDigits = function (value) { return value && "".concat(value).replace(/[^\d]/g, ''); };
export var usdToCents = function (usd) {
    return Math.round(100 * parseFloat(stripNonNumeric("".concat(usd))));
};
export var centsToUsd = function (cents) {
    return cents / 100;
};
export var centsToUsdString = function (cents, showCents, onlyNumbers) {
    if (showCents === void 0) { showCents = true; }
    if (onlyNumbers === void 0) { onlyNumbers = false; }
    if (typeof cents === 'undefined')
        return '';
    var formattedUsd = cents < 0
        ? "-$".concat(((cents / 100) * -1).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'))
        : "$".concat((cents / 100).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'));
    return [formattedUsd]
        .map(function (usd) { return (showCents ? usd : usd.split('.')[0]); })
        .map(function (usd) { return (onlyNumbers ? stripNonNumeric(usd) : usd); })[0];
};
export var thousandUsdToCentsString = function (value) {
    if (!value)
        return '';
    var suffix = lastCharacter(value) === '.' ? '.' : '';
    var cents = +value.replace(/[^\d.]/g, '') * 100000;
    return "".concat(cents).concat(suffix);
};
export var centsToThousandUsdString = function (value, precision, onlyNumbers) {
    if (precision === void 0) { precision = 1; }
    if (onlyNumbers === void 0) { onlyNumbers = false; }
    if (!value)
        return '';
    var suffix = lastCharacter(value) === '.' && precision ? '.' : '';
    var thousandUsd = +(value / 100000).toFixed(precision);
    return onlyNumbers ? "".concat(thousandUsd).concat(suffix) : "$".concat(thousandUsd).concat(suffix, "K");
};
export var centsToMillionUsdString = function (value, precision, onlyNumbers) {
    if (precision === void 0) { precision = 1; }
    if (onlyNumbers === void 0) { onlyNumbers = false; }
    if (!value)
        return '';
    var suffix = lastCharacter(value) === '.' && precision ? '.' : '';
    var millionUsd = +(value / 100000000).toFixed(precision);
    return onlyNumbers ? "".concat(millionUsd).concat(suffix) : "$".concat(millionUsd).concat(suffix, "M");
};
export var centsToShortUsdString = function (value, precision, onlyNumbers) {
    if (precision === void 0) { precision = 1; }
    if (onlyNumbers === void 0) { onlyNumbers = false; }
    return value > 99999999
        ? centsToMillionUsdString(value, precision, onlyNumbers)
        : centsToThousandUsdString(value, precision, onlyNumbers);
};
export var round = function (number, precision) {
    var shift = function (number, precision, reverseShift) {
        if (reverseShift) {
            precision = -precision;
        }
        var numArray = ('' + number).split('e');
        return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision));
    };
    return shift(Math.round(shift(number, precision, false)), precision, true);
};
