import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import Text from '@paperstac/ui/lib/Text';
import PropTypes from 'prop-types';
import React from 'react';

const NoteMetaGroup = React.memo(({ items, title, subtitle, ...props }) => {
  return (
    <Box {...props}>
      <Heading
        sx={{
          mb: 2,
          fontSize: 2,
          textTransform: 'uppercase',
        }}
      >
        {title}
      </Heading>
      {!!subtitle && <Box sx={{ mb: 2, fontSize: 1, color: 'darkBlue', fontWeight: 'bold' }}>{subtitle}</Box>}
      {items.map((item, i) => (
        <Flex key={i} fontSize={1} justifyContent="space-between">
          <Box>{item.label}</Box>
          <Text variant="bold" textAlign="right">
            {item.value}
          </Text>
        </Flex>
      ))}
    </Box>
  );
});

NoteMetaGroup.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

NoteMetaGroup.defaultProps = {};

NoteMetaGroup.displayName = 'NoteMetaGroup';

export default NoteMetaGroup;
