export const SET_COLUMN_SETTINGS = 'SET_COLUMN_SETTINGS';
export const SET_MANAGE_NOTES_COLUMN_SETTINGS = 'SET_MANAGE_NOTES_COLUMN_SETTINGS';
export const SET_VIEW_NOTES_COLUMN_SETTINGS = 'SET_VIEW_NOTES_COLUMN_SETTINGS';

export const setColumnSettings = (key, { settings, columns }) => {
  return { type: SET_COLUMN_SETTINGS, payload: { key, settings, columns } };
};

export const setManageNotesColumnSettings = ({ settings, columns }) => {
  return { type: SET_MANAGE_NOTES_COLUMN_SETTINGS, payload: { settings, columns } };
};

export const setViewNotesColumnSettings = ({ settings, columns }) => {
  return { type: SET_VIEW_NOTES_COLUMN_SETTINGS, payload: { settings, columns } };
};
