import React from 'react';
import ListingOverlay from './ListingOverlay';

const ListingDetailsContext = React.createContext();
const { Provider } = ListingDetailsContext;

const ListingDetailsProvider = React.memo(({ children }) => {
  const [listingId, setListingId] = React.useState(null);

  const showListing = React.useCallback((listingId) => setListingId(listingId), []);
  const hideListing = React.useCallback(() => setListingId(null), []);

  const value = React.useMemo(() => ({ showListing }), [showListing]);

  return (
    <Provider value={value}>
      {children}
      {!!listingId && <ListingOverlay listingId={listingId} onClose={hideListing} />}
    </Provider>
  );
});

ListingDetailsProvider.propTypes = {};

ListingDetailsProvider.defaultProps = {};

ListingDetailsProvider.displayName = 'ListingDetailsProvider';

export default ListingDetailsProvider;

export { ListingDetailsContext };
