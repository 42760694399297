import Overlay from '@paperstac/ui/lib/Overlay';
import OverlayCloseButton from '@paperstac/ui/lib/OverlayCloseButton';
import OverlayContent from '@paperstac/ui/lib/OverlayContent';
import PageTitle from '@paperstac/ui/lib/PageTitle';
import PropTypes from 'prop-types';
import React from 'react';
import PrivacyPolicyContent from './PrivacyPolicyContent';

const PrivacyPolicyOverlay = React.memo((props) => (
  <Overlay onClose={props.onClose}>
    <OverlayCloseButton />
    <OverlayContent maxWidth={800}>
      <PageTitle>Privacy Policy</PageTitle>
      <PrivacyPolicyContent />
    </OverlayContent>
  </Overlay>
));

PrivacyPolicyOverlay.propTypes = {
  onClose: PropTypes.func.isRequired,
};

PrivacyPolicyOverlay.defaultProps = {};

PrivacyPolicyOverlay.displayName = 'PrivacyPolicyOverlay';

export default PrivacyPolicyOverlay;
