import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import AvailabilityFilter from './AvailabiltyFilter';
import Box from '@paperstac/ui/lib/Box';
import ChevronDownIcon from '@paperstac/ui/lib/icons/ChevronDownIcon';
import ChevronUpIcon from '@paperstac/ui/lib/icons/ChevronUpIcon';
import UnstyledButton from '@paperstac/ui/lib/UnstyledButton';
import React from 'react';
import BaseFilterContainer from './BaseFilterContainer';
import FilterTitle from './FilterTitle';
import InterestRateFilter from './InterestRateFilter';
import InvestmentToBalanceFilter from './InvestmentToBalanceFilter';
import InvestmentToValueFilter from './InvestmentToValueFilter';
import LegalStatusFilter from './LegalStatusFilter';
import LienPositionFilter from './LienPositionFilter';
import ListingTypeFilter from './ListingTypeFilter';
import ListPriceFilter from './ListPriceFilter';
import LoanToValueFilter from './LoanToValueFilter';
import NoteTypeFilter from './NoteTypeFilter';
import PaymentsRemainingFilter from './PaymentsRemainingFilter';
import PerformanceFilter from './PerformanceFilter';
import PropertyTypeFilter from './PropertyTypeFilter';
import PropertyValueFilter from './PropertyValueFilter';
import SavedSearches from './SavedSearches';
import SellerFilter from './SellerFilter';
import StarredFilter from './StarredFilter';
import StateClassificationsFilter from './StateClassificationsFilter';
import StateFilter from './StateFilter';
import UnpaidPrincipalBalanceFilter from './UnpaidPrincipalBalanceFilter';

const Filters = React.memo((props) => {
  const { uid } = React.useContext(IdentityContext);
  const [showSavedSearches, setShowSavedSearches] = React.useState(false);
  const showNonGuestFilters = !!uid;
  return (
    <Box {...props}>
      {showSavedSearches && (
        <>
          <SavedSearchToggleButton isOpen={true} onClick={() => setShowSavedSearches(false)} borderBottom="none" />
          <SavedSearches />
        </>
      )}
      <Box display={showSavedSearches ? 'none' : 'block'}>
        {showNonGuestFilters && (
          <SavedSearchToggleButton isOpen={false} onClick={() => setShowSavedSearches(true)} borderBottom="default" />
        )}
        {showNonGuestFilters && <StarredFilter />}
        <AvailabilityFilter />
        <ListingTypeFilter />
        <LienPositionFilter />
        <PerformanceFilter />
        <NoteTypeFilter />
        <StateFilter />
        <SellerFilter sx={{ display: !!uid ? 'block' : 'none' }} />
        <ListPriceFilter />
        <PropertyValueFilter />
        <PropertyTypeFilter />
        <UnpaidPrincipalBalanceFilter />
        <InterestRateFilter />
        <InvestmentToBalanceFilter />
        <InvestmentToValueFilter />
        <LoanToValueFilter />
        <LegalStatusFilter />
        <PaymentsRemainingFilter />
        <StateClassificationsFilter />
      </Box>
    </Box>
  );
});

const SavedSearchToggleButton = ({ borderBottom, isOpen, onClick }) => (
  <BaseFilterContainer
    sx={{
      py: 0,
      borderBottom,
    }}
  >
    <UnstyledButton
      onClick={onClick}
      sx={{
        display: 'flex',
        width: '100%',
        py: 3,
        cursor: 'pointer',
        '&:hover': {
          color: 'black',
        },
      }}
    >
      <FilterTitle mb={0} flex={1}>
        My Saved Searches
      </FilterTitle>
      {isOpen && <ChevronUpIcon sx={{ color: 'gray.5' }} />}
      {!isOpen && <ChevronDownIcon sx={{ color: 'gray.5' }} />}
    </UnstyledButton>
  </BaseFilterContainer>
);

Filters.propTypes = {};

Filters.defaultProps = {};

Filters.displayName = 'Filters';

export default Filters;
