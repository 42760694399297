import Box from '@paperstac/ui/lib/Box';
import React from 'react';

const Section = React.memo((props) => <Box sx={{ py: 5, px: 4, borderBottom: 'default' }} {...props} />);

Section.propTypes = {};

Section.defaultProps = {};

Section.displayName = 'Section';

export default Section;
