import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { REM, SPACER } from '../../../theme';
import BlogMediaBox from '../../BlogMediaBox';
import BlogPostAuthorMikeMcLin from '../../BlogPostAuthorMikeMcLin';
import BlogPostLayout from '../../BlogPostLayout';
import CloudinaryImage from '../../CloudinaryImage';

export const metadata = {
  postTitle: 'Sell to Your Private Network With Paperstac Communities',
  metaTitle: 'Sell to Your Private Network With Paperstac Communities',
  metaDescription:
    'Paperstac Communities allow you to carve out your own private section of the Paperstac marketplace for you and your network of investors.',
  slug: 'sell-to-your-private-network-with-paperstac-communities',
  publishDate: '2018-10-31 10:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1540995045/website-assets/sell-to-your-private-network-with-paperstac-communities-feature.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1540995045/website-assets/sell-to-your-private-network-with-paperstac-communities-social.png',
  tags: ['communities', 'how-to'],
};

const TitleComponent = () => (
  <h1 style={{ marginTop: 0, marginBottom: SPACER(2) }}>
    <CloudinaryImage
      url="https://res.cloudinary.com/paperstac/image/upload/v1540995045/website-assets/sell-to-your-private-network-with-paperstac-communities-hero.png"
      alt={metadata.postTitle}
    />
  </h1>
);

const SellToYourPrivateNetworkWithPaperstacCommunities = () => (
  <BlogPostLayout metadata={metadata} AuthorComponent={BlogPostAuthorMikeMcLin} TitleComponent={TitleComponent}>
    <p>
      Paperstac Communities allow you to carve out your own private section of the Paperstac marketplace for you and
      your network of investors. You and your community will be able to buy and sell mortgage notes with each other in
      private. Only community members will be able to view and bid on mortgage notes listed in a community.
    </p>

    <h2 style={{ marginTop: SPACER(2) }}>How It Works</h2>

    <h3>Create a Community</h3>

    <p>First you need to create a community.</p>

    <BlogMediaBox>
      <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) =>
          isVisible ? (
            <video style={{ width: REM(720), height: REM(356) }} autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1540988431/website-assets/create-a-community.webm"
                type="video/webm"
              />
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1540988431/website-assets/create-a-community.mp4"
                type="video/mp4"
              />
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1540988431/website-assets/create-a-community.ogv"
                type="video/ogg"
              />
            </video>
          ) : (
            <div style={{ width: REM(720), height: REM(356) }}>&nbsp;</div>
          )
        }
      </VisibilitySensor>
    </BlogMediaBox>

    <h3 style={{ marginTop: SPACER(2) }}>List Mortgage Notes in Your Community</h3>

    <p>
      Then you can create either a <em>"Single-Asset"</em> or <em>"Asset Pool"</em> listing, and change the "Buyer
      Audience" to your community.
    </p>

    <BlogMediaBox>
      <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) =>
          isVisible ? (
            <video style={{ width: REM(720), height: REM(486) }} autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1540988436/website-assets/list-in-a-community.webm"
                type="video/webm"
              />
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1540988436/website-assets/list-in-a-community.mp4"
                type="video/mp4"
              />
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1540988436/website-assets/list-in-a-community.ogv"
                type="video/ogg"
              />
            </video>
          ) : (
            <div style={{ width: REM(720), height: REM(486) }}>&nbsp;</div>
          )
        }
      </VisibilitySensor>
    </BlogMediaBox>

    <h3 style={{ marginTop: SPACER(2) }}>Invite People to Your Community</h3>

    <p>
      A community isn't much fun until you have a network of investors in it. Paperstac provides an invite system for
      the community owner. The owner can send and retract invites to their community.
    </p>

    <BlogMediaBox>
      <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) =>
          isVisible ? (
            <video style={{ width: REM(720), height: REM(486) }} autoPlay loop muted playsInline>
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1540988438/website-assets/invite-to-a-community.webm"
                type="video/webm"
              />
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1540988438/website-assets/invite-to-a-community.mp4"
                type="video/mp4"
              />
              <source
                src="https://res.cloudinary.com/paperstac/video/upload/q_auto:best/v1540988438/website-assets/invite-to-a-community.ogv"
                type="video/ogg"
              />
            </video>
          ) : (
            <div style={{ width: REM(720), height: REM(486) }}>&nbsp;</div>
          )
        }
      </VisibilitySensor>
    </BlogMediaBox>

    <h3 style={{ marginTop: SPACER(2) }}>Your Private Investor Network Is Complete!</h3>

    <p>
      That's it! Now the mortgage note listings in the community will only be visible to community members, and your
      community has the ability to trade in private with your own personal slice of Paperstac.
    </p>

    <h2 style={{ marginTop: SPACER(2) }}>What's Next?</h2>

    <p>
      We are introducing Paperstac Communities today, but we will be enhancing the feature over the next couple of weeks
      based on your feedback. We already have a few features on our roadmap:
    </p>

    <ul style={{ paddingLeft: SPACER() }}>
      <li>Allow community owner to add a transaction fee to deals done within a community.</li>
      <li>Introduce a public listing of communities where users will be able to submit request to become a member.</li>
      <li>Allow community owner to give seller privileges to other community members.</li>
      <li>And much more!</li>
    </ul>
  </BlogPostLayout>
);

export default SellToYourPrivateNetworkWithPaperstacCommunities;
