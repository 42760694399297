import React from 'react';
import report from './report';
import Section from './Section';
import StatGrid from './StatGrid';

const Itb = React.memo((props) => {
  return (
    <Section title="Investment to Balance" subtitle="(sale price / unpaid principal balance)" bg="white">
      <StatGrid stat1={report.itb[0]} stat2={report.itb[1]} stat3={report.itb[2]} stat4={report.itb[3]} />
    </Section>
  );
});

Itb.propTypes = {};

Itb.defaultProps = {};

Itb.displayName = 'Itb';

export default Itb;
