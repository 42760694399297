import Box from '@paperstac/ui/lib/Box';
import LinkButton from '@paperstac/ui/lib/LinkButton';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';

const NeedsAttentionList = React.memo(({ reasons, ...props }) => {
  const [reasonsVisible, setReasonsVisible] = React.useState(false);
  const toggleReasons = React.useCallback(() => setReasonsVisible(!reasonsVisible), [reasonsVisible]);

  if (!reasons || !reasons.length) return null;

  if (reasons.length === 1)
    return (
      <Box fontSize={1} fontWeight="bold" color="red" {...props}>
        {reasons[0]}
      </Box>
    );

  return (
    <Box fontSize={1} {...props}>
      <Box>
        <Text
          sx={{
            display: 'inline',
            color: 'red',
            fontWeight: 'bold',
          }}
        >
          Needs Attention
        </Text>{' '}
        (<LinkButton onClick={toggleReasons}>{reasonsVisible ? 'Hide' : 'Show'} reasons</LinkButton>)
      </Box>
      {reasonsVisible && (
        <Box color="red" mt={2}>
          {reasons.join(', ')}
        </Box>
      )}
    </Box>
  );
});

NeedsAttentionList.propTypes = {};

NeedsAttentionList.defaultProps = {};

NeedsAttentionList.displayName = 'NeedsAttentionList';

export default NeedsAttentionList;
