export const SHOW_NOTE_FILES_OVERLAY = 'SHOW_NOTE_FILES_OVERLAY';
export const HIDE_NOTE_FILES_OVERLAY = 'HIDE_NOTE_FILES_OVERLAY';

export const SHOW_NOTE_HISTORY_OVERLAY = 'SHOW_NOTE_HISTORY_OVERLAY';
export const HIDE_NOTE_HISTORY_OVERLAY = 'HIDE_NOTE_HISTORY_OVERLAY';

export const SHOW_NOTE_LISTING_OVERLAY = 'SHOW_NOTE_LISTING_OVERLAY';
export const HIDE_NOTE_LISTING_OVERLAY = 'HIDE_NOTE_LISTING_OVERLAY';

export const SHOW_NOTE_POOL_LISTING_OVERLAY = 'SHOW_NOTE_POOL_LISTING_OVERLAY';
export const HIDE_NOTE_POOL_LISTING_OVERLAY = 'HIDE_NOTE_POOL_LISTING_OVERLAY';

export const SHOW_NOTE_PHOTOS_OVERLAY = 'SHOW_NOTE_PHOTOS_OVERLAY';
export const HIDE_NOTE_PHOTOS_OVERLAY = 'HIDE_NOTE_PHOTOS_OVERLAY';

export const showNoteFilesOverlay = () => {
  return { type: SHOW_NOTE_FILES_OVERLAY };
};

export const hideNoteFilesOverlay = () => {
  return { type: HIDE_NOTE_FILES_OVERLAY };
};

export const showNoteHistoryOverlay = () => {
  return { type: SHOW_NOTE_HISTORY_OVERLAY };
};

export const hideNoteHistoryOverlay = () => {
  return { type: HIDE_NOTE_HISTORY_OVERLAY };
};

export const showNoteListingOverlay = () => {
  return { type: SHOW_NOTE_LISTING_OVERLAY };
};

export const hideNoteListingOverlay = () => {
  return { type: HIDE_NOTE_LISTING_OVERLAY };
};

export const showNotePoolListingOverlay = () => {
  return { type: SHOW_NOTE_POOL_LISTING_OVERLAY };
};

export const hideNotePoolListingOverlay = () => {
  return { type: HIDE_NOTE_POOL_LISTING_OVERLAY };
};

export const showNotePhotosOverlay = () => {
  return { type: SHOW_NOTE_PHOTOS_OVERLAY };
};

export const hideNotePhotosOverlay = () => {
  return { type: HIDE_NOTE_PHOTOS_OVERLAY };
};
