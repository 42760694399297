import { AccountsContext } from '@paperstac/common/lib/components/AccountsProvider';
import Avatar from '@paperstac/ui/lib/Avatar';
import Box from '@paperstac/ui/lib/Box';
import Flex from '@paperstac/ui/lib/Flex';
import ChevronRightIcon from '@paperstac/ui/lib/icons/ChevronRightIcon';
import ListGroup from '@paperstac/ui/lib/ListGroup';
import ListGroupItem from '@paperstac/ui/lib/ListGroupItem';
import React from 'react';
import { IdentityManagerContext } from '../IdentityManager';
import ViewTitle from './ViewTitle';

const ChooseAccountView = React.memo((props) => {
  const { currentAccountId, setCurrentAccountId, accounts } = React.useContext(AccountsContext);
  const { hideAccountChooser } = React.useContext(IdentityManagerContext);

  const handleChoice = React.useCallback(
    (accountId) => {
      setCurrentAccountId(accountId);
      hideAccountChooser();
    },
    [setCurrentAccountId, hideAccountChooser]
  );

  return (
    <Box>
      <ViewTitle>Choose an Account</ViewTitle>
      <ListGroup>
        {accounts.map((account) => (
          <ListGroupItem
            key={account.id}
            as="button"
            onClick={() => handleChoice(account.id)}
            variant={currentAccountId === account.id ? 'active' : 'default'}
            py={3}
            px={3}
            style={{ outline: 'none' }}
          >
            <Flex alignItems="center">
              <Box>
                <Avatar src={account.avatar} alt={account.displayName} size={30} display="block" mr={2} />
              </Box>
              <Box flex={1} fontSize={1}>
                {account.displayName}
              </Box>
              <Box>
                <ChevronRightIcon />
              </Box>
            </Flex>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Box>
  );
});

ChooseAccountView.propTypes = {};

ChooseAccountView.defaultProps = {};

ChooseAccountView.displayName = 'ChooseAccountView';

export default ChooseAccountView;
