import { PAPERSTAC_AVATAR, TRANSACTION_EVENT_VIA_WEBSITE } from '@paperstac/constants';
import dateid from '@paperstac/helpers/lib/dateid';
import getPublicProfile from '@paperstac/helpers/lib/getPublicProfile';
import { array, mixed, number, object, string } from 'yup';
import { NOTE_TYPE } from './accountNotes';
import { transactionByIdRef } from './transactions';
export var transactionEventsRef = function transactionEventsRef(transactionId) {
  if (!transactionId) console.log('transactionEventsRef() was not given a "transactionId".  Returning null...');
  return transactionId ? transactionByIdRef(transactionId).collection('events') : null;
};
export var transactionEventByIdRef = function transactionEventByIdRef(transactionId, transactionEventId) {
  return transactionId && transactionEventId ? transactionEventsRef(transactionId).doc(transactionEventId) : null;
};
export var transactionEventsByTypeRef = function transactionEventsByTypeRef(transactionId, type) {
  return transactionId ? transactionEventsRef(transactionId).where('type', '==', type) : null;
};
export var dispatchSystemEvent = function dispatchSystemEvent(manager, transactionRef, event) {
  event.id = event.id || dateid();
  event.transactionId = transactionRef.id;
  event.author = getPublicProfile({
    id: 0,
    displayName: 'Paperstac',
    avatar: PAPERSTAC_AVATAR
  });
  event.via = TRANSACTION_EVENT_VIA_WEBSITE;
  event.createdDate = manager.now;
  return manager.set(transactionEventByIdRef(event.transactionId, event.id), event);
};
export var TRANSACTION_EVENT_TYPE = {
  ABANDON: 'ABANDON',
  ACCEPT_PRICE: 'ACCEPT_PRICE',
  ADD_PHONE_REQUEST: 'ADD_PHONE_REQUEST',
  APPROVE_CLOSING: 'APPROVE_CLOSING',
  APPROVED_CUSTOM_DOCUMENT: 'APPROVED_CUSTOM_DOCUMENT',
  AUDITOR_NOTIFIED_TO_SHIP: 'AUDITOR_NOTIFIED_TO_SHIP',
  AUDITOR_NOTIFIED_TO_TRANSFER: 'AUDITOR_NOTIFIED_TO_TRANSFER',
  AUDITOR_SHIPPED_COLLATERAL: 'AUDITOR_SHIPPED_COLLATERAL',
  AUDITS_COMPLETE: 'AUDITS_COMPLETE',
  AUDITS_ORDERED: 'AUDITS_ORDERED',
  BUYER_APPROVED_COLLATERAL: 'BUYER_APPROVED_COLLATERAL',
  COLLATERAL_ALREADY_AT_PARTNER: 'COLLATERAL_ALREADY_AT_PARTNER',
  COLLATERAL_STAYING_WITH_PARTNER: 'COLLATERAL_STAYING_WITH_PARTNER',
  COMPLETE: 'COMPLETE',
  COUNTER_OFFER: 'COUNTER_OFFER',
  CREATED_FROM_LISTING: 'CREATED_FROM_LISTING',
  DELETE_SHARED_FILE: 'DELETE_SHARED_FILE',
  DOCUMENT_GENERATED: 'DOCUMENT_GENERATED',
  ESCROW_DISBURSEMENT: 'ESCROW_DISBURSEMENT',
  ESCROW_NOTIFIED_TO_DISBURSE: 'ESCROW_NOTIFIED_TO_DISBURSE',
  ESCROW_ORDERED: 'ESCROW_ORDERED',
  EXECUTED_DOCUMENT: 'EXECUTED_DOCUMENT',
  EXPORTED_TRANSACTION: 'EXPORTED_TRANSACTION',
  FUNDING_APPROVAL_CHANGED: 'FUNDING_APPROVAL_CHANGED',
  FUNDING_FILE_PROVIDED: 'FUNDING_FILE_PROVIDED',
  FUNDING_MESSAGE: 'FUNDING_MESSAGE',
  IMPORTED_TRANSACTION: 'IMPORTED_TRANSACTION',
  INVOICE_COMPLETE: 'INVOICE_COMPLETE',
  MAKE_OFFER: 'MAKE_OFFER',
  MESSAGE: 'MESSAGE',
  MODIFY_CLOSING_SCHEDULE: 'MODIFY_CLOSING_SCHEDULE',
  MODIFY_CUTOFF_DATE: 'MODIFY_CUTOFF_DATE',
  NOTE_ADDED: 'NOTE_ADDED',
  NOTE_BLOCKED: 'NOTE_BLOCKED',
  NOTE_REMOVED: 'NOTE_REMOVED',
  NOTE_UNBLOCKED: 'NOTE_UNBLOCKED',
  NUDGE: 'NUDGE',
  OVERRIDE_TRANSACTION_TODO: 'OVERRIDE_TRANSACTION_TODO',
  PAID_FEES: 'PAID_FEES',
  PAID_INVOICE: 'PAID_INVOICE',
  PHONE_CALL: 'PHONE_CALL',
  PROVIDED_BANK_ACCOUNT: 'PROVIDED_BANK_ACCOUNT',
  PROVIDED_SHIPPING_ADDRESS: 'PROVIDED_SHIPPING_ADDRESS',
  REMOVED_CUSTOM_DOCUMENT: 'REMOVED_CUSTOM_DOCUMENT',
  ROLLBACK_TO_NEGOTIATIONS: 'ROLLBACK_TO_NEGOTIATIONS',
  SELLER_PROVIDED_SHIPPING_INFO: 'SELLER_PROVIDED_SHIPPING_INFO',
  SELLER_PSA_SIGNATURE_EXPIRED: 'SELLER_PSA_SIGNATURE_EXPIRED',
  SELLER_SHIPPED_COLLATERAL: 'SELLER_SHIPPED_COLLATERAL',
  SERVICERS_NOTIFIED_ABOUT_TRANSFER: 'SERVICERS_NOTIFIED_ABOUT_TRANSFER',
  SERVICING_INFORMATION_PROVIDED: 'SERVICING_INFORMATION_PROVIDED',
  SHARE_FILE: 'SHARE_FILE',
  SHIPMENT_DELIVERED: 'SHIPMENT_DELIVERED',
  SHIPPING_LABEL_GENERATED: 'SHIPPING_LABEL_GENERATED',
  TRANSFERRED_COLLATERAL: 'TRANSFERRED_COLLATERAL',
  UPB_CHANGED: 'UPB_CHANGED',
  UPB_VERIFIED: 'UPB_VERIFIED',
  UPDATED_CLOSING_DATA: 'UPDATED_CLOSING_DATA',
  UPLOADED_CUSTOM_DOCUMENT: 'UPLOADED_CUSTOM_DOCUMENT',
  VESTING_INFORMATION_PROVIDED: 'VESTING_INFORMATION_PROVIDED',
  VOICEMAIL: 'VOICEMAIL'
};
export var TRANSACTION_EVENT_VIA = {
  WEBSITE: 'website',
  EMAIL: 'email'
};
var signer = function signer() {
  return object().shape({
    vestingProfileId: string().length(22).required(),
    name: string().required(),
    title: string()
  });
};
export var closingDataValidator = function closingDataValidator() {
  return object().shape({
    allongeSigners: array().of(signer()).min(1).required(),
    apnParcelNumber: string().when('noteType', {
      is: function is(val) {
        return [NOTE_TYPE.DEED_OF_TRUST, NOTE_TYPE.MORTGAGE].includes(val);
      },
      then: string().required(),
      otherwise: string()
    }),
    assignmentSigners: array().of(signer()).min(1).required(),
    book: string(),
    borrowerName: string().required(),
    buyingEntityAddress: string().required(),
    buyingEntityName: string().required(),
    instrumentNumber: string().when('noteType', {
      is: function is(val) {
        return val !== NOTE_TYPE.CFD;
      },
      then: string().required(),
      otherwise: string()
    }),
    lastRecordingDate: string().when('noteType', {
      is: function is(val) {
        return val !== NOTE_TYPE.CFD;
      },
      then: string().matches(/\d{4}-\d{2}-\d{2}/).required(),
      otherwise: string()
    }),
    legalDescription: string().required(),
    lienPosition: number().positive().integer().min(1).max(10).required(),
    noteType: mixed().oneOf(Object.values(NOTE_TYPE)).required(),
    originalBalance: number().positive().integer().required(),
    originalLender: string().required(),
    originationDate: string().matches(/\d{4}-\d{2}-\d{2}/).required(),
    pages: string(),
    propertyCounty: string().required(),
    propertyFullAddress: string().required(),
    sellingEntityAddress: string().required(),
    sellingEntityName: string().required()
  });
};