var noop = function noop() {
  console.log('performanceMock is installed');
};
var traceMock = {
  getAttribute: noop,
  getAttributes: noop,
  getMetric: noop,
  incrementMetric: noop,
  putAttribute: noop,
  putMetric: noop,
  record: noop,
  removeAttribute: noop,
  start: noop,
  stop: noop
};
var performanceMock = {
  trace: function trace() {
    return traceMock;
  }
};
var performance = performanceMock;
export var setPerformance = function setPerformance(perf) {
  performance = perf;
};
export var getPerformance = function getPerformance() {
  return performance;
};