import { firestore } from '../services/firebaseClient';
import dateid from '../utils/dateid';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const SET_RESTORE_POINTS = 'SET_RESTORE_POINTS';

const unsubscribes = {};

const transformCollection = (querySnapshot) => {
  return querySnapshot.docs.map(transformDoc);
};

const transformDoc = transformFirestoreTimestamps;

export const setRestorePoints = (payload) => {
  return { type: SET_RESTORE_POINTS, payload };
};

export const subscribeRestorePoints = () => {
  return (dispatch) => {
    if (unsubscribes.restorePoints) {
      unsubscribes.restorePoints();
    }
    unsubscribes.restorePoints = firestore
      .collection('restorePoints')
      .orderBy('createdDate', 'desc')
      .limit(100)
      .onSnapshot((querySnapshot) => {
        dispatch(setRestorePoints(transformCollection(querySnapshot)));
      });
  };
};

export const unsubscribeRestorePoints = () => {
  return (dispatch) => {
    if (unsubscribes.restorePoints) {
      unsubscribes.restorePoints();
      unsubscribes.restorePoints = null;
    }
  };
};

export const createRestorePointRequest = () => {
  const payload = { id: dateid() };
  return (dispatch) => {
    return firestore.doc(`restorePoints/${payload.id}`).set(payload);
  };
};

export const updateRestorePointRequest = (payload) => {
  return (dispatch) => {
    return firestore.doc(`restorePoints/${payload.id}`).update(payload);
  };
};
