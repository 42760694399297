import firestore from '@paperstac/firebase/lib/firestore';
import useFirestoreSubscribe from '@paperstac/hooks/lib/useFirestoreSubscribe';
import * as Yup from 'yup';
export var accountsRef = function accountsRef() {
  return firestore.collection('accounts');
};
export var sellersRef = function sellersRef() {
  return accountsRef().where('completedSellerOnboarding', '==', true);
};
export var accountByIdRef = function accountByIdRef(accountId) {
  if (!accountId) console.log('accountByIdRef() was not given an "accountId".  Returning null...');
  return accountId ? firestore.doc("accounts/".concat(accountId)) : null;
};
export var profileByIdRef = function profileByIdRef(accountId) {
  return accountId ? firestore.doc("accounts/".concat(accountId, "/profiles/").concat(accountId)) : null;
};
export var teamsByMemberIdRef = function teamsByMemberIdRef(accountId) {
  return accountId ? firestore.collection('accounts').where("members.".concat(accountId, ".id"), '==', accountId) : null;
};
export var accountsByIdentityIdRef = function accountsByIdentityIdRef(identityId) {
  return identityId ? firestore.collection('accounts').where("members.".concat(identityId, ".id"), '==', identityId) : null;
};
export var accountsByStripeCustomerIdQuery = function accountsByStripeCustomerIdQuery(stripeCustomerId) {
  return stripeCustomerId ? firestore.collection('accounts').where('stripeCustomerId', '==', stripeCustomerId) : null;
};
export var vestingProfileByIdRef = function vestingProfileByIdRef(accountId, vestingProfileId) {
  return accountId ? firestore.doc("accounts/".concat(accountId, "/vestingProfiles/").concat(vestingProfileId)) : null;
};
export var vestingProfilesBySdiraIntegrationIdRef = function vestingProfilesBySdiraIntegrationIdRef(accountId, sdiraIntegrationId) {
  return accountId ? firestore.collection("accounts/".concat(accountId, "/vestingProfiles")).where("sdiraIntegrationId", '==', sdiraIntegrationId) : null;
};
export var createAccountDispatchValidator = function createAccountDispatchValidator() {
  return Yup.object().shape({
    avatar: Yup.string().nullable(),
    displayName: Yup.string().required(),
    isBroker: Yup["boolean"]().required(),
    phone: Yup.string()
  });
};
export var removeAccountMemberValidator = function removeAccountMemberValidator() {
  return Yup.object().shape({
    accountId: Yup.string().required(),
    memberId: Yup.string().required()
  });
};
export var updateAccountDispatchValidator = function updateAccountDispatchValidator() {
  return Yup.object().shape({
    avatar: Yup.string(),
    id: Yup.string().required(),
    ownerId: Yup.string(),
    displayName: Yup.string(),
    isBroker: Yup["boolean"](),
    phone: Yup.string()
  });
};
export var verifyAccountPhoneValidator = function verifyAccountPhoneValidator() {
  return Yup.object().shape({
    accountId: Yup.string().required(),
    code: Yup.string().required()
  });
};
export var sendAccountInviteValidator = function sendAccountInviteValidator() {
  return Yup.object().shape({
    accountId: Yup.string().required(),
    email: Yup.string().email().required()
  });
};
export var deleteAccountInviteValidator = sendAccountInviteValidator;
export var acceptAccountInviteValidator = function acceptAccountInviteValidator() {
  return Yup.object().shape({
    actionToken: Yup.string().required()
  });
};
export var useAccount = function useAccount(accountId) {
  return useFirestoreSubscribe(accountByIdRef(accountId), [accountId]);
};
export var useIdentityAccounts = function useIdentityAccounts(identityId) {
  return useFirestoreSubscribe(accountsByIdentityIdRef(identityId), [identityId]);
};