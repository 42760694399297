import { post } from './api';
import { auth } from './firebaseClient';

export const register = async (payload) => {
  return post('register', payload);
};

export const login = async (email, password) => {
  try {
    const { user } = await auth.signInWithEmailAndPassword(email, password);
    return { id: user.uid, displayName: user.displayName, photoUrl: user.photoUrl };
  } catch (error) {
    switch (error.code) {
      case 'auth/user-not-found':
        throw new Error('Email does not exist in our system.');
      case 'auth/wrong-password':
        throw new Error('Password is incorrect.');
      default:
        console.error(error);
        throw new Error('We experienced a system error.');
    }
  }
};

export const logout = () => {
  try {
    return auth.signOut();
  } catch (error) {
    throw new Error(error.message);
  }
};

export const sendPasswordResetEmail = async (email) => {
  try {
    return await auth.sendPasswordResetEmail(email);
  } catch (error) {
    switch (error.code) {
      case 'auth/user-not-found':
        throw new Error('Email does not exist in our system.');
      default:
        throw new Error(error.message);
    }
  }
};

export const getEmailFromPasswordResetToken = async (token) => {
  try {
    return await auth.verifyPasswordResetCode(token);
  } catch (error) {
    switch (error.code) {
      case 'auth/invalid-action-code':
        throw new Error('Your reset password link has expired.');
      default:
        throw new Error(error.message);
    }
  }
};

export const getEmailFromRecoverEmailToken = async (token) => {
  try {
    const info = await auth.checkActionCode(token);
    return info['data']['email'];
  } catch (error) {
    switch (error.code) {
      case 'auth/invalid-action-code':
        throw new Error('Your recover email link has expired.');
      default:
        throw new Error(error.message);
    }
  }
};

export const resetPassword = async (token, password) => {
  try {
    return await auth.confirmPasswordReset(token, password);
  } catch (error) {
    switch (error.code) {
      case 'auth/invalid-action-code':
        throw new Error('Your reset password link has expired.');
      default:
        throw new Error(error.message);
    }
  }
};

export const restoreEmail = async (token) => {
  try {
    return await auth.applyActionCode(token);
  } catch (error) {
    switch (error.code) {
      case 'auth/invalid-action-code':
        throw new Error('Your recover email link has expired.');
      default:
        throw new Error(error.message);
    }
  }
};

export const updateEmail = (email) => {
  return auth.currentUser.updateEmail(email);
};

export const updatePassword = (password) => {
  return auth.currentUser.updatePassword(password);
};
