export var normalizeCampaign = function normalizeCampaign() {
  var campaign = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var sourceTransform = function sourceTransform(value) {
    var transformations = {
      Adwords: 'adwords'
    };
    return transformations[value] || value;
  };
  var mediumTransform = function mediumTransform(value) {
    var transformations = {
      Search: 'ppc',
      cpc: 'ppc',
      CPC: 'ppc',
      ppch: 'ppc'
    };
    return transformations[value] || value;
  };
  var normalized = {};
  normalized.campaign = typeof campaign.campaign === 'string' && campaign.campaign !== 'unknown' ? campaign.campaign.trim() : '';
  normalized.source = typeof campaign.source === 'string' && campaign.source !== 'unknown' ? sourceTransform(campaign.source.trim()) : '';
  normalized.medium = typeof campaign.medium === 'string' && campaign.medium !== 'unknown' ? mediumTransform(campaign.medium.trim()) : '';
  normalized.term = typeof campaign.term === 'string' && campaign.term !== 'unknown' ? campaign.term.trim() : '';
  normalized.content = typeof campaign.content === 'string' && campaign.content !== 'unknown' ? campaign.content.trim() : '';
  return normalized;
};