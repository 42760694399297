import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import ViewContainer from './ViewContainer';
import ViewTitle from './ViewTitle';

const CompleteView = React.memo(({ exportIdentityEmail, importIdentityEmail, onComplete, onRestart }) => (
  <ViewContainer>
    <ViewTitle>Success!</ViewTitle>

    <Box mb={5}>
      You have successfully merged{' '}
      <Text
        sx={{
          display: 'inline',
          fontWeight: 'bold',
        }}
      >
        {exportIdentityEmail}
      </Text>{' '}
      into your{' '}
      <Text
        sx={{
          display: 'inline',
          fontWeight: 'bold',
        }}
      >
        {importIdentityEmail}
      </Text>{' '}
      Paperstac Identity.
    </Box>

    <Box mb={3}>
      <Button variant="primary" block onClick={onComplete}>
        Done
      </Button>
    </Box>
    <Box>
      <Button onClick={onRestart} variant="default" block>
        Merge Another Identity
      </Button>
    </Box>
  </ViewContainer>
));

CompleteView.propTypes = {};

CompleteView.defaultProps = {};

CompleteView.displayName = 'CompleteView';

export default CompleteView;
