import useBreakpoints from '@paperstac/ui/lib/hooks/useBreakpoints';
import { FOR_SALE_ARCHIVE } from '@paperstac/routes/lib/webRoutes';
import Box from '@paperstac/ui/lib/Box';
import Button from '@paperstac/ui/lib/Button';
import Container from '@paperstac/ui/lib/Container';
import Flex from '@paperstac/ui/lib/Flex';
import Heading from '@paperstac/ui/lib/Heading';
import Text from '@paperstac/ui/lib/Text';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { background, bottom, display, left, space, position, right, top } from 'styled-system';

const Wrapper = styled(Box)`
  ${background}
  ${space}
  ${position}
`;

const Content = styled(Flex)`
  ${position}
  ${top}
  ${right}
  ${bottom}
  ${left}
`;

const ButtonWrapper = styled(Box)`
  ${display}
`;

const HeroSection = React.memo((props) => {
  const { isMobile } = useBreakpoints();

  return (
    <Wrapper {...props}>
      <Content position={['static', 'absolute']} top={0} right={0} bottom={0} left={0} alignItems="center">
        <Container>
          <Heading
            as="h1"
            fontSize={[17, 24, 30, 48, 64]}
            fontWeight={[700, 700, 700, 800]}
            mt={[5, 0]}
            mb={[3, 3, 3, 22, 4]}
            textAlign={['center', 'left']}
            color="white"
            style={{ textShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)' }}
          >
            The Modern Way to Buy and Sell
            <br />
            Mortgage Notes Online
          </Heading>
          <Text fontSize={[14, 14, 16, 20, 24, 4]} mb={[3, 3, 3, 26, 4]} textAlign={['center', 'left']} color="white">
            Join the fastest-growing note marketplace and upgrade {!isMobile && <br />} your investing experience with
            our online closings.
          </Text>
          <Flex mb={[5, 0]} justifyContent={['center', 'flex-start']}>
            <ButtonWrapper display={['flex', 'flex', 'flex', 'none']}>
              <Button
                as={Link}
                to={FOR_SALE_ARCHIVE}
                variant="primary"
                style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)' }}
              >
                View Assets For Sale
              </Button>
            </ButtonWrapper>
            <ButtonWrapper display={['none', 'none', 'none', 'flex']}>
              <Button
                as={Link}
                to={FOR_SALE_ARCHIVE}
                variant="primary"
                size="large"
                style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)' }}
              >
                View Assets For Sale
              </Button>
            </ButtonWrapper>
          </Flex>
        </Container>
      </Content>
    </Wrapper>
  );
});

HeroSection.propTypes = {};

HeroSection.defaultProps = {
  background: [
    'linear-gradient(110.83deg, #47A3CD 5.6%, #98CBE2 90.5%)',
    'center / cover #72B4D5 url(img/homepage-hero.svg)',
  ],
  pt: ['0', '41%', '41%', '41%', '36%'],
  position: 'relative',
};

HeroSection.displayName = 'HeroSection';

export default HeroSection;
