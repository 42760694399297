import React from 'react';
import CenteredContentLayout from './CenteredContentLayout';
import DelayComponent from './DelayComponent';
import Spinner from './Spinner';

export default ({ title, delay }) => (
  <DelayComponent delay>
    <CenteredContentLayout title={title}>
      <Spinner />
    </CenteredContentLayout>
  </DelayComponent>
);
