import * as routes from '@paperstac/routes/lib/webRoutes';
import loadable from 'loadable-components';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import blogPosts from './components/blogPosts';
import FrozenPage from './components/FrozenPage';
import ForSaleArchivePage from './components/pages/ForSalePage/ForSalePage';
import HomePage from './components/pages/HomePage/HomePage';
import RefreshWebsiteOverlay from './components/RefreshWebsiteOverlay';
import ScrollToTop from './components/ScrollToTop';
import withTracker from './components/withTracker';
import { BLOG_POSTS_SINGLE, getPath } from './routes';

const options = { ErrorComponent: RefreshWebsiteOverlay };

const Async = {
  AboutUsPage: loadable(() => import('./components/pages/AboutUsPage/AboutUsPage'), options),
  AcceptTeamInvitePage: loadable(() => import('./components/AcceptTeamInvitePage'), options),
  AccountStatementsPage: loadable(
    () => import('./components/pages/AccountStatementsPage/AccountStatementsPage'),
    options
  ),
  ActionPage: loadable(() => import('./components/pages/ActionPage/ActionPage'), options),
  AdminCampaignsPage: loadable(() => import('./components/AdminCampaignsPage'), options),
  AdminAccountsPage: loadable(() => import('./components/pages/AdminAccountsPage/AdminAccountsPage'), options),
  AdminDatabasePage: loadable(() => import('./components/pages/AdminDatabasePage/AdminDatabasePage'), options),
  AdminDeveloperPage: loadable(() => import('./components/pages/AdminDeveloperPage/AdminDeveloperPage'), options),
  AdminEscrowOrdersPage: loadable(
    () => import('./components/pages/AdminEscrowOrdersPage/AdminEscrowOrdersPage'),
    options
  ),
  AdminGenerateDocumentsPage: loadable(
    () => import('./components/pages/AdminGenerateDocumentsPage/AdminGenerateDocumentsPage'),
    options
  ),
  AdminIdentitiesPage: loadable(() => import('./components/pages/AdminIdentitiesPage/AdminIdentitiesPage'), options),
  AdminJobsPage: loadable(() => import('./components/pages/AdminJobsPage/AdminJobsPage'), options),
  AdminPppPage: loadable(() => import('./components/pages/AdminPppPage/AdminPppPage'), options),
  AdminModerateEventsPage: loadable(() => import('./components/AdminModerateEventsPage'), options),
  AdminRestorePointsPage: loadable(() => import('./components/AdminRestorePointsPage'), options),
  AdminSellerOnboardingPage: loadable(
    () => import('./components/pages/AdminSellerOnboarding/AdminSellerOnboardingPage'),
    options
  ),
  AdminSettingsPage: loadable(() => import('./components/pages/AdminSettingsPage/AdminSettingsPage'), options),
  AdminShortUrlsPage: loadable(() => import('./components/AdminShortUrlsPage/AdminShortUrlsPage'), options),
  AdminSensitivityReviewPage: loadable(() => import('./components/AdminSensitivityReviewPage'), options),
  AdminReportsPage: loadable(() => import('./components/pages/AdminReportsPage/AdminReportsPage'), options),
  AdminTasksPage: loadable(() => import('./components/pages/AdminTasksPage/AdminTasksPage'), options),
  AdminTransactionsPage: loadable(
    () => import('./components/pages/AdminTransactionsPage/AdminTransactionsPage'),
    options
  ),
  AdminVerifyIdentityPage: loadable(
    () => import('./components/pages/AdminVerifyIdentityPage/AdminVerifyIdentityPage'),
    options
  ),
  AuthActionsPage: loadable(() => import('./components/pages/AuthActionsPage/AuthActionsPage'), options),
  AuditOrdersArchivePage: loadable(() => import('./components/AuditOrdersArchivePage'), options),
  AuditOrdersSinglePage: loadable(() => import('./components/AuditOrdersSinglePage'), options),
  BankAccountsPage: loadable(() => import('./components/BankAccountsPage'), options),
  BlockedAccountsPage: loadable(() => import('./components/pages/BlockedAccountsPage/BlockedAccountsPage'), options),
  BlogPostsArchivePage: loadable(() => import('./components/pages/BlogPostsArchivePage/BlogPostsArchivePage'), options),
  BlogPostsSinglePage: loadable(() => import('./components/pages/BlogPostsSinglePage/BlogPostsSinglePage'), options),
  BlogTagsSinglePage: loadable(() => import('./components/pages/BlogTagsSinglePage/BlogTagsSinglePage'), options),
  DartPage: loadable(() => import('./components/pages/DartPage/DartPage'), options),
  MyCommunitiesArchivePage: loadable(() => import('./components/MyCommunitiesArchivePage'), options),
  EscrowAccountBalancePage: loadable(() => import('./components/EscrowAccountBalancePage'), options),
  EscrowOrdersArchivePage: loadable(() => import('./components/EscrowOrdersArchivePage'), options),
  EscrowOrdersSinglePage: loadable(() => import('./components/EscrowOrdersSinglePage'), options),
  ForgotPasswordPage: loadable(() => import('./components/pages/ForgotPasswordPage/ForgotPasswordPage'), options),
  ForSaleSinglePage: loadable(() => import('./components/pages/ListingPage/ListingPage'), options),
  FrozenPage: loadable(() => import('./components/FrozenPage'), options),
  ImportDetailPage: loadable(() => import('./components/ImportDetailPage'), options),
  IntegrationsPage: loadable(() => import('./components/pages/IntegrationsPage/IntegrationsPage'), options),
  InvestPage: loadable(() => import('./components/pages/InvestPage/InvestPage'), options),
  InvestInPaperstacPage: loadable(
    () => import('./components/pages/InvestInPaperstacPage/InvestInPaperstacPage'),
    options
  ),
  LoginPage: loadable(() => import('./components/pages/LoginPage/LoginPage'), options),
  ManageAccountPage: loadable(() => import('./components/pages/ManageAccountPage/ManageAccountPage'), options),
  ManageCommunityPage: loadable(() => import('./components/ManageCommunityPage'), options),
  ManageCommunityInvitesPage: loadable(() => import('./components/ManageCommunityInvitesPage'), options),
  MyFeedPage: loadable(() => import('./components/pages/MyFeedPage/MyFeedPage'), options),
  MyImportsPage: loadable(() => import('./components/MyImportsPage'), options),
  MyListingsPage: loadable(() => import('./components/pages/MyListingsPage/MyListingsPage'), options),
  MyNotesPage: loadable(() => import('./components/MyNotesPage'), options),
  NoteschoolPage: loadable(() => import('./components/pages/NoteschoolPage/NoteschoolPage'), options),
  NotFoundPage: loadable(() => import('./components/pages/NotFoundPage/NotFoundPage'), options),
  NotificationPreferencesPage: loadable(
    () => import('./components/pages/NotificationPreferencesPage/NotificationPreferencesPage'),
    options
  ),
  PaymentMethodsPage: loadable(() => import('./components/pages/PaymentMethodsPage/PaymentMethodsPage'), options),
  PasswordResetPage: loadable(() => import('./components/pages/PasswordResetPage/PasswordResetPage'), options),
  PrivacyPolicyPage: loadable(() => import('./components/PrivacyPolicyPage'), options),
  RegisterPage: loadable(() => import('./components/pages/RegisterPage/RegisterPage'), options),
  SdiraIntegrationsPage: loadable(
    () => import('./components/pages/SdiraIntegrationsPage/SdiraIntegrationsPage'),
    options
  ),
  SellerOnboardingPage: loadable(() => import('./components/SellerOnboardingPage'), options),
  ServicerProfilesPage: loadable(() => import('./components/pages/ServicerProfilesPage/ServicerProfilesPage'), options),
  ShippingAddressesPage: loadable(
    () => import('./components/pages/ShippingAddressesPage/ShippingAddressesPage'),
    options
  ),
  SignaturePadPage: loadable(() => import('./components/pages/SignaturePadPage/SignaturePadPage'), options),
  TeamsPage: loadable(() => import('./components/TeamsPage'), options),
  TermsPage: loadable(() => import('./components/TermsPage'), options),
  TransactionsArchivePage: loadable(() => import('./components/pages/TransactionsPage/TransactionsPage'), options),
  TransactionsSinglePage: loadable(() => import('./components/pages/TransactionPage/TransactionPage'), options),
  UserActionsPage: loadable(() => import('./components/UserActionsPage'), options),
  VestingProfilesPage: loadable(() => import('./components/pages/VestingProfilesPage/VestingProfilesPage'), options),
};

const PaperstacRoutes = React.memo((props) => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route exact path={routes.ABOUT} component={withTracker(Async.AboutUsPage)} />
          <Route exact path={routes.ACCEPT_TEAM_INVITE} component={withTracker(Async.AcceptTeamInvitePage)} />
          <Route exact path={routes.ACCOUNT_STATEMENTS} component={withTracker(Async.AccountStatementsPage)} />
          <Route exact path={routes.ACTION} component={withTracker(Async.ActionPage)} />
          <Route exact path={routes.ADMIN_CAMPAIGNS} component={withTracker(Async.AdminCampaignsPage)} />
          <Route exact path={routes.ADMIN} component={withTracker(Async.AdminSettingsPage)} />
          <Route exact path={routes.ADMIN_IDENTITIES} component={withTracker(Async.AdminIdentitiesPage)} />
          <Route exact path={routes.ADMIN_ACCOUNTS} component={withTracker(Async.AdminAccountsPage)} />
          <Route exact path={routes.ADMIN_DATABASE} component={withTracker(Async.AdminDatabasePage)} />
          <Route exact path={routes.ADMIN_DEVELOPER} component={withTracker(Async.AdminDeveloperPage)} />
          <Route exact path={routes.ADMIN_ESCROW_ORDERS} component={withTracker(Async.AdminEscrowOrdersPage)} />
          <Route
            exact
            path={routes.ADMIN_GENERATE_DOCUMENTS}
            component={withTracker(Async.AdminGenerateDocumentsPage)}
          />
          <Route exact path={routes.ADMIN_JOBS} component={withTracker(Async.AdminJobsPage)} />
          <Route exact path={routes.ADMIN_PPP} component={withTracker(Async.AdminPppPage)} />
          <Route exact path={routes.ADMIN_MODERATE_EVENTS} component={withTracker(Async.AdminModerateEventsPage)} />
          <Route exact path={routes.ADMIN_REPORTS} component={withTracker(Async.AdminReportsPage)} />
          <Route exact path={routes.ADMIN_RESTORE_POINTS} component={withTracker(Async.AdminRestorePointsPage)} />
          <Route
            exact
            path={routes.ADMIN_SENSITIVITY_REVIEW}
            component={withTracker(Async.AdminSensitivityReviewPage)}
          />
          <Route exact path={routes.ADMIN_SELLER_ONBOARDING} component={withTracker(Async.AdminSellerOnboardingPage)} />
          <Route exact path={routes.ADMIN_SETTINGS} component={withTracker(Async.AdminSettingsPage)} />
          <Route exact path={routes.ADMIN_SHORT_URLS} component={withTracker(Async.AdminShortUrlsPage)} />
          <Route exact path={routes.ADMIN_TASKS} component={withTracker(Async.AdminTasksPage)} />
          <Route exact path={routes.ADMIN_TRANSACTIONS} component={withTracker(Async.AdminTransactionsPage)} />
          <Route exact path={routes.ADMIN_VERIFY_IDENTITY} component={withTracker(Async.AdminVerifyIdentityPage)} />
          <Route exact path={routes.AUTH_ACTIONS} component={withTracker(Async.AuthActionsPage)} />
          <Route exact path={routes.AUDIT_ORDERS_ARCHIVE} component={withTracker(Async.AuditOrdersArchivePage)} />
          <Route exact path={routes.AUDIT_ORDERS_SINGLE} component={withTracker(Async.AuditOrdersSinglePage)} />
          <Route exact path={routes.BLOCKED_ACCOUNTS} component={withTracker(Async.BlockedAccountsPage)} />
          <Route exact path={routes.BLOG_POSTS_ARCHIVE} component={withTracker(Async.BlogPostsArchivePage)} />
          <Route exact path={routes.BLOG_POSTS_SINGLE} component={withTracker(Async.BlogPostsSinglePage)} />
          <Route exact path={routes.BLOG_TAGS_SINGLE} component={withTracker(Async.BlogTagsSinglePage)} />
          <Route exact path={routes.DART} component={withTracker(Async.DartPage)} />
          <Route exact path={routes.MY_COMMUNITIES_ARCHIVE} component={withTracker(Async.MyCommunitiesArchivePage)} />
          <Route exact path={routes.ESCROW_ACCOUNT_BALANCE} component={withTracker(Async.EscrowAccountBalancePage)} />
          <Route exact path={routes.ESCROW_ORDERS_ARCHIVE} component={withTracker(Async.EscrowOrdersArchivePage)} />
          <Route exact path={routes.ESCROW_ORDERS_SINGLE} component={withTracker(Async.EscrowOrdersSinglePage)} />
          <Route exact path={routes.FORGOT_PASSWORD} component={withTracker(Async.ForgotPasswordPage)} />
          <Route exact path={routes.FOR_SALE_ARCHIVE} component={withTracker(ForSaleArchivePage)} />
          <Route exact path={routes.FOR_SALE_SINGLE} component={withTracker(Async.ForSaleSinglePage)} />
          <Route exact path={routes.FROZEN} component={withTracker(FrozenPage)} />
          <Route exact path={routes.HOME} component={withTracker(HomePage)} />
          <Route exact path={routes.INTEGRATIONS} component={withTracker(Async.IntegrationsPage)} />
          <Route exact path={routes.INVEST} component={withTracker(Async.InvestPage)} />
          <Route exact path={routes.INVEST_IN_PAPERSTAC} component={withTracker(Async.InvestInPaperstacPage)} />
          <Route exact path={routes.IMPORTS_ARCHIVE} component={withTracker(Async.MyImportsPage)} />
          <Route exact path={routes.IMPORT_SINGLE} component={withTracker(Async.ImportDetailPage)} />
          <Route exact path={routes.LISTINGS_ARCHIVE} component={withTracker(Async.MyListingsPage)} />
          <Route exact path={routes.LOGIN} component={withTracker(Async.LoginPage)} />
          <Route exact path={routes.MANAGE_ACCOUNT} component={withTracker(Async.ManageAccountPage)} />
          <Route exact path={routes.MANAGE_COMMUNITY} component={withTracker(Async.ManageCommunityPage)} />
          <Route
            exact
            path={routes.MANAGE_COMMUNITY_INVITES}
            component={withTracker(Async.ManageCommunityInvitesPage)}
          />
          <Route exact path={routes.MY_FEED} component={withTracker(Async.MyFeedPage)} />
          <Route exact path={routes.NOTESCHOOL} component={withTracker(Async.NoteschoolPage)} />
          <Route exact path={routes.NOTES_ARCHIVE} component={withTracker(Async.MyNotesPage)} />
          <Route
            exact
            path={routes.NOTIFICATION_PREFERENCES}
            component={withTracker(Async.NotificationPreferencesPage)}
          />
          <Route exact path={routes.PAYMENT_METHODS} component={withTracker(Async.PaymentMethodsPage)} />
          <Route exact path={routes.PRIVACY_POLICY} component={withTracker(Async.PrivacyPolicyPage)} />
          <Route exact path={routes.REGISTER} component={withTracker(Async.RegisterPage)} />
          <Route exact path={routes.SDIRA_INTEGRATIONS} component={withTracker(Async.SdiraIntegrationsPage)} />
          <Route exact path={routes.SELLER_ONBOARDING} component={withTracker(Async.SellerOnboardingPage)} />
          <Route exact path={routes.SERVICER_PROFILES_ARCHIVE} component={withTracker(Async.ServicerProfilesPage)} />
          <Route exact path={routes.SHIPPING_ADDRESSES_ARCHIVE} component={withTracker(Async.ShippingAddressesPage)} />
          <Route exact path={routes.SIGNATURE_PAD} component={withTracker(Async.SignaturePadPage)} />
          <Route exact path={routes.TEAMS_ARCHIVE} component={withTracker(Async.TeamsPage)} />
          <Route exact path={routes.TERMS} component={withTracker(Async.TermsPage)} />
          <Route exact path={routes.TRANSACTIONS_ARCHIVE} component={withTracker(Async.TransactionsArchivePage)} />
          <Route exact path={routes.TRANSACTIONS_SINGLE} component={withTracker(Async.TransactionsSinglePage)} />
          <Route exact path={routes.TRANSACTIONS_SINGLE_TAB} component={withTracker(Async.TransactionsSinglePage)} />
          <Route exact path={routes.TRANSACTIONS_SINGLE_NOTE} component={withTracker(Async.TransactionsSinglePage)} />
          <Route exact path={routes.USER_ACTIONS} component={withTracker(Async.UserActionsPage)} />
          <Route exact path={routes.VESTING_PROFILES_ARCHIVE} component={withTracker(Async.VestingProfilesPage)} />
          {Object.keys(blogPosts).map((blogComponentName) => (
            <Route
              key={blogPosts[blogComponentName].metadata.slug}
              exact
              path={getPath(BLOG_POSTS_SINGLE, blogPosts[blogComponentName].metadata.slug)}
              component={withTracker(
                loadable(() => import(`./components/blogPosts/${blogComponentName}/${blogComponentName}`), options)
              )}
            />
          ))}
          <Route component={withTracker(Async.NotFoundPage)} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
});

PaperstacRoutes.propTypes = {};

PaperstacRoutes.defaultProps = {};

PaperstacRoutes.displayName = 'PaperstacRoutes';

export default PaperstacRoutes;
