import Box from '@paperstac/ui/lib/Box';
import Drawer from '@paperstac/ui/lib/Drawer';
import DrawerHeader from '@paperstac/ui/lib/DrawerHeader';
import Overlay from '@paperstac/ui/lib/Overlay';
import React from 'react';
import { IdentityContext } from '@paperstac/common/lib/components/IdentityProvider';
import TitleSubtext from '../AuthOverlay/TitleSubtext';
import { IDENTITY_VERIFICATION_SERVICE } from '@paperstac/firestore-collections/lib/identities';
import ValidateIdentityBlockscore from './ValidateIdentityBlockscore';
import ValidateIdentityStripe from './ValidateIdentityStripe';
import VerifyIdentityView from './VerifyIdentityView';

const VerifyIdentityOverlay = React.memo((props) => {
  const { identity } = React.useContext(IdentityContext);

  return (
    <Overlay onClose={props.onClose}>
      <Drawer width={360}>
        <DrawerHeader text="Verify Identity" onClose={props.onClose} />
        <Box sx={{ overflowY: 'auto', flex: '1 1 auto', p: 4 }}>
          <TitleSubtext>
            We need to verify your personal identity for compliance and to keep Paperstac safe. This data will not be
            publicly visible.
          </TitleSubtext>
          {identity.verificationService === IDENTITY_VERIFICATION_SERVICE.BLOCKSCORE && !identity.isValid && (
            <ValidateIdentityBlockscore />
          )}
          {identity.verificationService === IDENTITY_VERIFICATION_SERVICE.BLOCKSCORE && !!identity.isValid && (
            <VerifyIdentityView />
          )}
          {identity.verificationService === IDENTITY_VERIFICATION_SERVICE.STRIPE && <ValidateIdentityStripe />}
        </Box>
      </Drawer>
    </Overlay>
  );
});

VerifyIdentityOverlay.propTypes = {};

VerifyIdentityOverlay.defaultProps = {};

VerifyIdentityOverlay.displayName = 'VerifyIdentityOverlay';

export default VerifyIdentityOverlay;
