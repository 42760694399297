import React from 'react';
import report from './report';
import Section from './Section';
import StatGrid from './StatGrid';

const Price = React.memo((props) => {
  return (
    <Section title="Sale Prices" subtitle="(per individual asset)" bg="white">
      <StatGrid
        stat1={report.price[0]}
        stat2={report.price[1]}
        stat3={report.price[2]}
        stat4={report.price[3]}
        currency={true}
      />
    </Section>
  );
});

Price.propTypes = {};

Price.defaultProps = {};

Price.displayName = 'AveragePrice';

export default Price;
