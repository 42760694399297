import { getSession, setSessionToken } from '@paperstac/common/lib/services/session';
import { API_ENDPOINT_URL } from '@paperstac/env';
import fetch from 'isomorphic-fetch';
import { auth } from './firebaseClient';

const fetchToken = (resolve, reject, attempts = 0) => {
  const WAIT_BETWEEN_ATTEMPTS_MS = 200;
  const TIMEOUT_ERROR_MS = 10000;
  const ALLOWED_ATTEMPTS = Math.round(TIMEOUT_ERROR_MS / WAIT_BETWEEN_ATTEMPTS_MS);

  if (resolve && auth.currentUser) {
    resolve(auth.currentUser.getIdToken(true));
  } else if (resolve) {
    if (attempts > ALLOWED_ATTEMPTS) {
      reject(new Error('Your session is no longer active. Please sign in again.'));
    }
    setTimeout(() => fetchToken(resolve, reject, ++attempts), WAIT_BETWEEN_ATTEMPTS_MS);
  } else {
    return new Promise((resolve, reject) => {
      fetchToken(resolve, reject);
    });
  }
};

const getHeaders = async () => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (getSession().token) {
    const token = await fetchToken();
    setSessionToken(token);
    headers.Authorization = `Bearer ${token}`;
  }
  if (getSession().currentAccountId) {
    headers['X-Paperstac-Account-ID'] = getSession().currentAccountId;
  }
  return headers;
};

export const get = async (path) => {
  const response = await fetch(`${API_ENDPOINT_URL}/${path}`, {
    method: 'GET',
    credentials: 'include',
    headers: await getHeaders(),
  });
  if (response.status === 204) {
    return null;
  }
  if (response.status === 500) {
    const err = new Error('We experienced a system error');
    err.status = 500;
    throw err;
  }
  const json = await response.json();
  if (!response.ok) {
    const err = new Error(json.message);
    err.status = response.status;
    throw err;
  }
  return json;
};

export const post = async (path, payload) => {
  const response = await fetch(`${API_ENDPOINT_URL}/${path}`, {
    method: 'POST',
    credentials: 'include',
    headers: await getHeaders(),
    body: JSON.stringify(payload),
  });
  if (response.status === 204) {
    return null;
  }
  const json = await response.json();
  if (!response.ok) {
    throw new Error(json.message);
  }
  return json;
};

export const patch = async (path, payload) => {
  const response = await fetch(`${API_ENDPOINT_URL}/${path}`, {
    method: 'PATCH',
    credentials: 'include',
    headers: await getHeaders(),
    body: JSON.stringify(payload),
  });
  if (response.status === 204) {
    return null;
  }
  const json = await response.json();
  if (!response.ok) {
    throw new Error(json.message);
  }
  return json;
};

export const destroy = async (path) => {
  const response = await fetch(`${API_ENDPOINT_URL}/${path}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: await getHeaders(),
  });
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.message);
  }
};
