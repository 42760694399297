import { BASE_URL } from '@paperstac/env';
import getPath from '@paperstac/routes/lib/getPath';
import { BLOG_POSTS_SINGLE } from '@paperstac/routes/lib/webRoutes';
import React from 'react';
import FacebookComments from '../../common/FacebookComments';
import BlogPostLayout from '../../layouts/BlogPostLayout';
import DealOverview from './DealOverview';
import HeroSection from './HeroSection';
import IntroSection from './IntroSection';
import Itb from './Itb';
import Itv from './Itv';
import Price from './Price';
import Section from './Section';

const slug = '2021-market-report';
export const metadata = {
  postTitle: "Paperstac's 2021 Market Report",
  metaTitle: "Paperstac's 2021 Market Report",
  metaDescription:
    'The 2021 End of the Year Market Report provides an overview on the current trends in the mortgage note market. The report presents a detailed analysis of the market based on sales numbers & other interesting factors.',
  slug,
  url: `${BASE_URL}${getPath(BLOG_POSTS_SINGLE, slug)}`,
  publishDate: '2022-02-09 08:00',
  featureImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1643752955/website-assets/2021-market-report-featured.png',
  socialImageUrl:
    'https://res.cloudinary.com/paperstac/image/upload/v1643752955/website-assets/2021-market-report-social.png',
  tags: ['report'],
};

const MarketReport2021 = React.memo(() => {
  return (
    <BlogPostLayout metadata={metadata}>
      <HeroSection postTitle={metadata.postTitle} />
      <IntroSection />
      <Itb />
      <Itv />
      <Price />
      <DealOverview />
      <Section title="Comments" subtitle="Let us know what you think...">
        <FacebookComments url={metadata.url} />
      </Section>
    </BlogPostLayout>
  );
});

MarketReport2021.propTypes = {};

MarketReport2021.defaultProps = {};

MarketReport2021.displayName = 'MarketReport2021';

export default MarketReport2021;
