import debounce from 'lodash/debounce';
import { firestore } from '../services/firebaseClient';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';

export const CREATE_NOTE_PHOTO_REQUEST = 'CREATE_NOTE_PHOTO_REQUEST';
export const DELETE_NOTE_PHOTO_REQUEST = 'DELETE_NOTE_PHOTO_REQUEST';
export const SET_NOTE_PHOTOS = 'SET_NOTE_PHOTOS';
export const SUBSCRIBE_NOTE_PHOTOS = 'SUBSCRIBE_NOTE_PHOTOS';
export const UNSUBSCRIBE_NOTE_PHOTOS = 'UNSUBSCRIBE_NOTE_PHOTOS';
export const UPDATE_NOTE_PHOTO_REQUEST = 'UPDATE_NOTE_PHOTO_REQUEST';

let unsubscribe = null;

const transformNotePhotos = (querySnapshot) => {
  return querySnapshot.docs.map(transformFirestoreTimestamps);
};

export const debouncedSetNotePhotos = debounce((dispatch, noteId, items) => {
  // We debounce because this is called rapidly when updating sort order,
  // and it can cause visual ordering glitches if items aren't updated in exact order.
  dispatch({ type: SET_NOTE_PHOTOS, payload: { id: noteId, items } });
}, 100);

export const subscribeNotePhotos = (accountId, noteId) => {
  return (dispatch) => {
    if (!unsubscribe) {
      dispatch({ type: SUBSCRIBE_NOTE_PHOTOS, payload: { id: noteId } });
      unsubscribe = firestore
        .collection(`accounts/${accountId}/notes/${noteId}/photos`)
        .orderBy('order')
        .onSnapshot((querySnapshot) => {
          debouncedSetNotePhotos(dispatch, noteId, transformNotePhotos(querySnapshot));
        });
    }
  };
};

export const unsubscribeNotePhotos = (noteId) => {
  return (dispatch) => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      dispatch({ type: UNSUBSCRIBE_NOTE_PHOTOS, payload: { id: noteId } });
    }
  };
};

export const createNotePhotoRequest = (accountId, noteId, id, filePath, order = 9999) => {
  return (dispatch) => {
    const payload = { id, accountId, noteId, filePath, ready: false, order };
    dispatch({ type: CREATE_NOTE_PHOTO_REQUEST, payload });
    return firestore.doc(`accounts/${accountId}/notes/${noteId}/photos/${id}`).set(payload);
  };
};

export const updateNotePhotoRequest = (accountId, noteId, id, payload) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_NOTE_PHOTO_REQUEST, payload });
    return firestore.doc(`accounts/${accountId}/notes/${noteId}/photos/${id}`).update(payload);
  };
};

export const deleteNotePhotoRequest = (accountId, noteId, id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_NOTE_PHOTO_REQUEST, payload: { noteId, id } });
    return firestore.doc(`accounts/${accountId}/notes/${noteId}/photos/${id}`).delete();
  };
};
