import { firestore } from '../services/firebaseClient';
import { SubmissionError } from 'redux-form';
import transformFirestoreTimestamps from '../utils/transformFirestoreTimestamps';
import { addToast } from './toasterActions';
import { patch, post } from '../services/api';
import { getAddressMeta } from '../utils/address';

export const CREATE_SHIPPING_ADDRESS_REQUEST = 'CREATE_SHIPPING_ADDRESS_REQUEST';
export const CREATE_SHIPPING_ADDRESS_SUCCESS = 'CREATE_SHIPPING_ADDRESS_SUCCESS';
export const CREATE_SHIPPING_ADDRESS_FAILURE = 'CREATE_SHIPPING_ADDRESS_FAILURE';
export const DELETE_SHIPPING_ADDRESS_REQUEST = 'DELETE_SHIPPING_ADDRESS_REQUEST';
export const DELETE_SHIPPING_ADDRESS_SUCCESS = 'DELETE_SHIPPING_ADDRESS_SUCCESS';
export const DELETE_SHIPPING_ADDRESS_FAILURE = 'DELETE_SHIPPING_ADDRESS_FAILURE';
export const FETCH_SHIPPING_ADDRESSES_REQUEST = 'FETCH_SHIPPING_ADDRESSES_REQUEST';
export const FETCH_SHIPPING_ADDRESSES_SUCCESS = 'FETCH_SHIPPING_ADDRESSES_SUCCESS';
export const FETCH_SHIPPING_ADDRESSES_FAILURE = 'FETCH_SHIPPING_ADDRESSES_FAILURE';
export const SET_SHIPPING_ADDRESSES = 'SET_SHIPPING_ADDRESSES';
export const SUBSCRIBE_SHIPPING_ADDRESSES = 'SUBSCRIBE_SHIPPING_ADDRESSES';
export const UNSUBSCRIBE_SHIPPING_ADDRESSES = 'UNSUBSCRIBE_SHIPPING_ADDRESSES';
export const UPDATE_SHIPPING_ADDRESS_REQUEST = 'UPDATE_SHIPPING_ADDRESS_REQUEST';
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_SHIPPING_ADDRESS_SUCCESS';
export const UPDATE_SHIPPING_ADDRESS_FAILURE = 'UPDATE_SHIPPING_ADDRESS_FAILURE';

let unsubscribe = null;

const transformQuerySnapshot = (querySnapshot) => {
  return querySnapshot.docs.map((docSnap) => ({
    ...transformFirestoreTimestamps(docSnap),
    ...getAddressMeta(docSnap.data()),
  }));
};

export const setShippingAddresses = (payload) => {
  return { type: SET_SHIPPING_ADDRESSES, payload };
};

export const fetchShippingAddressesRequest = (accountId) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SHIPPING_ADDRESSES_REQUEST });
    return firestore
      .collection(`accounts/${accountId}/shippingAddresses`)
      .get()
      .then((querySnapshot) => {
        dispatch({ type: FETCH_SHIPPING_ADDRESSES_SUCCESS });
        dispatch(setShippingAddresses(transformQuerySnapshot(querySnapshot)));
      })
      .catch((error) => {
        dispatch({ type: FETCH_SHIPPING_ADDRESSES_FAILURE, message: error.message });
      });
  };
};

export const subscribeShippingAddresses = (accountId) => {
  return (dispatch) => {
    if (!unsubscribe) {
      dispatch({ type: SUBSCRIBE_SHIPPING_ADDRESSES, accountId });
      unsubscribe = firestore.collection(`accounts/${accountId}/shippingAddresses`).onSnapshot((querySnapshot) => {
        dispatch(setShippingAddresses(transformQuerySnapshot(querySnapshot)));
      });
    }
  };
};

export const unsubscribeShippingAddresses = () => {
  return (dispatch) => {
    if (unsubscribe) {
      unsubscribe();
      unsubscribe = null;
      dispatch({ type: UNSUBSCRIBE_SHIPPING_ADDRESSES });
    }
  };
};

export const createShippingAddressRequest = (accountId, payload) => {
  return (dispatch) => {
    dispatch({ type: CREATE_SHIPPING_ADDRESS_REQUEST, payload });
    return post('shipping-addresses', payload)
      .then((shippingAddress) => {
        dispatch({ type: CREATE_SHIPPING_ADDRESS_SUCCESS });
        return shippingAddress;
      })
      .catch((error) => {
        dispatch({ type: CREATE_SHIPPING_ADDRESS_FAILURE, message: error.message });
        throw new SubmissionError({ _error: error.message });
      });
  };
};

export const updateShippingAddressRequest = (accountId, payload) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SHIPPING_ADDRESS_REQUEST, payload });
    return patch(`shipping-addresses/${payload.id}`, payload)
      .then(() => dispatch({ type: UPDATE_SHIPPING_ADDRESS_SUCCESS }))
      .catch((error) => {
        dispatch({ type: UPDATE_SHIPPING_ADDRESS_FAILURE, message: error.message });
        throw new SubmissionError({ _error: error.message });
      });
  };
};

export const deleteShippingAddressRequest = (accountId, id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_SHIPPING_ADDRESS_REQUEST, id });
    return firestore
      .doc(`accounts/${accountId}/shippingAddresses/${id}`)
      .delete()
      .then(() => dispatch({ type: DELETE_SHIPPING_ADDRESS_SUCCESS }))
      .catch((error) => {
        dispatch({ type: DELETE_SHIPPING_ADDRESS_FAILURE, message: error.message });
        dispatch(addToast(error.message, null, 'error'));
      });
  };
};
