import Box from '@paperstac/ui/lib/Box';
import Heading from '@paperstac/ui/lib/Heading';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { background } from 'styled-system';

const HeroSection = React.memo(({ postTitle }) => (
  <Hero
    width="100%"
    pt={['102%', '102%', '37%']}
    background={[
      'center / cover url(/img/2021-mid-year-market-report-hero-stacked.svg)',
      'center / cover url(/img/2021-mid-year-market-report-hero-stacked.svg)',
      'center / cover url(/img/2021-mid-year-market-report-hero.svg)',
    ]}
  >
    <Heading as="h1" style={{ position: 'absolute', textIndent: '-9999px' }}>
      {postTitle}
    </Heading>
  </Hero>
));

const Hero = styled(Box)`
  ${background}
`;

HeroSection.propTypes = {
  postTitle: PropTypes.string.isRequired,
};

HeroSection.defaultProps = {};

HeroSection.displayName = 'HeroSection';

export default HeroSection;
