import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/performance';
import 'firebase/compat/storage';
import { setFirebase } from './firebase';
import { setPerformance } from './performance';
export var init = function init(env) {
  if (!firebase.apps.length) {
    var options = {
      apiKey: env.FIREBASE_API_KEY,
      authDomain: env.FIREBASE_AUTH_DOMAIN,
      databaseURL: env.FIREBASE_DATABASE_URL,
      projectId: env.FIREBASE_PROJECT_ID,
      storageBucket: env.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
      appId: env.FIREBASE_APP_ID
    };
    if (env.FIREBASE_MEASURE_ID) options.measurementId = env.FIREBASE_MEASURE_ID;
    firebase.initializeApp(options);
  }
  if (process.env.NODE_ENV !== 'production') {
    var auth = firebase.apps[0].auth();
    auth.useEmulator('http://localhost:9099');
    var db = firebase.apps[0].firestore();
    db.useEmulator('localhost', 8080);
    firebase.apps[0].functions().useEmulator('localhost', 5001);
    var storage = firebase.apps[0].storage();
    storage.useEmulator('localhost', 9199);
  }
  setFirebase(firebase);

  // Initialize Performance Monitoring
  if (navigator && navigator.userAgent !== 'ReactSnap') setPerformance(firebase.performance());

  // Initialize Analytics
  if (env.FIREBASE_MEASURE_ID) firebase.analytics();
};
export default firebase;