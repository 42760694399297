import * as FebruaryFeatureRelease from './FebruaryFeatureRelease/FebruaryFeatureRelease';
import * as HowToBuyMortgageNotesFromASelfDirectedIRA from './HowToBuyMortgageNotesFromASelfDirectedIRA/HowToBuyMortgageNotesFromASelfDirectedIRA';
import * as ItsHereMeetTheImprovedPaperstac from './ItsHereMeetTheImprovedPaperstac/ItsHereMeetTheImprovedPaperstac';
import * as MarketReport2020 from './MarketReport2020/MarketReport2020';
import * as MidYearMarketReport2019 from './MidYearMarketReport2019/MidYearMarketReport2019';
import * as MidYearMarketReport2021 from './MidYearMarketReport2021/MidYearMarketReport2021';
import * as MortgageNoteInvestingMarketNumbersDuringCovid19 from './MortgageNoteInvestingMarketNumbersDuringCovid19/MortgageNoteInvestingMarketNumbersDuringCovid19';
import * as NeverMissADealWithSavedSearches from './NeverMissADealWithSavedSearches/NeverMissADealWithSavedSearches';
import * as PhoneCallFeatureLaunchedAtNoteExpo2018 from './PhoneCallFeatureLaunchedAtNoteExpo2018/PhoneCallFeatureLaunchedAtNoteExpo2018';
import * as SellToYourPrivateNetworkWithPaperstacCommunities from './SellToYourPrivateNetworkWithPaperstacCommunities/SellToYourPrivateNetworkWithPaperstacCommunities';
import * as MarketReport2021 from './MarketReport2021/MarketReport2021';
import * as MidYearMarketReport2022 from './MidYearMarketReport2022/MidYearMarketReport2022';

export default {
  FebruaryFeatureRelease,
  HowToBuyMortgageNotesFromASelfDirectedIRA,
  ItsHereMeetTheImprovedPaperstac,
  MarketReport2020,
  MarketReport2021,
  MidYearMarketReport2019,
  MidYearMarketReport2021,
  MidYearMarketReport2022,
  MortgageNoteInvestingMarketNumbersDuringCovid19,
  NeverMissADealWithSavedSearches,
  PhoneCallFeatureLaunchedAtNoteExpo2018,
  SellToYourPrivateNetworkWithPaperstacCommunities,
};
